﻿

	 import { Moment } from 'moment';
	 import { RequestItemDTO } from '@models/requests/RequestItemDTO';
    
    export class RequestItemFullDTO extends RequestItemDTO {
        
        
        /**
        * Request Information unique identifier
        */
        id?: number | null;
        
        /**
        * Parent Application Request unique identifier
        */
        requestId: number;
        
        /**
        * User that created the entity
        */
        createdBy: string;
        
        /**
        * Date and time of entity creation
        */
        createdOn: Moment;
    }
