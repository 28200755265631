import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (this.authService.isUserAuthenticated()) {
        return true;
      }

      // Not logged in so redirect to login page with the return url set
      //  - except: don't include param if redirect is to /dashboard because that is the default route.
      if (state.url === '/dashboard') {
        this.router.navigate(['login']);
      }
      else {
        this.router.navigate(['login'], { queryParams: { r: state.url } });
      }

      return false;
    }
}
