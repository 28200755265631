import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'EmptyField'
})
export class EmptyFieldPipe implements PipeTransform {

    public transform(value: string = "", useWhenEmpty: string = '-'): string {

      if (!value) {
        return useWhenEmpty;
      }

      return value;
    }
}
