import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AuthenticationGuard } from '@app/core/authentication-guard.service';
import { ClaimsGuard } from '../core/claims.guard';

import { EntityService } from '@app/entity/entity.service';
import { IntegrationsService } from '@app/integrations/integrations.service';

import { SettingsComponent } from './settings.component';
import { RequestSettingsComponent } from './tabs/request-settings/request-settings.component';
import { ChatSettingsComponent } from './tabs/chat-settings/chat-settings.component';
import { AppSettingsComponent } from './tabs/app-settings/app-settings.component';
import { NotificationSettingsComponent } from './tabs/notification-settings/notification-settings.component';
import { BillSettingsComponent } from './tabs/bill-settings/bill-settings.component';
import { VoiceSettingsComponent } from './tabs/voice-settings/voice-settings.component';
import { MembershipSettingsComponent } from './tabs/membership-settings/membership-settings.component';
import { IntegrationSettingsComponent } from './tabs/integration-settings/integration-settings.component';
import { PressreaderSettingsComponent } from './tabs/pressreader-settings/pressreader-settings.component';
import { PosSettingsComponent } from './tabs/pos-settings/pos-settings.component';
import { ActivitiesSettingsComponent } from './tabs/activities-settings/activities-settings.component';
import { MyStaysSettingsComponent } from './tabs/my-stays-settings/my-stays-settings.component';
import { AppearanceSettingsComponent } from './tabs/appearance-settings/appearance-settings.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild([
      {
        path: 'settings',
        data: { title: 'Settings' },
        component: SettingsComponent,
        canActivate: [AuthenticationGuard, ClaimsGuard]
      }
    ])
  ],
  exports: [
    SettingsComponent,
    RequestSettingsComponent,
    ChatSettingsComponent,
    AppSettingsComponent,
    NotificationSettingsComponent,
    BillSettingsComponent,
    VoiceSettingsComponent,
    MembershipSettingsComponent,
    IntegrationSettingsComponent,
    PressreaderSettingsComponent,
    PosSettingsComponent,
    ActivitiesSettingsComponent,
    MyStaysSettingsComponent,
    AppearanceSettingsComponent,
  ],
  declarations: [
    SettingsComponent,
    RequestSettingsComponent,
    ChatSettingsComponent,
    AppSettingsComponent,
    NotificationSettingsComponent,
    BillSettingsComponent,
    VoiceSettingsComponent,
    MembershipSettingsComponent,
    IntegrationSettingsComponent,
    PressreaderSettingsComponent,
    PosSettingsComponent,
    ActivitiesSettingsComponent,
    MyStaysSettingsComponent,
    AppearanceSettingsComponent,
  ],
  providers: [
    EntityService,
    IntegrationsService,
  ],
  entryComponents: [
    RequestSettingsComponent,
    ChatSettingsComponent,
    AppSettingsComponent,
    NotificationSettingsComponent,
    BillSettingsComponent,
    VoiceSettingsComponent,
    MembershipSettingsComponent,
    IntegrationSettingsComponent,
    PressreaderSettingsComponent,
    PosSettingsComponent,
    ActivitiesSettingsComponent,
    MyStaysSettingsComponent,
    AppearanceSettingsComponent,
  ]
})
export class SettingsModule { }
