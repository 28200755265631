﻿


    
    export class HospitalityTypeDTO {
        
        
        /**
        * Unique identifier
        */
        id?: number | null;
        
        /**
        * Hospitality type name
        */
        name: string;
    }
