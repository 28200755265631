﻿

	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    /**
    * 
    */
    
    export class CountrySearchFilter extends SearchFilter {
        
        
        /**
        * Country unique code identifier, ISO 3166-1 alpha2
        */
        isoCode: string;
    }
