import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './core/authentication.service';
import { ElementRef } from '@angular/core';
import { GoogleAnalyticsService } from "@app/core/google-analytics.service";
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ["app.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  @ViewChild('appDimmer') requestsGridContainer: ElementRef;
  // Actions component (chat service state, global and for active users) all Subscriptions
  private subscription = new Subscription();

  public isUserLoggedIn: boolean = false;
  private userInformation: any;
  public username: string;

  constructor(private router: Router, private authenticationService: AuthenticationService, private googleAnalyticsService: GoogleAnalyticsService)
  {
    //when the websocket fail to connect(firewall or proxy) this was save in localstorage and the firebase dont try reconnect again
    localStorage.removeItem('firebase:previous_websocket_failure');

    // Setup G.Analytics
    this.googleAnalyticsService.appendGaTrackingCode(document);
  }

  ngOnInit() {
    this.subscription.add(this.authenticationService
      .isUserAuthenticated$
      .subscribe((status: boolean) => this.isUserLoggedIn = status));

    this.subscription.add(this.authenticationService
      .userInformation$
      .subscribe((info: any) => {
        this.userInformation = info;
      }));

    this.isUserLoggedIn = this.authenticationService.isUserAuthenticated();
    this.userInformation = this.authenticationService.getUserInformation();
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscription.unsubscribe();
  }

  private logout(): void {
    this.authenticationService.logout().subscribe();
  }
}
