﻿

    
    /**
    * Application Screen Views content type enumeration (GENERIC only).  This maps directly to the ViewContentType DB table and IsExpanded column.
    */
    
    export enum ViewMode {
        
        
        Collapsed = "Collapsed",
        
        Expanded = "Expanded"
    }
