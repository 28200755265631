<div #pushNotificationsContainer class="ui basic segment without padding">
  <div *ngIf="showAlertMessageDiv()" class="ui no vertical margins grid">
    <div class="doubling two column vertically unpadded row">
      <div class="vertically unpadded column">
        <div class="ui form">
          <div class="field">
            <label>Alert Message</label>
            <div>
              <div class="ui"><i class=" globe icon "></i>Global (All languages)</div>
              <textarea placeholder="Write a message to your guests"
                        rows="2"
                        maxlength="255"
                        class="fixed size"
                        style="background-color: rgba(151, 175, 152, 0.3); margin-bottom: 10px;"
                        name="pushNotificationTextInput"
                        [(ngModel)]="pushNotificationText">
            </textarea>
            </div>
            <div *ngFor="let language of languages; let i = index">
              <div class="ui"><i class="{{language?.countryIsoCode.replace('-', ' ') | lowercase }} flag"></i>{{language?.englishName}}</div>
              <textarea placeholder="Write a message to your guests"
                        rows="2"
                        maxlength="255"
                        class="fixed size"
                        style="background-color: rgba(151, 175, 152, 0.3); margin-bottom: 10px;"
                        name="pushNotificationTextInput"
                        [(ngModel)]="pushNotifications[i].description">
            </textarea>
            </div>
          </div>
          <div class="field">
            <button class="ui compact tiny primary button"
                    (click)="popup.show()"
                    [disabled]="shouldSendPushBeDisabled()">
              Send Notification
            </button>
            <popup #popup [(configuration)]="popupConfiguration"
                   (onDeny)="onPopupDeny($event)"
                   (onApprove)="onPopupApprove($event)">
            </popup>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showAlertMessageDiv()" class="ui hidden divider"></div>
  <!-- History records area -->
  <h4 class="ui dividing header">History</h4>
  <data-grid #dataGridComponent
             [(configuration)]="dataGridConfiguration"
             [data]="notifications"
             [dataPageSize]="dataPageSize"
             [dataTotalRecords]="dataTotalRecords"
             (dataRowClick)="onDataRowClick($event)"
             (dataPageChange)="onDataGridPagingChange($event)"
             (dataSortingChange)="onDataGridSortingChange($event)">
  </data-grid>

</div>
