import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from './core/token.httpinterceptor';
import { ReactiveFormsModule } from '@angular/forms';
/* Third Party components */
import { ToastrModule } from 'ngx-toastr';
/* - Application Components */
import { AppComponent } from './app.component';
/* Services */
import { AppService } from '@app/app.service';
import { TitleService } from '@app/core/services/title.service';
/* Application Modules */
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { LoginModule } from './authentication/login.module';
import { NavigationModule } from './navigation/navigation.module';
import { RequestModule } from './requests/request.module';
import { ChatModule } from './chat/chat.module';
import { SharedModule } from '@app/shared/shared.module';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { SurveysModule } from '@app/surveys/surveys.module';
import { PushNotificationsModule } from '@app/push-notifications/push-notifications.module';
import { DevicesModule } from '@app/devices/devices.module';
import { SettingsModule } from '@app/settings/settings.module';
import { NotificationsModule } from '@app/notifications/notifications.module';
import { ReportsModule } from '@app/reports/reports.module';
import { UserPreferencesModule } from '@app/user-preferences/user-preferences.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  // Needed for this module:
  imports: [
    // Angular framework items
    BrowserModule,
    ReactiveFormsModule,
    // import HttpClientModule after BrowserModule.
    HttpClientModule,
    FormsModule,
    CommonModule,
    // Animation
    BrowserAnimationsModule, // required animations module
    // App modules
    CoreModule,
    SharedModule,
    LoginModule,
    NavigationModule,
    RequestModule,
    ChatModule,
    DashboardModule,
    SurveysModule,
    PushNotificationsModule,
    DevicesModule,
    SettingsModule,
    NotificationsModule,
    ReportsModule,
    UserPreferencesModule,
    // Third Party components, modules, services, ...
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      onActivateTick: true,
      enableHtml: true,
      extendedTimeOut: 2000
    }), // ToastrModule added
    // App routing modules - AppRouting should be last
    AppRoutingModule,
    DragDropModule
  ],
  // What belongs to this module:
  declarations: [
    AppComponent
  ],
  // What an importing module can use:
  exports: [
  ],
  // Service providers:
  providers: [
    // Services, Guards, ...
    TitleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AppService
  ],
  // Startup components:
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(titleService: TitleService) {
    titleService.init();
  }
}
