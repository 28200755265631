﻿

	 import { ChatUserFullDTO } from '@models/chat/ChatUserFullDTO';
	 import { ApplicationDTO } from '@models/ApplicationDTO';
	 import { EntityDTO } from '@models/entity/EntityDTO';
	 import { ChatUserHistoryRecordDTO } from '@models/chat/ChatUserHistoryRecordDTO';
	 import { RequestTypeDTO } from '@models/requests/RequestTypeDTO';
	 import { ContentDTO } from '@models/contents/ContentDTO';
	 import { RequestState } from '@models/enum/request/RequestState';
	 import { Platform } from '@models/enum/admin/Platform';
	 import { Moment } from 'moment';
	 import { IRequestDTO } from '@models/requests/IRequestDTO';
    
    export class RequestDTO implements IRequestDTO {
        
        
        /**
        * Request unique identifier
        */
        id?: number | null;
        
        /**
        * Request sender
        */
        chatUser: ChatUserFullDTO;
        
        /**
        * Mobile application from which the request came unique identifier (source)
        */
        sourceApplication: ApplicationDTO;
        
        /**
        * The Entity to whom the request is directed to (target)
        */
        targetEntity: EntityDTO;
        
        /**
        * User's history
        */
        historyRecord: ChatUserHistoryRecordDTO;
        
        /**
        * Request type
        */
        type: RequestTypeDTO;
        
        /**
        * Application Request associated content
        */
        associatedContent: ContentDTO;
        
        /**
        * The request current state
        */
        state: RequestState;
        
        /**
        * Platform in which the Request came from
        */
        platform?: Platform | null;
        
        /**
        * Indicates if a Request's details were ever retrieved
        */
        wasRead: boolean;
        
        /**
        * Unique identifier for the user that has first "seen" the Request's details
        */
        firstReadBy?: number | null;
        
        /**
        * Date and time of the first time that the Request's details retrieved and marked as "seen"
        */
        firstReadOn?: Moment | null;
        
        /**
        * Date and time of entity creation
        */
        createdOn: Moment;
        
        /**
        * Date and time of entity update
        */
        updatedOn: Moment;
        
        lastUpdate?: Moment | null;
        
        /**
        * Total count of existing unread notes
        */
        unreadNotes: number;
    }
