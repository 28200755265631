import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core/authentication.service';
import '@app/core/extensions/HttpClient';
import { PagedResult } from '@app/core/PagedResult';
import {
  ChatConversationDTO,
  ChatConversationFullDTO,
  ChatMessageFullDTO,
  ChatUserUpdateStateDTO
} from '@app/models';
import {
  ChatConversationSearchFilter,
  ChatMessageSearchFilter
  } from '@models/search-filters';

import * as moment from "moment/moment";

// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.core + 'chat';

@Injectable()
export class ChatService {
  constructor(private httpClient: HttpClient, private authService: AuthenticationService) { }

  public get(filter: ChatConversationSearchFilter): Observable<PagedResult<ChatConversationDTO[]>> {
    filter.entityId = this.authService.getUserSelectedEntityId();

    return this.httpClient
      .getPagedResult<ChatConversationDTO[]>(API_BASE_URL, filter)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getByIdentifier(identifier: string): Observable<ChatConversationFullDTO> {
    return this.httpClient
      .get<ChatConversationFullDTO>(API_BASE_URL + '/' + identifier)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getMessages(conversationId: string, filter: ChatMessageSearchFilter): Observable<PagedResult<ChatMessageFullDTO[]>> {
    if (!filter) {
      filter = new ChatMessageSearchFilter();
    }

    if (!conversationId) {
      return throwError('Missing or invalid parameters');
    }

    return this.httpClient
      .getPagedResult<ChatMessageFullDTO[]>(API_BASE_URL + '/' + conversationId + '/messages', filter)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateState(conversationId: string, updateStateDto: ChatUserUpdateStateDTO): Observable<ChatConversationFullDTO> {
    return this.httpClient
      .patch<ChatConversationFullDTO>(API_BASE_URL + '/' + conversationId + '/state', updateStateDto)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public addMessage(conversationId: string, msg: ChatMessageFullDTO): Observable<HttpResponse<ChatMessageFullDTO>> {
    return this.httpClient
      .post<ChatMessageFullDTO>(API_BASE_URL + '/' + conversationId + '/messages', msg, { observe: 'response' })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public markMessageAsRead(conversationId: string, fromDate: moment.Moment): Observable<void> {
    const httpOptions =  new HttpHeaders({ 'Content-Type':  'application/json' });

    return this.httpClient
      .patch<void>(API_BASE_URL + '/' + conversationId + '/messages/read-before', "\""+moment(fromDate).utc().toJSON()+"\"", { headers: httpOptions })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getMessagesCheckRead(conversationId: string, messagesIds: number[]): Observable<number[]> {
    return this.httpClient
      .post<number[]>(API_BASE_URL + '/' + conversationId + '/messages/checkread', messagesIds)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }
}
