﻿

    
    export enum GameOperationStatus {
        
        
        Success = "Success",
        
        Error = "Error",
        
        InvalidGameId = "InvalidGameId",
        
        NameMissing = "NameMissing",
        
        StartDateMissing = "StartDateMissing",
        
        EndDateMissing = "EndDateMissing",
        
        DateIncorrectFormat = "DateIncorrectFormat",
        
        CreditsMissing = "CreditsMissing",
        
        ExtraCreditsMissing = "ExtraCreditsMissing",
        
        CreditsInvalid = "CreditsInvalid",
        
        ExtraCreditsInvalid = "ExtraCreditsInvalid",
        
        NumberOfPointsMissing = "NumberOfPointsMissing",
        
        NumberOfPointsIncorrectFormat = "NumberOfPointsIncorrectFormat",
        
        FormValid = "FormValid",
        
        NumberOfPointsDifferentFromGeneratedPoints = "NumberOfPointsDifferentFromGeneratedPoints",
        
        MoreThanOneFileSpecified = "MoreThanOneFileSpecified",
        
        CreditsHigherThanNumberOfPoints = "CreditsHigherThanNumberOfPoints",
        
        RadiusInvalid = "RadiusInvalid",
        
        NMaxPointsReturnedInvalid = "NMaxPointsReturnedInvalid",
        
        EndDateMinorThatTodayDate = "EndDateMinorThatTodayDate"
    }
