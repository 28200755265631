﻿


    
    /**
    * Data transfer object for a Request note creation
    */
    
    export class RequestNoteCreateDTO {
        
        
        /**
        * Note text
        */
        text: string;
    }
