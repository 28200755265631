import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from "@app/core/authentication.service";
import { StatisticsService } from '@app/statistics/statistics.service';
import { ChartConfiguration } from 'chart.js';
import { ChartComponent } from "@app/shared";

@Component({
  selector: 'rating-average-entities',
  templateUrl: 'rating-average-entities.component.html',
  styleUrls: ['rating-average-entities.component.css']
})
/** rating-average-entities component*/
export class RatingAverageEntitiesComponent implements OnInit, OnDestroy {

  // All Subscriptions that should be disposed on destroy
  private rxSubscription = new Subscription();

  public chartData: ChartConfiguration;

  @ViewChild(ChartComponent) chart: ChartComponent;

  /** rating-average ctor */
  constructor(private statisticsService: StatisticsService, private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.init();
    this.rxSubscription.add(this.authService.selectedEntity$.subscribe((entity) => {
      this.getChartsInformation();
    }));
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  private init() {
    this.chartData = {
      type: "horizontalBar",
      data: {
        labels: [],
        datasets: [
          {
            label: "Rating average 7 days",
            data: []
          },
          {
            label: "Rating average 30 days",
            data: []
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
           // align: 'left',
            anchor: 'end',
            clamp: true,
            font: {
              weight: 'bold'
            }
          }
        }
      }
    };
  }

  public getChartsInformation() {

    this.chart.isDimmed = true;
    this.rxSubscription.add(this.statisticsService.getSurveyAveragePerDatepartAllEntities().pipe(finalize(() => this.chart.isDimmed = false)).subscribe((data) => {

      let newLabels = [];
      let newDataSet7Days = [];
      let newDataSet30Days = [];
      let newDataSet3Month = [];

      data.forEach((row) => {
        newLabels.push(row.name);
        newDataSet7Days.push(row.average7Days);
        newDataSet30Days.push(row.average30Days);
        newDataSet3Month.push(row.average3Month);
      });

      // we need to chance the full obj because the angular references detected changes or implements ngDoCheck hook 
      this.chartData.data.datasets = [
        {
          label: "Rating average 7 days",
          data: newDataSet7Days
        },
        {
          label: "Rating average 30 days",
          data: newDataSet30Days
        },
        {
          label: "Rating average 3 months",
          data: newDataSet3Month
        }
      ];

      this.chartData.data.labels = newLabels;
    }));

  }
}
