﻿

	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    /**
    * 
    */
    
    export class GenericContentSearchFilter extends SearchFilter {
        
        
        id?: number | null;
    }
