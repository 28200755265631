import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppConfigurationDTO } from '@app/models';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: "bill-settings",
  templateUrl: "./bill-settings.component.html",
  styleUrls: ["./bill-settings.component.css"],
})
export class BillSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("componentDimmer") componentDimmer: ElementRef;

  private fetchingComponentData: boolean;
  public appConfigurationDto: AppConfigurationDTO;
  private rxSubscription = new Subscription();

  constructor(
    private entityService: EntityService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.getAppConfigurations();
  }

  public getAppConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .getAppConfigurations()
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((appConfig) => {
            this.appConfigurationDto = appConfig;
          })
      );
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  public save() {
    this.setComponentDimmerVisibility(true);
    this.entityService
      .updateAppConfigurations(this.appConfigurationDto)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
      .subscribe((appConfig) => {
        this.appConfigurationDto = appConfig;
      });
  }

  public onCheckboxChange(value: boolean) {
    this.save();
  }

  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }
}
