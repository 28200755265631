export class CurrencyListingDTO {
    /**
    * Currency unique identifier.
    */
    id: number;
    
    /**
    * Currency name
    */
    name: string;
    
    /**
    * Currency ISO 4217 code 
    */
    code: string;
    
    /**
    * Currency symbol, see https://transferwise.com/gb/blog/world-currency-symbols for more information
    */
    symbol: string;
}