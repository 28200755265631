import { Injectable, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MessagingUserState } from '@models/enum';

@Injectable()
export class ChatDetailActionsService implements OnInit {
  private dropDownSelectedValue = new Subject<MessagingUserState>();
  private dropDownSilentlySelectedValue = new Subject<MessagingUserState>();
  private chatUserState = new Subject<MessagingUserState>();
  private dropDownLoadingState = new Subject<boolean>();

  ngOnInit(): void {
  }

  /* General Audience --- UI/UX */
  
  public getDropDownSelectedValue(): Observable<MessagingUserState> {
    return this.dropDownSelectedValue.asObservable();
  }

  public getDropDownSilentlySelectedValue(): Observable<MessagingUserState> {
    return this.dropDownSilentlySelectedValue.asObservable();
  }
  
  public setDropDownSelectedValue(state: MessagingUserState, silently: boolean = false) {
    if (!silently) {
      this.dropDownSelectedValue.next(state);
    }
    else {
      this.dropDownSilentlySelectedValue.next(state);
    }
  }

  public getDropDownLoadingState(): Observable<boolean> {
    return this.dropDownLoadingState.asObservable();
  }

  public setDropDownLoadingState(isLoading: boolean) {
    this.dropDownLoadingState.next(isLoading);
  }

  /* General Audience --- Actions */

  public getChatUserState(): Observable<MessagingUserState> {
    return this.chatUserState.asObservable();
  }

  public setChatUserState(state: MessagingUserState) {
    this.chatUserState.next(state);
  }

}
