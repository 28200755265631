import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
import {
  AppHeaderService, BreadcrumbSection, TabsComponent
} from '@app/shared';
import { Subscription } from 'rxjs';

import { AppSettingsComponent } from './tabs/app-settings/app-settings.component';
import { ChatSettingsComponent } from "./tabs/chat-settings/chat-settings.component";
import { RequestSettingsComponent } from "./tabs/request-settings/request-settings.component";
import { NotificationSettingsComponent } from "./tabs/notification-settings/notification-settings.component";
import { BillSettingsComponent } from "./tabs/bill-settings/bill-settings.component";
import { VoiceSettingsComponent } from "./tabs/voice-settings/voice-settings.component";
import { MembershipSettingsComponent } from "./tabs/membership-settings/membership-settings.component";
import { IntegrationSettingsComponent } from "./tabs/integration-settings/integration-settings.component";
import { PressreaderSettingsComponent } from './tabs/pressreader-settings/pressreader-settings.component';
import { PosSettingsComponent } from './tabs/pos-settings/pos-settings.component';
import { ActivitiesSettingsComponent } from './tabs/activities-settings/activities-settings.component';
import { MyStaysSettingsComponent } from './tabs/my-stays-settings/my-stays-settings.component';
import { AppearanceSettingsComponent } from './tabs/appearance-settings/appearance-settings.component';

@Component({
  selector: 'settings',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.css']
})
/** settings component*/
export class SettingsComponent implements OnInit, OnDestroy {

  @ViewChild(TabsComponent) tabsComponent: TabsComponent;

  // All Subscriptions that should be disposed on destroy
  private rxSubscription = new Subscription();

  /** settings ctor */
  constructor(private authService: AuthenticationService,
    private appHeaderService: AppHeaderService) {
    this.appHeaderService.resetBreadcrumb(new BreadcrumbSection('Settings', ['/settings']));
  }

  ngOnInit(): void {
    this.rxSubscription.add(this.authService.selectedEntity$.subscribe((entity) => {
      this.initTabs();
    }));
  }

  initTabs() {
    this.tabsComponent.reset();

    this.tabsComponent.addTab({
      title: 'App',
      component: AppSettingsComponent,
      isVisible: this.appTabShouldBeVisible()
    });

    this.tabsComponent.addTab({
      title: 'Appearance',
      component: AppearanceSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'Chat',
      component: ChatSettingsComponent,
      isVisible: this.showTabs()
    });
    
    this.tabsComponent.addTab({
      title: 'Request',
      component: RequestSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'Notification',
      component: NotificationSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'Bill',
      component: BillSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'Voice',
      component: VoiceSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'Membership',
      component: MembershipSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'Integrations',
      component: IntegrationSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'PressReader',
      component: PressreaderSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'POS',
      component: PosSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'Activities',
      component: ActivitiesSettingsComponent,
      isVisible: this.showTabs()
    });

    this.tabsComponent.addTab({
      title: 'My Stays',
      component: MyStaysSettingsComponent,
      isVisible: this.showTabs()
    });
  }

  public showTabs(): boolean {
    if (this.authService.userIsAdminOrManager() && !this.authService.getUserSelectedEntity()) {
      return false;
    }

    return true;
  }

  public appTabShouldBeVisible(): boolean {
    return this.authService.userIsAdminOrManager() && this.authService.getUserSelectedEntity() != null;
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

}
