﻿


    
    /**
    * Defines a simple representation of a geography point
    */
    
    export class GeoPointDTO {
        
        
        /**
        * Latitude value
        */
        latitude: number;
        
        /**
        * Longitude value
        */
        longitude: number;
        
        /**
        * Spatial reference identifier.  The SRID corresponds to a spatial reference system based on the specific  ellipsoid used for either flat-earth mapping or round-earth mapping.
        */
        srid: number;
    }
