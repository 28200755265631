<div #devicesGridContainer class="ui basic blurring dimmable segment without padding">
  <div class="ui form">
    <div class="fields">
      <div class="two wide field">
        <label>Device</label>
        <sui-input [placeholder]="'Device name'"
                   [(inputValue)]="searchFilter.deviceName"
                   (keydown.enter)="applyDataFilter()">
        </sui-input>
      </div>
      <div class="two wide field">
        <label>Assigned</label>
        <sui-input [placeholder]="'Assigned name'"
                   [(inputValue)]="searchFilter.assignment"
                   (keydown.enter)="applyDataFilter()">
        </sui-input>
      </div>
      <div class="three wide field">
        <label>Guest name</label>
        <sui-input [placeholder]="'Guest name'"
                   [(inputValue)]="searchFilter.guestName"
                   (keydown.enter)="applyDataFilter()">
        </sui-input>
      </div>

      <div class="three wide field">
        <label>Status</label>
        <div #DdStates class="ui selection dropdown multiple">
          <input type="hidden" name="all">
          <i class="dropdown icon"></i>
          <div class="default text">Status</div>
          <div class="menu">
            <div class="item" *ngFor="let status of devStatusList" [attr.data-value]="status">{{status}}</div>
          </div>
        </div>
      </div>

      <div class="two wide field">
        <label></label>
        <div (click)="applyDataFilter()" class="ui primary button">Search</div>
      </div>

    </div>
  </div>

  <data-grid #dataGridComponent
             [(configuration)]="gridConfiguration"
             [data]="guestuPhones"
             (dataSortingChange)="onDataGridSortingChange($event)">
  </data-grid>

  <openlayers #openlayersComponent></openlayers>

  <popup [(configuration)]="checkOutUpdatePopupConfiguration" (onClose)="onCheckOutDateUpdatePopupDeny($event)" (onDeny)="onCheckOutDateUpdatePopupDeny($event)" (onApprove)="onCheckOutDateUpdatePopupApprove($event)"></popup>
  <popup [(configuration)]="checkInPopupConfiguration" (onClose)="onCheckInPopupDeny()"></popup>
</div>
