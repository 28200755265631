/**
* Data transfer object for pressreader configuration access lifetime
*/
import { nanoid } from 'nanoid';

export class PressReaderConfigurationAccessLifetimeDTO {

  _nanoid: string = nanoid();

  id: number | null;

  min: string | null;

  max: string | null;

  duration: string | null;

  constructor(min: string = null, max: string = null, duration: string = null) {
    this.min = min;
    this.max = max;
    this.duration = duration;
  }
}
