import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { NotificationsService } from '@app/notifications/notifications.service';
import { AuthenticationService } from '@app/core/authentication.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    CoreModule
  ],
  exports:[
  ],
  declarations: [
  ],
  providers: [
    NotificationsService,
    AuthenticationService
  ],
  entryComponents: [
  ]
})
export class NotificationsModule {}
