<sui-dropdown
  [defaultText]="placeholder"
  [collection]="languages"
  [(selectedValue)]="selectedLanguage"
  (valueChange)="onValueChange($event)"
  [nameResolver]="languageNameResolver"
  [itemIconResolver]="languageIconResolver"
  [observable]="getDataObservable"
  [loadDataImmediately]="loadDataImmediately"
  [itemType]="dropdownItemType">
</sui-dropdown>
