﻿

	 import { EntityDTO } from '@models/entity/EntityDTO';
    
    export class ApplicationDTO {
        
        
        id?: number | null;
        
        name: string;
        
        entity: EntityDTO;
    }
