import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { finalize } from 'rxjs/operators';
import {
  NotificationConfigurationDTO, DatesDTO,
  AppLayout, EntityType
} from '@app/models';

@Component({
  selector: 'notification-settings',
  templateUrl: 'notification-settings.component.html',
  styleUrls: ['notification-settings.component.css']
})
/** notification-settings component*/
export class NotificationSettingsComponent  implements OnInit, OnDestroy {

  public notificationConfigDto: NotificationConfigurationDTO;
  // > Dimmers
  @ViewChild('componentDimmer') componentDimmer: ElementRef;
  private fetchingComponentData: boolean;

  // All Subscriptions that should be disposed on destroy
  private rxSubscription = new Subscription();

  /** notification-settings ctor */
  constructor(private entityService: EntityService, private authService: AuthenticationService) {
    
  }

  ngOnInit(): void {
    this.notificationConfigDto = new NotificationConfigurationDTO();
    this.getNotificationConfigurations();
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  public getNotificationConfigurations() {

    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(this.entityService.getNotificationConfigurations()
        .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((config) => {
          if (config) {
            this.notificationConfigDto = config;
          } else {
            this.notificationConfigDto = new NotificationConfigurationDTO();
          }
        }));
    }
  }

  public onCheckboxChange(value: boolean) {
    this.save();
  }

  public save() {
    this.setComponentDimmerVisibility(true);

    this.entityService.updateNotificationConfigurations(this.notificationConfigDto)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((config) => {
        this.notificationConfigDto = config;
      });
  }

  // Component 
  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement =
      $(this.componentDimmer.nativeElement)
        .dimmer({
          duration: { show: 400, hide: 800 }
        }
        );

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer('show');
    } else {
      dimmerElement.dimmer('hide');
      this.fetchingComponentData = false;
    }
  }
}
