﻿


    
    export class RequestTypeListingDTO {
        
        
        /**
        * Given name
        */
        id?: number | null;
        
        /**
        * Given name
        */
        name: string;
        
        /**
        * Representative image file relative path.
        */
        icon: string;
    }
