﻿

    
    export enum ScreenSize {
        
        
        Unknown = "Unknown",
        
        Inch3_5 = "Inch3_5",
        
        Inch4 = "Inch4"
    }
