﻿

	 import { MessagingUserState } from '@models/enum/chat/MessagingUserState';
	 import { Moment } from 'moment';
	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    export class ChatConversationSearchFilter extends SearchFilter {
        
        
        /**
        * Entity unique identifier
        */
        entityId?: number | null;
        
        /**
        * User unique identifier filter value
        */
        chatUserId?: number | null;
        
        /**
        * User name filter value
        */
        userName: string;
        
        /**
        * User contact filter value
        */
        userContact: string;
        
        /**
        * Messaging User state
        */
        state: MessagingUserState[];
        
        /**
        * Message time change filter value
        */
        lastMessageUpdatedOn?: Moment | null;
    }
