﻿


    
    export class LocationDTO {
        
        
        /**
        * Entity unique identifier.
        */
        id?: number | null;
        
        /**
        * Entity name.
        */
        name: string;
        
        /**
        * Location's representative point latitude value
        */
        latitude?: number | null;
        
        /**
        * Location's representative point longitude value
        */
        longitude?: number | null;
    }
