﻿

    
    export enum AuditActionType {
        
        
        Create = "Create",
        
        Read = "Read",
        
        Update = "Update",
        
        Delete = "Delete"
    }
