import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { MyStaysSearchMethodDTO } from '@models/listings';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ListingsService } from '@app/listings/listings.service';
import { MyStaysConfigurationDTO } from '@models/my-stays';
import { NotifierService } from '@app/core/notifications/notifier.service';

@Component({
  selector: 'my-stays-settings',
  templateUrl: './my-stays-settings.component.html',
  styleUrls: ['./my-stays-settings.component.css']
})
export class MyStaysSettingsComponent implements OnInit, OnDestroy {

  @ViewChild("componentDimmer") componentDimmer: ElementRef;

  public searchMethodsList: MyStaysSearchMethodDTO[];
  public configurationDTO: MyStaysConfigurationDTO;
  public availableSearchMethodsList: MyStaysSearchMethodDTO[] = []; 
  public selectedSearchMethodsList: MyStaysSearchMethodDTO[] = [];
  public defaultSearchMethodName: string = null;
  private rxSubscription: Subscription = new Subscription();
  private fetchingComponentData: boolean;
  private minSelectedSearchMethods: number = 1;

  constructor(
    private authService: AuthenticationService,
    private entityService: EntityService,
    private listingsService: ListingsService,
    private notifierService: NotifierService
  ) { }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  private load() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.listingsService.getMyStaysSearchMethods()
          .pipe(
            finalize(() => {
              this.setComponentDimmerVisibility(false);
              this.getMyStaysConfigurations();
            })
          )
          .subscribe((data) => {
            this.searchMethodsList = data;
            if (this.searchMethodsList) {
              this.defaultSearchMethodName = this.searchMethodsList[0].name;
            }
          })
      );
    }
  }

  private getMyStaysConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService.getMyStaysConfigurations()
          .pipe(
            finalize(() => {
              this.setComponentDimmerVisibility(false);
            })
          )
          .subscribe((data) => {
            this.configurationDTO = data;
            if (this.configurationDTO && this.configurationDTO.searchMethods && this.searchMethodsList) {
              let available = this.searchMethodsList;
              let selected = this.configurationDTO.searchMethods.split(',');
              for (let i = 0; i < selected.length; i++) {
                let available_index = -1;
                for (let j = 0; j < available.length; j++) {
                  if (selected[i] == available[j].code) {
                    available_index = j;
                    break;
                  }
                }
                if (available_index > -1) {
                  this.selectedSearchMethodsList.push(available[available_index]);
                  available.splice(available_index, 1);
                }
              }
              this.availableSearchMethodsList = available;
            }
          })
      );
    }
  }

  save(): void {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService.updateMyStaysConfigurations(this.configurationDTO)
          .pipe(
            finalize(() => this.setComponentDimmerVisibility(false))
          )
          .subscribe((data) => {
            this.configurationDTO = data;
          })
      );
    }
  }

  onChangeSelectedItems(values: MyStaysSearchMethodDTO[]) {
    if (values && values.length < this.minSelectedSearchMethods) {
      this.notifierService.warning(`The minimum allowed it\'s ${this.minSelectedSearchMethods} search method!`, 'Operation Not Allowed');
      this.getMyStaysConfigurations();
      return;
    }
    let searchMethods = "";
    values.forEach((row) => {
      searchMethods += row.code + ",";
    })
    this.configurationDTO.searchMethods = searchMethods.slice(0, -1);
    this.save();
  }

  private setComponentDimmerVisibility(visible: boolean): void {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }
}
