﻿

	 import { EntityDTO } from '@models/entity/EntityDTO';
	 import { MobileOS } from '@models/enum/MobileOS';
	 import { RequestItemDTO } from '@models/requests/RequestItemDTO';
	 import { RequestDTO } from '@models/requests/RequestDTO';
    
    export class RequestFullDTO extends RequestDTO {
        
        
        deliveryEntity: EntityDTO;
        
        /**
        * OS of the device where the Request came from
        */
        mobileOS?: MobileOS | null;
        
        /**
        * List of information submitted on the Request as Key/Value pairs
        */
        requestItems: RequestItemDTO[];
        
        /**
        * User that created the entity
        */
        createdBy: string;
        
        /**
        * User that last updated the entity
        */
        updatedBy: string;
    }
