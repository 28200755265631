<div #popupCommand class="ui modal" [ngClass]="configuration.size" style="display: none;">
  <div class="header">{{configuration.header}}</div>
  <div class="content">
    <ng-template viewContainer></ng-template>
    <ng-container *ngIf="configuration.paragraphs" >
    <p *ngFor="let paragraph of configuration.paragraphs">{{paragraph}}</p>
  </ng-container>
  </div>
  <div *ngIf="configuration.showConfirmButton || configuration.showCancelButton" class="actions">
    <div *ngIf="configuration.showCancelButton" (click)="onCancelButtonClick($event)" class="ui negative button">{{configuration.cancelButtonText}}</div>
    <div *ngIf="configuration.showConfirmButton" (click)="onConfirmButtonClick($event)" class="ui positive right labeled icon button">{{configuration.confirmButtonText}}<i class="checkmark icon"></i></div>
  </div>
</div>
