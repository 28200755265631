﻿

	 import { CountryDTO } from '@models/i18n/CountryDTO';
    
    export class ChatUserUpdateProfileDTO {
        
        
        /**
        * Messaging User unique identifier
        */
        chatUserId: number;
        
        /**
        * Identity database unique identifier (the real id for the User)
        */
        userId: number;
        
        /**
        * User's unique generated code (from mobile devices)
        */
        code: string;
        
        /**
        * User's name
        */
        name: string;
        
        /**
        * User's email address
        */
        email: string;
        
        /**
        * User's telephone number
        */
        telephone: string;
        
        /**
        * User's nationality country
        */
        country: CountryDTO;
    }
