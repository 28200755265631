/*
    @Component
        Login form component
    @Description
        Component for user authentication
    @Notes
        -
*/

// # Imports
import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../core/authentication.service';
import { environment } from '@env/environment';

// # Component
@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ["./login.component.css"]
})
export class AuthLoginComponent implements OnInit, OnDestroy {
  @ViewChild('loginForm') loginForm: ElementRef;
  @ViewChild('login-form-container') loginFormContainer: ElementRef;
  public authFormShoudBeVisible: boolean = true;
  private errorMessage: string = null;
  private username: string = null;
  private password: string = null;

  constructor(private authenticationService: AuthenticationService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    if (this.authenticationService.isUserAuthenticated()) {
      this.router.navigate(['']);
    }
  }

  ngOnDestroy() {
  }

  login(): void {
    if (!this.authenticationService.isUserAuthenticated()) {
      $('#login-form-container').dimmer('show');

      this.authenticationService
        .login(this.username, this.password)
        .subscribe(
          (success: any) => this.afterLoginNavigate(success),
          error => this.loginErrorHandle(error)
        );
    }
  }

  logout(): void {
    if (this.authenticationService.isUserAuthenticated()) {
      this.authenticationService
        .logout()
        .subscribe(
          () => this.router.navigate(['login']),
          error => {
            this.logoutErrorHandle(error);
            throw (error);
          }
        );
    }
  }

  private afterLoginNavigate(success: boolean) {
    $('#login-form-container').dimmer('hide');
    this.authFormShoudBeVisible = false;
    this.clearErrorMessage();

    if (success) {
      const redirectUrl: string = this.route.snapshot.queryParams['r'];

      if (redirectUrl) {
        if (environment.dev) {
          console.log('Login done, redirecting to: ' + redirectUrl);
        }

        this.router.navigate([redirectUrl]);
      }
      else {
        this.router.navigate(['']);
      }
    }
  }

  private loginErrorHandle(error: any): void {
    this.loginForm.nativeElement.classList.add('error');

    if (error.status === 401) {
      this.errorMessage = 'Incorrect username or password.';
    } else {
      this.errorMessage = 'Authentication failed.';
    }

    $('#login-form-container').dimmer('hide');

    if (environment.dev) {
      console.log(error);
    }
  }

  private logoutErrorHandle(error: any) {
    if (environment.dev) {
      console.log(error);
    }
  }

  private clearErrorMessage(): void {
    this.loginForm.nativeElement.classList.remove('error');
  }
}
