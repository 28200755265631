  <!-- Info area, grid -->
<div class="ui vertically padded blurring dimmable grid">

  <!--Begin Container-->
  <div class="row">
    <!-- » Guest Info Column -->
    <div class="seven wide column">

      <h4 class="ui top attached header">
        <div><i class="user circle icon"></i>Guest info</div>
      </h4>
      <div class="ui attached segment">
        <div class="ui grid">
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Guest name</div>
              <p>{{conversation?.user?.name | EmptyField}}</p>
            </div>
            <div class="column">
              <div class="ui tiny header">Country</div>
              <p>
                <i class="ui bottom aligned smaller" [ngClass]="getFlagClass()">
                  {{conversation?.user?.country?.name | EmptyField}}
                </i>
              </p>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Check-in</div>
              <p>{{conversation?.checkInDate | CustomDate:true}}</p>
            </div>
            <div class="column">
              <div class="ui tiny header">Check-out</div>
              <p>{{conversation?.checkOutDate | CustomDate:true}}</p>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Email</div>
              <p>{{conversation?.user?.email | EmptyField}}</p>
            </div>
            <div class="column">
              <div class="ui tiny header">Telephone</div>
              <p>{{conversation?.user?.telephone | EmptyField}}</p>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Source</div>
              <p>{{getNameFromPlatform(conversation?.platform) | EmptyField}}</p>
            </div>
            <div class="column">
              <div class="ui tiny header">Property</div>
              <p>{{conversation?.entity?.name | EmptyField}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- chat messages -->
    <div class="nine wide column">
      <div class="chat">

        <h4 class="ui top attached header">
          <div><i class="sticky icon user"></i>Chat with {{conversation?.user?.name}}</div>
        </h4>

        <messaging-container class="chat-history ui attached segment" #messagesContainer
                             [collection]="messages"
                             [showDimmerOnInit]="false"
                             [userNameResolver]="messageUserNameResolver"
                             [dateResolver]="messageDateResolver"
                             [messageResolver]="messageMessageResolver"
                             [isReadResolver]="messageIsReadResolver"
                             [isFromEndResolver]="messageIsFromEndUser"
                             [enableEventScroll]="true"
                             (scrollOnTop)="onScroll($event)">
        </messaging-container>

        <div class="ui attached segment no-top-padding">
          <div class="ui form">
            <div class="field">
              <textarea #newMessageTextArea minlength="1" maxlength="512" class="fixed size" name="message-to-send" id="message-to-send" placeholder="Write a message to your guest" rows="3" [(ngModel)]="newMessageText"></textarea>
            </div>
            <div class="ui grid">
              <div class="column">
                <button class="right floated ui compact tiny primary button" (click)="addMessage()" [disabled]="shouldSendMsgBeDisabled()">
                  Send message
                </button>
              </div>
            </div>
          </div>

        </div>
        <!-- dimmer -->
        <div #messagesAreaDimmer class="ui inverted dimmer">
          <div class="ui loader"></div>
        </div>
        <!-- end chat-message -->
      </div> <!-- end chat -->

    </div>
  </div>

  <!--Finish Container-->
  <!-- dimmer -->
  <div #componentDimmer class="ui inverted dimmer">
    <div class="ui loader"></div>
  </div>

</div>
