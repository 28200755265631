﻿

	 import { ApplicationDTO } from '@models/ApplicationDTO';
	 import { Moment } from 'moment';
	 import { IPushNotificationDTO } from '@models/push-notifications/IPushNotificationDTO';
    
    export class PushNotificationDTO implements IPushNotificationDTO {
        
        
        /**
        * Unique Identifier
        */
        id?: number | null;
        
        application: ApplicationDTO;
        
        description: string;
        
        createdOn: Moment;
        
        createdBy: string;
    }
