import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public authService: AuthenticationService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.withCredentials) {
      let headers: any =
      {
        Authorization: `Bearer ${this.authService.getToken()}`,
        Accept: 'application/json',
      };
      
      let contentType: string = request.headers.get('Content-Type');
      //default contentType
      if (!contentType && !(request.body instanceof FormData)) { 
        headers['Content-Type'] = 'application/json';
      }

      request = request.clone({
        setHeaders: headers
      });
    }

    const redirectUrl = this.router.url;

    return next.handle(request)
      .pipe(
        tap(
          () => { },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              // @401 UnAuthenticated -> Reset session, clean storage, etc, and redirect to login
              if (err.status === 401) {
                if (environment.dev || environment.qa) {
                  console.log('Http Request ERROR (status = ' + err.status + '):');
                  console.log('UnAuthenticated user! Redirecting to login page...');
                }

                this.authService.frontEndLogout(redirectUrl);
              }

              // @403 Forbidden -> No permission/access warning should be the consequence
              if (err.status === 403) {
                if (environment.dev || environment.qa) {
                  console.log('Http Request ERROR (status = ' + err.status + '):');
                  console.log(err.message);
                  console.dir(err);
                }
              }
            }
          }
        )
      );
  }
}
