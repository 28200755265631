﻿

    
    export enum ActionType {
        
        
        Unknown = "Unknown",
        
        Favorites = "Favorites",
        
        FirstTimeInCity = "FirstTimeInCity",
        
        ChatRegistration = "ChatRegistration",
        
        QuestionAnswer = "QuestionAnswer",
        
        Discovery = "Discovery",
        
        HotAndCold = "HotAndCold",
        
        BeforeAndLater = "BeforeAndLater"
    }
