import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnInit {

  private initInputValue: string;
  inputValueLocal: string;
  @Input()
  set inputValue(value: string) {
    this.inputValueLocal = value;
    this.inputValueChange.emit(this.inputValueLocal);
  }  
  get inputValue() {
    return this.inputValueLocal;
  }
  @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChangeValue: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onOpen(value: string): void {
    this.initInputValue = value;
  }

  onClose(value: string): void {
    if (value != this.initInputValue) {
      this.inputValue = value.toUpperCase();
      this.onChangeValue.emit(this.inputValue);
    }
  }

  onChange(event: Event) : void {
    if (!this.inputValue) {
      this.inputValue = '#FFFFFF';
    }
    if (!this.inputValue.startsWith('#')) {
      this.inputValue = `#${this.inputValue}`;
    }
    this.inputValue = this.inputValue.toUpperCase();
    this.onChangeValue.emit(this.inputValue);
  }
}
