<div #messagingContainer (scroll)="onScroll($event)" class="flex-container for columns ui blurring dimmable" style="min-height: 220px; max-height: 50vh; font-size: 0.9em; display: block;">
  <div class="flex-container for columns ui messaging items">
    <div #message class="ui item" *ngFor="let item of collection">
      <div class="content">
        <div class="ui small header">{{resolveUserName(item)}}</div>
        <div class="inline meta">{{resolveDate(item)}}</div>
        <div class="inline meta">
          <span>
            <i class="tiny check icon" [ngClass]="{'blue': resolveIsRead(item), 'grey': !resolveIsRead(item)}"></i>
            <i *ngIf="resolveIsRead(item)" class="blue tiny check icon"></i>
          </span>
        </div>
        <div class="description">
          <div class="messaging baloon" [style.background-color]="resolveIsFromEndUser(item) ? 'rgb(243, 232, 226)' : 'rgba(151, 175, 152, 0.3)'">{{resolveMessage(item)}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- dimmer -->
  <div #dimmer class="ui inverted dimmer">
    <div class="ui loader"></div>
  </div>
</div>
