import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { AuthenticationGuard } from "./authentication-guard.service";
import { TrimmerPipe } from '@app/core/pipes/trimmer.pipe';
import { CustomDatePipe } from '@app/core/pipes/custom-date.pipe';
import { EmptyFieldPipe } from '@app/core/pipes/empty-field.pipe';
import { PersistenceManager } from '@models/persistence/persistence-manager';
import { GoogleAnalyticsService } from "@app/core/google-analytics.service";
import { ErrorsHandler } from '@app/core/errors/errors.handler';
import { ErrorsComponent } from './errors/errors-component/errors.component';
import { ErrorRoutingModule } from '@app/core/errors/errors-routing.module';
import { ErrorsService } from '@app/core/errors/errors.service';
import { NotifierService } from '@app/core/notifications/notifier.service';
import { ServerErrorsInterceptor } from '@app/core/errors/server-errors.httpinterceptor';

@NgModule({
    imports: [
      ErrorRoutingModule
    ],
    exports: [
      TrimmerPipe,
      CustomDatePipe,
      EmptyFieldPipe
    ],
    declarations: [
      TrimmerPipe,
      CustomDatePipe,
      EmptyFieldPipe,
      ErrorsComponent
    ],
    providers: [
      ErrorsService,
      {
        provide: ErrorHandler,
        useClass: ErrorsHandler,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ServerErrorsInterceptor,
        multi: true,
      },
      AuthenticationGuard,
      AuthenticationService,
      NotifierService,
      PersistenceManager,
      GoogleAnalyticsService
    ]
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }

}
