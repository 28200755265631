﻿

	 import { ContentType } from '@models/enum/ContentType';
    
    export class ContentDTO {
        
        
        /**
        * Entity unique identifier.
        */
        id: number;
        
        /**
        * Entity name.
        */
        name: string;
        
        /**
        * Entity content type.
        */
        type: ContentType;
    }
