import {
  Component, AfterViewInit, ViewChild, ElementRef,
  Output, EventEmitter, Input, ViewContainerRef,
  Type, ComponentFactoryResolver, ComponentRef, OnInit
} from '@angular/core';
import { environment } from '@env/environment';
import { ViewContainerDirective } from '@shared/directives/view-container.directive';

@Component({
  selector: 'popup',
  templateUrl: 'popup.component.html',
  styleUrls: ['popup.component.css']
})
/** popup component*/
export class PopupComponent implements OnInit, AfterViewInit {
  /*  Configuration */
  @Input() @Output() public configuration: IPopupConfig;
  @Output() public onDeny: EventEmitter<JQuery<HTMLElement>> = new EventEmitter();
  @Output() public onApprove: EventEmitter<JQuery<HTMLElement>> = new EventEmitter();
  @Output() public onClose: EventEmitter<JQuery<HTMLElement>> = new EventEmitter();

  private isClosed: boolean = true;
  @ViewChild('popupCommand') popupCommand: ElementRef;
  @ViewChild(ViewContainerDirective) contentComponentTemplate: ViewContainerDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    if (this.configuration.component) {
      this.loadContentComponent(this.contentComponentTemplate.viewContainerRef);
    }
  }

  ngAfterViewInit(): void {
    this.initPopup();
  }
  
  private initPopup() {
    $(this.popupCommand.nativeElement).modal({
      autofocus: this.configuration.autofocus,
      closable: this.configuration.closable,
      onHide: (elem) => {
        if (this.isClosed) {
          this.onClose.emit(elem);
        }
        this.isClosed = true;
      },
      onDeny: (elem): false | void => {
        this.isClosed = false;
        this.onDeny.emit(elem);
        if (this.configuration.cancellationCallback) {
          return this.configuration.cancellationCallback();
        }
      },
      onApprove: (elem): false | void => {
        this.isClosed = false;
        this.onApprove.emit(elem);
        if (this.configuration.confirmationCallback) {
          return this.configuration.confirmationCallback();
        }
      }
    });
  }

  public onConfirmButtonClick(event: Event) {
  }

  public onCancelButtonClick(event: Event) {
  }

  public show() {
    $(this.popupCommand.nativeElement).modal("show");
  }

  public hide() {
    $(this.popupCommand.nativeElement).modal("hide");
  }

  /**
   * Loads a component dynamically, by type, on a specific data content
   */
  private loadContentComponent(viewContainerRef: ViewContainerRef) {
    // Get component type factory and component template container (@ViewChildren)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.configuration.component as Type<any>);
    // Clear (if) existing elements on ViewContainer
    viewContainerRef.clear();
    // Creates a component instance
    let componentRef = viewContainerRef.createComponent(componentFactory);

    this.configuration.componentResolver(componentRef);
    // Needed to avoid ExpressionChangedAfterItHasBeenCheckedError error in runtime - force verification at this point
    componentRef.changeDetectorRef.detectChanges();
  }

  public reloadContentComponent() {
    if (!this.configuration.component) {
      if (environment.dev || environment.qa) {
        console.log('Popup component configuration is missing component property. (method: reloadContentComponent())');
      }
      return;
    }

    this.loadContentComponent(this.contentComponentTemplate.viewContainerRef);
  }

}

export enum PopupSize {
  Mini = "mini",
  Tiny = "tiny",
  Small = "small",
  Large = "large"
}

/**
 * Column value component resolver (function) type
 */
export type ContentComponentResolver = (componentRef: ComponentRef<any>) => void;

export type PopupActionCallback = () => false | void;

/**
 * Describes a Popup configuration
 */
export interface IPopupConfig {
  header: string | null;
  paragraphs?: Array<string> | null;
  component?: Type<any>;
  componentResolver?: ContentComponentResolver | null;
  showConfirmButton: boolean | null;
  showCancelButton: boolean | null;
  confirmButtonText: string | null;
  cancelButtonText: string | null;
  confirmationCallback: PopupActionCallback | null;
  cancellationCallback: PopupActionCallback | null;

  /**
  * Setting to false will not allow you to close the modal by clicking on the dimmer
  */
  closable: boolean;

  /**
   * When true, the first form input inside the modal will receive focus when shown. Set this to false to prevent this behavior.
  */
  autofocus: boolean;

  /**
   * Popup size
  */
  size: PopupSize;
}

export class PopupConfig implements IPopupConfig {
  public header: string;
  public paragraphs: Array<string>;
  public component: Type<any>;
  public componentResolver: ContentComponentResolver;
  public showConfirmButton: boolean;
  public showCancelButton: boolean;
  public confirmButtonText: string | null;
  public cancelButtonText: string | null;
  public confirmationCallback: PopupActionCallback;
  public cancellationCallback: PopupActionCallback;

  /**
  * Setting to false will not allow you to close the modal by clicking on the dimmer
  */
  public closable: boolean;

  /**
  * When true, the first form input inside the modal will receive focus when shown. Set this to false to prevent this behavior.
  */
  public autofocus: boolean;

  /**
  * Popup size
  */
  public size: PopupSize;

  constructor(header: string, paragraphs?: Array<string>, component?: Type<any>, componentResolver?: ContentComponentResolver,
    size: PopupSize = PopupSize.Tiny, autofocus: boolean = true, closable: boolean = true,
    showConfirmButton: boolean = true, confirmButtonText: string = 'Confirm',
    showCancelButton: boolean = true, cancelButtonText: string = 'Cancel',
    confirmationCallback?: PopupActionCallback, cancellationCallback?: PopupActionCallback) {
    this.header = header;
    this.paragraphs = paragraphs;
    this.component = component;
    this.componentResolver = componentResolver;
    this.size = size;
    this.closable = closable;
    this.autofocus = autofocus;
    this.showConfirmButton = showConfirmButton;
    this.confirmButtonText = confirmButtonText;
    this.showCancelButton = showCancelButton;
    this.cancelButtonText = cancelButtonText;
    this.confirmationCallback = confirmationCallback;
    this.cancellationCallback = cancellationCallback;
  }
}
