<div class="concierge status ui form">
  <div class="inline fields">
    <!-- chat user State Dropdown -->
    <div class="field">
      <div #chatUserStateDdl class="ui floating pointing dropdown top right tiny compact floated button" [ngClass]="getChatUserStateDdlBgColor()" [class.disabled]="chatUserStatusDdlIsLoading">
        <span class="text"></span>
        <i class="dropdown icon"></i>
        <div class="menu">
          <div class="scrolling menu">
            <div data-value="Open" data-text="Open" data-color="green" class="item">
              <div class="ui tiny green empty circular label"></div>
              Open
            </div>
            <div data-value="Closed" data-text="Closed" data-color="red" class="item">
              <div class="ui tiny red empty circular label"></div>
              Closed
            </div>
          </div>
        </div>
      </div>
    <!--<label>for guests</label>-->
    </div>
  </div>
</div>
