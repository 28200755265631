  <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="configurationDTO">
      
      <div class="sixteen wide column">
        
        <h4 class="ui top attached header">
          <div><i class="eye icon"></i>Settings</div>
        </h4>
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">  
            
            <div class="row" *ngIf="showIfGroupMember()">
              <div class="column">
                <sui-checkbox [(inputValue)]="configurationDTO.overrideGroupAppearanceSettings" 
                              [isSlider]="false"
                              [labelName]="'Override group appearance settings'" 
                              (onChangeValue)="save()">
                </sui-checkbox>
              </div>
            </div>

            <div class="row">
              <div class="column">
                <h3 class="ui block header">
                  Home Screen
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="column ui form">
                <div class="content pr-20 pl-20">
                  <div class="fields" [class.disabled]="disabledGroupAppearanceSettings()">
                    <div class="three wide field">
                      <div class="ui tiny header">Background</div>
                      <color-picker [(inputValue)]="configurationDTO.homeScreenBackgroundColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                    <div class="three wide field" >
                      <div class="ui tiny header">Header</div>
                      <color-picker [(inputValue)]="configurationDTO.homeScreenHeaderColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                    <div class="three wide field" >
                      <div class="ui tiny header">Main</div>
                      <color-picker [(inputValue)]="configurationDTO.homeScreenMainColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                    <div class="three wide field" >
                      <div class="ui tiny header">Secondary</div>
                      <color-picker [(inputValue)]="configurationDTO.homeScreenSecondaryColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                    <div class="three wide field" >
                      <div class="ui tiny header">Main Text</div>
                      <color-picker [(inputValue)]="configurationDTO.homeScreenMainTextColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                  </div>
                </div>
              </div>              
            </div>

            <div class="row">
              <div class="column">
                <h3 class="ui block header">
                  Content Pages
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="column ui form">
                <div class="content pr-20 pl-20">
                  <div class="fields" [class.disabled]="disabledGroupAppearanceSettings()">
                    <div class="three wide field">
                      <div class="ui tiny header">Background</div>
                      <color-picker [(inputValue)]="configurationDTO.contentPageBackgroundColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                    <div class="three wide field" >
                      <div class="ui tiny header">Header</div>
                      <color-picker [(inputValue)]="configurationDTO.contentPageHeaderColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                    <div class="three wide field" >
                      <div class="ui tiny header">Main</div>
                      <color-picker [(inputValue)]="configurationDTO.contentPageMainColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                    <div class="three wide field" >
                      <div class="ui tiny header">Secondary</div>
                      <color-picker [(inputValue)]="configurationDTO.contentPageSecondaryColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                    <div class="three wide field" >
                      <div class="ui tiny header">Main Text</div>
                      <color-picker [(inputValue)]="configurationDTO.contentPageMainTextColor"
                                    (onChangeValue)="save()">
                      </color-picker>
                    </div>
                  </div>
                </div>
              </div>              
            </div>

            <div class="row">
              <div class="column">
                <h3 class="ui block header">
                  Header Font Selector
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="column ui form">
                <div class="content pr-20 pl-20">
                  <div class="fields" [class.disabled]="disabledGroupAppearanceSettings()">
                    <div class="four wide field">
                      <div class="ui tiny header">Header Font Family</div>
                      <fonts-dropdown #headerFontFamilyDropdown defaultText="Select font family" [collection]="fontFamilyList"
                                      [(selectedValue)]="configurationDTO.headerFontFamilyId"
                                      (valueChange)="save()" [observable]="headerFontFamilyDropdownObservable"
                                      [nameResolver]="headerFontFamilyDropdownNameResolver" loadDataImmediately="true"
                                      forceSelectedValueOnStart="true" [showFirstLoading]="false">
                      </fonts-dropdown>
                    </div>
                    <div class="three wide field">
                      <div class="ui tiny header">Header Font Weight</div>
                      <sui-dropdown #headerFontWeightDropdown defaultText="Select font weight" [collection]="fontWeightList"
                                    [(selectedValue)]="configurationDTO.headerFontWeight"
                                    (valueChange)="save()" [observable]="headerFontWeightDropdownObservable"
                                    [nameResolver]="headerFontWeightDropdownNameResolver" loadDataImmediately="true"
                                    forceSelectedValueOnStart="true" [showFirstLoading]="false">
                      </sui-dropdown>
                    </div>
                    <div class="three wide field" >
                      <div class="ui tiny header">Header Font Italic</div>
                      <div class="pt-14">
                        <sui-checkbox [(inputValue)]="configurationDTO.headerFontItalic" 
                                      (onChangeValue)="save()">
                        </sui-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="column">
                <h3 class="ui block header">
                  Body Font Selector
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="column ui form">
                <div class="content pr-20 pl-20">
                  <div class="fields" [class.disabled]="disabledGroupAppearanceSettings()">
                    <div class="four wide field" >
                      <div class="ui tiny header">Body Font Family</div>
                      <fonts-dropdown #bodyFontFamilyDropdown defaultText="Select font family" [collection]="fontFamilyList"
                                      [(selectedValue)]="configurationDTO.bodyFontFamilyId"
                                      (valueChange)="save()" [observable]="bodyFontFamilyDropdownObservable"
                                      [nameResolver]="bodyFontFamilyDropdownNameResolver" loadDataImmediately="true"
                                      forceSelectedValueOnStart="true" [showFirstLoading]="false">
                      </fonts-dropdown>
                    </div>
                    <div class="three wide field">
                      <div class="ui tiny header">Body Font Weight</div>
                      <sui-dropdown #bodyFontWeightDropdown defaultText="Select font weight" [collection]="fontWeightList"
                                    [(selectedValue)]="configurationDTO.bodyFontWeight"
                                    (valueChange)="save()" [observable]="bodyFontWeightDropdownObservable"
                                    [nameResolver]="bodyFontWeightDropdownNameResolver" loadDataImmediately="true"
                                    forceSelectedValueOnStart="true" [showFirstLoading]="false">
                      </sui-dropdown>
                    </div>
                    <div class="three wide field">
                      <div class="ui tiny header">Body Font Italic</div>
                      <div class="pt-14">
                        <sui-checkbox [(inputValue)]="configurationDTO.bodyFontItalic"
                                      (onChangeValue)="save()">
                        </sui-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
  </div>
  
