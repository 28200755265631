﻿


    
    export class NotificationCountersDTO {
        
        
        /**
        * Unread item count on Request's scope
        */
        unreadRequests: number;
        
        /**
        * Active item count on Request's scope
        */
        activeRequests: number;
        
        /**
        * Active item count on Chat/Conversations's scope
        */
        activeConversations: number;
        
        /**
        * Unread item count on Chat/Conversations's scope
        */
        unreadConversations: number;
        
        /**
        * New surveys to read
        */
        newSurveys: number;
    }
