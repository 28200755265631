﻿

    
    export enum PushNotificationTargetView {
        
        
        Home = "Home",
        
        Place = "Place",
        
        Collection = "Collection",
        
        Event = "Event",
        
        Concierge = "Concierge",
        
        Request = "Request",
        
        RequestState = "RequestState"
    }
