﻿

	 import { ISearchFilter } from '@models/search-filters/ISearchFilter';
    
    /**
    * Filtering and ordering core definitions for readable and searchable business entities
    */
    
    export class SearchFilter implements ISearchFilter {
        
        
        /**
        * Page number
        */
        page: number;
        
        /**
        * Number of entities per page
        */
        pageSize: number;
        
        /**
        * Order by expression
        */
        orderBy: string;
        
        /**
        * Language (unique identifier) in which results will be retrieved
        */
        contentLanguage?: number | null;
        
        /**
        * Indicates if results metadata such as total record count should be calculated and returned.
        */
        includeMetadata: boolean;
    }
