﻿

	 import { MobileOS } from '@models/enum/MobileOS';
	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    /**
    * 
    */
    
    export class PushDeviceRegistrationSearchFilter extends SearchFilter {
        
        
        /**
        * Entity unique identifier
        */
        id?: number | null;
        
        /**
        * Registration related Application's unique identifier
        */
        applicationId?: number | null;
        
        /**
        * Mobile device operating system
        */
        mobileOS?: MobileOS | null;
        
        /**
        * Push Notification System registration token for the device.  <remarks>Token can be from GCM or APNS</remarks>
        */
        registrationToken: string;
        
        /**
        * Chat user unique identifier
        */
        chatUserId?: number | null;
        
        /**
        * Language unique identifier
        */
        languageId?: number | null;
        
        /**
        * List of Languages unique identifiers to excluded
        */
        excludedLanguages: number[];
    }
