import { Injectable, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {AppHeaderService} from '@app/shared/app-header/app-header.service';
import { TitleService } from '@app/core/services/title.service';
import { AuthenticationService } from '@app/core/authentication.service';


@Injectable()
export class AppService implements OnInit {

  constructor(private authService: AuthenticationService,
    private appHeaderService: AppHeaderService,
    private titleService: TitleService)
  {
    this.setupWindowTitleManagement();
  }

  public ngOnInit(): void {
  }

  private setupWindowTitleManagement() {
    this.authService.isUserAuthenticated$
      .subscribe(
        (isAuthenticated: boolean) => {
          if (!isAuthenticated) {
            this.titleService.setNotificationsCount(null);
          }
        }
      );
  }

  /*
     App/Page Title
  */
  
  public getTitle(): string {
    return this.titleService.getTitle();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
