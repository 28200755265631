import { Component, Input, ElementRef, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { Chart, ChartType, ChartData, ChartOptions, ChartDataSets, ChartPoint } from 'chart.js';
import  ChartDataLabels  from 'chartjs-plugin-datalabels';

//this is a plugin to calculate the total in bar stacked graphics atm
const totalizer = {
  id: 'totalizer',

  beforeUpdate: (chart) => {
    let totals = {}
    let utmost = 0;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (chart.isDatasetVisible(datasetIndex)) {
        utmost = datasetIndex;
        dataset.data.forEach((value, index) => {
          totals[index] = (totals[index] || 0) + value;
        });
      }
    });

    chart.$totalizer = { totals: totals, utmost: utmost }
  }
}

@Component({
  selector: 'chart',
  templateUrl: 'chart.component.html',
  styleUrls: ['chart.component.css']
})
/** chart component*/
export class ChartComponent implements OnInit, OnChanges, OnDestroy {

  public static defaultColors: Array<number[]> = [
    [255, 99, 132], // Wild Watermelon (Red)
    [54, 162, 235], // Summer Sky (Blue)
    [255, 206, 86], // Mustard (Yellow)
    [75, 192, 192], // Viking (Blue)
    [253, 180, 92], // Texas Rose (Orange)
    [231, 233, 237], // Solitude (Blue)
    [151, 187, 205], // Morning Glory (Blue)
    [220, 220, 220], // Gainsboro (White)
    [247, 70, 74], // Sunset Orange (Orange)
    [70, 191, 189], // Viking (Blue)
    [148, 159, 177], // Rock Blue (Blue)
    [77, 83, 96] // Fiord (Blue)
  ];

  @ViewChild('chartCanvas') chartCanvas: ElementRef;

  private chart: Chart;

  @Input() public type: ChartType | string;
  @Input() public chartData: ChartData;
  @Input() public options: ChartOptions;
  @Input() public colors: Array<any>;
  @Input() public legend: boolean;
  @Input() public datasets: ChartDataSets[];
  @Input() public labels: Array<string | string[]> = [];
  @Input() public data: number[] | any[] | Array<number | null | undefined> | ChartPoint[];
  @Input() public dataLabel: string;

  @Output() public clickCanvas = new EventEmitter();
  @Output() public clickDataSet = new EventEmitter();
  @Output() public clickElements = new EventEmitter();
  @Output() public clickElement = new EventEmitter();
  @Output() public chartClick: EventEmitter<any> = new EventEmitter();
  @Output() public chartHover: EventEmitter<any> = new EventEmitter();

  private sizeHeightLocal: number;
  @Input()
  set sizeHeight(val) {

    this.sizeHeightLocal = val;

    if (this.chart) {
      this.chart.resize();
    }
  }

  get sizeHeight() {
    return this.sizeHeightLocal;
  }


  @Input() public width: number;
  /**
   * Is dimmed active
  */
  public isDimmed: boolean;

  private isDataChanged: boolean;

  constructor(public elementRef: ElementRef, public renderer: Renderer2) { }

  ngOnInit() {

    if (this.chartData) {
      this.chart = new Chart(this.chartCanvas.nativeElement, {
        plugins: [totalizer],
        type: this.type,
        data: this.chartData,
        options: this.options
      });
      return;
    }

    this.refresh();
  }

  public ngOnDestroy(): any {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.chart) {

      this.isDataChanged = true;
      if (changes.hasOwnProperty('data') || changes.hasOwnProperty('chartData') || changes.hasOwnProperty('datasets')) {
        if (changes.hasOwnProperty('labels') || changes.hasOwnProperty('options') || changes.hasOwnProperty('type') || changes.hasOwnProperty('colors') || changes.hasOwnProperty('legend')) {
          this.refresh();
          return;
        }

        if (changes['data']) {
          // check if the number of series has changed
          if (changes['data'].currentValue.length !== changes['data'].previousValue.length) {
            this.refresh();
            return;
          }

          this.updateChartData(changes['data'].currentValue);
        } else if (changes['chartData']) {
          this.updateChartData(changes['chartData'].currentValue);
        } else if (changes['datasets']) {
          // check if the number of series has changed
          if (changes['datasets'].currentValue.length !== changes['datasets'].previousValue.length) {
            this.refresh();
            return;
          }

          this.updateChartData(changes['datasets'].currentValue);
        }

        this.chart.update();
      } else {
        this.refresh();
      }
    }
  }


  public onClick(event: MouseEvent) {
    this.clickCanvas.next(event);
    if (this.clickDataSet.observers.length) {
      this.clickDataSet.next(this.chart.getDatasetAtEvent(event));
    }
    if (this.clickElements.observers.length) {
      this.clickElements.next(this.chart.getElementsAtEvent(event));
    }
    if (this.clickElement.observers.length) {
      this.clickElement.next(this.chart.getElementAtEvent(event));
    }
  }

  public getChartBuilder(ctx: any): any {

    if (this.legend === false) {
      this.options.legend = { display: false };
    }
    // hock for onHover and onClick events
    this.options.hover = this.options.hover || {};
    if (!this.options.hover.onHover) {
      this.options.hover.onHover = (event: any, active: Array<any>) => {
        if (active && !active.length) {
          return null;
        }
        this.chartHover.emit({ active });
      };
    }

    if (!this.options.onClick) {
      this.options.onClick = (event: any, active: Array<any>) => {
        this.chartClick.emit({ event, active });
      };
    }

    if (!this.options.animation) {
      this.options.animation = {
        onProgress: (animation): void => {
          if (this.isDataChanged) {
            this.isDimmed = true;
          }
        },
        onComplete: (animation): void => {
          if (this.isDataChanged) {
            this.isDimmed = this.isDataChanged = false;
          }
        }
      }
    }

    if (!this.options.plugins) {
      Chart.plugins.unregister(<Object>ChartDataLabels);
    }

    let opts = {
      plugins: [totalizer],
      type: this.type,
      data: {
        labels: this.labels,
        datasets: this.getDataSets()
      },
      options: this.options
    };

    return new Chart(ctx, opts);
  }

  private updateChartData(newDataValues: number[] | any[]): void {
    if (Array.isArray(newDataValues[0].data)) {
      this.chart.data.datasets.forEach((dataSet: ChartDataSets, i: number) => {
        dataSet.data = newDataValues[i].data;

        if (newDataValues[i].label) {
          dataSet.label = newDataValues[i].label;
        }
      });
    } else {
      this.chart.data.datasets[0].data = newDataValues;
    }
  }

  public showIfHasData() {
    return this.hasData() || this.isDimmed ? null : true;
  }

  public hasData() : boolean {
    return this.datasets && this.datasets.length > 0 && this.datasets[0].data && this.datasets[0].data.length > 0 || this.isDimmed;
  }

  private getDataSets(): ChartDataSets[] {
    let datasets: ChartDataSets[] = null;

    // in case if datasets is not provided, but data is present
    if (!this.datasets || !this.datasets.length && (this.data && this.data.length)) {

      if (Array.isArray(this.data[0])) {
        datasets = (this.data as Array<number[]>).map<ChartDataSets>((data: number[], index: number) => {
          return { data: data, label: <string>(this.labels[index] || `Label ${index}`) };
        });
      } else {
        datasets = [{ data: this.data, label: this.dataLabel || `Label 0` }];
      }
    }

    if (this.datasets && this.datasets.length || (datasets && datasets.length)) {

      datasets = (this.datasets || datasets).map((elm: ChartDataSets, index: number) => {
        let newElm: ChartDataSets = Object.assign({}, elm);
        if (this.colors && this.colors.length) {
          Object.assign(newElm, this.colors[index]);
        } else {
          Object.assign(newElm, getColors(this.type, index, newElm.data.length));
        }
        return newElm;
      });
    }

    if (!datasets) {
      throw new Error(`ng-charts configuration error,
      data or datasets field are required to render char ${this.type}`);
    }

    return datasets;
  }

  private refresh(): void {
    // todo: remove this line, it is producing flickering
    this.ngOnDestroy();
    //this.chart.clear();
    this.chart = this.getChartBuilder(this.chartCanvas.nativeElement);
    //this.chart.update();
  }

  public getStyle() {
    if (!this.sizeHeight) {
      return {
        'width': '100%',
        'height': '100%'
      }
    }

    return {
      'height': this.sizeHeight + 'px'
    };
  }
}

// private helper functions
export interface Color {
  backgroundColor?: string | string[];
  borderWidth?: number | number[];
  borderColor?: string | string[];
  borderCapStyle?: string;
  borderDash?: number[];
  borderDashOffset?: number;
  borderJoinStyle?: string;

  pointBorderColor?: string | string[];
  pointBackgroundColor?: string | string[];
  pointBorderWidth?: number | number[];

  pointRadius?: number | number[];
  pointHoverRadius?: number | number[];
  pointHitRadius?: number | number[];

  pointHoverBackgroundColor?: string | string[];
  pointHoverBorderColor?: string | string[];
  pointHoverBorderWidth?: number | number[];
  pointStyle?: string | string[];

  hoverBackgroundColor?: string | string[];
  hoverBorderColor?: string | string[];
  hoverBorderWidth?: number;
}

// pie | doughnut
export interface Colors extends Color {
  data?: number[];
  label?: string;
}

function rgba(colour: Array<number>, alpha: number): string {
  return 'rgba(' + colour.concat(alpha).join(',') + ')';
}

function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function formatLineColor(colors: Array<number>): Color {
  return {
    backgroundColor: rgba(colors, 0.4),
    borderColor: rgba(colors, 1),
    pointBackgroundColor: rgba(colors, 1),
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: rgba(colors, 0.8)
  };
}

function formatBarColor(colors: Array<number>): Color {
  return {
    backgroundColor: rgba(colors, 0.6),
    borderColor: rgba(colors, 1),
    hoverBackgroundColor: rgba(colors, 0.8),
    hoverBorderColor: rgba(colors, 1)
  };
}

function formatPieColors(colors: Array<number[]>): Colors {
  return {
    backgroundColor: colors.map((color: number[]) => rgba(color, 0.6)),
    borderColor: colors.map(() => '#fff'),
    pointBackgroundColor: colors.map((color: number[]) => rgba(color, 1)),
    pointBorderColor: colors.map(() => '#fff'),
    pointHoverBackgroundColor: colors.map((color: number[]) => rgba(color, 1)),
    pointHoverBorderColor: colors.map((color: number[]) => rgba(color, 1))
  };
}

function formatPolarAreaColors(colors: Array<number[]>): Color {
  return {
    backgroundColor: colors.map((color: number[]) => rgba(color, 0.6)),
    borderColor: colors.map((color: number[]) => rgba(color, 1)),
    hoverBackgroundColor: colors.map((color: number[]) => rgba(color, 0.8)),
    hoverBorderColor: colors.map((color: number[]) => rgba(color, 1))
  };
}

function getRandomColor(): number[] {
  return [getRandomInt(0, 255), getRandomInt(0, 255), getRandomInt(0, 255)];
}

/**
 * Generate colors for line|bar charts
 * @param index
 * @returns {number[]|Color}
 */
function generateColor(index: number): number[] {
  return ChartComponent.defaultColors[index] || getRandomColor();
}

/**
 * Generate colors for pie|doughnut charts
 * @param count
 * @returns {Colors}
 */
function generateColors(count: number): Array<number[]> {
  let colorsArr: Array<number[]> = new Array(count);
  for (let i = 0; i < count; i++) {
    colorsArr[i] = ChartComponent.defaultColors[i] || getRandomColor();
  }
  return colorsArr;
}

/**
 * Generate colors by chart type
 * @param chartType
 * @param index
 * @param count
 * @returns {Color}
 */
function getColors(chartType: string, index: number, count: number): Color | any {
  if (chartType === 'pie' || chartType === 'doughnut') {
    return formatPieColors(generateColors(count));
  }

  if (chartType === 'polarArea') {
    return formatPolarAreaColors(generateColors(count));
  }

  if (chartType === 'line' || chartType === 'radar') {
    return formatLineColor(generateColor(index));
  }

  if (chartType === 'bar' || chartType === 'horizontalBar') {
    return formatBarColor(generateColor(index));
  }
  return generateColor(index);
}
