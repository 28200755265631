import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorsService } from '@app/core/errors/errors.service';
import * as StackTraceParser from 'error-stack-parser';
import { environment } from '@env/environment';
import { NotifierService } from '@app/core/notifications/notifier.service';


@Injectable()
export class ErrorsHandler implements ErrorHandler {
  // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
  constructor(private injector: Injector, private zone: NgZone) {
  }
  
  public handleError(error: Error | HttpErrorResponse) {
    const notifierService = this.injector.get(NotifierService);
    const errorsService = this.injector.get(ErrorsService);
    const router = this.injector.get(Router);
    
    if (error instanceof HttpErrorResponse) {
      // Server error happened      
      if (!navigator.onLine) {
        // No Internet connection
        return notifierService.error('No internet connection detected', 'Connection Error');
      }

      // Http Error
      // Send the error to the server
      errorsService
        .log(error)
        .subscribe(
          (error: any) => {
             //console.log('Log Error:' + error);
          }
        );

      if (environment.dev) {
        console.log('>> Error');
        console.dir(`${ error.status } - ${ error.message }`);
      }

      // Show notification to the user
      return notifierService.error('An error occurred on our servers while processing your request.', 'Server Error');
    } else {
      // Client Error Happened
      // Send the error to the server and then
      // redirect the user to the page with all the info

      if (environment.dev) {
        console.log('>> Client-side error!');
        console.dir(error);
        throw error;
      }
      
      errorsService
        .log(error)
        .subscribe(
          (errorWithContextInfo: any) => {
            //this.zone.run(() => { router.navigate(['error'], { queryParams: errorWithContextInfo }); });
          }
      );

      return notifierService.error('An error occurred on our web application while processing your request.', 'Client Error');
    }
  }
}
