  <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="appConfigurationDto">
      <!-- left side -->
      <div class="eight wide column">
  
        <h4 class="ui top attached header">
          <div><i class="dollar sign icon"></i>Bill</div>
        </h4>

        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">

            <div class="doubling two column row">

              <div class="column">
                <div class="ui tiny header">Has Express Check-Out</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.hasExpressCheckout"
                              (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>

              <div class="column">
                <div class="ui tiny header">Use Transactions on Express Check-Out</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.useTransactionsOnExpressCheckOut"
                              (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>

            </div>

            <div class="doubling two column row">
              
              <div class="column">
                <div class="ui tiny header">Show Time on Guest Bill</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.showTimeOnGuestBill"
                              (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>

              <div class="column">
                <div class="ui tiny header">Display Filter</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.billDisplayFilter"
                              (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>

            </div>

            <div class="doubling two column row">

              <div class="column">
                <div class="ui tiny header">Window Filter</div>
                <sui-input [placeholder]="'Window Filter'"
                           [(inputValue)]="appConfigurationDto.billWindowFilter"
                           (onChangeComplete)="save()"
                           (keydown.enter)="save()">
                </sui-input>
              </div>
  
              <div class="column">
              </div>

            </div>

            <div class="doubling two column row">
              
              <div class="column">
                <div class="ui tiny header">Only Allow Check-Out on Departure Date</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.onlyAllowCheckOutOnDepartureDate"
                              (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>

              <div class="column">
              </div>

            </div>

          </div>
        </div>
  
      </div>
  
      <!-- right side -->
      <div class="eight wide column">
      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
  </div>
  
  