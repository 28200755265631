﻿

	 import { Moment } from 'moment';
	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    /**
    * 
    */
    
    export class SurveySearchFilter extends SearchFilter {
        
        
        /**
        * Entity unique identifier
        */
        entityId?: number | null;
        
        /**
        * This Date search/filter
        */
        date?: Moment | null;
        
        /**
        * FromDate search/filter
        */
        fromDate?: Moment | null;
        
        /**
        * ToDate search/filter
        */
        toDate?: Moment | null;
        
        /**
        * Indicates if a Survey's details were ever retrieved
        */
        wasRead?: boolean | null;
        
        /**
        * User name filter value
        */
        userName: string;
    }
