﻿

    
    export enum OrderState {
        
        
        Unknown = "Unknown",
        
        Pending = "Pending",
        
        Confirmed = "Confirmed",
        
        Validated = "Validated",
        
        Processing = "Processing",
        
        Canceled = "Canceled",
        
        NotValid = "NotValid",
        
        CanceledByUser = "CanceledByUser",
        
        Delivered = "Delivered",
        
        NotDelivered = "NotDelivered"
    }
