import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ListingsService } from '@app/listings/listings.service';
import { LanguageDTO } from '@models/i18n/LanguageDTO';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'languages-dropdown',
  templateUrl: './languages-dropdown.component.html',
  styleUrls: ['./languages-dropdown.component.css']
})
export class LanguagesDropdownComponent implements OnInit {
  /**
   * Holds the current languages list
   */
  public languages: Array<any>;
  /**
   * Placeholder text
   */
  public readonly placeholder: string = 'Select a language';
  /**
   * Defines which <sui-dropdown> component list item type to render
   */
  public readonly dropdownItemType: string = 'Icon';

  /**
   * Load data as soon as possible or wait until first opening of dropdown list
   */
  @Input() loadDataImmediately: boolean;
  /**
   * Holds the currently selected language
   */
  @Input() public selectedLanguage: LanguageDTO;
  /**
   * Language change event emitter
   */
  @Output() public selectedLanguageChange: EventEmitter<LanguageDTO> = new EventEmitter<LanguageDTO>();

  
  constructor(private listingsService: ListingsService) {
  }

  ngOnInit() {
  }

  
  /**
   * Gets the observable of languages listing
   * @return Countries DTO list
   */
  public getDataObservable = (): Observable<LanguageDTO[]> => {
    return this.listingsService.getLanguages();
  }

  /**
   * Gets the display name from a language object instance
   * @param item Language object instance
   * @return Language name in english
   */
  public languageNameResolver(item: LanguageDTO) {
    return item.englishName;
  }

  /**
   * Gets the display icon from a language object instance
   * @param item Language object instance
   * @return Language ISO code 
   */
  public languageIconResolver(item: LanguageDTO) {
    return item.countryIsoCode.toLowerCase() + ' flag';
  }

  /**
   * Value change handler
   * @param event Event instance and elements { value: (option val.), item: (DTO) }
   * @emits selectedLanguageChange
   */
  public onValueChange(event: any) {
    // store...
    this.selectedLanguage = event.item;
    // & forward
    this.selectedLanguageChange.emit(event.item);
  }

  /**
   * Error handler
   * @param error Error description object
   */
  private errorHandle(error: any): void {
    if (!environment.production) {
      console.log(error);
    }
  }
}
