<!-- Normal Header with dynamic actions component -->
<div class="ui stackable horizontally padded two column grid">
  <!-- » Text/Description -->
  <div class="bottom aligned horizontally unpadded column">
    <sui-breadcrumb #breadcrumb cssClasses="big" [dividerIsIcon]="false" dividerValue="/"></sui-breadcrumb>
  </div>
  <!-- » Actions/Buttons -->
  <div class="bottom aligned horizontally unpadded column">
    <div class="ui basic right aligned segment without padding">
      <ng-template #tpl headerActionsComponent></ng-template>
    </div>
  </div>
</div>
