import { Injectable, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ConciergeServiceState } from '@models/enum';

@Injectable()
export class ConversationListActionsService implements OnInit {
  private forGeneralAudienceDdlSelectedValue = new Subject<ConciergeServiceState>();
  private forGeneralAudienceConciergeState = new Subject<ConciergeServiceState>();
  private forGeneralAudienceDdlLoadingState = new Subject<boolean>();

  private forActiveUsersDdlSelectedValue = new Subject<ConciergeServiceState>();
  private forActiveUsersConciergeState = new Subject<ConciergeServiceState>();
  private forActiveUsersDdlLoadingState = new Subject<boolean>();

  private hidden = new Subject<boolean>();

  ngOnInit(): void {
  }

  /* General Audience --- UI/UX */

  public getConciergeStateSelectedValue(): Observable<ConciergeServiceState> {
    return this.forGeneralAudienceDdlSelectedValue.asObservable();
  }

  public setConciergeStateSelectedValue(state: ConciergeServiceState) {
    this.forGeneralAudienceDdlSelectedValue.next(state);
  }


  public getConciergeDdlLoadingState(): Observable<boolean> {
    return this.forGeneralAudienceDdlLoadingState.asObservable();
  }

  public setConciergeDdlLoadingState(isLoading: boolean) {
    this.forGeneralAudienceDdlLoadingState.next(isLoading);
  }

  /* General Audience --- Actions */

  public getConciergeState(): Observable<ConciergeServiceState> {
    return this.forGeneralAudienceConciergeState.asObservable();
  }

  public setConciergeState(state: ConciergeServiceState) {
    this.forGeneralAudienceConciergeState.next(state);
  }

  public getHidden(): Observable<boolean> {
    return this.hidden.asObservable();
  }

  public setHidden(hidden: boolean) {
    this.hidden.next(hidden);
  }

  /* Active Users/Conversations Audience --- UI/UX */

  public getConciergeForActiveUsersSelectedValue(): Observable<ConciergeServiceState> {
    return this.forActiveUsersDdlSelectedValue.asObservable();
  }

  public setConciergeForActiveUsersSelectedValue(state: ConciergeServiceState) {
    this.forActiveUsersDdlSelectedValue.next(state);
  }


  public getConciergeForActiveUsersDdlLoadingState(): Observable<boolean> {
    return this.forActiveUsersDdlLoadingState.asObservable();
  }

  public setConciergeForActiveUsersLoadingState(isLoading: boolean) {
    this.forActiveUsersDdlLoadingState.next(isLoading);
  }

  /* Active Users/Conversations Audience --- Actions */

  public getConciergeForActiveUsersState(): Observable<ConciergeServiceState> {
    return this.forActiveUsersConciergeState.asObservable();
  }

  public setConciergeForActiveUsersState(state: ConciergeServiceState) {
    this.forActiveUsersConciergeState.next(state);
  }
}
