import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from '@env/environment';


@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  private retries: number = 0;

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.dev) {
      //console.log('ServerErrorsInterceptor: Intercepting... Will retry ' + this.retries + ' times');
    }

    // If the call fails, retry until X times before throwing an error
    return next.handle(request).pipe(retry(this.retries));
  }
}
