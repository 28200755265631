import { Component, OnInit, ComponentFactoryResolver, ViewChild, Type } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderActionsComponentDirective } from '@app/shared/app-header/header-actions-component.directive';
import { AppHeaderService, IBreadcrumbSection } from '@app/shared/app-header/app-header.service';
import { Router, ResolveEnd } from '@angular/router';
import { BreadcrumbComponent } from '@app/shared/sui-components/sui-breadcrumb/sui-breadcrumb.component';

@Component({
  selector: 'app-header',
  templateUrl: 'app-header.component.html',
  styleUrls: ['app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  public actionsComponentType: Type<any>;
  private actionsComponentRef: any;
  @ViewChild(HeaderActionsComponentDirective) topHeaderActions: HeaderActionsComponentDirective;
  @ViewChild(BreadcrumbComponent) breadcrumb: BreadcrumbComponent;

  private headerText: string;
  private subHeaderText: string;
  private hasActionsComponent: boolean = false;
  // Actions component (chat service state, global and for active users) all Subscriptions
  private subscription = new Subscription();

  constructor(private router: Router, private appHeaderService: AppHeaderService, private componentFactoryResolver: ComponentFactoryResolver) {
    this.subscription.add(this.router.events.subscribe((event: any) => {
        if (event instanceof ResolveEnd) {
          this.unloadActionsComponent();
        }
      }));

    this.subscription.add(this.appHeaderService
        .getActionsComponentType()
        .subscribe((type: Type<any>) => {
          this.actionsComponentType = type;
          this.loadActionsComponent();
        }));

    this.subscription.add(this.appHeaderService
        .getBreadcrumbReset()
        .subscribe((section: IBreadcrumbSection) => {
          this.breadcrumb.resetBreadcrumb(section);
        }));

    this.subscription.add(this.appHeaderService
        .getBreadcrumbSectionPush()
        .subscribe((section: IBreadcrumbSection) => this.breadcrumb.pushSection(section)));

    this.subscription.add(this.appHeaderService
        .getBreadcrumbSectionPop()
        .subscribe(() => this.breadcrumb.popSection()));

    this.subscription.add(this.appHeaderService
        .getUnloadActionsComponent()
        .subscribe(() => this.unloadActionsComponent()));
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public loadActionsComponent() {
    if (!this.actionsComponentType) {
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.actionsComponentType);
    const viewContainerRef = this.topHeaderActions.viewContainerRef;
    viewContainerRef.clear();

    this.actionsComponentRef = viewContainerRef.createComponent(componentFactory);
  }

  private unloadActionsComponent() {
    const viewContainerRef = this.topHeaderActions.viewContainerRef;
    viewContainerRef.clear();
  }
}
