﻿

	 import { QuerySearchFilter } from '@models/search-filters/QuerySearchFilter';
    
    export class GroupMembersSearchFilter extends QuerySearchFilter {
        
        
        /**
        * Parent Entity unique identifier.  Should be an Entity of "group" type.
        */
        groupParentId: number;
        
        name: string;
    }
