import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from "@app/core/authentication.service";
import { StatisticsService } from '@app/statistics/statistics.service';
import * as moment from "moment/moment";
import { ChartConfiguration } from 'chart.js';
import {
  ChartComponent
} from "@app/shared";
import {
  MessageTotalAverageDTO
} from "@app/models";

@Component({
  selector: 'chat-average-response',
  templateUrl: 'chat-average-response.component.html',
  styleUrls: ['chat-average-response.component.css']
})
/** chat-average-response component*/
export class ChatAverageResponseComponent implements OnInit, OnDestroy {

  // All Subscriptions that should be disposed on destroy
  private rxSubscription = new Subscription();

  public chartData: ChartConfiguration;

  public chartDataByHour: ChartConfiguration;

  public totalAverageDto: MessageTotalAverageDTO;

  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('chartByHour') chartByHour: ChartComponent;

  @Input() public fromDate: moment.Moment;

  /** rating-average ctor */
  constructor(private statisticsService: StatisticsService, private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.init();
    this.rxSubscription.add(this.authService.selectedEntity$.subscribe((entity) => {
      this.getChartsInformation(this.fromDate);
    }));
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  private init() {
    this.chartData = {
      type: "line",
      data: {
        labels: [],
        datasets: [
          {
            label: "Chat Average response (Minutes)",
            data: []
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            align: 'top',
            anchor: 'end',
            font: {
              weight: 'bold'
            }
          }
        }
      }
    };

    this.chartDataByHour = {
      type: "line",
      data: {
        labels: [],
        datasets: [
          {
            label: "Guest Messages per hour",
            data: []
          },
          {
            label: "Staff Messages per hour",
            data: []
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: true
        },
        plugins: {
          datalabels: {
            align: 'top',
            anchor: 'end',
            font: {
              weight: 'bold'
            }
          }
        }
      }
    };
  }

  public getChartsInformation(date: moment.Moment = moment()) {

    this.chart.isDimmed = true;
    this.chartByHour.isDimmed = true;
    this.rxSubscription.add(this.statisticsService.getAverageMessageResponse(date.utc()).pipe(finalize(() => this.chart.isDimmed = false)).subscribe((data) => {
      let newLabels = [];
      let newDataSets = [];
      let dateFormat: string = 'YYYY/MM/DD';
      //Difference in number of days
      if (moment.utc().diff(moment(date), 'days') > 60) {
        dateFormat = 'YYYY/MM';
      }

      data.forEach((row) => {
        newLabels.push(moment(row.startDate).format(dateFormat));
        newDataSets.push(row.average);
      });
      // we need to chance the full obj because the angular references detected changes or implements ngDoCheck hook 
      this.chartData.data.datasets[0].data = newDataSets;
      this.chartData.data.labels = newLabels;
      this.chart.isDimmed = false;
    }));


    this.rxSubscription.add(this.statisticsService.getTotalMessagesByHour(date.utc()).pipe(finalize(() => this.chartByHour.isDimmed = false)).subscribe((data) => {
      let newLabels = [];
      let msgGuestDataSets = [];
      let msgStaffDataSets = [];

      data.forEach((row) => {
        newLabels.push(row.hour);
        msgGuestDataSets.push(row.messageGuest);
        msgStaffDataSets.push(row.messageStaff);
      });

      // we need to chance the full obj because the angular references detected changes or implements ngDoCheck hook 
      this.chartDataByHour.data.datasets = [
        {
          label: "Guest Messages per hour",
          data: msgGuestDataSets
        },
        {
          label: "Staff Messages per hour",
          data: msgStaffDataSets
        }
      ];
      this.chartDataByHour.data.labels = newLabels;
      this.chartByHour.isDimmed = false;
    }));

    this.rxSubscription.add(this.statisticsService.getMessageResponseTotalAverage(date.utc()).subscribe((data) => {
      this.totalAverageDto = data;
    }));

  }
}
