import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { DevicesListComponent } from './devices-list/devices-list.component';
import { DevicesService } from './devices.service';
import { AuthenticationGuard } from '../core/authentication-guard.service';
import { SharedModule } from '@app/shared/shared.module';
import { NavigationService } from '@app/navigation/navigation.service';
import { environment } from '@env/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { DeviceCheckinFormComponent } from './device-checkin-form/device-checkin-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ClaimsGuard } from '../core/claims.guard';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    RouterModule.forChild([
      {
          path: 'guestu-phones',
          data: { title: 'Phones' },
          component: DevicesListComponent,
          canActivate: [AuthenticationGuard, ClaimsGuard]
      }
    ])
  ],
  declarations: [
    DevicesListComponent,
    DeviceCheckinFormComponent
  ],
  exports: [
    DeviceCheckinFormComponent
  ],
  providers: [
    DevicesService,
    NavigationService
  ],
  entryComponents: [
    DeviceCheckinFormComponent
  ]
})
export class DevicesModule {}
