﻿


    
    export class SimpleBarGraphicDTO {
        
        
        /**
        * Value
        */
        value: number;
        
        /**
        * Name
        */
        name: string;
    }
