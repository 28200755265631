<nav id="app-nav">
  <popup #popup [(configuration)]="popupConfiguration" (onClose)="onPopupDeny($event)" (onDeny)="onPopupDeny($event)" (onApprove)="onPopupApprove($event)"></popup>
  <!-- Minimum Nav -->
  <div id="app-nav-minimum-left" *ngIf="isUserLoggedIn" class="ui top small inverted fixed menu">
    <div class="item">
      <img src="./assets/images/logos/guBlackIconSmall.png" alt="GuestU">
    </div>
    <div class="right menu" *ngIf="isUserLoggedIn">
      <a class="link item">
        <i class="bell icon"></i>
      </a>
      <div class="link item" style="word-wrap: break-word;" [innerHTML]="userInformation != null ? userInformation.display_name : ''"></div>
      <a class="link item" (click)="logout()">
        <i class="sign out icon"></i>
      </a>
    </div>
  </div>
  <!-- Medium Nav -->
  <div id="app-nav-medium-left" class="flex-none flex-container for columns vertical navigation menu ui left fixed inverted vertical labeled mini icon menu">
    <div class="ui padded middle aligned one column centered grid">
      <div class="centered row">
        <div class="center aligned vertically unpadded column">
          <img class="ui small centered image" src="./assets/images/logos/guestu_white_300.png" alt="GuestU"/>
        </div>
      </div>
    </div>
    <!-- Menu Items -->
    <div class="flex-child scroller on vertical overflow navigation links">
      <a class="item" [routerLink]="['/dashboard']" routerLinkActive="active">
        <i class="home icon"></i>Dashboard
      </a>
      <a *ngIf="authService.hasClaimCapability('requests')" class="item" [routerLink]="['/requests']" routerLinkActive="active">
        <i class="concierge bell icon"></i>Requests
        <div style="margin-top: 5px;">
          <div *ngIf="notificationCounters?.unreadRequests > 0" class="ui circular mini label red flag">{{notificationCounters?.unreadRequests}}</div>
          <div *ngIf="notificationCounters?.activeRequests > 0" class="ui circular mini label green flag">{{notificationCounters?.activeRequests}}</div>
        </div>
      </a>
      <a *ngIf="authService.hasClaimCapability('chat')" class="item" [routerLink]="['/chat']" routerLinkActive="active">
        <i class="comments icon"></i>Chat
        <div style="margin-top: 5px;">
          <div *ngIf="notificationCounters?.unreadConversations > 0" class="ui circular mini label red flag">{{notificationCounters?.unreadConversations}}</div>
          <div *ngIf="notificationCounters?.activeConversations > 0" class="ui circular mini label green flag">{{notificationCounters?.activeConversations}}</div>
        </div>
      </a>
      <a *ngIf="authService.hasClaimCapability('guests-notifications')" class="item" [routerLink]="['/guests-notifications']" routerLinkActive="active">
        <i class="announcement icon"></i>Notify Guests
      </a>
      <a *ngIf="authService.hasClaimCapability('guests-feedback')" class="item" [routerLink]="['/guests-feedback']" routerLinkActive="active">
        <i class="comment dots icon"></i>Guests Feedback
        <div style="margin-top: 5px;">
          <div *ngIf="notificationCounters?.newSurveys > 0" class="ui circular mini label red flag">{{notificationCounters?.newSurveys}}</div>
        </div>
      </a>
      <a *ngIf="authService.hasClaimCapability('guestu-phones')" class="item" [routerLink]="['/guestu-phones']" routerLinkActive="active">
        <i class="mobile alternate icon"></i>GuestU Phones
      </a>
      <a *ngIf="authService.hasClaimCapability('reports')" class="item" [routerLink]="['/reports']" routerLinkActive="active">
        <i class="chart line icon"></i>Reports
      </a>
      <a *ngIf="authService.hasClaimCapability('settings')" class="item" [routerLink]="['/settings']" routerLinkActive="active">
        <i class="cog icon"></i>Settings
      </a>
      <a class="item" [routerLink]="['/user-preferences']" routerLinkActive="active">
        <i class="user icon"></i>User Preferences
      </a>
    </div>
    <div class="flex-none bottom aligned flex-container for rows">
      <div class="user info container flex-container for columns">
        <div class="flex-child">
          <div class="nav bar ui inverted horizontal divider">
            <i class="circular user icon"></i>
          </div>
        </div>
        <div class="flex-child">
          <div class="username ui inverted basic center aligned without padding segment" [innerHTML]="userInformation != null ? userInformation.display_name : ''"></div>
        </div>
        <div class="flex-child">
          <div class="entityname ui inverted basic center aligned without padding segment" [class.underlinelink]="showLinkToPopupEntityChange()" (click)="showLinkToPopupEntityChange() && popup.show()" [innerHTML]="getEntityName()"></div>
        </div>
        <div class="ui divider"></div>
        <div class="flex-child">
          <div class="ui inverted basic center aligned segment">
            <a class="white text logout link" href="#" (click)="logout(); false">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Full menu -->
  <div id="app-nav-full-left" *ngIf="isUserLoggedIn" class="flex-container for columns vertical navigation menu ui left fixed inverted vertical small menu">
    <div class="flex-none logo ui padded middle aligned one column centered grid">
      <div class="centered row">
        <div class="center aligned vertically unpadded column">
          <img class="ui small centered image" src="./assets/images/logos/guestu_white_300.png" alt="GuestU"/>
        </div>
      </div>
    </div>
    <!-- Menu Items -->
    <div class="flex-child scroller on vertical overflow navigation links">
      <a class="item" [routerLink]="['/dashboard']" routerLinkActive="active">
        <i class="home icon"></i>Dashboard
      </a>
      <a *ngIf="authService.hasClaimCapability('requests')" class="item" [routerLink]="['/requests']" routerLinkActive="active">
        <i class="concierge bell icon"></i>Requests
        <div *ngIf="notificationCounters?.unreadRequests > 0" class="ui circular mini label red flag">{{truncateNotificationsCounterValue(notificationCounters?.unreadRequests)}}</div>
        <div *ngIf="notificationCounters?.activeRequests > 0" class="ui circular mini label green flag">{{truncateNotificationsCounterValue(notificationCounters?.activeRequests)}}</div>
      </a>
      <a *ngIf="authService.hasClaimCapability('chat')" class="item" [routerLink]="['/chat']" routerLinkActive="active">
        <i class="comments icon"></i>Chat
        <div *ngIf="notificationCounters?.unreadConversations > 0" class="ui circular mini label red flag">{{truncateNotificationsCounterValue(notificationCounters?.unreadConversations)}}</div>
        <div *ngIf="notificationCounters?.activeConversations > 0" class="ui circular mini label green flag">{{truncateNotificationsCounterValue(notificationCounters?.activeConversations)}}</div>
      </a>
      <a *ngIf="authService.hasClaimCapability('guests-notifications')" class="item" [routerLink]="['/guests-notifications']" routerLinkActive="active">
        <i class="announcement icon"></i>Notify Guests
      </a>
      <a *ngIf="authService.hasClaimCapability('guests-feedback')" class="item" [routerLink]="['/guests-feedback']" routerLinkActive="active">
        <i class="comment dots icon"></i>Guests Feedback
        <div *ngIf="notificationCounters?.newSurveys > 0" class="ui circular mini label red flag">{{truncateNotificationsCounterValue(notificationCounters?.newSurveys)}}</div>
      </a>
      <a *ngIf="authService.hasClaimCapability('guestu-phones')" class="item" [routerLink]="['/guestu-phones']" routerLinkActive="active">
        <i class="mobile alternate icon"></i>GuestU Phones
      </a>
      <a *ngIf="authService.hasClaimCapability('reports')" class="item" [routerLink]="['/reports']" routerLinkActive="active">
        <i class="chart line icon"></i>Reports
      </a>
      <a *ngIf="authService.hasClaimCapability('settings')" class="item" [routerLink]="['/settings']" routerLinkActive="active">
        <i class="cog icon"></i>Settings
      </a>
      <a class="item" [routerLink]="['/user-preferences']" routerLinkActive="active">
        <i class="user icon"></i>User Preferences
      </a>
    </div>
    <div class="flex-none bottom aligned flex-container for rows">
      <div class="user info container flex-container for columns">
        <div class="flex-child">
          <div class="nav bar ui inverted horizontal divider">
            <i class="circular user icon"></i>
          </div>
        </div>
        <div class="flex-child">
          <div class="username ui inverted basic center aligned without padding segment" [innerHTML]="userInformation != null ? userInformation.display_name : ''"></div>
        </div>
        <div class="flex-child">
          <div class="entityname ui inverted basic center aligned without padding segment" [class.underlinelink]="showLinkToPopupEntityChange()" (click)="showLinkToPopupEntityChange() && popup.show()" [innerHTML]="getEntityName()"></div>
        </div>
        <div class="ui divider"></div>
        <div class="flex-child">
          <div class="ui inverted basic center aligned segment">
            <a class="white text logout link" href="#" (click)="logout(); false">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
