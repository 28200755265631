﻿

	 import { ChatUserDTO } from '@models/chat/ChatUserDTO';
	 import { LanguageDTO } from '@models/i18n/LanguageDTO';
	 import { PushNotificationDTO } from '@models/push-notifications/PushNotificationDTO';
	 import { IPushNotificationFullDTO } from '@models/push-notifications/IPushNotificationFullDTO';
    
    export class PushNotificationFullDTO extends PushNotificationDTO implements IPushNotificationFullDTO {
        
        
        accommodationId?: number | null;
        
        tripProfileId?: number | null;
        
        locationId?: number | null;
        
        chatUser: ChatUserDTO;
        
        language: LanguageDTO;
    }
