import { ISearchFilter } from '@models/search-filters/ISearchFilter';
import { IEntityDropdownsConfig } from "@app/shared";

export class PersistenceSearchFilter<TS extends ISearchFilter>{

  /**
  * Searchh Filter ref object.
  */
  public searchFilter: TS;

  /**
  * Entity dropdown configuration to save the selected values
  */
  public entityDropdownConfig: IEntityDropdownsConfig;

}
