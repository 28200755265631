export * from "./DashboardDTO";
export * from "./DoubleBarGraphicDTO";
export * from "./MessageTotalAverageDTO";
export * from "./MessageTotalPerHourDTO";
export * from "./RequestTotalAverageDTO";
export * from "./SimpleBarGraphicDTO";
export * from "./StatsSurveyDTO";
export * from "./SurveysAveragePerWeekDTO";
export * from "./SurveysAveragesDTO";
export * from "./TotalAverageDTO";
export * from "./TupleTotalDTO";
