import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '../core/authentication.service';
import {
  ChatConfigurationDTO, EntityDTO, ChatConfigurationUpdateDTO,
  AppConfigurationDTO, MultimediaContentDTO, LanguageDTO,
  DeviceDTO, NotificationConfigurationDTO, VoiceConfigurationDTO, RequestConfigurationDTO,
  FullMembershipConfigurationDTO,
  PressReaderConfigurationDTO,
  PosConfigurationDTO, ActivitiesConfigurationDTO, MyStaysConfigurationDTO,
  AppearanceConfigurationDTO
} from '@app/models';
import {
  ClosedOlderThan
} from '@models/enum';
import { IntegrationConfigurationDTO } from '@models/integrations';
import { PressReaderConfigurationAccessLifetimeDTO } from '@models/pressreader/PressReaderConfigurationAccessLifetimeDTO';
import { ChatSettingsDTO } from '@models/chat/ChatSettingsDTO';

// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.core + 'entities';

@Injectable()
export class EntityService {
  constructor(private httpClient: HttpClient, private authService: AuthenticationService) {
  }

  public getByIdentifier(identifier: string): Observable<EntityDTO> {
    return this.httpClient
      .get<EntityDTO>(API_BASE_URL + '/' + identifier)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getChatConfiguration(): Observable<ChatConfigurationDTO> {
    let entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<ChatConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/chat')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateForActiveUsersState(chatConfigurationUpdateDto: ChatConfigurationUpdateDTO): Observable<ChatConfigurationDTO> {
    let entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<ChatConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/chat/stateUsers', chatConfigurationUpdateDto)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateConciergeState(chatConfigurationUpdateDto: ChatConfigurationUpdateDTO): Observable<ChatConfigurationDTO> {
    let entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<ChatConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/chat/state', chatConfigurationUpdateDto)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public patchRequestClosed(olderThan: ClosedOlderThan): Observable<void> {
    let entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<void>(API_BASE_URL + '/' + entityIdentifier + '/requests/closed', "\"" + olderThan + "\"")
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public patchChatClosed(olderThan: ClosedOlderThan): Observable<void> {
    let entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<void>(API_BASE_URL + '/' + entityIdentifier + '/chat/closed', "\"" + olderThan + "\"")
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }

  public getAppConfigurations(): Observable<AppConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<AppConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/app')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateAppConfigurationsUploadedLogo(formData: FormData): Observable<AppConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    return this.httpClient
      .post<AppConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/app/uploadedLogo', formData)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateAppConfigurationsRemoveLogo(file: MultimediaContentDTO): Observable<AppConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();
    return this.httpClient
      .patch<AppConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/app/removeLogo', file)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateAppConfigurations(appConfigurationDto: AppConfigurationDTO): Observable<AppConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();
    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<AppConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/app', appConfigurationDto)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getLanguages(): Observable<LanguageDTO[]> {
    let entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<LanguageDTO[]>(API_BASE_URL + '/' + entityIdentifier + '/languages')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateFirebaseCheckout(device: DeviceDTO): Observable<void> {
    let entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<void>(API_BASE_URL + '/' + entityIdentifier + '/firebaseCheckout', device)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getNotificationConfigurations(): Observable<NotificationConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<NotificationConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/notification')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateNotificationConfigurations(appConfigurationDto: NotificationConfigurationDTO): Observable<NotificationConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();
    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<NotificationConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/notification', appConfigurationDto)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getVoiceConfigurations(): Observable<VoiceConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<VoiceConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/voice')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public createVoiceConfigurations(voiceConfigurationDto: VoiceConfigurationDTO): Observable<VoiceConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .post<VoiceConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/voice', voiceConfigurationDto)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateVoiceConfigurations(voiceConfigurationDto: VoiceConfigurationDTO): Observable<VoiceConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<VoiceConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/voice', voiceConfigurationDto)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public importVoiceExtensions(formData: FormData): Observable<VoiceConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    return this.httpClient
      .post<VoiceConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/voice/extensions', formData)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public exportVoiceExtensions(): Observable<Blob> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    return this.httpClient
      .get(API_BASE_URL + '/' + entityIdentifier + '/config/voice/extensions', { responseType: 'blob' })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getRequestConfigurations(): Observable<RequestConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<RequestConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/request')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateRequestConfigurations(requestConfigurationDTO: RequestConfigurationDTO): Observable<RequestConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<RequestConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/request', requestConfigurationDTO)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getMembershipConfigurations(): Observable<FullMembershipConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<FullMembershipConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/membership')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public createMembershipConfigurations(membershipConfigurationDto: FullMembershipConfigurationDTO, firstEntry: boolean): Observable<FullMembershipConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .post<FullMembershipConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/membership/' + firstEntry, membershipConfigurationDto)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateMembershipConfigurations(membershipConfigurationDto: FullMembershipConfigurationDTO): Observable<FullMembershipConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<FullMembershipConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/membership', membershipConfigurationDto)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }


  public updateMembershipConfigurationsUploadStatusBarLogo(formData: FormData): Observable<FullMembershipConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    return this.httpClient
      .post<FullMembershipConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/membership/uploadstatusbarlogo', formData)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateMembershipConfigurationsRemoveStatusBarLogo(file: FullMembershipConfigurationDTO): Observable<FullMembershipConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();
    return this.httpClient
      .patch<FullMembershipConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/membership/removestatusbarlogo', file)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }


  public uploadMembershipIcon(formData: FormData): Observable<FullMembershipConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    return this.httpClient
      .post<FullMembershipConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/membership/icon', formData)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public removeMembershipIcon(): Observable<FullMembershipConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();
    return this.httpClient
      .delete<FullMembershipConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/membership/icon')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getIntegrationConfigurations(): Observable<IntegrationConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<IntegrationConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/integration')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateIntegrationConfigurations(integrationConfigurationDTO: IntegrationConfigurationDTO): Observable<IntegrationConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .post<IntegrationConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/integration', integrationConfigurationDTO)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getPressReaderConfigurations(): Observable<PressReaderConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<PressReaderConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/pressreader')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updatePressReaderConfigurations(pressReaderConfigurationDTO: PressReaderConfigurationDTO): Observable<PressReaderConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .post<PressReaderConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/pressreader', pressReaderConfigurationDTO)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getPressReaderConfigurationAccessLifetime(): Observable<PressReaderConfigurationAccessLifetimeDTO[]> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<PressReaderConfigurationAccessLifetimeDTO[]>(API_BASE_URL + '/' + entityIdentifier + '/config/pressreader/access-lifetime')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updatePressReaderConfigurationAccessLifetime(accessLifetimesDTO: PressReaderConfigurationAccessLifetimeDTO[]): Observable<PressReaderConfigurationAccessLifetimeDTO[]> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .post<PressReaderConfigurationAccessLifetimeDTO[]>(API_BASE_URL + '/' + entityIdentifier + '/config/pressreader/access-lifetime', accessLifetimesDTO)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getPosConfigurations(): Observable<PosConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<PosConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/pos')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updatePosConfigurations(posConfigurationDTO: PosConfigurationDTO): Observable<PosConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .post<PosConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/pos', posConfigurationDTO)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getChatSettings(): Observable<ChatSettingsDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<ChatSettingsDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/chat-settings')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateChatSettings(chatSettingsDTO: ChatSettingsDTO): Observable<ChatSettingsDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .post<ChatSettingsDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/chat-settings', chatSettingsDTO)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getActivitiesConfigurations(): Observable<ActivitiesConfigurationDTO> {
    const entityId = this.authService.getUserSelectedEntityId();

    if (!entityId) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<ActivitiesConfigurationDTO>(API_BASE_URL + '/' + entityId + '/config/activities')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateActivitiesConfigurations(configurationDTO: ActivitiesConfigurationDTO): Observable<ActivitiesConfigurationDTO> {
    const entityId = this.authService.getUserSelectedEntityId();

    if (!entityId) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .post<ActivitiesConfigurationDTO>(API_BASE_URL + '/' + entityId + '/config/activities', configurationDTO)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getMyStaysConfigurations(): Observable<MyStaysConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<MyStaysConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/my-stays')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateMyStaysConfigurations(myStaysConfigurationDTO: MyStaysConfigurationDTO): Observable<MyStaysConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<MyStaysConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/my-stays', myStaysConfigurationDTO)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getAppearanceConfiguration(): Observable<AppearanceConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get<AppearanceConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/appearance')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateAppearanceConfiguration(data: AppearanceConfigurationDTO): Observable<AppearanceConfigurationDTO> {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .patch<AppearanceConfigurationDTO>(API_BASE_URL + '/' + entityIdentifier + '/config/appearance', data)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }
}
