import { Component, Input, Output, EventEmitter } from '@angular/core';

export type CheckedResolver = (entry: any) => true | null;

@Component({
  selector: 'radio',
  templateUrl: 'radio.component.html',
  styleUrls: ['radio.component.css']
})

/** radio component*/
export class RadioComponent {

  @Input() collection: Array<any> = [];

  @Input() public groupLabelName: string;
  @Input() public isInline: boolean = false;
  @Input() public labelNameResolver: Function;
  @Input() public valueResolver: Function;
  @Input() public checkedResolver: CheckedResolver;
  @Output() onSelectChanged: EventEmitter<any> = new EventEmitter();

  /**
  * selectedValue [()]
  */
  selectedValueLocal: any;
  @Input()
  set selectedValue(val) {
    this.selectedValueLocal = val;
    this.selectedValueChange.emit(this.selectedValueLocal);
  }

  get selectedValue() {
    return this.selectedValueLocal;
  }
  @Output() selectedValueChange: EventEmitter<any> = new EventEmitter();

  /** radio ctor */
  constructor() {

  }

  public onSelectionChange(entry: any) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedValue = this.resolveValue(entry);
    this.onSelectChanged.emit(this.selectedValue);
  }

  public resolveValue(entry: any) {
    return this.valueResolver(entry);
  }

  public resolveChecked(entry: any, a: any): true | null {
    return this.checkedResolver(entry);
  }

  public resolveLabelName(entry: any) {
    return this.labelNameResolver(entry);
  }

  public trackByIndex(index: number, item: any): number {
    return index;
  }

}
