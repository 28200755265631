import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '../core/authentication.service';
import {
  DashboardDTO, StatsSurveyDTO, MessageTotalPerHourDTO,
  SurveysAveragePerWeekDTO, TotalAverageDTO,
  SurveysAveragesDTO, DoubleBarGraphicDTO,
  TupleTotalDTO, SimpleBarGraphicDTO, 
  RequestTotalAverageDTO, MessageTotalAverageDTO
} from '@models/statistics';
import * as moment from "moment/moment";

// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.core + 'statistics';

@Injectable()
export class StatisticsService {
  constructor(private httpClient: HttpClient, private authService: AuthenticationService) {
  }

  public getDashboard(): Observable<DashboardDTO> {
    const entityId = this.authService.getUserSelectedEntityId();

    const httpParameters = entityId ? { params: new HttpParams().set('entityId', entityId.toString()) } : {};

    return this.httpClient
      .get<DashboardDTO>(API_BASE_URL + '/dashboard', httpParameters)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getSurvey(): Observable<StatsSurveyDTO> {
    const entityId = this.authService.getUserSelectedEntityId();
    const httpParameters = entityId ? { params: new HttpParams().set('entityId', entityId.toString()) } : {};

    return this.httpClient
      .get<StatsSurveyDTO>(API_BASE_URL + '/survey', httpParameters)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getSurveysAveragePerWeek(fromDate: moment.Moment, toDate?: moment.Moment): Observable<SurveysAveragePerWeekDTO[]> {
    const entityId = this.authService.getUserSelectedEntityId();

    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<SurveysAveragePerWeekDTO[]>(API_BASE_URL + '/surveyAveragePerWeek', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getSurveyAveragePerDatepartAllEntities(): Observable<SurveysAveragesDTO[]> {
    return this.httpClient
      .get<SurveysAveragesDTO[]>(API_BASE_URL + '/surveyAveragePerDatepartAllEntities')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getAverageMessageResponse(fromDate: moment.Moment, toDate?: moment.Moment): Observable<SurveysAveragePerWeekDTO[]> {
    const entityId = this.authService.getUserSelectedEntityId();

    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<SurveysAveragePerWeekDTO[]>(API_BASE_URL + '/averageMessageResponse', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getAverageRequestNotesResponse(fromDate: moment.Moment, toDate?: moment.Moment): Observable<SurveysAveragePerWeekDTO[]> {
    const entityId = this.authService.getUserSelectedEntityId();

    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<SurveysAveragePerWeekDTO[]>(API_BASE_URL + '/averageRequestNotesResponse', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getSurveysAverageByCountry(fromDate: moment.Moment, toDate?: moment.Moment): Observable<DoubleBarGraphicDTO[]> {
    const entityId = this.authService.getUserSelectedEntityId();

    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<DoubleBarGraphicDTO[]>(API_BASE_URL + '/surveysAverageByCountry', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getTotalCheckinByCountry(fromDate: moment.Moment, toDate?: moment.Moment): Observable<any[]> {
    const entityId = this.authService.getUserSelectedEntityId();

    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<any[]>(API_BASE_URL + '/totalCheckinByCountry', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getSurveysTotalAverage(fromDate: moment.Moment, toDate?: moment.Moment): Observable<TotalAverageDTO> {
    const entityId = this.authService.getUserSelectedEntityId();
    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<TotalAverageDTO>(API_BASE_URL + '/surveysTotalAverage', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getMessageResponseTotalAverage(fromDate: moment.Moment, toDate?: moment.Moment): Observable<MessageTotalAverageDTO> {
    const entityId = this.authService.getUserSelectedEntityId();
    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<MessageTotalAverageDTO>(API_BASE_URL + '/messageResponseTotalAverage', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getRequestNotesResponseTotalAverage(fromDate: moment.Moment, toDate?: moment.Moment): Observable<RequestTotalAverageDTO> {
    const entityId = this.authService.getUserSelectedEntityId();
    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<RequestTotalAverageDTO>(API_BASE_URL + '/requestNotesResponseTotalAverage', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getCheckinTotalAverage(fromDate: moment.Moment, toDate?: moment.Moment): Observable<TupleTotalDTO> {
    const entityId = this.authService.getUserSelectedEntityId();
    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<TupleTotalDTO>(API_BASE_URL + '/checkinTotalAverage', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getInteractions(fromDate: moment.Moment, toDate?: moment.Moment): Observable<any[]> {
    const entityId = this.authService.getUserSelectedEntityId();
    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<any[]>(API_BASE_URL + '/interactions', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getMessageResponseAveragePerCustomer(): Observable<SurveysAveragesDTO[]> {
    return this.httpClient
      .get<SurveysAveragesDTO[]>(API_BASE_URL + '/messageResponseAveragePerCustomer')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getTotalMessagesByHour(fromDate: moment.Moment, toDate?: moment.Moment): Observable<MessageTotalPerHourDTO[]> {
    const entityId = this.authService.getUserSelectedEntityId();
    let params = new HttpParams();

    if (entityId) {
      params = params.set('entityId', entityId.toString());
    }

    if (fromDate) {
      params = params.set('fromDate', moment(fromDate).utc().toJSON());
    }

    if (toDate) {
      params = params.set('toDate', toDate ? moment(toDate).utc().toJSON(): null);
    }

    return this.httpClient
      .get<MessageTotalPerHourDTO[]>(API_BASE_URL + '/messageTotalPerHour', { params: params })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }
  
  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }

}
