﻿

	 import { MobileOS } from '@models/enum/MobileOS';
	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    /**
    * 
    */
    
    export class PushServiceAuthenticationSearchFilter extends SearchFilter {
        
        
        /**
        * Entity unique identifier
        */
        id?: number | null;
        
        /**
        * Registration related Application's unique identifier
        */
        applicationId?: number | null;
        
        /**
        * Device mobile operating system
        */
        mobileOS?: MobileOS | null;
    }
