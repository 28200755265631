﻿

	 import { SimpleBarGraphicDTO } from '@models/statistics/SimpleBarGraphicDTO';
    
    export class DoubleBarGraphicDTO extends SimpleBarGraphicDTO {
        
        
        /**
        * Second Value
        */
        value2: number;
    }
