﻿

    
    export enum MessagingCommunicationMedium {
        
        
        Undefined = "Undefined",
        
        IP = "IP",
        
        SMS = "SMS"
    }
