import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, ComponentRef } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { PressReaderConfigurationDTO } from '@models/pressreader';
import { PressReaderConfigurationAccessLifetimeDTO } from '@models/pressreader/PressReaderConfigurationAccessLifetimeDTO';
import { ActionButtonConfig, ActionButtonsComponent } from '@shared/components/action-buttons/action-buttons.component';
import { DataGridComponent, DataGridConfiguration } from '@app/shared';
import { EditableLabelComponent } from '@shared/sui-components/sui-editable-label/sui-editable-label.component';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'pressreader-settings',
  templateUrl: './pressreader-settings.component.html',
  styleUrls: ['./pressreader-settings.component.css']
})
export class PressreaderSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("componentDimmer") componentDimmer: ElementRef;
  @ViewChild(DataGridComponent) dataGridComponent: DataGridComponent;

  public configurationDTO: PressReaderConfigurationDTO;
  private rxSubscription: Subscription = new Subscription();
  private fetchingComponentData: boolean;
  public gridConfiguration: DataGridConfiguration<PressReaderConfigurationAccessLifetimeDTO>;
  public accessLifetimeDTO: PressReaderConfigurationAccessLifetimeDTO[];
  private filterWaiting: boolean = false;

  constructor(
    private entityService: EntityService,
    private authService: AuthenticationService,
  ) {
    this.setupAccessLifetimeDataGrid();
  }

  ngOnInit() {
    this.load();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  private load() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .getPressReaderConfigurations()
          .pipe(finalize(() => {
            this.setComponentDimmerVisibility(false);
            this.loadAccessLifetime();
          }))
          .subscribe((data) => {
            this.configurationDTO = data;
          })
      );
    }
  }

  private loadAccessLifetime() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .getPressReaderConfigurationAccessLifetime()
          .pipe(finalize(() => {
            this.setComponentDimmerVisibility(false);
          }))
          .subscribe((data) => {
            if (this.filterWaiting) {
              return;
            }
            this.accessLifetimeDTO = cloneDeep(data);
          })
      );
    }
  }

  save(): void {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .updatePressReaderConfigurations(this.configurationDTO)
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((data) => {
            this.configurationDTO = data;
          })
      );
    }
  }

  saveAccessLifetime(): void {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .updatePressReaderConfigurationAccessLifetime(this.accessLifetimeDTO)
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((data) => {
            if (this.filterWaiting) {
              return;
            }
            this.accessLifetimeDTO = cloneDeep(data);
          })
      );
    }
  }

  isDisabled(): boolean {
    return !this.configurationDTO || !this.configurationDTO.enabled;
  }

  onCheckboxChange(value: boolean): void {
    this.save();
  }

  private setupAccessLifetimeDataGrid(): void {
    this.gridConfiguration = new DataGridConfiguration(null, false, true, true, false, false, true, null, true);

    this.gridConfiguration.addColumn({
      index: 0,
      isSortable: false,
      headerText: 'Minimum',
      columnGridWidth: 4,
      component: EditableLabelComponent,
      componentResolver: (componentRef: ComponentRef<EditableLabelComponent>, item: PressReaderConfigurationAccessLifetimeDTO) => {
        if (this.filterWaiting) {
          return;
        }

        const instance = componentRef.instance;
        instance.inputValue = item.min;
        
        if (!(<any>componentRef).updatingValues) {
          instance.useDefaultValue = true;
          instance.click = () => {
            this.filterWaiting = true;
          };
          instance.blur = () => {
            this.filterWaiting = false;
          };

          const componentSubscriptions = new Subscription();

          componentSubscriptions.add(
            instance.valueChange.subscribe(
              (value) => {
                item.min = value;
              }
          ));

          componentSubscriptions.add(
            instance.inputValueChange.subscribe(
              (value) => {
                item.min = value;
              }
          ));

          (<any>instance).eventSubscriptions = componentSubscriptions;

          componentRef.onDestroy(() => {
            if ((<any>instance).eventSubscriptions)
              (<any>instance).eventSubscriptions.unsubscribe();
          });
        }
      }
    });

    this.gridConfiguration.addColumn({
      index: 1,
      isSortable: false,
      headerText: 'Maximum',
      columnGridWidth: 4,
      component: EditableLabelComponent,
      componentResolver: (componentRef: ComponentRef<EditableLabelComponent>, item: PressReaderConfigurationAccessLifetimeDTO) => {
        if (this.filterWaiting) {
          return;
        }

        const instance = componentRef.instance;
        instance.inputValue = item.max;
        
        if (!(<any>componentRef).updatingValues) {
          instance.useDefaultValue = true;
          instance.click = () => {
            this.filterWaiting = true;
          };
          instance.blur = () => {
            this.filterWaiting = false;
          };

          const componentSubscriptions = new Subscription();

          componentSubscriptions.add(
            instance.valueChange.subscribe(
              (value) => {
                item.max = value;
              }
          ));

          componentSubscriptions.add(
            instance.inputValueChange.subscribe(
              (value) => {
                item.max = value;
              }
          ));

          (<any>instance).eventSubscriptions = componentSubscriptions;

          componentRef.onDestroy(() => {
            if ((<any>instance).eventSubscriptions)
              (<any>instance).eventSubscriptions.unsubscribe();
          });
        }
      }
    });

    this.gridConfiguration.addColumn({
      index: 2,
      isSortable: false,
      headerText: 'Duration',
      columnGridWidth: 4,
      component: EditableLabelComponent,
      componentResolver: (componentRef: ComponentRef<EditableLabelComponent>, item: PressReaderConfigurationAccessLifetimeDTO) => {
        if (this.filterWaiting) {
          return;
        }

        const instance = componentRef.instance;
        instance.inputValue = item.duration;
        
        if (!(<any>componentRef).updatingValues) {
          instance.useDefaultValue = true;
          instance.click = () => {
            this.filterWaiting = true;
          };
          instance.blur = () => {
            this.filterWaiting = false;
          };

          const componentSubscriptions = new Subscription();

          componentSubscriptions.add(
            instance.valueChange.subscribe(
              (value) => {
                item.duration = value;
              }
          ));

          componentSubscriptions.add(
            instance.inputValueChange.subscribe(
              (value) => {
                item.duration = value;
              }
          ));

          (<any>instance).eventSubscriptions = componentSubscriptions;

          componentRef.onDestroy(() => {
            if ((<any>instance).eventSubscriptions)
              (<any>instance).eventSubscriptions.unsubscribe();
          });
        }
      }
    });

    this.gridConfiguration.addColumn({
      index: 3,
      headerText: '',
      isSortable: false,
      columnGridWidth: 1,
      component: ActionButtonsComponent,
      componentResolver: (componentRef: ComponentRef<ActionButtonsComponent>, item: PressReaderConfigurationAccessLifetimeDTO) => {
        const instance = componentRef.instance;
        
        if (!instance.configuration) {
          instance.configuration = [
            new ActionButtonConfig(
              "red trash alternate outline",
              (event: Event) => {
                this.actionButtonDelete(item);
              },
              null,
              null,
              null,
              null,
              true)
          ];
        }
      }
    });
  }

  private actionButtonDelete(item: PressReaderConfigurationAccessLifetimeDTO) {
    let index = this.accessLifetimeDTO.findIndex((element) => {
      if (item._nanoid !== null && item._nanoid !== undefined) {
        if (element._nanoid === item._nanoid) {
          return true;
        }
      } else if (item.id !== null && item.id !== undefined) {
        if (element.id === item.id) {
          return true;
        }
      }
      return false;
    });
    if (index > -1) {
      this.accessLifetimeDTO.splice(index, 1);
      this.accessLifetimeDTO = cloneDeep(this.accessLifetimeDTO);
    }
  }

  addNewAccessLifetimeOnClick(event: EventTarget) {
    if (this.accessLifetimeDTO == null || this.accessLifetimeDTO == undefined) {
      this.accessLifetimeDTO = new Array<PressReaderConfigurationAccessLifetimeDTO>();
    }
    this.accessLifetimeDTO.push(new PressReaderConfigurationAccessLifetimeDTO());
    this.accessLifetimeDTO = cloneDeep(this.accessLifetimeDTO);
  }

  saveAccessLifetimeOnClick(event: EventTarget) {
    this.saveAccessLifetime();
  }
  
  private setComponentDimmerVisibility(visible: boolean): void {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }
}
