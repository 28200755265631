import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { RequestService } from '../request.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AppHeaderService,
  BreadcrumbSection,
  MessagingContainerComponent
} from '@app/shared';

import { RequestDetailActionsComponent } from '@app/requests/request-detail-actions/request-detail-actions.component';
import { RequestDetailActionsService } from '@app/requests/request-detail-actions/request-detail-actions.service';
import { AuthenticationService } from '@app/core/authentication.service';
import {
  RequestFullDTO,
  RequestNoteFullDTO,
  RequestNoteCreateDTO,
  RequestUpdateStateDTO
} from '@models/requests';
import { RequestNotesSearchFilter } from '@models/search-filters';
import { RequestState, Platform } from '@models/enum';
import { of, Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { NotificationsService } from '@app/notifications/notifications.service';
import { NotifierService } from '@app/core/notifications/notifier.service';
import * as moment from 'moment';


@Component({
  selector: 'request-detail',
  templateUrl: 'request-detail.component.html',
  styleUrls: ['request-detail.component.css']
})
export class RequestDetailComponent implements OnInit, OnDestroy {
  // State
  // Request
  private requestId: number;
  public request: RequestFullDTO = null;
  // Notes
  public clientAppSupportsNotes: boolean = false;
  private notes: RequestNoteFullDTO[] = null;
  private notesListingPageSize: number = 10;
  private totalExistingNotes: number;
  private notesListingCurrentPage: number = 1;
  private newNoteText: string = '';
  private getLastMessagesDelay: number = 15000;
  private getLastMessagesIntervalId: any = null;
  // Actions component (chat service state, global and for active users) all Subscriptions
  private subscription = new Subscription();

  // UI
  @ViewChild('newNoteTextArea') newNoteTextArea: ElementRef;
  @ViewChild('componentDimmer') componentDimmer: ElementRef;
  private fetchingComponentData: boolean;
  @ViewChild('notesAreaDimmer') notesAreaDimmer: ElementRef;
  private fetchingNotesData: boolean;

  // Child Components @View
  @ViewChild(MessagingContainerComponent) private messagesContainer: MessagingContainerComponent;

  constructor(private requestsService: RequestService, private router: Router, private route: ActivatedRoute,
    private appHeaderService: AppHeaderService, private reqDetailActionsService: RequestDetailActionsService,
    private authService: AuthenticationService, private notificationsService: NotificationsService,
    private notifierService: NotifierService)
  {
    this.subscription.add(
      this.reqDetailActionsService
      .getRequestState()
      .subscribe(
        (state: RequestState) =>
          this.updateState(state)
      ));
  }

  ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe(
        (params: any) => {
          this.requestId = +params['id'];
          this.getByIdentifier(this.requestId);
        }));

    if (this.clientAppSupportsNotes) {
      this.newNoteTextArea.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    // Message polling for active/opened conversation
    clearTimeout(this.getLastMessagesIntervalId);
    this.getLastMessagesIntervalId = null;
    // Unsubscribes
    this.subscription.unsubscribe();
  }

  // Request Info
  private getByIdentifier(identifier: number): void {
    this.setComponentDimmerVisibility(true);
    let requestWasReadNow: boolean = false;

    this.subscription.add(
      this.requestsService
      .getByIdentifier(identifier)
      .pipe(
        flatMap(request => {
          if (request.wasRead || this.authService.userIsAdminOrManager()) { // Should MarkAsRead be called?
            return of(request); // Use getById result
          }
          else {
            requestWasReadNow = true;
            return this.requestsService.markAsRead(request.id); // Patch Request instance and use return object later on subscribe()
          }
        })
      )
      .subscribe(
        (request) => {
          this.request = request;
          this.appHeaderService.breadcrumbSectionPush(new BreadcrumbSection('Request ' + this.request.id + ' - ' + this.request.type.name, ['/requests', this.request.id]));
          this.appHeaderService.setActionsComponent(RequestDetailActionsComponent);
          this.reqDetailActionsService.setDropDownSelectedValue(this.request.state);

          // Notes support on current Request instance
          this.clientAppSupportsNotes = this.isClientAppSupportsNotes();
          if (this.clientAppSupportsNotes) {
            this.getNotes();
          }

          // Update notification counters (nav menu) immediately
          if (requestWasReadNow) {
            this.notificationsService.refreshNotificationCounters();
          }
        },
        (error: any) => {
          switch ((error.status as number)) {
            case 403:
              this.notifierService.error('You don\'t have access to the specified request.', 'Permission Denied');
              this.router.navigate(['/requests']);
              break;
            case 404:
              this.notifierService.warning('The Request you tried to access wasn\'t found!', 'Not Found');
              this.router.navigate(['/requests']);
              break;
            default:
              this.handleErrors(error);
              throw(error);
          }
        },
        () => {
           this.setComponentDimmerVisibility(false);
        }
      ));
    }

  private isClientAppSupportsNotes() {
      return !!this.request.historyRecord && !!this.request.historyRecord.appVersion;
  }

  private refreshRequestState() {
    this.subscription.add(this.requestsService
      .getByIdentifier(this.requestId)
      .subscribe(
        (request) => {
          this.reqDetailActionsService.setDropDownSelectedValue(request.state, true);
        },
        (error: any) => {
          this.handleErrors(error);
          throw(error);
        }
      )
    );
  }

  // State
  public updateState(state: RequestState): void {
    // if same state selected, skip...
    if (this.request.state === state) {
      return;
    }

    const stateDto = new RequestUpdateStateDTO();
    stateDto.state = state;

    this.setComponentDimmerVisibility(true);
    this.reqDetailActionsService.setDropDownLoadingState(true);

    this.subscription.add(this.requestsService
      .updateState(this.request.id, stateDto)
      .subscribe(
        (response) => {
          this.request = response.body;
          this.notificationsService.refreshNotificationCounters();
          this.clientAppSupportsNotes = this.isClientAppSupportsNotes();
        },
        (error: any) => this.handleStateUpdateErrors(error),
        () => {
          this.reqDetailActionsService.setDropDownLoadingState(false);
          this.setComponentDimmerVisibility(false);
          setTimeout(() => this.getNotes(), 1000);
        }
      ));
  }

  public shouldSendMsgBeDisabled(): boolean {
    return (this.request.state === RequestState.Closed || this.request.state === RequestState.Cancelled);
  }

  public messagePlaceHolder(): string {
    if (this.shouldSendMsgBeDisabled()) {
      return `Request chat is not available while status is ${RequestState[this.request.state].toLowerCase()}`;
    } else {
      return 'Write a message to your guest about the request';
    }
  }

  // Notes
  private getNotes(page: number = 1, toAppend: boolean = false): void {
    //check if is the last page
    if (page !== 0 && page > Math.ceil(this.totalExistingNotes / this.notesListingCurrentPage)) {
      return;
    }

    const filter: RequestNotesSearchFilter = new RequestNotesSearchFilter();

    // Enforce default
    filter.pageSize = this.notesListingPageSize;
    filter.includeMetadata = true;
    filter.page = page;
    filter.orderBy = null;

    if (!toAppend && this.notes && this.notes.length > 0) {
      const lastMsg = this.notes[this.notes.length - 1];
      filter.fromDate = lastMsg.createdOn;
    }

    this.setNotesDimmerVisibility(true);

    this.subscription.add(this.requestsService
      .getNotes(this.requestId, filter)
      .subscribe(
        (response) => {
          var newMessages = response.items.reverse();

          // Set focus on input
          if (this.clientAppSupportsNotes) {
            this.newNoteTextArea.nativeElement.focus();
          }

          // If new messages exist
          if (newMessages.length > 0) {

            this.totalExistingNotes = response.totalCount;
            this.notesListingCurrentPage = page;

            //when the notes/message list is empty we put the scroll on bottom
            if (!toAppend && (!this.notes || this.notes.length <= 0)) {
              this.notes = newMessages;
              this.messagesContainer.scrollOnBottom();
              this.markCurrentPageNotesAsRead(newMessages);
              this.getMessagesCheckRead();
              return;
            }

            if (toAppend) {
              this.notes = newMessages.concat(this.notes);
              this.messagesContainer.scrollKeepPosition();
              return;
            }

            this.notes = this.notes.concat(newMessages);
            this.getMessagesCheckRead();
            this.markCurrentPageNotesAsRead(newMessages);

            const newTotalExistingMessages = response.totalCount;
            // Calculate in what page we should be now (that new messages were added to the message list)
            const totalPages = Math.ceil(this.totalExistingNotes / this.notesListingCurrentPage);
            const newTotalPages = Math.ceil((newTotalExistingMessages + this.totalExistingNotes) / this.notesListingCurrentPage);

            this.notesListingCurrentPage = Math.ceil(this.notesListingCurrentPage * newTotalPages / totalPages);
            this.totalExistingNotes += newTotalExistingMessages;
          }
        },
        (error: any) => this.handleNotesErrors(error),
        () => {
          this.checkLoopGetNewMessage();
          this.setNotesDimmerVisibility(false);
        }
      ));
  }

  private addNote(): void {
    const noteText = this.newNoteText.trim();

    if (noteText.length === 0) {
      // TODO: Validate form; see Angular validations or implement as needed.
      this.newNoteText = '';
      return;
    }

    const note = new RequestNoteCreateDTO();
    note.text = this.newNoteText.trim();

    this.setNotesDimmerVisibility(true);

    this.subscription.add(this.requestsService
      .addNote(this.request.id, note)
      .subscribe(
        (response) => {
          /* newly created note discarded as it's not needed for now */
          this.newNoteText = null;
          this.messagesContainer.scrollOnBottom();
          this.getNotes();
          this.refreshRequestState();
        },
        (error: any) => this.handleNotesErrors(error),
        () => {
          this.setNotesDimmerVisibility(false);
        }
      ));
  }

  // Messaging, messages container component
  public onScroll(isOnTop: boolean) {
    //get next page
    this.getNotes(++this.notesListingCurrentPage, true);
  }

  private loopGetNewMessages(): void {
    if (!!this.getLastMessagesIntervalId) {
      clearTimeout(this.getLastMessagesIntervalId);
    }

    this.getLastMessagesIntervalId = setTimeout(() => this.getNotes(), this.getLastMessagesDelay);
  }

  private checkLoopGetNewMessage(): void {
    if (this.shouldSendMsgBeDisabled()) {
      clearTimeout(this.getLastMessagesIntervalId);
    } else {
      this.loopGetNewMessages();
    }
  }

  public onClickOpenChat(event: Event) {
    const entityId = this.request.targetEntity? this.request.targetEntity.id : this.authService.getUserEntityAccessId();
    if (!entityId && !this.request && !this.request.chatUser.id) {
      return;
    }
    this.appHeaderService.resetBreadcrumb(new BreadcrumbSection('chat', ['/chat']));
    const conversationId = `${entityId}-${this.request.chatUser.id}`;
    this.router.navigate(["chat", conversationId]);
  }

  private markCurrentPageNotesAsRead(messages: RequestNoteFullDTO[]) {

    //admin or manager never set the clients notes to read
    if (this.authService.userIsAdminOrManager()) {
      return;
    }

    let lastMsg = this.notes[this.notes.length - 1];
    let fromDate = lastMsg.createdOn;

    this.subscription.add(this.requestsService.markMessageAsReadBefore(this.request.id, fromDate)
      .subscribe(() => {
        messages.forEach((message) => {
          if (message.wasSentByUser) {
            message.wasRead = true;
          }
        });
        this.notificationsService.refreshNotificationCounters();
      },
        (error: any) => {
          this.handleErrors(error);
          throw(error);
        },
        () => { }
      ));

  }

  private getMessagesCheckRead(): void {
    var messagesIds: number[] = new Array<number>();

    this.notes.forEach((message) => {
      if (!message.wasSentByUser && !message.wasRead) {
        messagesIds.push(message.id);
      }
    });

    this.subscription.add(this.requestsService.getMessagesCheckRead(this.request.id, messagesIds)
      .subscribe((readMessagesIds) => {
        this.updateMessagesState(readMessagesIds);
      },
        (error: any) => {
          this.handleErrors(error);
          throw(error);
        },
        () => { }
      ));
  }

  private updateMessagesState(messagesIds: number[]): void {
    messagesIds.forEach((id) => {
      this.notes.forEach((message) => {
        if (id === message.id) {
          message.wasRead = true;
        }
      });
    });
  }

  private noteIsFromEndUser(note: RequestNoteFullDTO): boolean {
    if (!note) {
      return null;
    }

    return note.wasSentByUser;
  }

  /*
   * Arrow function used in order to keep the correct value in the 'this' special variable;
   * Creating a regular function would lose the desired context
   * (the caller context would be assumed instead of this component instance)
   */
  public noteUserNameResolver = (note: RequestNoteFullDTO) => {
    if (!note || !this.request) {
      return null;
    }

    return note.wasSentByUser ? this.request.chatUser.name : 'Property';
  }

  public noteDateResolver(note: RequestNoteFullDTO) {
    if (!note || !note.createdOn) {
      return null;
    }

    return moment(note.createdOn).format('MMM, DD YYYY HH:mm');
  }

  public noteMessageResolver(note: RequestNoteFullDTO): string {
    if (!note) {
      return null;
    }

    return note.text;
  }

  public noteIsReadResolver(note: RequestNoteFullDTO): boolean {
    if (!note) {
      return null;
    }

    return note.wasRead;
  }

  private setNotesDimmerVisibility(visible: boolean) {
    if (visible && this.fetchingComponentData) {
      return;
    }

    const dimmerElement =
      $(this.notesAreaDimmer.nativeElement)
        .dimmer({
          duration: { show: 400, hide: 800 }
        }
        );

    if (visible) {
      this.fetchingNotesData = true;
      dimmerElement.dimmer('show');
    } else {
      dimmerElement.dimmer('hide');
      this.fetchingNotesData = false;
    }
  }

  public getFlagClass() {
    if (this.request && this.request.chatUser && this.request.chatUser.country) {
      return this.request.chatUser.country.isoCode.toLowerCase() + ' flag';
    }
    return "";
  }

  // Component
  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement =
      $(this.componentDimmer.nativeElement)
        .dimmer({
          duration: { show: 400, hide: 800 }
        }
        );

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer('show');
    } else {
      dimmerElement.dimmer('hide');
      this.fetchingComponentData = false;
    }
  }

  public getNameFromPlatform(platform: Platform): string {
    if (!platform) {
      return "";
    }

    switch (platform) {
      case Platform.GuestUPhone:
        return "GuestU Phone";
      case Platform.B2BApp:
        return "Mobile App";
      case Platform.Clarice:
        return "Clarice";
      case Platform.B2BAppNG:
        return "Web App";
      case Platform.Unknown:
      default:
        return "Unknown";
    }
  }

  // Error Handling
  private handleErrors(error: any): void {
    console.log(error);
    this.setNotesDimmerVisibility(false);
    this.setComponentDimmerVisibility(false);
  }

  private handleNotesErrors(error: any): void {
    console.log(error);
    this.setNotesDimmerVisibility(false);
    throw(error);
  }

  private handleStateUpdateErrors(error: any): void {
    console.log(error);
    this.reqDetailActionsService.setDropDownSelectedValue(this.request.state);
    this.reqDetailActionsService.setDropDownLoadingState(false);
    this.setComponentDimmerVisibility(false);
    throw(error);
  }
}
