  <!-- Info area, grid -->
<div class="ui blurring dimmable grid">

  <!--Begin Container-->
  <div class="row" *ngIf="true">
    <!-- left side -->
    <div class="eight wide column">

      <h4 class="ui top attached header">
        <div><i class="envelope circle icon"></i>Email notifications on</div>
      </h4>
      <div class="ui attached segment">
        <div class="ui grid">
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">New request</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.newRequests"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>

            <div class="column">
              <div class="ui tiny header">New request message</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.newRequestMessages"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">New chat message</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.newChatMessages"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>

            <div class="column">
              <div class="ui tiny header">New user</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.newUsers"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column ui form">
              <div class="fields">
                <div class="field">
                  <div class="ui tiny header">New survey</div>
                  <sui-checkbox [(inputValue)]="notificationConfigDto.newSurveys"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
                <div class=" field" [class.disabled]="!notificationConfigDto.newSurveys">
                  <div class="ui tiny header">equal or less than:</div>
                  <sui-input [placeholder]="'at rating?'"
                             [(inputValue)]="notificationConfigDto.surveysAtRating"
                             (onChangeComplete)="save()"
                             (keydown.enter)="save()">
                  </sui-input>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="ui tiny header">Unanswered conversations</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.unansweredConversations"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">New request integration error message</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.newRequestIntegrationError"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>

            <div class="column">
              <div class="ui tiny header">New express check-out</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.newExpressCheckOut"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Express check-out completed</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.expressCheckOutSuccess"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>

            <div class="column">
              <div class="ui tiny header">Express check-out failed</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.expressCheckOutFailure"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">New Order</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.newPOSOrder"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>

            <div class="column">
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Self Check-in successful</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.selfCheckInSuccess"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>

            <div class="column">
              <div class="ui tiny header">Self Check-in failed</div>
              <sui-checkbox [(inputValue)]="notificationConfigDto.selfCheckInFailure"
                            (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- right side -->
    <div class="eight wide column">
    </div>
  </div>

  <!-- Finish Container-->
  <!-- dimmer -->
  <div #componentDimmer class="ui inverted dimmer">
    <div class="ui loader"></div>
  </div>

</div>

