import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class RequestDetailGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
      const id = +route.url[1].path;

      if (isNaN(id) || id < 1) {
          console.log('Invalid Id');
          // start a new navigation to redirect to list page
          this.router.navigate(['/requests']);
          // abort current navigation
          return false;
      };

      return true;
    }
}
