﻿

    
    export enum AdhesionMonth {
        
        
        None = "None",
        
        Jan = "Jan",
        
        Feb = "Feb",
        
        Mar = "Mar",
        
        Apr = "Apr",
        
        May = "May",
        
        Jun = "Jun",
        
        Jul = "Jul",
        
        Aug = "Aug",
        
        Sep = "Sep",
        
        Oct = "Oct",
        
        Nov = "Nov",
        
        Dec = "Dec"
    }
