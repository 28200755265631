export class VoiceConfigurationDTO {

    enablePBX: boolean;

    hasPushes: boolean;

    hasKeyPad: boolean;

    hasCallReception: boolean;

    receptionPBX: string;
    
    wifiPBX: string;
    
    countryCodesPBX: string;
    
    doNotDisturbEnable: string;
    
    doNotDisturbDisable: string;
    
    useThirdPartyPBX: boolean;

    ipPBX: string;
}
