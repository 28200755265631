import {
  Component, OnInit, Output,
  EventEmitter, Input, ViewChild, ElementRef,
  OnChanges, SimpleChanges, Type, QueryList, ViewChildren, AfterViewInit,
  ComponentFactoryResolver, ViewContainerRef, ChangeDetectorRef, OnDestroy, ComponentRef, ChangeDetectionStrategy, ViewRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GridPaginationComponent } from '@app/shared/pagination/grid-pagination/grid-pagination.component';

@Component({
  selector: 'data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataGridComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /*  Configuration/Columns */
  @Input() @Output() public configuration: IDataGridConfiguration<any>;
  /* Data (to show on grid) */
  @Input() public data: Array<any>;

  /*  Paging  */
  private dataCurrentPageNumber: number = 1;
  @Input() private dataTotalRecords: number;
  @Input() private dataPageSize: number;
  @Output() private dataPageChange: EventEmitter<any> = new EventEmitter();
  /*  Sorting */
  @Output() private dataSortingChange: EventEmitter<any> = new EventEmitter();
  /*  Row click */
  @Output() private dataRowClick: EventEmitter<any> = new EventEmitter();

  @ViewChild(GridPaginationComponent) dataGridPaginationComponent: GridPaginationComponent;
  @ViewChildren('refRow', { read: ElementRef }) refRows: QueryList<ElementRef>;
  /**
   * Semantic UI width classes for table columns
   */
  private semanticUiColumnWidth: string[] = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen'];
  /*  Aux. */
  private dataHasChanged: boolean = false;
  private refRowsChangesSub: Subscription;

  /**
   * Is dimmed active
  */
  public isDimmed: boolean;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private changeDetectorRef: ChangeDetectorRef) {
  }

  /*
     NG Lifecycle Hooks
  */

  ngOnInit() {
    if (this.configuration.showLoadingAnimation) {
      this.setGridAsLoading(true);
    }
  }

  ngAfterViewInit(): void {
    this.refRowsChangesSub = this.refRows.changes.subscribe(() => {
      this.dataHasChanged = false;
      if (this.configuration.showLoadingAnimation) {
        this.setGridAsLoading(false);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.refRowsChangesSub) {
      this.refRowsChangesSub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.isFirstChange()) {
      return;
    }

    if (!this.arraysAreEqual(changes.data.currentValue, changes.data.previousValue)) {
      this.dataHasChanged = true;
      if (this.configuration.showLoadingAnimation) {
        this.setGridAsLoading(true);
        //this.changeDetectorRef.detectChanges();
      }
    }
  }

  /**
  * Compare two arrays if they are equals by object prop Id if this prop don't exist it will use the object references
  * @param arr1
  * @param arr2
  */
  private arraysAreEqual(arr1: Array<any>, arr2: Array<any>): boolean {
    if (arr1 == null && arr2 == null) {
      return true;
    }

    if (arr1 == null || arr2 == null) {
      return false;
    }

    if (arr1.length !== arr2.length)
      return false;
    for (let i = arr1.length; i--;) {
      if (arr1[i].hasOwnProperty('id')) {
        let exist: boolean = false;
        for (var j = 0; j < arr2.length; j++) {
          if (arr1[i].id === arr2[j].id) {
            exist = true;
            break;
          }
        }
        if (!exist)
          return false;
      } else if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  private objectEquals(obj1: Object, obj2: Object): boolean {
    for (var p in obj1) {
      if (typeof (obj1[p]) !== typeof (obj2[p])) return false;
      if ((obj1[p] === null) !== (obj2[p] === null)) return false;
      switch (typeof (obj1[p])) {
        case 'undefined':
          if (typeof (obj2[p]) != 'undefined') return false;
          break;
        case 'object':
          if (obj1[p] !== null && obj2[p] !== null && (obj1[p].constructor.toString() !== obj2[p].constructor.toString() || !obj1[p].equals(obj2[p]))) return false;
          break;
        case 'function':
          if (p != 'equals' && obj1[p].toString() != obj2[p].toString()) return false;
          break;
        default:
          if (obj1[p] !== obj2[p]) return false;
      }
    }
    return true;
  }

  private countProps(obj) {
    var count = 0;
    for (var k in obj) {
      if (obj.hasOwnProperty(k)) {
        count++;
      }
    }
    return count;
  };

  private objectEquals2(v1, v2) {

    if (typeof (v1) !== typeof (v2)) {
      return false;
    }

    if (typeof (v1) === "function") {
      return v1.toString() === v2.toString();
    }

    if (v1 instanceof Object && v2 instanceof Object) {
      if (this.countProps(v1) !== this.countProps(v2)) {
        return false;
      }
      var r = true;
      for (var k in v1) {
        if (v1.hasOwnProperty(k)) {
          r = this.objectEquals2(v1[k], v2[k]);
          if (!r) {
            return false;
          }
        }
      }
      return true;
    } else {
      return v1 === v2;
    }
  }

  /*
     Public Functions
  */

  /**
   * Set data grid loading animation and locks
   * @param loading
   */
  public setGridAsLoading(loading: boolean): void {
    if (this.isDimmed === loading) return;

    this.isDimmed = loading;
    if (this.configuration && !(<ViewRef>this.changeDetectorRef).destroyed) {
        this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Resets paging (to page one) and every column sorting to default
   */
  public resetPagingAndSorting() {
    this.configuration.resetColumnsSorting();

    if (this.configuration.hasPaging && this.dataGridPaginationComponent) {
      this.dataGridPaginationComponent.setPage(1);
    }
  }

  /*
     Private Functions
  */

  /**
   * Resolve data grid cell value.
   * Calls configured resolver function, creates dynamic component or accesses indicated property
   * @param dataItem
   * @param columnConfig
   * @param rowNumber
   */
  private resolveDataColumn(dataItem: any, columnConfig: IDataGridColumnConfig<any>, rowNumber: number, refRow: Element, refCell: Element, viewContainerRef: ViewContainerRef): any {
    if (!columnConfig.resolver && !columnConfig.component) {
      if (columnConfig.displayPropertyName) {
        const dataField = columnConfig.displayPropertyName.trim();

        if (dataField) {
          const properties: string[] = dataField.split('.');
          let obj = dataItem[properties[0]];

          for (let propIndex = 1; propIndex < properties.length; propIndex++) {
            obj = obj[properties[propIndex]];
          }

          return obj;
        }
      }

      return null;
    }

    if (columnConfig.resolver && columnConfig.component) {
      throw new Error('Resolver and Component properties cannot be defined simultaneously');
    }

    if (columnConfig.component) {
      this.loadColumnCellComponent(dataItem, columnConfig, rowNumber, refRow, refCell, viewContainerRef);
    }
    else if (columnConfig.resolver) {
      const value = columnConfig.resolver(dataItem, columnConfig, rowNumber, refRow, refCell);
      return value;
    }
    else {
      console.log(`Resolver function and component type not set. Empty contents for cell... [ColIndex] ${columnConfig.index} [ColName] ${columnConfig.name}`);
    }

    return null;
  }

  /**
   * Loads a component dynamically, by type, on a specific data grid cell
   * @param data
   * @param column
   * @param row
   * @param refRow
   * @param refCell
   */
  private loadColumnCellComponent(data: any, column: IDataGridColumnConfig<any>, row: number, refRow: Element, refCell: Element, viewContainerRef: ViewContainerRef) {

    if (!column.component) {
      return;
    }

    let componentRef;
    // IF data changed means that it need to be create
    if (this.dataHasChanged) {
      // Get component type factory and component template container (@ViewChildren)
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(column.component as Type<any>);
      // Clear (if) existing elements on ViewContainer
      viewContainerRef.clear();
      // Creates a component instance
      componentRef = viewContainerRef.createComponent(componentFactory);
      // Aux. variables inside the viewContainerRef for future checks
      (<any>viewContainerRef).componentRef = componentRef;
      (<any>viewContainerRef).previousData = data;
      (<any>viewContainerRef).componentRef.row = row;
      (<any>viewContainerRef).componentRef.column = column.index;
      (<any>viewContainerRef).componentRef.updatingValues = false;
      // Execute resolver, dynamic instance
      column.componentResolver(componentRef, data, refRow, refCell);
      // Needed to avoid ExpressionChangedAfterItHasBeenCheckedError error in runtime - force verification at this point
      componentRef.changeDetectorRef.detectChanges();
      
      if (!this.configuration.alwaysUpdateComponentData) {
        return;
      }

      return;
    }

    if (!this.configuration.alwaysUpdateComponentData) {
      return;
    }

    if (viewContainerRef && (<any>viewContainerRef).componentRef && this.getHashCode(data) !== this.getHashCode((<any>viewContainerRef).previousData)) {

      // get the componentRef from the dummy variable inside the viewContainerRef
      componentRef = (<any>viewContainerRef).componentRef;
      componentRef.previousData = data;
      componentRef.updatingValues = true;
      // Store reference for later use on destroy event
      column.componentResolver(componentRef, data, refRow, refCell);
      // Needed to avoid ExpressionChangedAfterItHasBeenCheckedError error in runtime - force verification at this point
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  private getHashCode(obj: Object) {
    const objStr = JSON.stringify(obj);
    var hash = 0, i, chr;

    if (objStr.length === 0) {
      return hash;
    }
    for (i = 0; i < objStr.length; i++) {
      chr   = objStr.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32-bit integer
    }
    return hash;
  }

  private resolveCellClass(dataItem: any, column: IDataGridColumnConfig<any>): string {
    if (column.resolveCellClass) {
      return column.resolveCellClass(dataItem);
    }
    return null;
  }

  /**
   * Gets the css classes for data grid's column headers
   * @param column
   */
  private getCssClassesForHeader(column: IDataGridColumn): any {
    let classes = {
      sorted: column.isSortable,
      ascending: column.isSortedAscendingly(),
      descending: column.isSortedDescendingly()
    };

    if (!this.configuration.equallySpacedColumns && column.columnGridWidth && column.columnGridWidth > 0) {
      classes[this.semanticUiColumnWidth[column.columnGridWidth - 1]] = true;
      classes['wide'] = true;
    }

    if (column.addCssClassesForHeader) {
      classes[column.addCssClassesForHeader] = true;
    }

    return classes;
  }

  /**
   * Gets the css classes for data grid's rows
   */
  private getCssClassesForRow(): any {
    let classes = {
      clickable: this.configuration.hasClickableRows,
      row: this.configuration.hasClickableRows
    };

    return classes;
  }

  /**
   * Indicates if a row should be displayed with warning styles
   * @param dataItem Data item to be bound tothe data grid row
   */
  private rowHasWarning(dataItem: any): boolean {
    if (!this.configuration.warningRowResolver) {
      return false;
    }

    return this.configuration.warningRowResolver(dataItem);
  }

  /*
     Grid Actions
  */

  /**
   * Pagination component page change handler
   * @param page
   */
  private onPageChange(page: number): void {
    if (!this.configuration.hasPaging) {
      return;
    }

    if (this.configuration.showLoadingAnimation) {
      this.setGridAsLoading(true);
    }

    this.dataCurrentPageNumber = page;
    this.dataPageChange.next(page);
  }

  /**
   * On column header click handler - sort
   * @param column
   */
  private onHeaderClick(column: IDataGridColumn): void {
    if (!column.isSortable || this.data.length === 0) {
      return;
    }

    column.currentSortDirection = !column.isSorted()
      ? DataGridColumnSortDirection.Asc
      : (column.isSortedDescendingly() ? null : DataGridColumnSortDirection.Desc);

    // Clear sort on other columns -- single column ordering for now
    this.configuration
      .columns
      .forEach(
        (col) => {
          if (col.index !== column.index) {
            col.currentSortDirection = null;
          }
        }
      );

    // Notify sorting change
    this.dataSortingChange.next(this.dataCurrentPageNumber);

    // Trigger page #1 get, IF configured
    if (this.configuration.hasPaging && this.configuration.backToFirstPageOnSort) {
      if (this.configuration.showLoadingAnimation) {
        this.setGridAsLoading(true);
      }

      this.dataGridPaginationComponent.setPage(1);
      this.dataPageChange.next(1);
    }
  }

  /**
   * On row click handler
   * @param dataItem
   */
  private onRowClick(dataItem: any): void {
    if (this.configuration.hasClickableRows) {
      this.dataRowClick.next(dataItem);
    }
  }

  public trackRowById(index: number, item: any): number {
    return item.id;
  }

  public trackCellByIndex(index: number, item: any): number {
    return index;
  }

}

/**
 * Sort direction enumeration for columns
 */
export enum DataGridColumnSortDirection {
  Asc = 0,
  Desc = 1
}

/**
 * Column value component resolver (function) type
 */
export type DataGridColumnComponentResolver<TD> = (componentRef: ComponentRef<any>, dataItem: TD, refRow: Element, refCell: Element) => void;

/**
 * Column value resolver (function) type
 */
export type DataGridColumnResolver<TD> = (dataItem: TD, columnConfig: IDataGridColumnConfig<TD>, rowNumber?: number, refRow?: Element, refCell?: Element) => string;

/**
 * Column warning resolver (function) type
 */
export type DataGridColumnWarningResolver = (dataItem: any) => boolean;

/**
 * Cell value resolver (function) type
 */
export type DataGridCellClassResolver<TD> = (dataItem: TD) => string;

/**
 * Describes a DataGrid column configuration
 */
export interface IDataGridColumnConfig<TD> {
  index?: number;
  name?: string | null;
  columnGridWidth?: number | null;
  headerText: string;
  displayPropertyName?: string;
  /* Sort */
  isSortable: boolean;
  sortDataField?: string | null;
  currentSortDirection?: DataGridColumnSortDirection | null;
  /* Content */
  component?: Type<any> | null;
  componentResolver?: DataGridColumnComponentResolver<TD> | null;
  resolver?: DataGridColumnResolver<TD> | null;
  /* css*/
  resolveCellClass?: DataGridCellClassResolver<TD>;
  addCssClassesForHeader?: string;
}

export class DataGridColumnConfig implements IDataGridColumnConfig<any> {
  public index: number;
  public name: string;
  public columnGridWidth: number;
  public headerText: string;
  public displayPropertyName: string;
  public isSortable: boolean;
  public sortDataField: string;
  public currentSortDirection: DataGridColumnSortDirection;
  public component: Type<any>;
  public componentResolver: DataGridColumnComponentResolver<any>;
  public resolver: DataGridColumnResolver<any> | null;
  public resolveCellClass: DataGridCellClassResolver<any>;
  public addCssClassesForHeader: string;
}

/**
 * Describes a DataGrid column
 */
export interface IDataGridColumn extends IDataGridColumnConfig<any> {
  resetSorting(): void;
  isSortedAscendingly(): boolean;
  isSortedDescendingly(): boolean;
  isSorted(): boolean;
}

/**
 * DataGrid column configuration object
 */
export class DataGridColumn<TD> implements IDataGridColumn {
  public index: number;
  public name: string;
  public columnGridWidth: number | null;
  public headerText: string;
  public displayPropertyName: string;
  /* Sort */
  public isSortable: boolean;
  public sortDataField: string;
  public currentSortDirection: DataGridColumnSortDirection | null;
  /* Content */
  public component: Type<any> | null;
  public componentResolver: DataGridColumnComponentResolver<TD> | null;
  public resolver: DataGridColumnResolver<TD> | null;
  /* css styles */
  public resolveCellClass: DataGridCellClassResolver<TD> | null;
  public addCssClassesForHeader: string;

  constructor(config: IDataGridColumnConfig<TD> = {} as IDataGridColumnConfig<TD>) {
    let {
      index = 0,
      name = null,
      columnGridWidth = null,
      headerText = null,
      displayPropertyName = null,
      isSortable = false,
      sortDataField = null,
      currentSortDirection = null,
      component = null,
      componentResolver = null,
      resolver = null,
      resolveCellClass = null,
      addCssClassesForHeader = null
    } = config;

    this.index = config.index;
    this.name = config.name;
    this.columnGridWidth = config.columnGridWidth;
    this.headerText = config.headerText;
    this.displayPropertyName = config.displayPropertyName;
    this.isSortable = config.isSortable;
    this.sortDataField = config.sortDataField;
    this.currentSortDirection = config.currentSortDirection;
    this.component = config.component;
    this.componentResolver = config.componentResolver;
    this.resolver = config.resolver;
    this.resolveCellClass = config.resolveCellClass;
    this.addCssClassesForHeader = config.addCssClassesForHeader;
  }


  public resetSorting(): void {
    if (this.isSortable) {
      this.currentSortDirection = null;
    }
  }

  public isSortedAscendingly(): boolean {
    return this.currentSortDirection === DataGridColumnSortDirection.Asc;
  }

  public isSortedDescendingly(): boolean {
    return this.currentSortDirection === DataGridColumnSortDirection.Desc;
  }

  public isSorted(): boolean {
    return this.currentSortDirection !== null;
  }
}

/**
 * Describes a DataGrind instance configuration
 */
export interface IDataGridConfiguration<TD> {
  columns: IDataGridColumn[];
  alternateRowsColor: boolean;
  hasPaging: boolean;
  hasClickableRows: boolean;
  showLoadingAnimation: boolean;
  backToFirstPageOnSort: boolean;
  equallySpacedColumns: boolean;
  warningRowResolver: DataGridColumnWarningResolver;

  addColumn(column: IDataGridColumnConfig<TD>): void;
  resetColumnsSorting(): void;
  getCurrentlySortedColumns(): IDataGridColumnConfig<TD>[];
  getColumnsSortAsApiCallParam(): string;
  alwaysUpdateComponentData: boolean;
}

/**
 * DataGrid instance configuration
 */
export class DataGridConfiguration<TD> implements IDataGridConfiguration<TD> {
  public columns: IDataGridColumn[];
  public alternateRowsColor: boolean;
  public hasPaging: boolean;
  public hasClickableRows: boolean;
  public showLoadingAnimation: boolean;
  public backToFirstPageOnSort: boolean;
  public equallySpacedColumns: boolean;
  public warningRowResolver: DataGridColumnWarningResolver;
  public alwaysUpdateComponentData: boolean;

  constructor(columns: IDataGridColumn[] = null, hasPaging: boolean = true, hasClickableRows: boolean = false,
    showLoadingAnimation: boolean = true, backToFirstPageOnSort: boolean = true, equallySpacedColumns: boolean = false,
    alternateRowsColor: boolean = true, warningRowResolver: DataGridColumnWarningResolver = null, alwaysUpdateComponentData: boolean = false) {
    this.columns = !columns ? new Array<IDataGridColumn>() : columns;
    this.alternateRowsColor = alternateRowsColor;
    this.hasPaging = hasPaging;
    this.hasClickableRows = hasClickableRows;
    this.showLoadingAnimation = showLoadingAnimation;
    this.backToFirstPageOnSort = backToFirstPageOnSort;
    this.equallySpacedColumns = equallySpacedColumns;
    this.warningRowResolver = warningRowResolver;
    this.alwaysUpdateComponentData = alwaysUpdateComponentData;
  }

  public addColumn(columnConfig: IDataGridColumnConfig<TD>) {
    if (columnConfig.index == null) {
      columnConfig.index = this.columns.length;
    }

    this.columns.push(new DataGridColumn(columnConfig));
  }

  public resetColumnsSorting(): void {
    for (let column of this.columns) {
      column.resetSorting();
    }
  }

  public getCurrentlySortedColumns(): IDataGridColumn[] {
    return this.columns.filter((col) => col.isSortable && col.sortDataField && col.currentSortDirection !== null);
  }

  public getColumnsSortAsApiCallParam(): string {
    const columns = this.getCurrentlySortedColumns();

    if (columns.length === 0) {
      return null;
    }

    let expression = '';

    for (let index = 0; index < columns.length; index++) {
      if (!columns[index].isSortable || !columns[index].sortDataField) {
        continue;
      }

      expression += `,${columns[index].isSortedDescendingly() ? `-${columns[index].sortDataField}` : columns[index].sortDataField}`;
    }

    expression = expression.startsWith(',')
      ? expression.slice(1, expression.length)
      : expression;

    return !expression
      ? null
      : expression;
  }
}
