<!-- Info area, grid -->
<div class="ui blurring dimmable grid">

  <!--Begin Container-->
  <div class="row" *ngIf="voiceConfigurationDto">
    <!-- left side -->
    <div class="eight wide column">

      <h4 class="ui top attached header">
        <div><i class="phone icon"></i>PBX</div>
      </h4>
      <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
        <div class="ui grid">

          <div class="doubling two column row">
            <div class="column ui form">
              <div class="ui tiny header">Enabled</div>
              <sui-checkbox [(inputValue)]="voiceConfigurationDto.enablePBX" (onChangeValue)="onCheckboxChange($event)">
              </sui-checkbox>
            </div>

            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Has Pushes</div>
                <sui-checkbox [(inputValue)]="voiceConfigurationDto.hasPushes" (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
          </div>

          <div class="doubling two column row">
            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Has KeyPad</div>
                <sui-checkbox [(inputValue)]="voiceConfigurationDto.hasKeyPad" (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>

            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Has Call Reception</div>
                <sui-checkbox [(inputValue)]="voiceConfigurationDto.hasCallReception"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
          </div>

          <div class="doubling two column row">
            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Ip</div>
                <sui-input [placeholder]="'Ip'" [(inputValue)]="voiceConfigurationDto.ipPBX" (onChangeComplete)="save()"
                  (keydown.enter)="save()">
                </sui-input>
              </div>
            </div>
            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Reception</div>
                <sui-input [placeholder]="'Reception'" [(inputValue)]="voiceConfigurationDto.receptionPBX"
                  (onChangeComplete)="save()" (keydown.enter)="save()">
                </sui-input>
              </div>
            </div>
          </div>

          <div class="doubling two column row">
            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Do Not Disturb Enable Extension</div>
                <sui-input [placeholder]="'Do Not Disturb Enable Extension'"
                  [(inputValue)]="voiceConfigurationDto.doNotDisturbEnable" (onChangeComplete)="save()"
                  (keydown.enter)="save()">
                </sui-input>
              </div>
            </div>
            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Do Not Disturb Disabled Extension</div>
                <sui-input [placeholder]="'Do Not Disturb Disabled Extension'"
                  [(inputValue)]="voiceConfigurationDto.doNotDisturbDisable" (onChangeComplete)="save()"
                  (keydown.enter)="save()">
                </sui-input>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Required Network Wifi</div>
                <chips [defaultText]="'Wifi network'" [tags]="voiceConfigurationDto.wifiPBX"
                  (onChangeValue)="onChangeWifi($event)">
                </chips>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Allowed Country Codes Prefixes</div>
                <chips [defaultText]="'Country code prefix'" [tags]="voiceConfigurationDto.countryCodesPBX"
                  (onChangeValue)="onChangeCountryCodes($event)">
                </chips>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="column ui form">
              <div class="field" [class.disabled]="isDisabled()">
                <div class="ui tiny header">Extensions</div>
                <div class="ui basic icon buttons">
                  <label for="importExtensionsInput" class="ui basic button"
                    data-tooltip="Import new list (file format: *.csv)" data-position="top left">
                    <i class="upload blue icon"></i>
                    Import
                  </label>
                  <input type="file" accept=".csv" (change)="importExtensionsChange($event)" name="importExtensions"
                    style="display: none;" id="importExtensionsInput" />
                  <label class="ui basic button" data-tooltip="Export current list" data-position="top left"
                    (click)="exportExtensionsClick($event)">
                    <i class="download green icon"></i>
                    Export
                  </label>
                  <label class="ui basic button" data-tooltip="Download example" data-position="top left"
                    (click)="downloadExampleClick($event)">
                    <i class="file icon"></i>
                    Example
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <!-- right side -->
    <div class="eight wide column">
    </div>

  </div>

  <!--Finish Container-->
  <!-- dimmer -->
  <div #componentDimmer class="ui inverted dimmer">
    <div class="ui loader"></div>
  </div>

</div>