import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ConversationListActionsService } from '@app/chat/conversation-list-actions/conversation-list-actions.service';
import { ConciergeServiceState } from '@models/enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'conversation-list-actions',
  templateUrl: 'conversation-list-actions.component.html',
  styleUrls: ['conversation-list-actions.component.css']
})
export class ConversationListActionsComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  public hidden: boolean = true;

  @ViewChild('conciergeStatusDdl') conciergeStatusDdl: ElementRef;
  public conciergeStatusDdlIsLoading: boolean;

  @ViewChild('conciergeForActiveUsersStatusDdl') conciergeForActiveUsersStatusDdl: ElementRef;
  public conciergeForActiveUsersDdlIsLoading: boolean;

  constructor(private conversationListActionsService: ConversationListActionsService) {
    this.subscription.add(this.conversationListActionsService
      .getConciergeStateSelectedValue()
      .subscribe((state: ConciergeServiceState) => this.setDropDownSelectedValue(this.conciergeStatusDdl, state)));

    this.subscription.add(this.conversationListActionsService
      .getConciergeDdlLoadingState()
      .subscribe((isLoading: boolean) => this.setDropDownLoadingState(this.conciergeStatusDdl, isLoading)));

    this.subscription.add(this.conversationListActionsService
      .getConciergeForActiveUsersSelectedValue()
      .subscribe((state: ConciergeServiceState) => this.setDropDownSelectedValue(this.conciergeForActiveUsersStatusDdl, state)));

    this.subscription.add(this.conversationListActionsService
      .getConciergeForActiveUsersDdlLoadingState()
      .subscribe((isLoading: boolean) => this.setDropDownLoadingState(this.conciergeForActiveUsersStatusDdl, isLoading)));

    this.subscription.add(this.conversationListActionsService
      .getHidden().subscribe((hidden: boolean) => this.setHidden(hidden)));
  }

  ngOnInit() {
    this.configureConciergeDdl();
    this.configureConciergeForActiveUsersDdl();
  }

  private setHidden(hidden: boolean) {
    this.hidden = hidden;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setDropDownSelectedValue(dropDownElement: ElementRef, state: ConciergeServiceState) {
    if (!state) {
      state = ConciergeServiceState.None;
    }

    $(dropDownElement.nativeElement).dropdown('set selected', state);
  }

  private setDropDownLoadingState(dropDownElement: ElementRef, isLoading: boolean) {
    if (isLoading) {
      $(dropDownElement.nativeElement).dropdown('set loading');
    }

    $(dropDownElement.nativeElement).dropdown('remove loading');
  }

  private getDropDownBgColor(dropDownElement: ElementRef): string {
    const jQElement = $(dropDownElement.nativeElement);
    const value = jQElement.dropdown('get value');
    const item = value ? jQElement.dropdown('get item', value) : null;

    return item ? item.data('color') : null;
  }

  /* General Audience --- UI/UX */
  private configureConciergeDdl() {
    $(this.conciergeStatusDdl.nativeElement).dropdown({
      direction: 'downward',
      action: 'activate',
      preserveHTML: false,
      showOnFocus: false,
      keepOnScreen: true,
      context: $(this.conciergeStatusDdl.nativeElement).parent(),
      onChange: (value, text, optionElement) =>
        this.conversationListActionsService.setConciergeState(<ConciergeServiceState>value)
    }
    );
  }

  public getConciergeDdlBgColor() {
    return this.getDropDownBgColor(this.conciergeStatusDdl);
  }

  /* Active Users/Conversations Audience */
  private configureConciergeForActiveUsersDdl() {
    $(this.conciergeForActiveUsersStatusDdl.nativeElement).dropdown({
      direction: 'downward',
      action: 'activate',
      preserveHTML: false,
      showOnFocus: false,
      keepOnScreen: true,
      context: $(this.conciergeForActiveUsersStatusDdl.nativeElement).parent(),
      onChange: (value, text, optionElement) =>
        this.conversationListActionsService.setConciergeForActiveUsersState(<ConciergeServiceState>value)
    }
    );
  }

  public getConciergeForActiveUsersDdlBgColor() {
    return this.getDropDownBgColor(this.conciergeForActiveUsersStatusDdl);
  }
}
