﻿

	 import { CountryDTO } from '@models/i18n/CountryDTO';
	 import { ChatUserDTO } from '@models/chat/ChatUserDTO';
    
    export class ChatUserFullDTO extends ChatUserDTO {
        
        
        /**
        * User's nationality country
        */
        country: CountryDTO;
        
        /**
        * User who has created the entity.
        */
        createdBy: string;
        
        /**
        * Last User to update the entity.
        */
        lastUpdatedBy: string;
    }
