import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'data-grid-counter-item',
  templateUrl: 'data-grid-counter-item.component.html',
  styleUrls: ['data-grid-counter-item.component.css']
})
export class DataGridCounterItemComponent implements OnInit {
  @Input() public text: string;
  @Input() public hasLabel: boolean;
  @Input() public labelValue: string | any;
  @Input() public labelIsCircular: boolean = true;
  @Input() public labelColor: string;

  constructor() {
    this.labelColor = "red";
  }

  ngOnInit() {
  }
}
