﻿

	 import { ContactType } from '@models/enum/ContactType';
	 import { ContactTypeDTO } from '@models/ContactTypeDTO';
    
    export class ContactDTO {
        
        
        /**
        * Entity unique identifier.
        */
        id?: number | null;
        
        /**
        * The actual contact value.
        */
        value: string;
        
        /**
        * Contact description  <remarks>Translatable property</remarks>
        */
        description: string;
        
        /**
        * The Contact type.
        */
        type: ContactType;
        
        /**
        * Contact's <see cref="P:AppGenerator.Data.TransferObjects.ContactDTO.ContactType" /> type
        */
        contactType: ContactTypeDTO;
        
        /**
        * Entity display order number.
        */
        displayOrderNumber?: number | null;
    }
