<div id="requests-grid-container" #requestsGridContainer class="ui basic blurring dimmable segment without padding">
  <div class="ui form">
    <div class="fields">
      <div class="three wide field">
        <label>Request</label>
        <sui-dropdown
          [defaultText]="'Select Request'"
          [collection]="requestsTypes"
          [(selectedValue)]="searchFilter.type"
          [nameResolver]="requestNameResolver"
          [itemImageResolver]="requestIconResolver"
          [observable]="requestObservableResolver"
          [itemType]="'Image'">
        </sui-dropdown>
      </div>
      <div class="three wide field">
        <label>Guest name</label>
        <sui-input [placeholder]="'Guest name'"
                   [(inputValue)]="searchFilter.userName"
                   (keydown.enter)="applyDataFilter()">
        </sui-input>
      </div>
      <div class="three wide field">
        <label>Contact</label>
        <sui-input
          [placeholder]="'Contact'"
          [(inputValue)]="searchFilter.userContact"
          (keydown.enter)="applyDataFilter()">
        </sui-input>
      </div>

      <div class="three wide field">
        <label>State</label>
        <div #DdStates class="ui selection dropdown multiple">
          <input name="state" type="hidden">
          <i class="dropdown icon"></i>
          <div class="default text">State</div>
          <div class="menu">
            <div class="item" *ngFor="let state of requestStatesList" [attr.data-value]="state">{{state}}</div>
          </div>
        </div>
      </div>

      <div class="one field">
        <label></label>
        <div (click)="applyDataFilter()" class="ui primary button">Search</div>
      </div>

    </div>
  </div>

  <data-grid #dataGridComponent
             [(configuration)]="gridConfiguration"
             [data]="requests"
             [dataPageSize]="pageSize"
             [dataTotalRecords]="totalRecords"
             (dataRowClick)="onDataRowClick($event)"
             (dataPageChange)="onDataGridPagingChange($event)"
             (dataSortingChange)="onDataGridSortingChange($event)">
  </data-grid>

  <div class="ui basic without padding segment">
    Close and mark as read requests older than
    <div #DdClose class="ui inline dropdown">
      <div class="default text">none</div>
      <i class="dropdown icon"></i>
      <div class="menu">
        <div *ngFor="let requestState of requestClosedList" class="item" [attr.data-value]="requestState" [attr.data-text]="resolveRequestState(requestState)">{{resolveRequestState(requestState)}}</div>
      </div>
    </div>
    days
  </div>

  <div #popupCommand class="ui tiny modal" style="display: none;">
    <div class="header">Close requests</div>
    <div class="content">
      <p>Are you sure you want to close the requests?</p>
    </div>
    <div class="actions">
      <div class="ui negative button">No</div>
      <div class="ui positive right labeled icon button">
        Yes
        <i class="checkmark icon"></i>
      </div>
    </div>
  </div>

</div>
