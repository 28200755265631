<div class="ui basic blurring dimmable segment without padding" [class.dimmed]="isDimmed">
  <!-- Call isDimmed=false|true on this element on loading/ready data states -->
  <table #dataGrid class="ui compact small selectable single line sortable celled tablet stackable table" [class.striped]="configuration.alternateRowsColor">
    <thead>
      <tr>
        <th *ngFor="let column of configuration.columns"
            [ngClass]="getCssClassesForHeader(column)"
            (click)="onHeaderClick(column)">{{column.headerText}}</th>
      </tr>
    </thead>
    <tbody>
      <tr #refRow class="middle aligned" *ngFor="let dataItem of data; let rowIndex = index; trackBy: trackRowById" [class.warning]="rowHasWarning(dataItem)" [ngClass]="getCssClassesForRow()" (click)="onRowClick(dataItem)">
        <td #refCell *ngFor="let column of configuration.columns; trackBy: trackCellByIndex" [attr.data-component-row]="rowIndex" [attr.data-component-col]="column.index" [ngClass]="resolveCellClass(dataItem, column)" [class.ellipsisText]="!column.component">
          <ng-template viewContainer #columnComponentTemplate="viewContainer"></ng-template>
          {{resolveDataColumn(dataItem, column, rowIndex, refRow, refCell, columnComponentTemplate.viewContainerRef)}}
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="configuration.hasPaging && data && this.data.length > 0">
      <tr>
        <th [attr.colspan]="configuration.columns.length">
          <grid-pagination (pageChange)="onPageChange($event)" [pageSize]="dataPageSize" [totalRecords]="dataTotalRecords"></grid-pagination>
        </th>
      </tr>
    </tfoot>
  </table>
  <div class="ui simple inverted dimmer">
    <div class="ui loader"></div>
  </div>
  <div *ngIf="data && data.length === 0" class="ui basic center aligned segment" id="no-records-message">
    <h4 class="ui center aligned icon header">
      <i class="big earlybirds icon"></i>No records found!
    </h4>
  </div>
</div>
