import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment/moment';
import { EmptyFieldPipe } from './empty-field.pipe';

@Pipe({
    name: 'CustomDate'
})
export class CustomDatePipe implements PipeTransform {

    public transform(value: moment.MomentInput, onlyDate: boolean = false, manyDays: number = 2): string {

      if (!value) {
        return new EmptyFieldPipe().transform();
      }

      if (onlyDate) {
        return moment(value).format('YYYY/MM/DD');
      }

      if (manyDays && manyDays >= moment.utc().diff(moment(value), 'days')) {
        return moment(value).local().fromNow();
      }

      return moment(value).format('YYYY/MM/DD HH:mm');
    }
}
