﻿

    
    export enum MessagingSource {
        
        
        Undefined = "Undefined",
        
        GuestU = "GuestU",
        
        Twilio = "Twilio"
    }
