﻿

	 import { Moment } from 'moment';
    
    export class SurveysAveragePerWeekDTO {
        
        
        /**
        * StartDate
        */
        startDate: Moment;
        
        /**
        * EndDate
        */
        endDate: Moment;
        
        /**
        * Rating average
        */
        average: number;
        
        /**
        * Entity name
        */
        name: string;
    }
