import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sui-textarea',
  templateUrl: 'sui-textarea.component.html',
  styleUrls: ['sui-textarea.component.css']
})

export class TextareaComponent implements OnInit {

  @Output() public blur: EventEmitter<any> = new EventEmitter();
  @Output() public onChangeComplete: EventEmitter<void> = new EventEmitter();

  @Input() placeholder: string;
  @Input() public rows: number;

  private autofocus: boolean;

  /**
  * inputValue [()]
  */
  inputValueLocalOld: string;
  inputValueLocal: string;
  @Input()
  set inputValue(val) {
    this.inputValueLocal = val;
    this.inputValueChange.emit(this.inputValueLocal);
  }

  get inputValue() {
    return this.inputValueLocal;
  }
  @Output() inputValueChange: EventEmitter<any> = new EventEmitter();

  ngOnInit() {

  }

  onBlur(event: FocusEvent) {
    if (this.blur) {
      this.blur.emit(event);
      if (this.inputValueLocalOld !== this.inputValueLocal) {
        this.inputValueLocalOld = this.inputValueLocal;
        this.onChangeComplete.emit();
      }
    }
  }

  private errorHandle(error: any): void {
    console.log(error);
  }

}
