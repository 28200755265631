﻿

    
    export enum RequestType {
        
        
        Unknown = "Unknown",
        
        CallATaxi = "CallATaxi",
        
        Transfer = "Transfer",
        
        PrivateCar = "PrivateCar",
        
        ExternalUrl = "ExternalUrl",
        
        WifiPassword = "WifiPassword",
        
        RequestTowels = "RequestTowels",
        
        BookATable = "BookATable",
        
        AccomodationBooking = "AccomodationBooking",
        
        CheckIn = "CheckIn",
        
        CheckOut = "CheckOut",
        
        LateCheckOut = "LateCheckOut",
        
        LostOrDelayedLuggage = "LostOrDelayedLuggage",
        
        RequestANanny = "RequestANanny",
        
        SPA = "SPA",
        
        Massage = "Massage",
        
        BedAndBathExtras = "BedAndBathExtras",
        
        RoomService = "RoomService",
        
        HouseKeeping = "HouseKeeping",
        
        Newspaper = "Newspaper",
        
        PickUpBags = "PickUpBags",
        
        WakeUpCall = "WakeUpCall",
        
        Maintenance = "Maintenance",
        
        RoomCleaning = "RoomCleaning",
        
        ToursAndActivities = "ToursAndActivities",
        
        Event = "Event",
        
        Login = "Login",
        
        PersonalTrainning = "PersonalTrainning",
        
        Enquire = "Enquire",
        
        CallATaxiSimplified = "CallATaxiSimplified",
        
        WakeUpCallSimplified = "WakeUpCallSimplified",
        
        ToursActivitiesSelection = "ToursActivitiesSelection",
        
        RoomServiceSelection = "RoomServiceSelection",
        
        RentACar = "RentACar",
        
        TransferSelection = "TransferSelection",
        
        ShowsActivities = "ShowsActivities",
        
        EnquireNow = "EnquireNow",
        
        EnquireNowSelection = "EnquireNowSelection",
        
        ParkingCar = "ParkingCar",
        
        BookATableApartments = "BookATableApartments",
        
        CallATaxiApartments = "CallATaxiApartments",
        
        LateCheckOutApartments = "LateCheckOutApartments",
        
        ParkingApartments = "ParkingApartments",
        
        FruitBowl = "FruitBowl",
        
        DogPackage = "DogPackage",
        
        CheckInDetailed = "CheckInDetailed",
        
        MaintenanceApartments = "MaintenanceApartments",
        
        BookASeat = "BookASeat",
        
        PrivateEventDetailed = "PrivateEventDetailed",
        
        BreakfastApartments = "BreakfastApartments",
        
        BreakfastHotel = "BreakfastHotel",
        
        CheckInSEF = "CheckInSEF",
        
        Reviews = "Reviews",
        
        ToursActivitieswithPromoCode = "ToursActivitieswithPromoCode"
    }
