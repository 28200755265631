import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatService } from '../chat.service';
import {
  ChatConversationFullDTO,
  ChatMessageFullDTO,
  ChatUserUpdateStateDTO
} from '@models/chat';

import {
  ChatMessageSearchFilter
} from '@models/search-filters';

import {
  MessagingUserState,
  MessagingSource,
  MessagingType,
  MessagingState,
  MessagingCommunicationMedium,
  Platform
} from '@models/enum';

import {
  AppHeaderService,
  BreadcrumbSection,
  MessagingContainerComponent
} from '@app/shared';

import { ChatDetailActionsService } from '@app/chat/chat-detail-actions/chat-detail-actions.service';
import { ChatDetailActionsComponent } from '@app/chat/chat-detail-actions/chat-detail-actions.component';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication.service';
import { NavigationService } from '@app/navigation/navigation.service';
import * as moment from 'moment';
import { NotificationsService } from '@app/notifications/notifications.service';
import { NotifierService } from '@app/core/notifications/notifier.service';


@Component({
  selector: 'chat-detail',
  templateUrl: 'chat-detail.component.html',
  styleUrls: ['chat-detail.component.css']
})

export class ChatDetailComponent implements OnInit, OnDestroy {
  // Open thread/conversation Identifiers
  private conversationEntityId: number;
  private conversationUserId: number;
  private conversationId: string;

  // Conversation (Active detailed/opened)
  public conversation: ChatConversationFullDTO;
  // Messages
  public messages: ChatMessageFullDTO[] = null;
  private messagesListingPageSize: number = 10;
  private totalExistingMessages: number;
  private messagesListingCurrentPage: number = 1;
  public newMessageText: string = '';
  private getLastMessagesDelay: number = 15000;
  private getLastMessagesIntervalId: any = null;
  // Actions component (chat service state, global and for active users) all Subscriptions
  private subscription = new Subscription();

  // > Dimmers
  @ViewChild('componentDimmer') componentDimmer: ElementRef;
  private fetchingComponentData: boolean;
  @ViewChild('messagesAreaDimmer') messagesAreaDimmer: ElementRef;
  @ViewChild('newMessageTextArea') newMessageTextArea: ElementRef;
  // Child Components @View
  @ViewChild(MessagingContainerComponent) private messagesContainer: MessagingContainerComponent;

  private fetchingMessagesData: boolean;

  constructor(private chatService: ChatService, private appHeaderService: AppHeaderService, private notifierService: NotifierService,
    private router: Router, private route: ActivatedRoute, private chatDetailActionsService: ChatDetailActionsService,
    private authService: AuthenticationService, private notificationsService: NotificationsService, private navigationService: NavigationService)
  {
    this.subscription.add(this.chatDetailActionsService
      .getChatUserState()
      .subscribe((state: MessagingUserState) =>
        this.updateChatUserState(state)
      ));
  }

  ngOnInit() {
    this.subscription.add(this.route.params
      .subscribe
      ((params: any) => {
        this.conversationId = params['id'];

        const conversationIdentifiers = params['id'].split('-', 2);
        [this.conversationEntityId, this.conversationUserId] = conversationIdentifiers;

        this.getByIdentifier(params['id']);
      }));

    this.newMessageTextArea.nativeElement.focus();
  }

  ngOnDestroy(): void {
    // Message polling for active/opened conversation
    clearTimeout(this.getLastMessagesIntervalId);
    this.getLastMessagesIntervalId = null;

    // Un-subscriptions
    this.subscription.unsubscribe();
  }

  // Conversation info. and messages
  private getByIdentifier(identifier: string): void {
    this.setComponentDimmerVisibility(true);

    this.subscription.add(
      this.chatService.getByIdentifier(identifier)
      .subscribe(
        (value) => {
          this.conversation = value;
          this.appHeaderService.breadcrumbSectionPush(new BreadcrumbSection('Chat with ' + this.conversation.user.name, ['/chat', this.conversation.id]));
          this.appHeaderService.setActionsComponent(ChatDetailActionsComponent);
          this.chatDetailActionsService.setDropDownSelectedValue(this.conversation.user.state);
          this.getMessages();
        },
        (error: any) => {
          switch ((error.status as number)) {
          case 403:
            this.notifierService.error('You don\'t have access to the specified Conversation.', 'Permission Denied');
            this.router.navigate(['/chat']);
            break;
          case 404:
            this.notifierService.warning('The Conversation you tried to access wasn\'t found!', 'Not Found');
            this.router.navigate(['/chat']);
            break;
          default:
            this.handleErrors(error);
            throw(error);
          }
        },
        () => { this.setComponentDimmerVisibility(false); }
      )
    );
  }

  // States -- Chat user, Concierge (general and for active users)
  public updateChatUserState(state: MessagingUserState): void {
    //if same state selected, skip...
    if (this.conversation.user.state === state) {
      return;
    }

    const stateDto = new ChatUserUpdateStateDTO();
    stateDto.state = state;

    this.setComponentDimmerVisibility(true);
    this.chatDetailActionsService.setDropDownLoadingState(true);

    this.subscription.add(this.chatService
      .updateState(this.conversation.id, stateDto)
      .subscribe(
        (response) => {
          this.conversation = response;
          this.notificationsService.refreshNotificationCounters();
        },
        (error: any) => {
          this.handleErrors(error);
          throw(error);
        },
        () => {
          this.chatDetailActionsService.setDropDownLoadingState(false);
          this.setComponentDimmerVisibility(false);
        }
      ));
  }

  // Messages
  private getMessages(page: number = 1, toAppend: boolean = false): void {
    //check if is the last page
    if (page !== 0 && page > Math.ceil(this.totalExistingMessages / this.messagesListingPageSize)) {
      return;
    }

    const filter: ChatMessageSearchFilter = new ChatMessageSearchFilter();

    // Enforce default
    filter.pageSize = this.messagesListingPageSize;
    filter.includeMetadata = true;
    filter.page = page;
    filter.orderBy = null;

    this.showMessagesContainerDimmer();

    this.subscription.add(this.chatService.getMessages(this.conversation.id, filter)
      .subscribe((response) => {
        if (toAppend) {
          this.messages = response.items.reverse().concat(this.messages);
          this.messagesContainer.scrollKeepPosition();
        } else {
          this.messages = response.items.reverse();
          this.messagesContainer.scrollOnBottom();
          this.markCurrentPageMessagesAsRead(this.messages);
          this.getMessagesCheckRead();
        }

        this.totalExistingMessages = response.totalCount;
        this.messagesListingCurrentPage = page;
      },
        (error: any) => {
          this.handleMessagesErrors(error);
          throw(error);
        },
        () => {
          this.loopGetNewMessages();
          this.hideMessagesContainerDimmer();
        }
      ));
  }

  private getLastMessages(page: number = 1): void {

    const filter: ChatMessageSearchFilter = new ChatMessageSearchFilter();

    // Enforce default
    filter.fromDate = (this.messages && this.messages.length > 0)
      ? this.messages[this.messages.length - 1].createdOn
      : moment.utc().subtract(5, "minutes");
    filter.includeMetadata = true;

    this.showMessagesContainerDimmer();

    this.subscription.add(this.chatService.getMessages(this.conversation.id, filter)
      .subscribe((response) => {
        var newMessages = response.items.reverse();
        // If new messages exist
        if (newMessages.length > 0) {

          this.markCurrentPageMessagesAsRead(newMessages);

          this.messages = this.messages.concat(newMessages);
          var newtotalExistingMessages = response.totalCount;
          // Calculate in what page we should be now (that new messages were added to the message list)
          const totalPages = Math.ceil(this.totalExistingMessages / this.messagesListingPageSize);
          const newTotalPages = Math.ceil((newtotalExistingMessages + this.totalExistingMessages) / this.messagesListingPageSize);

          this.messagesListingCurrentPage = Math.ceil(this.messagesListingCurrentPage * newTotalPages / totalPages);
          this.totalExistingMessages += newtotalExistingMessages;
        }

        this.getMessagesCheckRead();
      },
        (error: any) => {
          this.handleMessagesErrors(error);
          throw(error);
        },
        () => {
          this.loopGetNewMessages();
          this.hideMessagesContainerDimmer();
        }
      ));
  }

  public addMessage(): void {
    const messageText = this.newMessageText.trim();

    if (messageText.length === 0) {
      // TODO: Validate form; see Angular validations or implement as needed.
      this.newMessageText = '';
      return;
    }

    const message = new ChatMessageFullDTO();
    message.message = this.newMessageText.trim();
    message.source = MessagingSource.GuestU;
    message.type = MessagingType.TEXT;
    message.medium = MessagingCommunicationMedium.IP;
    message.coordinates = null;
    message.deviceIdentifier = null;

    this.setMessagesDimmerVisibility(true);

    this.subscription.add(
      this.chatService
      .addMessage(this.conversation.id, message)
      .subscribe((response) => {
          /* newly created message discarded as it's not needed for now */
          this.newMessageText = null;
          this.messagesContainer.scrollOnBottom();
          this.getLastMessages();
          this.newMessageTextArea.nativeElement.focus();
          this.refreshConversationState();
        },
        (error: any) => {
          this.handleMessagesErrors(error);
          throw(error);
        },
        () => {
          this.setMessagesDimmerVisibility(false);
        }
      )
    );
  }

  private refreshConversationState() {
    this.subscription.add(this.chatService
      .getByIdentifier(this.conversationId)
      .subscribe(
        (conversation) => {
          this.conversation = conversation;
          this.chatDetailActionsService.setDropDownSelectedValue(conversation.user.state, true);
        },
        (error: any) => {
          this.handleErrors(error);
          throw(error);
        }
      )
    );
  }

  private hideMessagesContainerDimmer() {
    this.fetchingMessagesData = false;
    this.messagesContainer.hideLoadingDimmer();
  }

  public shouldSendMsgBeDisabled() {
    return !this.newMessageText
      || (this.newMessageText && this.newMessageText.length === 0)
      && (this.fetchingMessagesData || this.fetchingComponentData || this.authService.userIsManager());
  }

  private showMessagesContainerDimmer() {
    if (this.fetchingComponentData) {
      return;
    }

    this.fetchingMessagesData = true;
    this.messagesContainer.showLoadingDimmer();
  }

  private setMessagesDimmerVisibility(visible: boolean) {
    if (visible && this.fetchingComponentData) {
      return;
    }

    const dimmerElement =
      $(this.messagesAreaDimmer.nativeElement)
        .dimmer({
          duration: { show: 400, hide: 800 }
        }
        );

    if (visible) {

      dimmerElement.dimmer('show');
    } else {
      dimmerElement.dimmer('hide');

    }
  }

  // Component 
  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement =
      $(this.componentDimmer.nativeElement)
        .dimmer({
          duration: { show: 400, hide: 800 }
        }
        );

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer('show');
    } else {
      dimmerElement.dimmer('hide');
      this.fetchingComponentData = false;
    }
  }


  // Error Handling
  private handleErrors(error: any): void {
    console.log(error);
    this.setMessagesDimmerVisibility(false);
    this.setComponentDimmerVisibility(false);
  }

  private handleMessagesErrors(error: any): void {
    console.log(error);
    this.setMessagesDimmerVisibility(false);
  }

  // Messaging, messages container component
  public onScroll(isOntop: boolean) {
    //get next page
    this.getMessages(++this.messagesListingCurrentPage, true);
  }

  public messageIsFromEndUser(msg: ChatMessageFullDTO): boolean {
    if (!msg) {
      return null;
    }

    return msg.isFromEndUser;
  }

  /*
   * Arrow function used in order to keep the correct value in the 'this' special variable;
   * Creating a regular function would lose the desired context
   * (the caller context would be assumed instead of this component instance)
   */
  public messageUserNameResolver = (msg: ChatMessageFullDTO) => {
    if (!msg || !this.conversation) {
      return null;
    }

    return msg.isFromEndUser ? this.conversation.user.name : 'Property';
  }

  public messageDateResolver(msg: ChatMessageFullDTO) {
    if (!msg || !msg.createdOn) {
      return null;
    }

    return moment(msg.createdOn).format('MMM, DD YYYY HH:mm');
  }

  public messageMessageResolver(msg: ChatMessageFullDTO): string {
    if (!msg) {
      return null;
    }

    return msg.message;
  }

  public messageIsReadResolver(msg: ChatMessageFullDTO): boolean {
    if (!msg) {
      return null;
    }

    return msg.state === MessagingState.Read;
  }

  private loopGetNewMessages(): void {
    if (!!this.getLastMessagesIntervalId) {
      clearTimeout(this.getLastMessagesIntervalId);
    }

    this.getLastMessagesIntervalId = setTimeout(() => this.getLastMessages(), this.getLastMessagesDelay);
  }

  public getFlagClass() {
    if (this.conversation && this.conversation.user && this.conversation.user.country) {
      return this.conversation.user.country.isoCode.toLowerCase() + ' flag';
    }
    return "";
  }

  public getNameFromPlatform(platform: Platform): string {
    if (!platform) {
      return "";
    }

    switch (platform) {
      case Platform.GuestUPhone:
        return "GuestU Phone";
      case Platform.B2BApp:
        return "Mobile App";
      case Platform.Clarice:
        return "Clarice";
      case Platform.B2BAppNG:
        return "Web App";
      case Platform.Unknown:
      default:
        return "Unknown";
    }
  }

  private markCurrentPageMessagesAsRead(messages: ChatMessageFullDTO[]): void {
    // Admin or Manager roles should never set the customers & end-users messages as read
    if (this.authService.userIsAdminOrManager()) {
      return;
    }

    const lastMsg = this.messages[this.messages.length - 1];
    const fromDate = lastMsg.createdOn;

    this.subscription.add(this.chatService.markMessageAsRead(this.conversation.id, fromDate)
      .subscribe(() => {
        messages.forEach((message) => {
          if (message.isFromEndUser) {
            message.state = MessagingState.Read;
          }
        });
        this.notificationsService.refreshNotificationCounters();
      },
        (error: any) => {
          this.handleErrors(error);
          throw(error);
        },
        () => { }
      ));
  }

  private getMessagesCheckRead(): void {
    var messagesIds: number[] = new Array<number>();

    this.messages.forEach((message) => {
      if (!message.isFromEndUser && message.state !== MessagingState.Read) {
        messagesIds.push(message.id);
      }
    });

    this.subscription.add(this.chatService.getMessagesCheckRead(this.conversation.id, messagesIds)
      .subscribe((readMessagesIds) => {
        this.updateMessagesState(readMessagesIds);
      },
        (error: any) => {
          this.handleErrors(error);
          throw(error);
        },
        () => { }
      ));
  }

  private updateMessagesState(messagesIds: number[]): void {
    messagesIds.forEach((id) => {
      this.messages.forEach((message) => {
        if (id === message.id) {
          message.state = MessagingState.Read;
        }
      });
    });
  }

}
