﻿

	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    /**
    * 
    */
    
    export class EntitySearchFilter extends SearchFilter {
        
        
        id?: number | null;
        
        /**
        * Name to search/filter for
        */
        name: string;
    }
