import { Moment } from 'moment';
import { SearchFilter } from '@models/search-filters/SearchFilter';
import { Status } from "@models/firebase/device";

/**
* Sort and filter class for Application Requests
*/

export class DeviceSearchFilter extends SearchFilter {

  /**
  * Delivered Entity unique identifier
  */
  entityId?: number | null;

  /**
  * Device name filter value
  */
  deviceName: string;

  /**
  * Assignment filter value
  */
  assignment: string;

  /**
  * Guest name filter value
  */
  guestName: string;

  /**
  * device Status
  */
  status: Status[];

  /**
  * Creation datetime
  */
  createdOn: Moment;

  /**
  * Last update datetime
  */
  updatedOn: Moment;

  /**
  * Last update datetime
  */
  lastNoteCreatedOn: Moment;
}
