import { throwError,  Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core/authentication.service';
import { RequestSearchFilter } from '@models/search-filters';
import { PagedResult } from '@app/core/PagedResult';
import '@app/core/extensions/HttpClient';
import { Device } from '@models/firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { IdTokenResult } from "@firebase/auth-types/index";
import { GPhoneSimpleCommandDTO } from '@models/guest-u-phone/commands/GPhoneSimpleCommandDTO';
import { GPhoneCheckOutDateUpdateCommandDTO } from '@models/guest-u-phone/commands/GPhoneCheckOutDateUpdateCommandDTO';
import { GPhoneCheckInCommandDTO } from '@models/guest-u-phone/commands/GPhoneCheckInCommandDTO';


// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.core + 'entities';

@Injectable()
export class DevicesService {
// ReSharper disable once InconsistentNaming
  private API_GUESTUPHONES_ROOT: string = environment.apis.core + 'guestu-phones/';

  constructor(private httpClient: HttpClient, private authService: AuthenticationService, public auth: AngularFireAuth) {
  }
  
  public getToken(identifier: number): Observable<string> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('entityId', identifier.toString());

    return this.httpClient
      .get<string>(this.API_GUESTUPHONES_ROOT + 'firebase/custom-token', { params: httpParams })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  /**
    * Get the firebase token and signIn 
    * @param identifier 
    * @param callback
    * @param error 
    * @returns {} 
    */
  public signInFirebase(identifier: number, callback?: ((value: IdTokenResult) => void | PromiseLike<void>), error?: (error: any) => void) {

    identifier = this.authService.getUserSelectedEntityId();

    if (!identifier) {
      if (error) error('Missing Entity identifier');
      return;
    }

    this.getToken(identifier).subscribe((token) => {
      if (this.auth.auth.currentUser) {
        this.auth.auth.signOut().then((value) => this.signInWithCustomTokenFirebase(token, callback));
      } else {
        this.signInWithCustomTokenFirebase(token, callback);
      }
    },
      this.handleError);
  }

  private signInWithCustomTokenFirebase(token: string, callback: (value: IdTokenResult) => void | PromiseLike<void>) {
    this.auth.auth.signInWithCustomToken(token).then((value) => this.auth.auth.currentUser.getIdTokenResult().then(callback).catch(this.handleError)).catch(this.handleError);
  }

  public signOutFirebase(): Promise<any> {
    return this.auth.auth.signOut();
  }

  public sendCommand(gcm: GPhoneSimpleCommandDTO): Observable<void> {
   
    if (!this.authService.userIsAdmin()) {
      gcm.entityId = this.authService.getUserEntityAccessId();
    }

    if (!gcm.entityId) {
      return throwError('Missing or invalid parameters');
    }

    return this.httpClient
      .post<void>(this.API_GUESTUPHONES_ROOT + 'commands', gcm)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public sendUpdateCheckOutDateCommand(extendCheckOutCommand: GPhoneCheckOutDateUpdateCommandDTO): Observable<void> {
    return this.httpClient
      .post<void>(this.API_GUESTUPHONES_ROOT + 'commands/update-check-out', extendCheckOutCommand)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public sendCheckInCommand(extendCheckOutCommand: GPhoneCheckInCommandDTO): Observable<void> {
    return this.httpClient
      .post<void>(this.API_GUESTUPHONES_ROOT + 'commands/check-in', extendCheckOutCommand)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }


  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }
}
