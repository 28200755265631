export class RequestConfigurationDTO {
    /**
    * Request start time
    */
    requestStartTime: string | null;
    
    /**
    * Request end time
    */
    requestEndTime: string | null;
}