import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { EntityType, FontListingDTO } from '@app/models';
import { ListingsService } from '@app/listings/listings.service';
import { DropdownComponent } from '@app/shared';
import { AppearanceConfigurationDTO } from '@models/appearance';

@Component({
  selector: 'appearance-settings',
  templateUrl: './appearance-settings.component.html',
  styleUrls: ['./appearance-settings.component.css']
})
export class AppearanceSettingsComponent implements OnInit, OnDestroy {

  @ViewChild("componentDimmer") componentDimmer: ElementRef;
  @ViewChild("headerFontFamilyDropdown") private headerFontFamilyDropdown: DropdownComponent;
  @ViewChild("headerFontWeightDropdown") private headerFontWeightDropdown: DropdownComponent;
  @ViewChild("bodyFontFamilyDropdown") private bodyFontFamilyDropdown: DropdownComponent;
  @ViewChild("bodyFontWeightDropdown") private bodyFontWeightDropdown: DropdownComponent;

  private rxSubscription: Subscription = new Subscription();
  public fontFamilyList: FontListingDTO[];
  public configurationDTO: AppearanceConfigurationDTO = new AppearanceConfigurationDTO();
  private fetchingComponentData: boolean;

  public fontWeightList: {id: string, name: string }[] = [
    {"id": "100", "name": "Thin"},
    {"id": "200", "name": "Extra Light"},
    {"id": "300", "name": "Light"},
    {"id": "400", "name": "Regular"},
    {"id": "500", "name": "Medium"},
    {"id": "600", "name": "Semi Bold"},
    {"id": "700", "name": "Bold"},
    {"id": "800", "name": "Extra Bold"},
    {"id": "900", "name": "Black"},
  ];

  constructor(
    private authService: AuthenticationService,
    private entityService: EntityService,
    private listingsService: ListingsService,
  ) { }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  private load() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.listingsService.getFonts()
          .pipe(
            finalize(() => {
              this.setComponentDimmerVisibility(false);
              this.getAppearanceConfiguration();
            })
          )
          .subscribe((data) => {
            this.fontFamilyList = data;
          })
      );
    }
  }

  private getAppearanceConfiguration() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService.getAppearanceConfiguration()
          .pipe(finalize(() => {
            this.setComponentDimmerVisibility(false);
            if (this.headerFontFamilyDropdown) {
              this.headerFontFamilyDropdown.setSelectedValue(this.configurationDTO.headerFontFamilyId)
              this.headerFontFamilyDropdown.hide();
            }
            if (this.headerFontWeightDropdown) {
              this.headerFontWeightDropdown.setSelectedValue(this.configurationDTO.headerFontWeight)
              this.headerFontWeightDropdown.hide();
            }
            if (this.bodyFontFamilyDropdown) {
              this.bodyFontFamilyDropdown.setSelectedValue(this.configurationDTO.bodyFontFamilyId)
              this.bodyFontFamilyDropdown.hide();
            }
            if (this.bodyFontWeightDropdown) {
              this.bodyFontWeightDropdown.setSelectedValue(this.configurationDTO.bodyFontWeight)
              this.bodyFontWeightDropdown.hide();
            }
          }))
          .subscribe((data) => {
            this.configurationDTO = data;
          })
      );
    }
  }

  public disabledGroupAppearanceSettings(): boolean {
    if (!this.configurationDTO) return true;

    return !this.configurationDTO.overrideGroupAppearanceSettings &&
      (this.authService.getUserSelectedEntity() && this.authService.getUserSelectedEntity().type === EntityType.GROUP_MEMBER);
  }

  public showIfGroupMember() {
    return this.authService.getUserSelectedEntity() && this.authService.getUserSelectedEntity().type === EntityType.GROUP_MEMBER;
  }

  save(): void {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      if (this.headerFontFamilyDropdown) {
        this.headerFontFamilyDropdown.hide();
      }
      if (this.headerFontWeightDropdown) {
        this.headerFontWeightDropdown.hide();
      }
      if (this.bodyFontFamilyDropdown) {
        this.bodyFontFamilyDropdown.hide();
      }
      if (this.bodyFontWeightDropdown) {
        this.bodyFontWeightDropdown.hide();
      }
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService.updateAppearanceConfiguration(this.configurationDTO)
          .pipe(finalize(() => { 
            this.setComponentDimmerVisibility(false);
            if (this.headerFontFamilyDropdown) {
              this.headerFontFamilyDropdown.setSelectedValue(this.configurationDTO.headerFontFamilyId)
              this.headerFontFamilyDropdown.hide();
            }
            if (this.headerFontWeightDropdown) {
              this.headerFontWeightDropdown.setSelectedValue(this.configurationDTO.headerFontWeight)
              this.headerFontWeightDropdown.hide();
            }
            if (this.bodyFontFamilyDropdown) {
              this.bodyFontFamilyDropdown.setSelectedValue(this.configurationDTO.bodyFontFamilyId)
              this.bodyFontFamilyDropdown.hide();
            }
            if (this.bodyFontWeightDropdown) {
              this.bodyFontWeightDropdown.setSelectedValue(this.configurationDTO.bodyFontWeight)
              this.bodyFontWeightDropdown.hide();
            }
          }))
          .subscribe((data) => {
            this.configurationDTO = data;
          })
      );
    }
  }

  public headerFontFamilyDropdownObservable = (): Observable<any> => {
    return of(this.fontFamilyList);
  }

  public headerFontFamilyDropdownNameResolver(item: FontListingDTO) {
    return item.name;
  }

  public headerFontWeightDropdownObservable = (): Observable<any> => {
    return of(this.fontWeightList);
  }

  public headerFontWeightDropdownNameResolver(item: {id: string, name: string }) {
    return item.name;
  }

  public bodyFontFamilyDropdownObservable = (): Observable<any> => {
    return of(this.fontFamilyList);
  }

  public bodyFontFamilyDropdownNameResolver(item: FontListingDTO) {
    return item.name;
  }

  public bodyFontWeightDropdownObservable = (): Observable<any> => {
    return of(this.fontWeightList);
  }

  public bodyFontWeightDropdownNameResolver(item: {id: string, name: string }) {
    return item.name;
  }

  private setComponentDimmerVisibility(visible: boolean): void {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }

}
