import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core/authentication.service';
import { Subscription } from 'rxjs';

@Injectable()
export class GoogleAnalyticsService implements OnDestroy {
  /**
   * Event subscription
   */
  private subscription = new Subscription();


  constructor(private router: Router, private authenticationService: AuthenticationService) {
    this.SetupNavigationSubscription();
  }

  ngOnDestroy(): void {
    if (environment.googleAnalytics.enabled && this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  /**
   * Setup for G. Analytics data collection on page navigation
   */
  private SetupNavigationSubscription(): void {
    if (environment.googleAnalytics.enabled === false) {
      console.debug('Skipping Google Analytics tracking as per environment variables.');
      return;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.url);
        ga('send', 'pageview');
      }
    });
  }

  /**
   * Emit google analytic event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   * @param {GaEventTracking} eventTracking Event to track on G.Analytics
   */
  public emitEvent(eventTracking: GaEventTracking) {
    if (environment.googleAnalytics.enabled === false) {
      return;
    }

    if (typeof ga === 'function') {
      ga('send', 'event', eventTracking);
    }
  }

  /**
   * Append G.Analytics tracking JS code to index page head section
   * @param document Current DOM document, index page.
   */
  public appendGaTrackingCode(document: Document) {
    if (environment.googleAnalytics.enabled === false) {
      return;
    }

    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '${environment.googleAnalytics.key}', 'auto');
      `;
      document.head.appendChild(script);

      this.subscription.add(this.authenticationService.userInformation$.subscribe((userInfo) => {
        if (!userInfo) return;

        //Set the user ID using signed-in user_id.
        if (userInfo.sub) {
          ga('set', 'userId', userInfo.sub);
        }
        // entity id
        if (userInfo.entity_access) {
          ga('set', 'dimension1', userInfo.entity_access);
        }
      }));

      this.subscription.add(this.authenticationService.userEntity$.subscribe((userEntity) => {
        if (!userEntity) return;

        // entity name
        if (userEntity.name) {
          ga('set', 'dimension2', userEntity.name);
        }
      }));
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }
}

/** Event tracking information class */
export class GaEventTracking {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string | null;
  eventValue?: number | null;
}
