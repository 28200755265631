import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, AfterViewInit, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'messaging-container',
  templateUrl: 'messaging-container.component.html',
  styleUrls: ['messaging-container.component.css']
})

export class MessagingContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  // Output
  @Output() private scrollOnTop: EventEmitter<boolean> = new EventEmitter();

  @Input() collection: Array<any>;
  @Input() showDimmerOnInit: boolean;
  @Input() userNameResolver: Function;
  @Input() dateResolver: Function;
  @Input() messageResolver: Function;
  @Input() isReadResolver: Function;
  @Input() isFromEndResolver: Function;
  @Input() enableEventScroll: boolean = false;

  private isScrollToBottom: boolean;
  private isScrollKeepPosition: boolean;
  private prevScrollTop: number;
  private prevScrollHeight: number;

  private isScrollOnBottom: boolean;

  @ViewChild('dimmer') dimmer: ElementRef;
  @ViewChild('messagingContainer') public messagingContainer: ElementRef;
  @ViewChildren('message') private messages: QueryList<ElementRef>;

  private messagesChangesSub: Subscription;

  constructor() { }

  public ngOnInit() {
    if (this.showDimmerOnInit) {
      this.showLoadingDimmer();
    }
  }

  ngOnDestroy(): void {
    if (this.messagesChangesSub) {
      this.messagesChangesSub.unsubscribe();
    }
  }

  public ngAfterViewInit() {
    if (this.enableEventScroll) {
      this.messagesChangesSub = this.messages.changes.subscribe((value) => this.processMessages(value));
    }
  }

  private processMessages(value: QueryList<ElementRef>): void {
    const elem = this.messagingContainer.nativeElement;
    if (this.isScrollToBottom || this.isScrollOnBottom) {
      this.isScrollToBottom = false;
      elem.scrollTop = elem.scrollHeight - elem.offsetHeight;
      this.isScrollOnBottom = true;
    } else if (this.isScrollKeepPosition) {
      this.isScrollKeepPosition = false;
      const scrollHeight: number = elem.scrollHeight;

      $(elem).animate(<JQuery.PlainObject>{ scrollTop: this.prevScrollTop + Math.abs((scrollHeight - this.prevScrollHeight)) }, 800);
      this.isScrollOnBottom = false;
    }
  }

  public scrollOnBottom() {
    this.isScrollToBottom = true;
  }

  public scrollKeepPosition() {
    this.isScrollKeepPosition = true;
    this.prevScrollTop = this.messagingContainer.nativeElement.scrollTop;
    this.prevScrollHeight = this.messagingContainer.nativeElement.scrollHeight;
  }

  /* Data Resolvers */

  private resolveUserName(item: string) {
    return this.userNameResolver(item);
  }

  private resolveDate(item: any) {
    return this.dateResolver(item);
  }

  private resolveIsRead(item: any) {
    return this.isReadResolver(item);
  }

  private resolveMessage(item: any) {
    return this.messageResolver(item);
  }

  private resolveIsFromEndUser(item: any) {
    return this.isFromEndResolver(item);
  }

  public onScroll(event: Event): void {
    var current: any = event.currentTarget;
    if (current.scrollTop === 0) {
      this.scrollOnTop.emit(true);
      //check if the scroll is on bottom
    } else if (Math.ceil(current.scrollTop) === (current.scrollHeight - current.offsetHeight)) {
      this.isScrollOnBottom = true;
    } else {
      this.isScrollOnBottom = false;
    }

  }

  /* Dimmer */
  public showLoadingDimmer() {
    return this.setDimmerVisibility(true);
  }

  public hideLoadingDimmer() {
    this.setDimmerVisibility(false);
  }

  private setDimmerVisibility(visible: boolean) {
    const dimmerElement = $(this.dimmer.nativeElement).dimmer({ duration: { show: 400, hide: 800 } });

    if (visible) {
      dimmerElement.dimmer('show');
    } else {
      dimmerElement.dimmer('hide');
    }
  }
}
