import { Component, OnInit, OnDestroy, ViewChild, ComponentRef } from '@angular/core';
import * as moment from "moment/moment";
import {
  IButtonGroupConfig, ButtonGroupConfig, TabsComponent, ITabConfig
} from "@app/shared";
import { AppHeaderService, BreadcrumbSection } from '@app/shared/app-header/app-header.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from "@app/core/authentication.service";
import { ExportDataSettingsComponent } from "./tabs/export-data-settings/export-data-settings.component";
import { RatingAverageComponent } from "./tabs/rating-average/rating-average.component";
import { ChatAverageResponseComponent } from "./tabs/chat-average-response/chat-average-response.component";
import { RatingAverageCountryComponent } from "./tabs/rating-average-country/rating-average-country.component";
import { RatingAverageEntitiesComponent } from "./tabs/rating-average-entities/rating-average-entities.component";
import { RequestAverageResponseComponent } from "./tabs/request-average-response/request-average-response.component";
import { TotalCheckinCountryComponent } from "./tabs/total-checkin-country/total-checkin-country.component";
import { InteractionsComponent } from "./tabs/interactions/interactions.component";
import { ChatResponseCustomerComponent } from "./tabs/chat-response-customer/chat-response-customer.component";

@Component({
  selector: 'reports',
  templateUrl: 'reports.component.html',
  styleUrls: ['reports.component.css']
})
/** reports component*/
export class ReportsComponent implements OnInit, OnDestroy {

  @ViewChild(TabsComponent) tabsComponent: TabsComponent;

  // All Subscriptions that should be disposed on destroy
  private rxSubscription = new Subscription();

  public btnGroupConfiguration: IButtonGroupConfig[];

  private fromDate: moment.Moment;

  /** reports ctor */
  constructor(private authService: AuthenticationService, private appHeaderService: AppHeaderService) {
    this.appHeaderService.resetBreadcrumb(new BreadcrumbSection('Reports', ['/reports']));
  }

  private selectedTab: ITabConfig;
  ngOnInit(): void {
    this.fromDate = moment().add(-7, 'day');

    this.rxSubscription.add(this.tabsComponent.selected.subscribe((tab: ITabConfig) => {
      this.selectedTab = tab;
    }));

    this.rxSubscription.add(this.authService.selectedEntity$.subscribe((entity) => {
      this.initButtonGroup();
      this.initTabs();
    }));
  }

  initTabs() {
    this.tabsComponent.reset();
    this.tabsComponent.addTab({
      title: 'Ratings',
      component: RatingAverageComponent,
      componentResolver: (componentRef: ComponentRef<RatingAverageComponent>) => {
        componentRef.instance.fromDate = this.fromDate;
      }
    });

    this.tabsComponent.addTab({
      title: 'Ratings by country',
      component: RatingAverageCountryComponent,
      componentResolver: (componentRef: ComponentRef<RatingAverageCountryComponent>) => {
        componentRef.instance.fromDate = this.fromDate;
      }
    });

    this.tabsComponent.addTab({
      title: 'Checkins by country',
      component: TotalCheckinCountryComponent,
      componentResolver: (componentRef: ComponentRef<TotalCheckinCountryComponent>) => {
        componentRef.instance.fromDate = this.fromDate;
      }
    });

    this.tabsComponent.addTab({
      title: 'Chat response time',
      component: ChatAverageResponseComponent,
      componentResolver: (componentRef: ComponentRef<ChatAverageResponseComponent>) => {
        componentRef.instance.fromDate = this.fromDate;
      }
    });

    this.tabsComponent.addTab({
      title: 'Request response time',
      component: RequestAverageResponseComponent,
      componentResolver: (componentRef: ComponentRef<RequestAverageResponseComponent>) => {
        componentRef.instance.fromDate = this.fromDate;
      }
    });

    this.tabsComponent.addTab({
      title: 'Interactions',
      component: InteractionsComponent,
      componentResolver: (componentRef: ComponentRef<InteractionsComponent>) => {
        componentRef.instance.fromDate = this.fromDate;
      }
    });

    this.tabsComponent.addTab({
      title: 'Rating per customer',
      component: RatingAverageEntitiesComponent
    });

    this.tabsComponent.addTab({
      title: 'Chat response time per customer',
      component: ChatResponseCustomerComponent
    });

    this.tabsComponent.addTab({
      title: 'Export guest data',
      component: ExportDataSettingsComponent
    });
  }

  private initButtonGroup() {
    this.btnGroupConfiguration = [
      new ButtonGroupConfig(1, "7 Days", this.onButtonGroupClick, true),
      new ButtonGroupConfig(2, "30 Days", this.onButtonGroupClick),
      new ButtonGroupConfig(3, "3 Months", this.onButtonGroupClick),
      new ButtonGroupConfig(4, "1 Year", this.onButtonGroupClick)
    ];
  }

  public showTabs(): boolean {
    if (this.authService.userIsAdminOrManager() && !this.authService.getUserSelectedEntity()) {
      return false;
    }

    return true;
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  public getChartsInformation(date: moment.Moment = moment()) {
    this.fromDate = date;
    if (this.selectedTab && this.selectedTab.tabComponent) {
      this.selectedTab.tabComponent.reload();
    }
  }

  public onButtonGroupClick(ev: { event: Event, btnConfig: IButtonGroupConfig }) {
    switch (ev.btnConfig.index) {
      case 1:
        this.getChartsInformation(moment().add(-7, 'day'));
        break;
      case 2:
        this.getChartsInformation(moment().add(-30, 'day'));
        break;
      case 3:
        this.getChartsInformation(moment().add(-3, 'month'));
        break;
      case 4:
        this.getChartsInformation(moment().add(-1, 'year'));
        break;
      default:
        this.getChartsInformation(moment().add(-7, 'day'));
        break;
    }
  }

}
