import { ViewChild, Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ElementRef } from '@angular/core';
import { RequestState } from '@models/enum';
import { RequestDetailActionsService } from '@app/requests/request-detail-actions/request-detail-actions.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'request-detail-actions',
  templateUrl: 'request-detail-actions.component.html',
  styleUrls: ['request-detail-actions.component.css']
})
export class RequestDetailActionsComponent implements OnInit, OnDestroy {
  // Input properties
  @Input() private autoLoadingOnStateChange: boolean;
  // Children components/refs
  @ViewChild('requestStateDDL') requestStateDropDown: ElementRef;
  // Component state
  public stateDropDownIsLoading: boolean;
  // Silently update (only UI, no API calls)
  public stateShouldBeSetInUiOnly: boolean = false;

  // Actions component (chat service state, global and for active users) all Subscriptions
  private subscription = new Subscription();

  constructor(private reqDetailActionService: RequestDetailActionsService) {
    this.subscription.add(this.reqDetailActionService
      .getDropDownSelectedValue()
      .subscribe((state: RequestState) => this.setSelectedStateValue(state)));

    this.subscription.add(this.reqDetailActionService
      .getDropDownSilentlySelectedValue()
      .subscribe((state: RequestState) => this.setSilentlySelectedStateValue(state)));

    this.subscription.add(this.reqDetailActionService
      .getDropDownLoadingState()
      .subscribe((isLoading: boolean) => this.setDropDownLoadingState(isLoading)));
  }

  ngOnInit() {
    this.configureStateDropDown();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // State DDL, UI/UX

  private configureStateDropDown() {
    $(this.requestStateDropDown.nativeElement).dropdown({
      direction: 'downward',
      action: 'activate',
      preserveHTML: false,
      showOnFocus: false,
      keepOnScreen: true,
      context: $(this.requestStateDropDown.nativeElement).parent(),
      onChange: (value, text, optionElement) => {
        if (!this.stateShouldBeSetInUiOnly) {
          this.updateState(<RequestState>value);
        }
      }
    }
    );
  }

  // Request State DDL

  public updateState(state: RequestState): void {
    this.reqDetailActionService.setRequestState(state);
  }

  private setSelectedStateValue(state: RequestState) {
    $(this.requestStateDropDown.nativeElement).dropdown('set selected', state);
    this.stateShouldBeSetInUiOnly = false;
  }

  private setSilentlySelectedStateValue(state: RequestState) {
    this.stateShouldBeSetInUiOnly = true;
    this.setSelectedStateValue(state);
  }

  private setDropDownLoadingState(isLoading: boolean) {
    if (isLoading) {
      $(this.requestStateDropDown.nativeElement).dropdown('set loading');
    }

    $(this.requestStateDropDown.nativeElement).dropdown('remove loading');

    this.stateDropDownIsLoading = isLoading;
  }

  public getStateDropDownBgColor(): string {
    const value = $(this.requestStateDropDown.nativeElement).dropdown('get value');
    const item = value ? $(this.requestStateDropDown.nativeElement).dropdown('get item', value) : null;
    return item ? item.data('color') : null;
  }

}
