<sui-dropdown #timezoneDropdown
  [defaultText]="placeholder"
  [collection]="collection"
  (collectionChange)="onCollectionChange()"
  [(selectedValue)]="selectedValue"
  (valueChange)="onValueChange($event)"
  [nameResolver]="nameResolver"
  [observable]="getDataObservable"
  [loadDataImmediately]="loadDataImmediately"
  [selectOnKeydown]="selectOnKeydown">
</sui-dropdown>
