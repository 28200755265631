<!-- State Dropdown -->
<div #requestStateDDL class="ui tiny compact floating dropdown button" [ngClass]="getStateDropDownBgColor()" [class.disabled]="stateDropDownIsLoading">
  <span class="text"></span>
  <i class="dropdown icon"></i>
  <div class="menu">
    <div class="scrolling menu">
      <div data-value="New" data-text="New" data-color="new-request" class="item">
        <div class="ui tiny new-request empty circular label"></div>
        New
      </div>
      <div data-value="Working" data-text="Working" data-color="working-request" class="item">
        <div class="ui tiny working-request empty circular label"></div>
        Working
      </div>
      <div data-value="Closed" data-text="Closed" data-color="grey" class="item">
        <div class="ui tiny grey empty circular label"></div>
        Closed
      </div>
      <div data-value="Cancelled" data-text="Cancelled" data-color="red" class="item">
        <div class="ui tiny red empty circular label"></div>
        Cancelled
      </div>
    </div>
  </div>
</div>
