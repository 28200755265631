﻿/*
    @Interface
        Interface for login objects.
*/
export interface ILogin {
    username: string;
    password: string;
    clientId: string;
    scopes: string;
}

/*
    @Entity
        Object for http login request payload.
*/
export class Login implements ILogin {
    constructor(public username: string, public password: string, public clientId: string, public scopes: string) { }
}
