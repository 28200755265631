import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ComponentRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ChatService } from '@app/chat/chat.service';
import { EndUsersService } from '@app/endusers/endusers.service';
import { EntityService } from '@app/entity/entity.service';
import {
  ChatConversationDTO,
  ChatConfigurationDTO,
  ChatConfigurationUpdateDTO,
  PersistenceManager,
  ChatConversationSearchFilter, ClosedOlderThan,
  MessagingUserState, ConciergeServiceState, Platform
} from '@app/models';
import { AuthenticationService } from '@app/core/authentication.service';
import { ConversationListActionsComponent } from '@app/chat/conversation-list-actions/conversation-list-actions.component';
import { ConversationListActionsService } from '@app/chat/conversation-list-actions/conversation-list-actions.service';
import {
  AppHeaderService, BreadcrumbSection, DataGridConfiguration,
  CheckboxComponent, DataGridComponent, DataGridCounterItemComponent
} from '@app/shared';
import { CustomDatePipe } from '@app/core/pipes/custom-date.pipe';
import { EmptyFieldPipe } from "@app/core/pipes/empty-field.pipe";
import { NotificationsService } from '@app/notifications/notifications.service';


@Component({
  selector: 'conversation-list',
  templateUrl: 'conversation-list.component.html',
  styleUrls: ['conversation-list.component.css']
})
export class ConversationListComponent implements OnInit, OnDestroy, AfterViewInit {
  public conversations: ChatConversationDTO[] = null;
  public totalRecords: number;
  private pageSize: number = 15;
  /*  DataGrid */
  public gridConfiguration: DataGridConfiguration<ChatConversationDTO>;
  /*  UI  */
  @ViewChild(DataGridComponent) dataGridComponent: DataGridComponent;
  @ViewChild('conversationsGridContainer') conversationsGridContainer: ElementRef;

  private chatConfiguration: ChatConfigurationDTO;
  // All Subscriptions that should be disposed on destroy
  private subscription = new Subscription();
  // filter
  public searchFilter: ChatConversationSearchFilter;
  private filterState: boolean;

  //dropdown states
  @ViewChild('DdStates') ddStates: ElementRef;
  public conversationStatesList: any[];
  @ViewChild('DdClose') ddClose: ElementRef;
  public conversationClosedList: any[];
  @ViewChild('popupCommand') popupCommand: ElementRef;

  constructor(private chatService: ChatService, private endUsersService: EndUsersService, private entityService: EntityService,
    private router: Router, private appHeaderService: AppHeaderService, private persistenceManager: PersistenceManager,
    private conversationListActionsService: ConversationListActionsService, private notificationsService: NotificationsService,
    private authService: AuthenticationService)
  {
    this.conversationStatesList = Object.keys(MessagingUserState);
    this.conversationClosedList = Object.keys(ClosedOlderThan);
    this.appHeaderService.resetBreadcrumb(new BreadcrumbSection('Chat', ['/chat']));

    this.subscription.add(
      this.conversationListActionsService
      .getConciergeState()
      .subscribe((state: ConciergeServiceState) => this.updateConciergeState(state)));

    this.subscription.add(
      this.conversationListActionsService
      .getConciergeForActiveUsersState()
      .subscribe((state: ConciergeServiceState) => this.updateConciergeForActiveUsersState(state)));

    this.subscription.add(
      this.notificationsService
      .onNotificationCountersUpdate()
      .subscribe(() => this.get())
    );

    this.setupRequestsDataGrid();
  }

  public ngOnInit(): void {
    this.setupPersistence();
    this.subscription.add(this.authService.selectedEntity$.subscribe((entity) => {
      this.get(1);
      if (entity) {
        this.getConfigurations();
      } else {
        this.conversationListActionsService.setHidden(true);
      }
    }));

    this.appHeaderService.setActionsComponent(ConversationListActionsComponent);

    if (!this.authService.userIsAdminOrManager()) {
      this.getConfigurations();
    }
  }

  ngAfterViewInit(): void {
    this.initDbStates();
    this.initPopup();
  }

  ngOnDestroy(): void {
    // Remove subscriptions
    this.subscription.unsubscribe();
  }

  private setupPersistence() {
    this.searchFilter = this.persistenceManager.store<ChatConversationSearchFilter>("ChatConversationPersistence", ChatConversationSearchFilter, psf => {
      psf.state = [MessagingUserState.Open];
    });
  }

  private initDbStates() {
    $(this.ddStates.nativeElement)
      .dropdown({
        maxSelections: 3,
        onChange: (value: any, text: string, $choice: JQuery): any => {
          let states: MessagingUserState[] = value.split(',');
          this.searchFilter.state = states ? states : null;
        }
      }).dropdown('set selected', this.searchFilter.state);

    $(this.ddClose.nativeElement)
      .dropdown({
        clearable: true,
        onChange: (value: any, text: string, $choice: JQuery): any => {
          if (value) {
            $(this.popupCommand.nativeElement).modal("show");
          }
        }
      });
  }

  private initPopup() {
    $(this.popupCommand.nativeElement).modal({
      onDeny: (elem): false | void => {
        $(this.ddClose.nativeElement).dropdown('clear');
      },
      onApprove: (elem): false | void => {
        let state: ClosedOlderThan = $(this.ddClose.nativeElement).dropdown('get value');
        if (!state) return;

        this.entityService.patchChatClosed(state).subscribe(() => {
          this.get();
        }
        );
        $(this.ddClose.nativeElement).dropdown('clear');
        this.notificationsService.refreshNotificationCounters();
      }
    });
  }

  private get(page?: number): void {
    if (page) {
      this.searchFilter.page = page;
    }
    this.searchFilter.pageSize = this.pageSize;
    this.searchFilter.includeMetadata = true;
    this.searchFilter.orderBy = this.gridConfiguration.getColumnsSortAsApiCallParam();

    this.dataGridComponent.setGridAsLoading(true);

    this.subscription.add(
      this.chatService
      .get(this.searchFilter)
      .subscribe(
        (response) => {
          this.conversations = response.items;
          this.pageSize = response.pageSize;
          this.totalRecords = response.totalCount;
        },
        (error: any) => {
          this.errorHandle(error);
          throw (error);
        },
        () => this.dataGridComponent.setGridAsLoading(false)
      )
    );
  }

  private getConfigurations() {

    this.subscription.add(this.entityService
      .getChatConfiguration()
      .subscribe(
        (value) => {
          this.chatConfiguration = value;

          this.conversationListActionsService.setConciergeStateSelectedValue(this.chatConfiguration.state.state);
          const activeUsersChatState = !this.chatConfiguration.stateForActiveUsers
            ? ConciergeServiceState.None
            : this.chatConfiguration.stateForActiveUsers.state;
          this.conversationListActionsService.setConciergeForActiveUsersSelectedValue(activeUsersChatState);
          this.conversationListActionsService.setHidden(false);
        },
        (error: any) => {
          this.errorHandle(error);
          throw (error);
        },
      ));
  }
  
  public updateConciergeForActiveUsersState(conciergeServiceState: ConciergeServiceState): void {
    // If same state selected, skip...
    if ((!this.chatConfiguration.stateForActiveUsers && conciergeServiceState === ConciergeServiceState.None)
      || (this.chatConfiguration.stateForActiveUsers && this.chatConfiguration.stateForActiveUsers.state === conciergeServiceState)) {
      return;
    }

    const stateDto = new ChatConfigurationUpdateDTO();
    stateDto.state = conciergeServiceState;

    this.conversationListActionsService.setConciergeForActiveUsersLoadingState(true);

    this.subscription.add(this.entityService
      .updateForActiveUsersState(stateDto)
      .subscribe(
        (response) => {
          this.chatConfiguration = response;
        },
        (error: any) => {
          this.errorHandle(error);
          throw (error);
        },
        () => {
          this.conversationListActionsService.setConciergeForActiveUsersLoadingState(false);
        }
      ));
  }

  public updateConciergeState(conciergeServiceState: ConciergeServiceState): void {
    //if same state selected, skip...
    if (this.chatConfiguration.state.state === conciergeServiceState) {
      return;
    }

    const stateDto = new ChatConfigurationUpdateDTO();
    stateDto.state = conciergeServiceState;

    this.conversationListActionsService.setConciergeDdlLoadingState(true);

    this.subscription.add(this.entityService
      .updateConciergeState(stateDto)
      .subscribe(
        (response) => {
          this.chatConfiguration = response;
        },
        (error: any) => {
          this.errorHandle(error);
          throw (error);
        },
        () => {
          this.conversationListActionsService.setConciergeDdlLoadingState(false);
        }
      ));
  }

  private setUserIsDemoProperty(checked: boolean, conv: ChatConversationDTO) {

    if (checked === conv.user.isDemo) {
      return;
    }

    this.subscription.add(this.endUsersService
      .updateDemo(conv.user.id, checked)
      .subscribe((response) => {
        conv.user = response;

        $(this.conversationsGridContainer.nativeElement).dimmer('hide');
      },
        (error: any) => {
          this.errorHandle(error);
          throw (error);
        }
      ));
  }

  /*
     DataGrid Component Interaction
  */

  private setupRequestsDataGrid(): void {
    this.gridConfiguration = new DataGridConfiguration(null, true, true);

    this.gridConfiguration.addColumn({
      index: 0,
      resolver: (dataItem: ChatConversationDTO) => { return new CustomDatePipe().transform(dataItem.lastMessage.lastUpdatedOn); },
      isSortable: true,
      currentSortDirection: null,
      sortDataField: 'updatedOn',
      headerText: 'Last update',
      columnGridWidth: 2
    });

    this.gridConfiguration.addColumn({
      index: 1,
      headerText: 'Guest name',
      isSortable: false,
      columnGridWidth: 3,
      component: DataGridCounterItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridCounterItemComponent>, conv: ChatConversationDTO) => {
        const instance = componentRef.instance;
        instance.text = conv.user.fullName || conv.user.name;
        instance.hasLabel = conv.unreadMessages && conv.unreadMessages > 0;
        instance.labelValue = conv.unreadMessages;
      }
    });

    this.gridConfiguration.addColumn({
      index: 2,
      headerText: 'State',
      isSortable: false,
      columnGridWidth: 2,
      component: DataGridCounterItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridCounterItemComponent>, conv: ChatConversationDTO) => {
        const instance = componentRef.instance;
        instance.text = conv.user.state;
        instance.hasLabel = true;
        instance.labelValue = null;
        instance.labelColor = this.getColorFromUserState(conv);
      }
    });

    this.gridConfiguration.addColumn({
      index: 3,
      headerText: 'Source',
      isSortable: false,
      resolver: (dataItem: ChatConversationDTO) => {
        if (!dataItem || !dataItem.user || !dataItem.user.session) {
          return new EmptyFieldPipe().transform();
        }
        return new EmptyFieldPipe().transform(this.getNameFromPlatform(dataItem.user.session.platform));
      },
      columnGridWidth: 1
    });

    this.gridConfiguration.addColumn({
      index: 4,
      headerText: 'Last Message',
      isSortable: false,
      columnGridWidth: 5,
      resolver: (conv: ChatConversationDTO) => { return new EmptyFieldPipe().transform(conv.lastMessage ? conv.lastMessage.message : null); }
    });

    this.gridConfiguration.addColumn({
      index: 5,
      headerText: 'Demo User?',
      columnGridWidth: 1,
      isSortable: false,
      component: CheckboxComponent,
      componentResolver: (componentRef: ComponentRef<CheckboxComponent>, conv: ChatConversationDTO) => {
        const instance = componentRef.instance;
        instance.inputValue = conv.user.isDemo;// one way only
        var subs: Subscription = instance.onChangeValue.subscribe((value) => this.setUserIsDemoProperty(value, conv));

        componentRef.onDestroy(() => { if (subs) subs.unsubscribe() });
      }
    });

    this.gridConfiguration.addColumn({
      index: 6,
      headerText: 'Property',
      isSortable: false,
      displayPropertyName: 'entity.name',
      columnGridWidth: 2
    });

  }

  public onDataRowClick(conv: ChatConversationDTO) {
    this.router.navigate(['/chat', conv.id]);
  }

  public onDataGridSortingChange(currentPage: number): void {
  }

  public onDataGridPagingChange(page: number) {
    this.searchFilter.page = page;
    this.get(page);
  }

  private getColorFromUserState(conv: ChatConversationDTO): string {
    switch (conv.user.state) {
      case MessagingUserState.Open:
        return "green";
      case MessagingUserState.Closed:
      default:
        return "red";
    }
  }

  private getNameFromPlatform(platform: Platform): string {
    if (!platform) {
      return "";
    }

    switch (platform) {
      case Platform.GuestUPhone:
        return "GuestU Phone";
      case Platform.B2BApp:
        return "Mobile App";
      case Platform.Clarice:
        return "Clarice";
      case Platform.B2BAppNG:
        return "Web App";
      case Platform.Unknown:
      default:
        return "Unknown";
    }
  }

  public resolveConversationState(olderThan: ClosedOlderThan): string {
    if (!olderThan) {
      return "";
    }

    switch (olderThan) {
      case ClosedOlderThan.Ten:
        return "10";
      case ClosedOlderThan.Thirty:
        return "30";
      case ClosedOlderThan.Ninety:
        return "90";
      case ClosedOlderThan.All:
      default:
        return "All";
    }
  }

  private errorHandle(error: any): void {
    console.log(error);
    this.dataGridComponent.setGridAsLoading(false);
  }


  /*
    Filtering Form
  */

  public applyDataFilter(): void {
    this.dataGridComponent.resetPagingAndSorting();
    this.get(1);
  }
}
