import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AuthenticationGuard } from '@app/core/authentication-guard.service';
import { UserPreferencesComponent } from '@app/user-preferences/user-preferences.component';
import { UserPreferencesService } from '@app/user-preferences/user-preferences.service';
import { DevicesModule } from '@app/devices/devices.module';


@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    DevicesModule,
    RouterModule.forChild([
      {
        path: 'user-preferences',
        data: { title: 'User preferences' },
        component: UserPreferencesComponent,
        canActivate: [AuthenticationGuard]
      }
    ])
  ],
  exports: [
    UserPreferencesComponent
  ],
  declarations: [
    UserPreferencesComponent
  ],
  providers: [
    UserPreferencesService
  ]
})
export class UserPreferencesModule { }
