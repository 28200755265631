﻿

	 import { Moment } from 'moment';
	 import { FileExtension } from '@models/enum/FileExtension';
    
    export class DatesDTO {
        
        
        /**
        * Unique identifier
        */
        id?: number | null;
        
        /**
        * FromDate search/filter
        */
        fromDate: Moment;
        
        /**
        * ToDate search/filter
        */
        toDate: Moment;
        
        /**
        * Output file format (eg: csv, xlsx, etc..)
        */
        format: FileExtension;
    }
