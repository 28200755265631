import { AuthenticationService } from '@app/core/authentication.service';
import { AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EntityService } from '@app/entity/entity.service';
import { finalize } from 'rxjs/operators';
import { IntegrationConfigurationDTO } from '@models/integrations';
import { IntegrationsService } from '@app/integrations/integrations.service';
import { NotifierService } from '@app/core/notifications/notifier.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.css']
})
export class IntegrationSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("componentDimmer") componentDimmer: ElementRef;
  @ViewChild('popupStaysSyncConfirm') popupStaysSyncConfirm: ElementRef;

  public integrationConfigurationDto: IntegrationConfigurationDTO;
  private rxSubscription = new Subscription();
  private fetchingComponentData: boolean;
  private notifierService = this.injector.get(NotifierService);

  constructor(
    private entityService: EntityService,
    private authService: AuthenticationService,
    private integrationsService: IntegrationsService,
    private injector: Injector,
  ) { }

  ngOnInit() {
    this.getIntegrationConfigurations();
  }

  getIntegrationConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .getIntegrationConfigurations()
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((data) => {
            this.integrationConfigurationDto = data;
          })
      );
    }
  }

  ngAfterViewInit(): void {
    this.initPopup();
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  isDisabled(): boolean {
    return !this.integrationConfigurationDto || !this.integrationConfigurationDto.staysSyncEnable;
  }

  onCheckboxChange(value: boolean) {
    this.save();
  }

  save() {
    this.setComponentDimmerVisibility(true);

    if (Number.isNaN(Number.parseInt(`${this.integrationConfigurationDto.staysSyncInterval}`)) || this.integrationConfigurationDto.staysSyncInterval < 0) {
      this.integrationConfigurationDto.staysSyncInterval = 0;
    }

    this.entityService
      .updateIntegrationConfigurations(this.integrationConfigurationDto)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
      .subscribe((data) => {
        this.integrationConfigurationDto = data;
      });
  }

  executeStaysSyncClick(event: EventTarget) {
    $(this.popupStaysSyncConfirm.nativeElement).modal("show"); 
  }

  private initPopup() {
    $(this.popupStaysSyncConfirm.nativeElement).modal({
      onDeny: (elem): false | void => {
      },
      onApprove: (elem): false | void => {
        const entityId = this.authService.getUserSelectedEntityId();
        if (entityId) {
          this.setComponentDimmerVisibility(true);
          this.rxSubscription.add(
            this.integrationsService
              .runStaysSync()
              .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
              .subscribe((data) => {
                this.notifierService.success('The stays sync event has been run with success', 'Stays Sync');
              })
          );
        }
      }
    });
  }

  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }

}
function Any<T>(): boolean {
  throw new Error('Function not implemented.');
}

