  <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="configurationDTO">

      <!-- left side -->
      <div class="eight wide column">
  
        <h4 class="ui top attached header">
          <div><i class="window restore outline icon"></i>Backoffice Settings</div>
        </h4>
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">

            <div class="doubling two column row">
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Show Pos Sync Tab</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.showPosSyncTab"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
            </div>
            
          </div>
        </div>
  
      </div>

      <!-- right side -->
      <div class="eight wide column">
        
        <h4 class="ui top attached header">
          <div><i class="external alternate icon"></i>Sync with Hub Settings</div>
        </h4>
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">
            <div class="doubling three column row">
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Has Outlet Name Update</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.hasOutletNameSyncUpdate"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Has Category Name Update</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.hasCategoryNameSyncUpdate"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Has Product Name Update</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.hasProductNameSyncUpdate"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
            </div>
            <div class="doubling three column row">
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Has Modifier Group Name Update</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.hasModifierGroupNameSyncUpdate"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Has Modifier Item Name Update</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.hasModifierItemNameSyncUpdate"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
            </div>

            <div class="doubling three column row">
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Outlet Published by Default</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.outletPublishedByDefault"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Category Published by Default</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.categoryPublishedByDefault"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Product Published by Default</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.productPublishedByDefault"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
            </div>

            <div class="doubling three column row">
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Has Single Product Update</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.hasSingleProductUpdate"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Has Single Modifier Item Update</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.hasSingleModifierUpdate"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
              <div class="column ui form"></div>
            </div>

          </div>
        </div>

      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
  </div>
  
