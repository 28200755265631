import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class AppNotifierService implements OnDestroy {
  private getNotificationSubject = new Subject<string>();

  public ngOnDestroy(): void {
  }


  public getNotification(): Observable<string> {
    return this.getNotificationSubject.asObservable();
  }

  public notify(message: string) {
    this.getNotificationSubject.next(message);
  }
}
