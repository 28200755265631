﻿


    
    export class MessageTotalAverageDTO {
        
        
        /**
        * Rating average
        */
        average: number;
        
        /**
        * Total guest message
        */
        messageGuest: number;
        
        /**
        * Total staff message
        */
        messageStaff: number;
    }
