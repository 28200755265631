import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AuthenticationService } from "@app/core/authentication.service";
import { EntityService } from "@app/entity/entity.service";
import { RequestConfigurationDTO } from "@app/models";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  selector: "request-settings",
  templateUrl: "request-settings.component.html",
  styleUrls: ["request-settings.component.css"],
})
/** request-settings component*/
export class RequestSettingsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("componentDimmer") componentDimmer: ElementRef;

  public requestConfigurationDTO: RequestConfigurationDTO;
  private rxSubscription:Subscription = new Subscription();
  private fetchingComponentData: boolean;

  constructor(
    private entityService: EntityService,
    public authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.getRequestConfigurations();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  getRequestConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService.getRequestConfigurations()
          .pipe(
              finalize(() => {
                this.setComponentDimmerVisibility(false);
              }))
          .subscribe((data) => {
            this.requestConfigurationDTO = data;
          })
      );
    }
  }

  onHasAvailabilityPeriodChange(value: boolean) {
    if (value) {
        this.requestConfigurationDTO.requestStartTime = this.requestConfigurationDTO.requestEndTime = "00:00";
    } else {
        this.requestConfigurationDTO.requestStartTime = this.requestConfigurationDTO.requestEndTime = null;
    }
    this.save();
  }

  onRequestTimeChange(value: string) {
    this.save();
  }

  save() {
    this.setComponentDimmerVisibility(true);
    this.entityService.updateRequestConfigurations(this.requestConfigurationDTO)
      .pipe(
          finalize(() => {
            this.setComponentDimmerVisibility(false);
          }))
      .subscribe((data) => {
        this.requestConfigurationDTO = data;
      });
  }

  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }
}
