import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'sui-dropdown',
  templateUrl: 'sui-dropdown.component.html',
  styleUrls: ['sui-dropdown.component.css']
})

export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() collection: Array<any>;
  @Input() nameResolver: Function;
  @Input() itemImageResolver: Function;
  @Input() itemIconResolver: Function;
  @Input() defaultText: string;
  @Input() itemType: DropdownItemType;
  @Input() observable: ObservableResolver; // Function
  @Input() observableError: Function;
  @Input() loadDataImmediately: boolean;

  /** Show dropdown menu automatically on the first loading */
  @Input() showFirstLoading: boolean = true;
  /** Whether to show dropdown menu automatically on element focus */
  @Input() showOnFocus: boolean = true;
  /** Whether dropdown should select new option when using keyboard shortcuts. Setting to false will require enter or left click to confirm a choice. */
  @Input() selectOnKeydown: boolean = true;
  /** Value change event emitter */
  @Output() private valueChange: EventEmitter<any> = new EventEmitter();
  /** Selected value */
  @Input() selectedValue: any;
  /** Selected value change emitter */
  @Output() selectedValueChange: EventEmitter<any> = new EventEmitter();
  /** Collection change emitter */
  @Output() private collectionChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('listingsDropDown') listingsDropDown: ElementRef;
  @ViewChildren('options') private options: QueryList<ElementRef>;

  private waitingData: boolean = false;
  private subscription: Subscription;
  private shouldSilenceEvents: boolean;
  @Input() forceSelectedValueOnStart: boolean;
  @Input() isClearable: boolean = true;

  constructor() {
  }

  ngOnInit() {
    if (!this.observable || !(this.observable instanceof Function)) {   
      throw new Error('observable function is missing');   
    }

    if (!this.itemType) {
      this.itemType = DropdownItemType.Text;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public ngAfterViewInit(): void {
    this.initListingsDropDown();

    if (this.loadDataImmediately) {
      this.getListingsService();
    }

    this.subscription = this.options.changes.subscribe((value) => this.processOptionsChanges(value));

    if (this.forceSelectedValueOnStart) {
      this.setSelectedValue(this.selectedValue);
    }
  }

  private processOptionsChanges(value: QueryList<ElementRef>): void {
    if (this.selectedValue) {
      $(this.listingsDropDown.nativeElement).dropdown('refresh').dropdown('set selected', this.selectedValue);
      this.subscription.unsubscribe();
    }
  }

  public reset(loadingData: boolean = false) {
    this.selectedValueChange.next(null);
    this.collection = [];
    $(this.listingsDropDown.nativeElement).dropdown('restore defaults');

    // load items list and select the value ()
    if (loadingData) {
      this.getListingsService();
    }
  }

  /**
   * Update the dropdown selected value with a new value
   * @param newSelectedValue
   */
  public updateSelected(newSelectedValue: any) {
    if (newSelectedValue) {
      $(this.listingsDropDown.nativeElement).dropdown('set selected', newSelectedValue);
    } else {
      $(this.listingsDropDown.nativeElement).dropdown('restore defaults');
    }
  }

  /**
   * Set the dropdown selected value with a new value without events
   * @param selectedValue
   */
  public setSelectedValue(selectedValue: any) {
    this.shouldSilenceEvents = true;
    if (selectedValue) {
      $(this.listingsDropDown.nativeElement).dropdown('set selected', selectedValue);
    } else {
      $(this.listingsDropDown.nativeElement).dropdown('restore defaults');
    }
    this.shouldSilenceEvents = false;
  }

  private getListingsService(): void {
    this.waitingData = true;
    $(this.listingsDropDown.nativeElement).dropdown('set loading');

    this.observable()
      .pipe(
        finalize(() => {
          this.waitingData = false;
          $(this.listingsDropDown.nativeElement).dropdown('remove loading');
          if (!this.selectedValue && this.showFirstLoading) {
            setTimeout(() => this.show(), 10);
          }
          this.showFirstLoading = true;
        }))
      .subscribe((response) => {
        this.collection = response;
        this.collectionChange.emit(true);
      },
        (error: any) => this.errorHandle(error)
      );
  }

  private errorHandle(error: any): void {
    console.log(error);
    if (this.observableError) {
      this.observableError(error);
    }
  }

  private initListingsDropDown() {
    var ddl =
      $(this.listingsDropDown.nativeElement)
        .dropdown({
          direction: 'auto',
          allowReselection: true,
          forceSelection: false,
          fullTextSearch: true,
          match: 'text',
          clearable: this.isClearable,
          showOnFocus: this.showFirstLoading ? this.showOnFocus : true,
          selectOnKeydown: this.selectOnKeydown,
          preserveHTML: this.itemType === DropdownItemType.Icon || this.itemType === DropdownItemType.Image,
          onShow: (): any => {
            if (!this.showFirstLoading && !this.showOnFocus) {
              $(this.listingsDropDown.nativeElement).dropdown('setting', 'showOnFocus', false);
            }

            if (this.waitingData) {
              return false;
            }

            if (this.collection && this.collection.length > 0) {
              return true;
            }

            this.getListingsService();
            return false;
          },
          onChange: (value: any, text: string, $choice: JQuery): any => {
            if (this.shouldSilenceEvents) {
              return;
            }
            
            this.selectedValueChange.emit(value);

            if (this.valueChange) {
              var item = null;
              if ($choice) {
                item = this.getCollectionItemByIndex($choice.data('index'));
              }
              this.valueChange.next({ value, item });
            }
          },
        });

    ddl.on("click.loading", this.dropdownClickHandler);

    // If an item is previously selected (passed to component), load items list and select the value ()
    if (this.selectedValue && (!this.collection || this.collection.length === 0)) {
      if (!this.showFirstLoading && !this.showOnFocus) {
        ddl.dropdown('setting', 'showOnFocus', false);
      }
      this.getListingsService();
    }
  }

  private getCollectionItemByIndex(index: number): any {
    if (this.collection && this.collection.length > 0) {
      return this.collection[index];
    }
    return null;
  }

  private dropdownClickHandler(event: Event) {
    const element: HTMLInputElement = <HTMLInputElement>event.currentTarget;

    const childs = element.getElementsByClassName('search');

    if (childs.length > 0) {
      const searchInput: HTMLInputElement = <HTMLInputElement>childs.item(0);
      searchInput.focus();
    }

    $(element).off("click.loading");
  }

  public itemTypeIsText(): boolean {
    return this.itemType === DropdownItemType.Text;
  }

  public itemTypeIsIcon(): boolean {
    return this.itemType === DropdownItemType.Icon;
  }

  public itemTypeIsImage(): boolean {
    return this.itemType === DropdownItemType.Image;
  }

  private resolveName(item: any): string {
    return this.nameResolver(item);
  }

  private resolveImage(item: string): string {
    return this.itemImageResolver(item);
  }

  private resolveItemIconClass(item: any): string {
    return this.itemIconResolver(item);
  }

  private removeOnClick(event: Event) {
    const elem: HTMLInputElement = <HTMLInputElement>event.currentTarget;
    if (elem) {
      const div: HTMLInputElement = <HTMLInputElement>elem.parentElement;
      if (div) {
        $(div.parentElement).dropdown('clear');
      }
    }
    event.stopPropagation();
  }

  public clickOnInput(event: Event) {
    if (!this.showOnFocus) {
      this.show();
    }
  }

  public show() {
    $(this.listingsDropDown.nativeElement).dropdown('show');
  }

  public hide() {
    $(this.listingsDropDown.nativeElement).dropdown('hide');
  }
}

/**
 * Observable resolver (function) type
 */
export type ObservableResolver = () => Observable<any>;

/**
 * Dropdown items render type.
 */
export enum DropdownItemType {
  Text = "Text",
  Icon = "Icon",
  Image = "Image"
}
