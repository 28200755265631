﻿

    
    /**
    * Contact Type enum. Maps to DB identity values.
    */
    
    export enum ContactType {
        
        
        Undefined = "Undefined",
        
        Address = "Address",
        
        Coordinates = "Coordinates",
        
        Telephone = "Telephone",
        
        Email = "Email",
        
        Facebook = "Facebook",
        
        Twitter = "Twitter",
        
        Tripadvisor = "Tripadvisor",
        
        Booking = "Booking",
        
        Fax = "Fax",
        
        Site = "Site",
        
        Instagram = "Instagram",
        
        Blogger = "Blogger",
        
        Flickr = "Flickr",
        
        Googleplus = "Googleplus",
        
        Printest = "Printest",
        
        Youtube = "Youtube",
        
        Hostelworld = "Hostelworld",
        
        Skype = "Skype",
        
        Foursquare = "Foursquare",
        
        Linkedin = "Linkedin",
        
        Wikipedia = "Wikipedia",
        
        BookingMail = "BookingMail",
        
        AirBnB = "AirBnB",
        
        VKontakte = "VKontakte",
        
        WhatsApp = "WhatsApp"
    }
