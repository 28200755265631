﻿

    
    export enum GeoCoordinates {
        
        
        Lat = "Lat",
        
        Long = "Long"
    }
