﻿

    
    export enum EntityState {
        
        
        Unknown = "Unknown",
        
        Created = "Created",
        
        AppConfiguration = "AppConfiguration",
        
        WaitingForCustomer = "WaitingForCustomer",
        
        RequestPublishToStores = "RequestPublishToStores",
        
        WaitingForApproval = "WaitingForApproval",
        
        Ready = "Ready",
        
        AppRemoveRequested = "AppRemoveRequested",
        
        AppRemoved = "AppRemoved",
        
        AppUpdateConfiguration = "AppUpdateConfiguration",
        
        UpdateWaitingForCustomer = "UpdateWaitingForCustomer",
        
        RequestUpdateToStores = "RequestUpdateToStores",
        
        WaitingForUpdateApproval = "WaitingForUpdateApproval",
        
        UpdateCancelled = "UpdateCancelled",
        
        Cancelled = "Cancelled",
        
        Test = "Test",
        
        Demo = "Demo",
        
        LeadCancelled = "LeadCancelled",
        
        GuestUCancel = "GuestUCancel",
        
        Imported = "Imported",
        
        Available = "Available",
        
        ReadyToPublishToStores = "ReadyToPublishToStores",
        
        ReadyToUpdateToStores = "ReadyToUpdateToStores"
    }
