﻿


    
    export class DashboardDTO {
        
        
        /**
        * Open Requests
        */
        openRequests: number;
        
        /**
        * Closed requests today
        */
        closedRequestsToday: number;
        
        /**
        * Closed requests month
        */
        closedRequestsMonth: number;
        
        /**
        * Unread request messages
        */
        unreadRequestMessages: number;
        
        /**
        * Open chats
        */
        openChats: number;
        
        /**
        * Closed chats today
        */
        closedChatsToday: number;
        
        /**
        * Closed chats month
        */
        closedChatsMonth: number;
        
        /**
        * Unread chat messages
        */
        unreadChatMessages: number;
    }
