import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ChatDetailActionsService } from '@app/chat/chat-detail-actions/chat-detail-actions.service';
import { MessagingUserState } from '@models/enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'chat-detail-actions',
  templateUrl: 'chat-detail-actions.component.html',
  styleUrls: ['chat-detail-actions.component.css']
})

export class ChatDetailActionsComponent implements OnInit, OnDestroy {
  // Actions component (chat service state, global and for active users) all Subscriptions
  private subscription = new Subscription();
  @ViewChild('chatUserStateDdl') chatUserStateDdl: ElementRef;
  public chatUserStatusDdlIsLoading: boolean;
  // Silently update (only UI, no API calls)
  public stateShouldBeSetInUiOnly: boolean = false;


  constructor(private chatDetailActionsService: ChatDetailActionsService) {
    this.subscription.add(this.chatDetailActionsService
      .getDropDownSelectedValue()
      .subscribe((state: MessagingUserState) => this.setDropDownSelectedValue(this.chatUserStateDdl, state)));

    this.subscription.add(this.chatDetailActionsService
      .getDropDownSilentlySelectedValue()
      .subscribe((state: MessagingUserState) => this.setSilentlySelectedStateValue(state)));

    this.subscription.add(this.chatDetailActionsService
      .getDropDownLoadingState()
      .subscribe((isLoading: boolean) => this.setDropDownLoadingState(this.chatUserStateDdl, isLoading)));
  }

  ngOnInit() {
    this.configureChatUserStateDdl();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setDropDownSelectedValue(dropDownElement: ElementRef, state: MessagingUserState) {
    $(dropDownElement.nativeElement).dropdown('set selected', state);
    this.stateShouldBeSetInUiOnly = false;
  }

  private setSilentlySelectedStateValue(state: MessagingUserState) {
    this.stateShouldBeSetInUiOnly = true;
    this.setDropDownSelectedValue(this.chatUserStateDdl, state);
  }

  private setDropDownLoadingState(dropDownElement: ElementRef, isLoading: boolean) {
    if (isLoading) {
      $(dropDownElement.nativeElement).dropdown('set loading');
    }

    $(dropDownElement.nativeElement).dropdown('remove loading');
    this.chatUserStatusDdlIsLoading = isLoading;
  }

  private getDropDownBgColor(dropDownElement: ElementRef): string {
    const jQElement = $(dropDownElement.nativeElement);
    const value = jQElement.dropdown('get value');
    const item = value ? jQElement.dropdown('get item', value) : null;

    return item ? item.data('color') : null;
  }

  /* General Audience --- UI/UX */
  private configureChatUserStateDdl() {
    $(this.chatUserStateDdl.nativeElement).dropdown({
      direction: 'downward',
      action: 'activate',
      preserveHTML: false,
      showOnFocus: false,
      onChange: (value: MessagingUserState, text, optionElement) => {
          if (!this.stateShouldBeSetInUiOnly) {
            this.chatDetailActionsService.setChatUserState(value);
          }
        }
      }
    );
  }

  public getChatUserStateDdlBgColor() {
    return this.getDropDownBgColor(this.chatUserStateDdl);
  }
}
