﻿

    
    export enum EntityType {
        
        
        Unknown = "Unknown",
        
        ENTITY = "ENTITY",
        
        GROUP = "GROUP",
        
        GROUP_MEMBER = "GROUP_MEMBER",
        
        DESTINATION = "DESTINATION",
        
        HOSPITALITY = "HOSPITALITY"
    }
