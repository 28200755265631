﻿


    
    export class LanguageDTO {
        
        
        /**
        * Entity unique identifier.
        */
        id: number;
        
        /**
        * Entity english name.
        */
        englishName: string;
        
        /**
        * Entity ISO code.
        */
        isoCode: string;
        
        /**
        * Associated country ISO code
        */
        countryIsoCode: string;
    }
