import { Component, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'buttons-group',
  templateUrl: 'buttons-group.component.html',
  styleUrls: ['buttons-group.component.css']
})
/** buttons-group component*/
export class ButtonsGroupComponent {

  /* Action button Configuration */
  @Input() @Output() public buttons: IButtonGroupConfig[];

  @Output() public onButtonGroupClick: EventEmitter<{ event: Event, btnConfig: IButtonGroupConfig }> = new EventEmitter();

  /** buttons-group ctor */
  constructor() {
  }

  public onButtonClick(event: Event, btnConfig: IButtonGroupConfig) {
    if (!btnConfig.active ) return;

    this.buttons.forEach((btn) => btn.selected = btnConfig === btn);

    this.onButtonGroupClick.emit({ event, btnConfig });
  }

  public trackRowByIndex(index: number, item: any): number {
    return index;
  }
}

/**
 * Describes a Action button configuration
 */
export interface IButtonGroupConfig {
  index: number;
  displayText?: string | null;
  selected?: boolean;
  active?: boolean;
  isVisible?: boolean;
  callbackResolver?: Function | null;
  isLoading: boolean;
}

export class ButtonGroupConfig implements IButtonGroupConfig {
  public index: number;
  public displayText: string;
  public selected?: boolean;
  public active?: boolean;
  public isVisible?: boolean;
  public callbackResolver: Function;
  public isLoading: boolean;

  constructor(index: number, displayText: string, callbackResolver: Function, selected: boolean = false, active: boolean = true, isVisible: boolean = true, isLoading: boolean = false) {
    this.index = index;
    this.displayText = displayText;
    this.selected = selected;
    this.active = active;
    this.isVisible = isVisible;
    this.callbackResolver = callbackResolver;
    this.isLoading = isLoading;
  }

}
