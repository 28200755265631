import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

export enum Side {
  Left = "Left",
  Right = "Right",
}

@Component({
  selector: 'simple-paging',
  templateUrl: 'simple-paging.component.html',
  styleUrls: ['simple-paging.component.css']
})
export class SimplePagingComponent implements OnInit {
  // Output
  @Output() private pageChange: EventEmitter<any> = new EventEmitter();

  // Private
  public pageNumber: number = 1;
  public LabelSide = Side;

  // Input
  //  - Config
  @Input() public disabledControls: boolean = false;
  @Input() public upDownControls: boolean = false;
  @Input() public showInfoLabel: boolean = true;
  @Input() public infoLabelSide:Side = Side.Left;
  
  //  - Parent information
  @Input() public totalRecords: number = 0;
  @Input() public pageSize: number = 0;


  constructor() { }


  public ngOnInit() {
  }

  private updateUI(): void {
    
  }

  public upPage(): void {
    if (this.pageNumber + 1 > this.getMaxPageNumber()) {
      return;
    }

    this.pageNumber++;
    this.pageChange.emit(this.pageNumber);
    this.updateUI();
  }

  public downPage(): void {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.pageChange.emit(this.pageNumber);
      this.updateUI();
    }
  }

  public getMaxPageNumber(): number {
    return Math.ceil(this.totalRecords / this.pageSize);
  }

  private getInfoLabelText(): string {
    const from: number = ((this.pageNumber - 1) * this.pageSize) + 1;
    const to: number = Math.min(from + this.pageSize - 1, this.totalRecords);

    return from + '-' + to + ' of ' + this.totalRecords;
  }
}
