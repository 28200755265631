import { Component, OnInit, EventEmitter, Output, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ListingsService } from '@app/listings/listings.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { DropdownComponent } from '@shared/sui-components/sui-dropdown/sui-dropdown.component';
import { CurrencyListingDTO } from '@models/listings/CurrencyListingDTO';

@Component({
  selector: 'currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.css']
})
export class CurrencyDropdownComponent implements OnInit, AfterViewInit {
  /**
   * Holds the current currency list
   */
  @Input() collection: Array<any>;
  /**
   * Placeholder text
   */
  public readonly placeholder: string = 'Select a currency';

  /**
   * Load data as soon as possible or wait until first opening of dropdown list
   */
  @Input() loadDataImmediately: boolean;
  /**
   * Holds the currently selected currency
   */
  @Input() selectedValue: any;
  /**
   * Currency change event emitter
   */
  @Output() selectedChange: EventEmitter<CurrencyListingDTO> = new EventEmitter<CurrencyListingDTO>();
  /** 
   * Currency collection change emitter 
   * */
  @Output() collectionChange: EventEmitter<any> = new EventEmitter<any>();
  /** 
   * Dropdown view child component
   * */
  @ViewChild("currencyDropdown") private currencyDropdown: DropdownComponent;
  /**
   * Whether dropdown should select new option when using keyboard shortcuts. Setting to false will require enter or left click to confirm a choice.
   */
  @Input() selectOnKeydown: boolean = false;
  
  constructor(private listingsService: ListingsService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.selectedValue) {
      this.setSelectedValue(this.selectedValue.id);
    }
  }

  /**
   * Gets the observable of currencies listing
   * @return Currency listing DTO
   */
  public getDataObservable = (): Observable<CurrencyListingDTO[]> => {
    return this.listingsService.getCurrencies();
  }

  /**
   * Gets the display name from a currency object instance
   * @param item Currency object instance
   * @return Currency name
   */
  public nameResolver(item: CurrencyListingDTO) {
    return item ? `${item.name} (${item.code})` : null;
  }

  /**
   * Value change handler
   * @param event Event instance and elements { value: (option val.), item: (DTO) }
   * @emits selectedChange
   */
  public onValueChange(event: any) {
    if (event) {
      // store...
      this.selectedValue = event.item;
      // & forward
      this.selectedChange.emit(event.item);
    }
  }

  /**
   * Collection change handler
   * @param event Event instance and elements { value: (option val.), item: (DTO) }
   * @emits collectionChange
   */
  public onCollectionChange() {
    this.collectionChange.emit();
  }

  /**
   * Set selected value change handler
   * @param item Currency DTO
   */
  public setSelectedValue(value: number) {
    if (this.currencyDropdown) {
      this.currencyDropdown.setSelectedValue(value);
    }
  }

  /**
   * Hide dropdown list
   */
  public hide() {
    if (this.currencyDropdown) {
      this.currencyDropdown.hide();
    }
  }

  /**
   * Error handler
   * @param error Error description object
   */
  private errorHandle(error: any): void {
    if (!environment.production) {
      console.log(error);
    }
  }
}
