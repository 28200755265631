<div #listingsDropDown class="ui fluid search selection dropdown">
  <input type="hidden"  >
  <i class="dropdown icon"></i>
  <div (click)="clickOnInput($event)" class="default text">{{defaultText}}</div>

  <input class="search" (click)="clickOnInput($event)" autocomplete="off" tabindex="0">
  <div class="menu">
    <ng-container *ngIf="itemTypeIsText()">
      <div #options *ngFor="let item of collection; let i = index" class="item" [attr.data-index]="i" [attr.data-value]="item.id">
        {{resolveName(item)}}
      </div>
    </ng-container>
    <ng-container *ngIf="!itemTypeIsText()">
      <div #options *ngFor="let item of collection; let i = index" class="item" [attr.data-index]="i" [attr.data-value]="item.id">
        <ng-container *ngIf="itemTypeIsImage()">
          <img src="{{resolveImage(item)}}" alt="">{{resolveName(item)}}
        </ng-container>
        <ng-container *ngIf="itemTypeIsIcon()">
          <i class="{{resolveItemIconClass(item)}}"></i>{{resolveName(item)}}
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
