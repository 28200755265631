import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PushNotificationsService } from '@app/push-notifications/push-notifications.service';
import { EntityService } from '@app/entity/entity.service';
import { AuthenticationService } from '@app/core/authentication.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CustomDatePipe } from '@app/core/pipes/custom-date.pipe';
import { environment } from '@env/environment';
import {
  EntityType,
  ApplicationDTO, EntityDTO,
  PushNotificationSearchFilter,
  PushNotificationDTO,
  PushNotificationFullDTO,
  PersistenceManager,
  LanguageDTO
} from '@app/models';

import {
  DataGridComponent, AppHeaderService, BreadcrumbSection,
  DataGridConfiguration, PopupConfig
} from '@app/shared';

@Component({
  selector: 'push-notifications',
  templateUrl: 'push-notifications.component.html',
  styleUrls: ['push-notifications.component.css']
})
export class PushNotificationsComponent implements OnInit, OnDestroy {
  public notifications: PushNotificationDTO[];
  public searchFilter: PushNotificationSearchFilter;
  public pushNotificationText: string;

  public languages: LanguageDTO[];
  public pushNotifications: PushNotificationFullDTO[];

  /* UI/UX */
  public formIsLocked: boolean;

  /* Data-Grid related properties */
  public dataPageSize: number;
  public dataTotalRecords: number;
  public dataGridConfiguration: DataGridConfiguration<PushNotificationDTO>;
  @ViewChild(DataGridComponent) dataGridComponent: DataGridComponent;

  // Actions component (chat service state, global and for active users) all Subscriptions
  private subscription = new Subscription();

  public popupConfiguration: PopupConfig;

  constructor(private pushNotificationsService: PushNotificationsService,
    private appHeaderService: AppHeaderService,
    private entityService: EntityService,
    private persistenceManager: PersistenceManager,
    private authService: AuthenticationService) {
    this.popupConfiguration = new PopupConfig("Notifications", ["Are you sure you want to send push notification?"]);

    appHeaderService.resetBreadcrumb(new BreadcrumbSection('Notify Guests', ['/guests-notifications']));
    this.setupDataGridConfiguration();
  }

  public ngOnInit() {
    this.setupPersistence();
    this.subscription.add(this.authService.selectedEntity$.subscribe((entity) => {
      if (this.showAlertMessageDiv()) {
        this.getLanguages();
      }
      this.get();
    }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setupPersistence() {
    this.searchFilter = this.persistenceManager.store<PushNotificationSearchFilter>("PushNotificationSearchFilter", PushNotificationSearchFilter, psf => {
      psf.page = 1;
      psf.includeMetadata = true;
    });
  }

  private get(): void {
    this.searchFilter.entityId = this.authService.getUserSelectedEntityId();

    this.setFormLock();
    this.dataGridComponent.setGridAsLoading(true);

    this.subscription.add(this.pushNotificationsService
      .get(this.searchFilter)
      .pipe(finalize(() => this.dataGridComponent.setGridAsLoading(false)))
      .subscribe(
        (response) => {
          this.notifications = response.items;
          this.dataPageSize = response.pageSize;
          this.dataTotalRecords = response.totalCount;
        },
        (error: any) => {
          throw (error);
        },
        () => {
          this.setFormLock(false);
        }
      ));
  }

  private getLanguages(): void {

    this.setFormLock();
    this.dataGridComponent.setGridAsLoading(true);

    this.subscription.add(this.entityService
      .getLanguages()
      .pipe(finalize(() => this.dataGridComponent.setGridAsLoading(false)))
      .subscribe(
        (response) => {

          this.pushNotifications = new Array<PushNotificationFullDTO>(response.length);

          let entity = this.authService.getUserSelectedEntity();
          if (entity) {
            response.forEach((language, index) => {
              let pushNotification = new PushNotificationFullDTO();
              pushNotification.application = Object.assign(new ApplicationDTO(), { id: entity.application.id, name: entity.application.name });
              pushNotification.application.entity = Object.assign(new EntityDTO(), { id: entity.id, name: entity.name, shortName: entity.shortName });
              pushNotification.language = language;
              this.pushNotifications[index] = pushNotification;
            });
            this.languages = response;
          }
        },
        (error: any) => {
          throw (error);
        },
        () => {
          this.setFormLock(false);
        }
      ));
  }

  private setFormLock(lock: boolean = true) {
    this.formIsLocked = lock;
  }

  public showAlertMessageDiv(): boolean {
    if (this.authService.getUserSelectedEntity()) {
      return true;
    }
    return false;
  }

  private checkIfAnyPushNotificationHaveText(): boolean {
    if (this.pushNotificationText) return true;

    if (!this.pushNotifications) return false;

    for (var i = 0; i < this.pushNotifications.length; i++) {
      const push = this.pushNotifications[i];
      if (push && push.description && push.description.length >= 0) {
        return true;
      }
    }
    return false;
  }

  public shouldSendPushBeDisabled() {
    const selectedEntity = this.authService.getUserSelectedEntity();

    const disabled = this.formIsLocked ||
      !this.checkIfAnyPushNotificationHaveText() ||
      (!selectedEntity || selectedEntity.type === EntityType.GROUP);

    return disabled;
  }

  /*
     Data Grid
  */

  private setupDataGridConfiguration(): void {
    this.dataGridConfiguration = new DataGridConfiguration(null, true, false);

    this.dataGridConfiguration.addColumn({
      headerText: 'Date',
      isSortable: true,
      sortDataField: 'createdOn',
      resolver: (pushNotification: PushNotificationDTO, columnConfig, row: number) => {
        return new CustomDatePipe().transform(pushNotification.createdOn, false, 0);
      }
    });

    this.dataGridConfiguration.addColumn({
      headerText: 'Alert Text',
      isSortable: false,
      displayPropertyName: 'description'
    });

    this.dataGridConfiguration.addColumn({
      headerText: 'Property',
      isSortable: false,
      displayPropertyName: 'application.entity.name',
      columnGridWidth: 3
    });
  }

  public onDataRowClick(request: PushNotificationDTO) {
    console.dir('Datarow Click:' + request);
  }

  public onDataGridSortingChange(currentPage: number): void {
    console.dir('Sorting Changed:' + currentPage);
    this.searchFilter.orderBy = this.dataGridConfiguration.getColumnsSortAsApiCallParam();
  }

  public onDataGridPagingChange(page: number) {
    this.searchFilter.page = page;
    this.get();
  }

  /*
     Send Push Notification
  */

  public onPopupDeny($event: JQuery<HTMLElement>) {

  }

  public onPopupApprove($event: JQuery<HTMLElement>) {
    this.sendPushNotification();
  }

  public sendPushNotification(): void {
    if (!this.checkIfAnyPushNotificationHaveText() || this.shouldSendPushBeDisabled()) {
      return;
    }

    let pushNotifications = new Array<PushNotificationFullDTO>();
    this.pushNotifications.forEach((push) => {
      if (push && push.description && push.description.length >= 0) {
        pushNotifications.push(push);
      }
    });

    let entity = this.authService.getUserSelectedEntity();
    if (entity && this.pushNotificationText) {
      let pushNotification = new PushNotificationFullDTO();
      pushNotification.description = this.pushNotificationText;
      pushNotification.application = Object.assign(new ApplicationDTO(), { id: entity.application.id, name: entity.application.name });
      pushNotification.application.entity = Object.assign(new EntityDTO(), { id: entity.id, name: entity.name, shortName: entity.shortName });
      pushNotifications.push(pushNotification);
    }

    this.subscription.add(this.pushNotificationsService
      .createCollection(pushNotifications)
      .subscribe(
        (response) => {
          this.pushNotifications.forEach((push) => push.description = null);
          this.pushNotificationText = null;
        },
        (error: any) => {
          throw (error);
        },
        () => { this.get(); }
      ));
  }
}
