


    
    export class NotificationConfigurationDTO {
        
        
        newRequests: boolean;
        
        newRequestMessages: boolean;
        
        newChatMessages: boolean;
        
        newUsers: boolean;
        
        newSurveys: boolean;
        
        surveysAtRating?: number | null;
        
        unansweredConversations: boolean;
        
        newRequestIntegrationError: boolean;
        
        newExpressCheckOut: boolean;
        
        expressCheckOutSuccess: boolean;
        
        expressCheckOutFailure: boolean;

        selfCheckInSuccess: boolean;
        
        selfCheckInFailure: boolean;

        newPOSOrder: boolean;
    }
