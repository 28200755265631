<div class="ui left icon input"  >
  <i class="calendar icon"></i>
  <input #someInput suiDatepicker
         placeholder="{{placeholder}}"
         [(ngModel)]="date"
         [pickerMode]="pickerMode"
         [pickerMaxDate]="maxDate"
         [pickerMinDate]="minDate"
         (pickerSelectedDateChange)="pickerSelectedDateChange($event)"
         [pickerUseNativeOnMobile]="false">
</div>
