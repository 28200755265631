

	 import { GPhoneCommandDTO } from '@models/guest-u-phone/commands/GPhoneCommandDTO';
   import { GPhoneCommandBodyDTO } from '@models/guest-u-phone/commands/GPhoneCommandBodyDTO';


   export class GPhoneSimpleCommandDTO extends GPhoneCommandDTO<GPhoneCommandBodyDTO> {
     constructor() {
       super();
     }
   }
