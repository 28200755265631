﻿

	 import { RequestType } from '@models/enum/request/RequestType';
	 import { RequestState } from '@models/enum/request/RequestState';
	 import { Moment } from 'moment';
	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    /**
    * Sort and filter class for Application Requests
    */
    
    export class RequestSearchFilter extends SearchFilter {
        
        
        /**
        * Delivered Entity unique identifier
        */
        entityId?: number | null;
        
        /**
        * Chat User unique identifier filter value
        */
        chatUserId?: number | null;
        
        /**
        * User name filter value
        */
        userName: string;
        
        /**
        * User contact filter value
        */
        userContact: string;
        
        /**
        * Application Request type
        */
        type?: RequestType | null;
        
        /**
        * Application Request state
        */
        state: RequestState[];
        
        /**
        * Indicates if a Request's details were ever retrieved
        */
        wasRead?: boolean | null;
        
        /**
        * Creation datetime
        */
        createdOn: Moment;
        
        /**
        * Last update datetime
        */
        updatedOn: Moment;
        
        /**
        * Total count of existing unread notes
        */
        unreadNotes: number;
    }
