<div class="ui icon input">
  <input type="number"
         inputmode="numeric" 
         placeholder="{{placeholder}}"
         pattern="[0-9]*"
         min="{{minValue}}"
         (blur)="onBlur($event)"
         (keypress)="onKeyPress($event)"
         [(ngModel)]="inputValue" text-display >
  <i class="remove link icon" [class.hidden-remove]="showRemove()" (click)="removeOnClick($event)"></i>
</div>
