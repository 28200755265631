import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit {

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() onChangeComplete: EventEmitter<string> = new EventEmitter();

  inputValueLocal: string;
  inputValueLocalOld: string;
  @Input()
  set inputValue(val) {
    this.inputValueLocal = val;
    this.inputValueChange.emit(this.inputValueLocal);
  }
  get inputValue() {
    return this.inputValueLocal;
  }
  
  @Output() inputValueChange: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.inputValueLocalOld = this.inputValueLocal;
  }

  onBlur(event: FocusEvent) {
    if (this.blur) {
      this.blur.emit(event);
      if (this.inputValueLocalOld !== this.inputValueLocal) {
        this.inputValueLocalOld = this.inputValueLocal;
        this.onChangeComplete.emit(this.inputValueLocal);
      }
    }
  }

  onKeydown(event: KeyboardEvent) {
    let patt = /^([0-9])$/;
    if (!patt.test(event.key)) {
      event.preventDefault();
    }
  }

  removeOnClick(event: Event) {
    event.stopPropagation();
    this.inputValue = null;
    this.inputValueLocalOld = this.inputValueLocal;
    this.onChangeComplete.emit();
  }

  showRemove(): boolean {
    return !this.inputValue || this.inputValue === "" && this.inputValue.length <= 0;
  }
}
