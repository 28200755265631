﻿


    
    export class MessageTotalPerHourDTO {
        
        
        /**
        * Hour
        */
        hour: number;
        
        /**
        * Total guest message
        */
        messageGuest: number;
        
        /**
        * Total staff message
        */
        messageStaff: number;
    }
