import { Component, OnInit, Input, Output, ComponentRef, ViewChild, ViewContainerRef, ComponentFactoryResolver, Type } from '@angular/core';
import { ViewContainerDirective } from '@shared/directives/view-container.directive';

@Component({
  selector: 'tab',
  templateUrl: 'tab.component.html',
  styleUrls: ['tab.component.css']
})
/** tab component*/
export class TabComponent implements OnInit {

  /*  Configuration */
  @Input() @Output() public configuration: ITabConfig;

  @Input() @Output() public isLoading = false;

  public firstLoad: boolean = false;

  @ViewChild(ViewContainerDirective) componentTemplate: ViewContainerDirective;

  /** tab ctor */
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    this.configuration.tabComponent = this;
    if (this.configuration.component && this.configuration.selected) {
      this.loadContentComponent(this.componentTemplate.viewContainerRef);
    }
  }

  /**
  * Loads a component dynamically, by type, on a specific data content
  */
  private loadContentComponent(viewContainerRef: ViewContainerRef) {
    // Get component type factory and component template container (@ViewChildren)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.configuration.component as Type<any>);
    // Clear (if) existing elements on ViewContainer
    viewContainerRef.clear();
    // Creates a component instance
    let componentRef = viewContainerRef.createComponent(componentFactory);

    if (this.configuration.componentResolver) {
      this.configuration.componentResolver(componentRef);
    }

    // Needed to avoid ExpressionChangedAfterItHasBeenCheckedError error in runtime - force verification at this point
    componentRef.changeDetectorRef.detectChanges();
  }

  public reload() {
    if (this.configuration.component && this.configuration.selected) {
      this.loadContentComponent(this.componentTemplate.viewContainerRef);
    }
  }
}

/**
 * Value component resolver (function) type
 */
export type ComponentResolver = (componentRef: ComponentRef<any>) => void;

/**
 * Describes a Popup configuration
 */
export interface ITabConfig {
  title: string;
  active?: boolean;
  isVisible?: boolean;
  selected?: boolean;
  component: Type<any>;
  componentResolver?: ComponentResolver | null;
  tabComponent?: TabComponent | null;
}

export class TabConfig implements ITabConfig {
  public title: string;
  public active: boolean | true;
  public isVisible: boolean | true;
  public selected: boolean | false;
  public component: Type<any>;
  public componentResolver: ComponentResolver;
  public tabComponent?: TabComponent;

  constructor(config: ITabConfig = {} as ITabConfig) {

    let {
      selected = false,
      isVisible = true,
      active = true,
      componentResolver = null,
      tabComponent = null
    } = config;

    this.title = config.title;
    this.selected = selected;
    this.active = active;
    this.isVisible = isVisible;
    this.component = config.component;
    this.componentResolver = componentResolver;
    this.tabComponent = tabComponent;
  }
}
