import { AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import { NotifierService } from '@app/core/notifications/notifier.service';
import { Subscription } from 'rxjs';
import { VoiceConfigurationDTO } from '@app/models';

@Component({
  selector: 'voice-settings',
  templateUrl: './voice-settings.component.html',
  styleUrls: ['./voice-settings.component.css']
})
export class VoiceSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("componentDimmer") componentDimmer: ElementRef;

  public voiceConfigurationDto: VoiceConfigurationDTO;
  private rxSubscription = new Subscription();
  private fetchingComponentData: boolean;
  private notifierService = this.injector.get(NotifierService);

  private voipExtensionsExample: string = `${environment.appUrl}docs/VoipExtensionsExample.csv`;

  constructor(
    private entityService: EntityService,
    private authService: AuthenticationService,
    private injector: Injector
  ) { }

  ngOnInit() {
    this.getVoiceConfigurations();
  }

  getVoiceConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .getVoiceConfigurations()
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((data) => {
            if (data == null) {
              this.createVoiceConfigurations();
            } else {
              this.voiceConfigurationDto = data;
            }
          })
      );
    }
  }

  createVoiceConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .createVoiceConfigurations(new VoiceConfigurationDTO())
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((data) => {
            this.voiceConfigurationDto = data;
          })
      );
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  isDisabled(): boolean {
    return !this.voiceConfigurationDto || !this.voiceConfigurationDto.enablePBX;
  }

  importExtensionsChange(event: EventTarget) {
    this.setComponentDimmerVisibility(true);

    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;
    const formData: FormData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.entityService
      .importVoiceExtensions(formData)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
      .subscribe((data) => {
        if (data == null) {
          this.notifierService.error('Could not import voip extensions list', 'Import Voip Extension');
        } else {
          this.notifierService.success('The new voip extensions list has been applied successfully', 'Import Voip Extension');
        }
      });

    //-- clean file input
    target.value = null;
  }

  exportExtensionsClick(event: EventTarget) {
    this.entityService
      .exportVoiceExtensions()
      .subscribe(response => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([response], { type: "text/csv" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const entityId = this.authService.getUserSelectedEntityId();

        var link = document.createElement('a');
        link.href = data;
        link.download = `${new Date().getTime()}_${entityId}.csv`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    });
  }

  downloadExampleClick(event: EventTarget) {
    window.open(this.voipExtensionsExample, "_blank");
  }

  onCheckboxChange(value: boolean) {
    this.save();
  }

  onChangeWifi(value: string) {
    this.voiceConfigurationDto.wifiPBX = value;
    this.save();
  }

  onChangeCountryCodes(value: string) {
    this.voiceConfigurationDto.countryCodesPBX = value;
    this.save();
  }

  save() {
    this.setComponentDimmerVisibility(true);
    this.entityService
      .updateVoiceConfigurations(this.voiceConfigurationDto)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
      .subscribe((data) => {
        this.voiceConfigurationDto = data;
      });
  }

  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }

}
