<div [attr.hidden]="showIfHasData()" style="height: 100%; width: 100%" [ngStyle]="getStyle()" class="ui basic blurring dimmable" [class.dimmed]="isDimmed">
  <canvas #chartCanvas (click)="onClick($event)"></canvas>
  <div class="ui simple inverted dimmer">
    <div class="ui loader"></div>
  </div>
</div>

<div *ngIf="!hasData()" class="ui basic center aligned segment" id="no-records-message">
  <h4 class="ui center aligned icon header">
    <i class="big earlybirds icon"></i>No records found!
  </h4>
</div>

