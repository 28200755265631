import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.css']
})
export class ChipsComponent implements OnInit {

  //-- viewchilds
  @ViewChild('dropdown') dropdown: ElementRef;

  //-- inputs
  @Input() defaultText: string;
  @Input() tags: string;

  //-- outputs
  @Output() public onChangeValue: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $(this.dropdown.nativeElement)
      .dropdown({
        allowAdditions: true,
        onChange: (value: any, text: string, $choice: JQuery): void => {
          this.onChangeValue.emit(value);
        }
      });
  }

}
