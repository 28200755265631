﻿

	 import { MultimediaType } from '@models/enum/MultimediaType';
    
    /**
    * Represents a multimedia file content
    */
    
    export class MultimediaContentDTO {
        
        
        /**
        * Multimedia file unique identifier
        */
        id?: number | null;
        
        /**
        * Multimedia file type.
        */
        type: MultimediaType;
        
        /**
        * File direct access URL
        */
        url: string;
        
        /**
        * Reduced size and/or quality copy file's direct access URL
        */
        thumbnailUrl: string;
        
        /**
        * File i18n corresponding language
        */
        languageId?: number | null;
    }
