import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';

@Injectable()
export class NotifierService implements OnDestroy {
  constructor(private toastrService: ToastrService) {
  }

  public message(message: string, title?: string) {
    let optionsOverride = { enableHtml: true } as IndividualConfig;
    this.toastrService.show(message, title, optionsOverride);
  }

  public success(message: string, title?: string) {
    let optionsOverride = { enableHtml: true } as IndividualConfig;
    this.toastrService.success(message, title, optionsOverride);
  }

  public error(message: string, title?: string) {
    let optionsOverride = { enableHtml: true } as IndividualConfig;
    this.toastrService.error(message, title, optionsOverride);
  }

  public warning(message: string, title?: string) {
    let optionsOverride = { enableHtml: true } as IndividualConfig;
    this.toastrService.warning(message, title, optionsOverride);
  }

  public info(message: string, title?: string) {
    let optionsOverride = { enableHtml: true } as IndividualConfig;
    this.toastrService.info(message, title, optionsOverride);
  }

  public ngOnDestroy(): void {
  }
}
