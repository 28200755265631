﻿


    
    export class StatsSurveyDTO {
        
        
        /**
        * Rating average
        */
        ratingAverage: number;
        
        /**
        * Total reviews
        */
        totalReviews: number;
        
        /**
        * Total reviews today
        */
        totalReviewsToday: number;
        
        /**
        * The Month Rating Average
        */
        ratingMonth: number;
    }
