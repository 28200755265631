/**
* Data transfer object for integration configuration
*/

export class IntegrationConfigurationDTO {

  staysSyncEnable: boolean;

  staysSyncInterval: number | null;
}
