import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AuthLoginComponent } from './login.component';

@NgModule({
    imports: [
      FormsModule,
      CommonModule,
      RouterModule.forChild([
          { path: 'login', component: AuthLoginComponent }
      ])
    ],
    declarations: [
      AuthLoginComponent
    ],
    providers: [
    ],
    exports: [
      AuthLoginComponent
    ]
})
export class LoginModule {
}
