import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ListingsService } from '@app/listings/listings.service';
import { CountryDTO } from '@models/i18n/CountryDTO';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';


@Component({
  selector: 'countries-dropdown',
  templateUrl: './countries-dropdown.component.html',
  styleUrls: ['./countries-dropdown.component.css']
})
export class CountriesDropdownComponent implements OnInit {
  /**
   * Holds the current countries list
   */
  public countries: Array<any>;
  /**
   * Placeholder text
   */
  public readonly placeholder: string = 'Select a country';
  /**
   * Defines which <sui-dropdown> component list item type to render
   */
  public readonly dropdownItemType: string = 'Icon';

  /**
   * Load data as soon as possible or wait until first opening of dropdown list
   */
  @Input() loadDataImmediately: boolean;
  /**
   * Holds the currently selected country
   */
  @Input() public selectedCountry: CountryDTO;
  /**
   * Country change event emitter
   */
  @Output() public selectedCountryChange: EventEmitter<CountryDTO> = new EventEmitter<CountryDTO>();

  
  constructor(private listingsService: ListingsService) {
  }

  ngOnInit() {
  }

  
  /**
   * Gets the observable of country listing
   * @return Countries DTO list
   */
  public getDataObservable = (): Observable<CountryDTO[]> => {
    return this.listingsService.getCountries();
  }

  /**
   * Gets the display name from a country object instance
   * @param item Country object instance
   * @return Country name
   */
  public countryNameResolver(item: CountryDTO) {
    return item.name;
  }

  /**
   * Gets the display icon from a country object instance
   * @param item Country object instance
   * @return Country ISO code 
   */
  public countryIconResolver(item: CountryDTO) {
    return item.isoCode.toLowerCase() + ' flag';
  }

  /**
   * Value change handler
   * @param event Event instance and elements { value: (option val.), item: (DTO) }
   * @emits selectedCountryChange
   */
  public onValueChange(event: any) {
    // store...
    this.selectedCountry = event.item;
    // & forward
    this.selectedCountryChange.emit(event.item);
  }

  /**
   * Error handler
   * @param error Error description object
   */
  private errorHandle(error: any): void {
    if (!environment.production) {
      console.log(error);
    }
  }
}
