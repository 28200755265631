﻿

    
    /**
    * Application Screen type enumeration.
    */
    
    export enum ScreenType {
        
        
        Unknown = "Unknown",
        
        ENTITY = "ENTITY",
        
        PLACES = "PLACES",
        
        COLLECTIONS = "COLLECTIONS",
        
        EVENTS = "EVENTS",
        
        NEARBY = "NEARBY"
    }
