import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { NotificationsService } from '@app/notifications/notifications.service';
import { NavigationService } from './navigation.service';

@NgModule({
    imports: [
      RouterModule,
      SharedModule
    ],
    declarations: [
      // Module items
      NavigationMenuComponent
    ],
    providers: [
      NotificationsService,
      NavigationService
    ],
    exports: [
      NavigationMenuComponent
    ]
})
export class NavigationModule {
}
