﻿

	 import { ConciergeServiceState } from '@models/enum/ConciergeServiceState';
    
    /**
    * Data transfer object for a create a new message
    */
    
    export class ChatConfigurationUpdateDTO {
        
        
        /**
        * General availability state of chat service.
        */
        state: ConciergeServiceState;
    }
