﻿

    
    export enum MessagingType {
        
        
        Undefined = "Undefined",
        
        TEXT = "TEXT",
        
        INFO = "INFO"
    }
