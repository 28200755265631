﻿

	 import { ChatServiceStateDTO } from '@models/chat/ChatServiceStateDTO';
	 import { Moment } from 'moment';
    
    /**
    * Data transfer object for Entity's Chat service configuration
    */
    
    export class ChatConfigurationDTO {
        
        
        /**
        * Unique identifier
        */
        id?: number | null;
        
        /**
        * General availability state of chat service.
        */
        state: ChatServiceStateDTO;
        
        /**
        * State of chat service for active users only.
        */
        stateForActiveUsers: ChatServiceStateDTO;
        
        /**
        * Entity to which is delegated the chat management in a B2B Application (pretty much all cases).
        */
        delegationEntity?: number | null;
        
        /**
        * Entity to which is delegated the chat management in an Application of <see cref="F:AppGenerator.Business.Enum.Entity.EntityType.DESTINATION" /> type.
        */
        delegationEntityForDestinationApps?: number | null;
        
        /**
        * Indicates if the concierge chat service is enabled to the belonging Entity.  If not enabled it won't be possible to see any indication for initiating conversations in the  corresponding Application.
        */
        isEnabled: boolean;
        
        /**
        * User that created the entity
        */
        createdBy: string;
        
        /**
        * Date and time of entity creation
        */
        createdOn: Moment;
        
        /**
        * User that last updated the entity
        */
        updatedBy: string;
        
        /**
        * Date and time of entity update
        */
        updatedOn: Moment;
    }
