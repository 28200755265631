import { Injectable, OnInit } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { RequestState } from '@models/enum';

@Injectable()
export class RequestDetailActionsService implements OnInit {
  private dropDownSelectedValue$ = new Subject<RequestState>();
  private dropDownSilentlySelectedValue$ = new Subject<RequestState>();
  private dropDownLoadingState$ = new Subject<boolean>();
  private requestState$ = new Subject<RequestState>();

  ngOnInit(): void {
  }

  /* UI/UX */

  public getDropDownSelectedValue(): Observable<RequestState> {
    return this.dropDownSelectedValue$.asObservable();
  }

  public getDropDownSilentlySelectedValue(): Observable<RequestState> {
    return this.dropDownSilentlySelectedValue$.asObservable();
  }

  public setDropDownSelectedValue(state: RequestState, silently: boolean = false) {
    if (!silently) {
      this.dropDownSelectedValue$.next(state);
    }
    else {
      this.dropDownSilentlySelectedValue$.next(state);
    }
  }
  
  public getDropDownLoadingState(): Observable<boolean> {
    return this.dropDownLoadingState$.asObservable();
  }

  public setDropDownLoadingState(isLoading: boolean) {
    this.dropDownLoadingState$.next(isLoading);
  }

  /* Actions */

  public getRequestState(): Observable<RequestState> {
    return this.requestState$.asObservable();
  }

  public setRequestState(state: RequestState) {
    this.requestState$.next(state);
  }
}
