﻿

	 import { MultimediaContentDTO } from '@models/multimedia-content/MultimediaContentDTO';
    
    /**
    * Represents a multimedia file content
    */
    
    export class MultimediaContentFullDTO extends MultimediaContentDTO {
        
        
        /**
        * File name
        */
        fileName: string;
        
        /**
        * File upload repository. This is the relative path to folder where file is stored
        */
        fileRepositoryPath: string;
        
        /**
        * File extension
        */
        fileExtension: string;
        
        /**
        * Original file name (on/from upload)
        */
        originalFileName: string;
        
        /**
        * Thumbnail file name
        */
        thumbnailFileName: string;
        
        /**
        * Original uploaded file direct access URL  <remarks>This content is unmodified in any way since it's upload</remarks>
        */
        originalUrl: string;
    }
