import { Component, OnInit, ElementRef, ViewChild, ComponentRef, OnDestroy, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { timer, Subscription, Subject } from 'rxjs';
import { map, switchMap, finalize, debounce } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DevicesService } from '../devices.service';
import { AuthenticationService } from '@app/core/authentication.service';
import { PersistenceManager } from '@models/persistence';
import {
  AppHeaderService, BreadcrumbSection, DataGridConfiguration, PopupSize,
  EditableLabelComponent, DataGridImageItemComponent, DataGridComponent,
  ActionButtonConfig, PopupConfig, DatePickerComponent, TextLinkComponent,
  ActionButtonsComponent, OpenlayersComponent, MomentDateComponent, PopupComponent
} from '@app/shared';
import { Device, Status, DeviceSearchFilter, Commands, GPhoneCommandBodyDTO } from '@app/models';
import { CustomDatePipe } from '@app/core/pipes/custom-date.pipe';
import { EmptyFieldPipe } from '@app/core/pipes/empty-field.pipe';
import { AngularFireDatabase } from '@angular/fire/database';
import { DatepickerMode } from 'ng2-semantic-ui';
import { GPhoneSimpleCommandDTO } from '@models/guest-u-phone/commands/GPhoneSimpleCommandDTO';
import { DeviceCheckinFormComponent } from '@app/devices/device-checkin-form/device-checkin-form.component';
import { GPhoneCheckOutDateUpdateCommandDTO } from '@models/guest-u-phone/commands/GPhoneCheckOutDateUpdateCommandDTO';
import { NotifierService } from '@app/core/notifications/notifier.service';
import * as moment from 'moment/moment';


@Component({
  selector: 'devices-list',
  templateUrl: 'devices-list.component.html',
  styleUrls: ['devices-list.component.css']
})
export class DevicesListComponent implements OnInit, OnDestroy, AfterViewInit {
  /** Devices list */
  public guestuPhones: Device[] = new Array<Device>();
  /** Currently selected device information */
  public guestuPhoneSelected: Device = null;
  /** Devices search filter */
  public searchFilter: DeviceSearchFilter;
  /** Indicates if only checked-in devices should be shown */
  public showCheckedInDevicesOnly: boolean = true;

  /*  Data Filtering & Filtering form */
  private filterWaiting: boolean = false;
  private isFirstLoading: boolean = true;
  private debounceTimeValue: number = 0; // Initially 0, will be changed after first data retrieval

  /** Data-grid configuration */
  public gridConfiguration: DataGridConfiguration<Device>;
  /** Data-grid component reference */
  @ViewChild(DataGridComponent) dataGridComponent: DataGridComponent;
  /** Data-grid component's container element reference */
  @ViewChild('devicesGridContainer') devicesGridContainer: ElementRef;
  /** OpenLayers component reference */
  @ViewChild(OpenlayersComponent) openlayersComponent: OpenlayersComponent;
  /** States dropdown list component element reference */
  @ViewChild('DdStates') ddStates: ElementRef;
  /** Devices possible status list */
  public devStatusList: any[];

  /** Extend check-out date popup component configuration object */
  public checkOutUpdatePopupConfiguration: PopupConfig;
  /** Extend check-in date popup component configuration object */
  public checkInPopupConfiguration: PopupConfig;
  /** Holds reference to check-in popup embedded form component's submit function */
  public checkInPopupSubmitFn: Function;
  /** Popup children components */
  @ViewChildren(PopupComponent) popupComponents: QueryList<PopupComponent>;

  // Store the subscription ref from get request because the loopGetLastRequests
  private subscription: Subscription = new Subscription();
  private subscriptionFilter: Subscription = new Subscription();
  private filter$: Subject<DeviceSearchFilter> = new Subject<DeviceSearchFilter>();


  constructor(private devicesService: DevicesService, private router: Router, private appHeaderService: AppHeaderService,
    private persistenceManager: PersistenceManager, private authService: AuthenticationService, private db: AngularFireDatabase,
    private notifierService: NotifierService)
  {
    this.devStatusList = Object.keys(Status);
    this.appHeaderService.resetBreadcrumb(new BreadcrumbSection('GuestU Phones', ['/guestu-phones']));
    this.setupRequestsDataGrid();
  }

  public ngOnInit(): void {
    this.setupPersistence();
    this.setupCheckOutDateUpdatePopup();
    this.setupCheckInPopup();
  }

  ngAfterViewInit(): void {
    this.initDbStates();
    this.subscription.add(this.authService.selectedEntity$.subscribe((entity) => this.getDevices()));
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscriptionFilter) {
      this.subscriptionFilter.unsubscribe();
    }

    if (this.filter$) {
      this.filter$.unsubscribe();
    }

    if (this.devicesService) {
      this.devicesService.signOutFirebase();
    }
  }

  private setupPersistence() {
    this.searchFilter = this.persistenceManager.store<DeviceSearchFilter>("DevicesSearchFilter", DeviceSearchFilter, psf => {
      psf.status = [Status.InUse]
    });
  }

  private getDevices() {
    this.devicesService.signInFirebase(this.searchFilter.entityId, (idTokenResult) => {
        const appId = idTokenResult.claims["entity_access_application_id"];
        if (this.subscriptionFilter) {
          this.subscriptionFilter.unsubscribe();
        }
        this.subscriptionFilter = this.filter$
          .pipe<Device[], Device[], Device[]>
          (
              switchMap(filter =>
              {
                if (this.dataGridComponent) {
                  this.dataGridComponent.setGridAsLoading(true);
                }
                
                return this.db.list<Device>('devices/', ref => ref.orderByChild("keys/entity_id").equalTo(appId))
                  .snapshotChanges()
                  .pipe
                  (
                    map((list) => list.map<Device>(elem => {
                      let dev: Device = Object.assign(new Device(elem.payload.key), elem.payload.val());
                      dev.setLastMsg();
                      return dev;
                    })
                    .sort((dev1, dev2) =>
                    {
                      if (dev1.keys.user_checkout_date && !dev2.keys.user_checkout_date)
                        return -1; // Device 1 comes first 
                      if (!dev1.keys.user_checkout_date && dev2.keys.user_checkout_date)
                        return 1;  // Device 2 comes first

                      return moment.utc(dev1.keys.user_checkout_date).diff(moment.utc(dev2.keys.user_checkout_date)) || this.compareFnStatus(dev1, dev2);
                    })
                    .filter(d =>
                      (this.authService.userIsAdminOrManager() || d.isDeployed()) &&
                      (!filter.deviceName || d.deviceId.toLowerCase().indexOf(filter.deviceName.toLowerCase()) >= 0) &&
                      (!filter.assignment || d.assignment && d.assignment.toLowerCase().indexOf(filter.assignment.toLowerCase()) >= 0) &&
                      (!filter.guestName || d.keys.user_name && d.keys.user_name.toLowerCase().indexOf(filter.guestName.toLowerCase()) >= 0) &&
                      (!filter.status || filter.status.includes(d.keys.status)))
                    )
                  );
              }),
              debounce(() => timer(this.debounceTimeValue)),
              finalize(() => {
                if (this.dataGridComponent) {
                  this.dataGridComponent.setGridAsLoading(false);
                }
              })
          )
          .subscribe(res => {
            if (this.filterWaiting) {
              return;
            }

            if (this.isFirstLoading) {
              this.isFirstLoading = false;
              this.debounceTimeValue = 500; // TODO: Optimize this time(r); Heuristics..?
            }
            
            this.guestuPhones = res;
            if (this.dataGridComponent) {
              this.dataGridComponent.setGridAsLoading(false);
            }
          },
            (error: any) => {
              this.errorHandle(error);
            }
          );
        // trigger the query
        this.filter$.next(this.searchFilter);
      },
      (error) => {
        this.guestuPhones = new Array<Device>();
        if (this.dataGridComponent) {
          this.dataGridComponent.setGridAsLoading(false);
        }
      }
    );
  }
  
  private initDbStates() {
    $(this.ddStates.nativeElement)
      .dropdown({
        maxSelections: 2,
        onChange: (value: any, text: string, $choice: JQuery): any => {
          if (!value) {
            this.searchFilter.status = null;
            return;
          }
          this.searchFilter.status = value.split(',');
        }
      }).dropdown('set selected', this.searchFilter.status);
  }

  private updateAssignedById(devId: string, newAssignment: string) {
    this.db.object('devices/' + devId).update({ assignment: newAssignment });
  }

  /** compareFn */
  private compareFnStatus(dev1: Device, dev2: Device): number {
    if (dev1.keys.status < dev2.keys.status) return -1; //dev1 comes first 
    if (dev1.keys.status > dev2.keys.status) return 1; //dev2 comes first 
    return 0; //equal, so order is irrelevant
  }

  /*
     DataGrid Component Interaction
  */
  private setupRequestsDataGrid(): void {

    this.gridConfiguration = new DataGridConfiguration(null, false, false);
    this.gridConfiguration.alwaysUpdateComponentData = true;

    this.gridConfiguration.addColumn({
      index: 0,
      headerText: 'Device',
      resolver: (dev, columnConfig, rowNumber?, refRow?, refCell?) => {
        if (refCell && dev.keys.version) {
          const tooltip = document.createAttribute('data-tooltip');
          tooltip.value = `Version ${dev.keys.version}`;
          refCell.attributes.setNamedItem(tooltip);
        }

        return dev.id;
      },
      isSortable: false,
      addCssClassesForHeader: "table-mini-width"
    });

    this.gridConfiguration.addColumn({
      index: 1,
      isSortable: false,
      headerText: 'Assigned',
      component: EditableLabelComponent,
      componentResolver: (componentRef: ComponentRef<EditableLabelComponent>, dev) => {
        if (this.filterWaiting) {
          return;
        }

        const instance = componentRef.instance;
        instance.inputValue = dev.assignment;
        
        if (!(<any>componentRef).updatingValues) {
          instance.useDefaultValue = true;
          instance.click = () => {
            this.filterWaiting = true;
          };
          instance.blur = () => {
            this.filterWaiting = false;
          };

          const componentSubscriptions = new Subscription();

          componentSubscriptions.add(
            instance.valueChange.subscribe(
              (value) => this.updateAssignedById(dev.deviceId, value)
          ));

          componentSubscriptions.add(
            instance.inputValueChange.subscribe(
              (value) => dev.assignment = value
          ));

          (<any>instance).eventSubscriptions = componentSubscriptions;

          componentRef.onDestroy(() => {
            if ((<any>instance).eventSubscriptions)
              (<any>instance).eventSubscriptions.unsubscribe();
          });
        }
      }
    });
    
    this.gridConfiguration.addColumn({
      index: 2,
      headerText: 'Status',
      isSortable: false,
      resolveCellClass: (dev: Device) => {
        if (dev.keys.status === Status.InUse) {
          return "ui text green";
        } else if (dev.keys.status === Status.OnBoarding) {
          return "ui text blue";
        }

        return "";
      },
      displayPropertyName: 'keys.status',
      addCssClassesForHeader: "table-mini-width"
    });
    
    this.gridConfiguration.addColumn({
      index: 3,
      headerText: 'Guest name',
      isSortable: false,
      resolveCellClass: (dev) => {
        if (dev.keys.user_checkin) {
          return '';
        }
        return 'disabled-with-color';
      },
      component: DataGridImageItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridImageItemComponent>, dev, refRow, refCell) => {
        const instance = componentRef.instance;

        if (dev && dev.keys && dev.keys.user_country) {
          const country = dev.keys.user_country.substr(0, 2).trimLeft().toLowerCase();
          instance.iconClass = country + ' flag';
        }

        instance.text = dev.keys.user_name;

        if (!dev.keys.user_checkin) {
          const tooltip = document.createAttribute('data-tooltip');
          tooltip.value = `Previous guest\'s name`;
          refCell.attributes.setNamedItem(tooltip);
          return;
        }

        if (dev.keys.last_call_reception) {
          instance.hasLabel = true;
          instance.rightIconClass = 'phone-sound gu icon';

          if (dev.keys.last_call_reception === 'CALLING') {
            instance.rightIconClass += ' ringing green';
            return;
          } else {
            const tooltip = document.createAttribute('data-tooltip');
            tooltip.value = `Called reception ${new CustomDatePipe().transform(dev.keys.last_call_reception)}`;
            refCell.attributes.setNamedItem(tooltip);
            instance.rightIconClass += ' grey';
          }
        } else {
          const tooltip = document.createAttribute('data-tooltip');
          tooltip.value = `Current guest\'s name`;
          refCell.attributes.setNamedItem(tooltip);
        }
      }
    });
    
    this.gridConfiguration.addColumn({
      index: 4,
      headerText: 'Check-Out',
      isSortable: false,
      component: DataGridImageItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridImageItemComponent>, dev, refRow, refCell) => {
        const instance = componentRef.instance;

        if (!dev.keys.user_checkin && !dev.keys.user_checkout_date) {
          instance.text = new EmptyFieldPipe().transform();
        } else {
          if (dev.keys.user_checkin && !dev.keys.user_checkout_date) {
            instance.tooltipLeft = new CustomDatePipe().transform(dev.keys.user_checkin, true);
          } else if (!dev.keys.user_checkin && dev.keys.user_checkout_date) {
            instance.tooltipLeft = new CustomDatePipe().transform(dev.keys.user_checkout_date, true);
          } else {
            instance.tooltipLeft = `${new CustomDatePipe().transform(dev.keys.user_checkin, true)} - ${new CustomDatePipe().transform(dev.keys.user_checkout_date, true)}`;
          }
        }

        if (dev.keys.user_checkout_date) {
          instance.text = moment(dev.keys.user_checkout_date).format('DD MMM');
        }

        if (dev.keys.status === Status.InUse) {
          if (!(<any>componentRef).updatingValues) {
            if ((<any>instance).clickSubscribe) {
              (<any>instance).clickSubscribe.unsubscribe();
            }

            (<any>instance).clickSubscribe = componentRef.instance.click.subscribe(() => {
              this.guestuPhoneSelected = dev;
              this.getCheckOutUpdatePopup().reloadContentComponent();
              this.getCheckOutUpdatePopup().show();
            });
            
            componentRef.onDestroy(() => {
              let inst = (<any>instance);

              if (inst.clickSubscribe) {
                inst.clickSubscribe.unsubscribe();
              }
            });
          }
        }
        else if(componentRef.instance.click && componentRef.instance.click.observers && componentRef.instance.click.observers.length > 0){
          componentRef.instance.click.unsubscribe();
        }

        if (dev && dev.keys && dev.keys.survey_rating) {
          instance.hasLabel = true;
          instance.rightIconClass = this.getClassFromRating(dev.keys.survey_rating);
          instance.tooltipRight = dev.keys.survey_comment ? dev.keys.survey_comment : "[No Comments]";
        }
      },
      resolveCellClass: (dev: Device) => {

        if (!dev || !dev.keys.user_new_checkout_date) return "";

        if (dev.keys.user_checkout_date !== dev.keys.user_new_checkout_date) {
          return "ui text red";
        }

        return "";
      },
      columnGridWidth: 1
    });
    
    this.gridConfiguration.addColumn({
      index: 5,
      headerText: 'Bat.',
      isSortable: false,
      addCssClassesForHeader: "table-mini-width",
      resolveCellClass: (dev) => {
        return "text-align-center";
      },
      component: DataGridImageItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridImageItemComponent>, dev, refRow, refCell) => {

        if (dev && dev.keys && dev.keys.battery_p) {
          if (refCell) {
            const tooltip = document.createAttribute('data-tooltip');
            tooltip.value = dev.keys.battery_p;
            refCell.attributes.setNamedItem(tooltip);
          }

          let value = Number(dev.keys.battery_p.substr(0, dev.keys.battery_p.length - 1));

          const instance = componentRef.instance;

          if (dev.keys.power === 'connected') {
            instance.iconClass = ' battery-charge gu';
          } else {
            instance.iconClass = ' battery ';
            if (value === 100) {
              instance.iconClass += 'full';
            } else if (value >= 75 && value <= 99) {
              instance.iconClass += 'three quarters';
            } else if (value >= 26 && value <= 74) {
              instance.iconClass += 'half';
            } else if (value >= 6 && value <= 25) {
              instance.iconClass += 'quarter';
            } else {
              instance.iconClass += 'empty';
            }
          }

          if (value === 100) {
            instance.iconClass += ' green';
          } else if (value >= 75 && value <= 99) {
            instance.iconClass += ' olive';
          } else if (value >= 26 && value <= 74) {
            instance.iconClass += ' yellow';
          } else if (value >= 6 && value <= 25) {
            instance.iconClass += ' orange';
          } else {
            instance.iconClass += ' red';
          }

          instance.iconClass += ' icon';
        }
      }
    });
    
    this.gridConfiguration.addColumn({
      index: 6,
      headerText: 'Last active',
      isSortable: false,
      component: MomentDateComponent,
      componentResolver: (componentRef: ComponentRef<MomentDateComponent>, dev) => {
        const instance = componentRef.instance;
        instance.date = dev.lastmsg ? dev.lastmsg.dateTime : dev.keys.location_time;
      },
      resolveCellClass: (dev: Device) => {
        if (dev.isWarn()) {
          return 'ui text red';
        }

        return '';
      }
    });
    
    this.gridConfiguration.addColumn({
      index: 7,
      headerText: 'Last location',
      isSortable: false,
      component: TextLinkComponent,
      componentResolver: (componentRef: ComponentRef<TextLinkComponent>, dev) => {
        const instance = componentRef.instance;
        
        if (!dev.keys.location_name && !dev.keys.location_alias) {
          instance.text = new EmptyFieldPipe().transform();
        } else if (dev.keys.location_name && !dev.keys.location_alias) {
          instance.text = dev.keys.location_name;
        } else if (!dev.keys.location_name && dev.keys.location_alias) {
          instance.text = dev.keys.location_alias;
        } else {
          instance.text = `${dev.keys.location_name} ${dev.keys.location_alias}`;
        }

        if (dev.keys.status !== Status.InUse) {
          instance.isHyperlink = true;
          instance.click = () => {
            const coords = dev.keys.location_coords.split(',');
            this.openlayersComponent.addOncePoint(Number(coords[0]), Number(coords[1]));
            this.openlayersComponent.popupHeader = `${dev.deviceId}` + (dev.assignment ? ` - ${dev.assignment}` : "");
            this.openlayersComponent.show();
          };
        } else {
          instance.isHyperlink = false;
          instance.click = () => { };
        }
      },
      resolveCellClass: (dev: Device) => {
        if (dev.isWarn()) {
          return "ui text red";
        }

        return "";
      }
    });

    this.gridConfiguration.addColumn({
        index: 8,
        headerText: 'Commands',
        isSortable: false,
        component: ActionButtonsComponent,
        componentResolver: (componentRef: ComponentRef<ActionButtonsComponent>, dev) => {
          if (dev && dev.keys && dev.keys.push_token) {
            const instance = componentRef.instance;
            instance.hasPopup = true;
            
            if (dev.keys.status && (dev.keys.status === Status.Shutdown || dev.keys.status == 'Shutdown*' || <string>dev.keys.status == 'Setup')) {
              if (instance.configuration) {
                instance.configuration = null;
              }
              return;
            }

            const syncTooltipText: string = "Request device to send its status";
            const checkoutTooltipText: string = "Force User Checkout";
            const quietSoundTooltipText: string = "Make device play a sound";
            const soundTooltipText: string = "Make device play a loud sound";
            const redirectToChatTooltipText: string = "Chat with guest";
            const checkinTooltip: string = "Check-in a guest";

            if (!instance.configuration) {
              instance.configuration = [
                // 0: 
                new ActionButtonConfig("sync",
                  (event: Event) => {
                    this.actionButtonSync(dev);
                  },
                  null,
                  `<p>${ syncTooltipText }</p><p>${ dev.deviceId +
                  (dev.keys.user_name
                    ? ` - ${ dev.keys.user_name }`
                    : "") }</p>`,
                  syncTooltipText),
                // 1: 
                new ActionButtonConfig("volume down",
                  (event: Event) => {
                    this.actionButtonQuietSound(dev);
                  },
                  null,
                  `<p>${ quietSoundTooltipText }</p><p>${ dev.deviceId +
                  (dev.keys.user_name
                    ? ` - ${ dev.keys.user_name }`
                    : "") }</p>`,
                  quietSoundTooltipText),
                // 2: 
                new ActionButtonConfig("volume up",
                  (event: Event) => {
                    this.actionButtonSound(dev);
                  },
                  null,
                  `<p>${ soundTooltipText }</p><p>${ dev.deviceId +
                  (dev.keys.user_name
                    ? ` - ${ dev.keys.user_name }`
                    : "") }</p>`,
                  soundTooltipText),
                // 3: 
                new ActionButtonConfig("green sign in",
                  (event: Event) => this.actionButtonCheckin(dev),
                  null,
                  `<p>${ checkinTooltip }</p><p>${ dev.deviceId +
                  (dev.keys.user_name
                    ? ` - ${ dev.keys.user_name }`
                    : "") }</p>`,
                  "Do guest check-in",
                  false
                ),
                // 4: 
                new ActionButtonConfig("red sign out",
                  (event: Event) => this.actionButtonCheckout(dev),
                  null,
                  `<p>${ checkoutTooltipText }</p><p>${ dev.deviceId +
                  (dev.keys.user_name
                    ? ` - ${ dev.keys.user_name }`
                    : "") }</p>`,
                  checkoutTooltipText),
              ];

              if (this.authService.hasClaimCapability('chat')) {
                instance.configuration.push(
                  // 5: 
                  new ActionButtonConfig("blue comments",
                    (event: Event) => {
                      this.redirectToChat(dev);
                    },
                    null,
                    null,
                    redirectToChatTooltipText,
                    false)
                );
              }
            }

            // Sign-in
            instance.configuration[3].isVisible = dev.keys.status && dev.keys.status === Status.OnCheckIn || dev.keys.status === Status.OnBoarding;

            // Sign-out
            instance.configuration[4].isVisible = !instance.configuration[3].isVisible && dev.keys.status === Status.InUse;

            // Chat w/Guest
            if (this.authService.hasClaimCapability('chat')) 
              instance.configuration[5].isVisible = !(!dev.keys.user_id || !dev.keys.user_checkin) && dev.keys.status === Status.InUse;
          }
        }
      }
    );
  }

  private getCheckOutUpdatePopup(): PopupComponent {
    return this.popupComponents.find((item, index, array) => {
      return index === 0;
    });
  }

  private getCheckInPopup(): PopupComponent {
    return this.popupComponents.find((item, index, array) => {
      return index === 1;
    });
  }

  private setupCheckOutDateUpdatePopup() {
    this.checkOutUpdatePopupConfiguration = new PopupConfig(
      "Check-out Update",
      null,
      DatePickerComponent,
      (componentRef: ComponentRef<DatePickerComponent>) => {
        if (!this.guestuPhoneSelected) {
          return;
        }

        const dateFormatStr: string = 'YYYY-MM-DDT00:00:00';

        // This is an exceptional case where we're not interested in having a date+tZone, just a plain date without hours being relevant
        componentRef.instance.date = <any>new Date(moment(this.guestuPhoneSelected.keys.user_checkout_date).format(dateFormatStr));
        componentRef.instance.pickerMode = DatepickerMode.Date;
        
        (<any>componentRef).dateChangeSubscriptions = componentRef.instance.dateChange.subscribe(
          (date) => {
            // This is an exceptional case where we're not interested in having a date+tZone, just a plain date without hours being relevant
            this.guestuPhoneSelected.keys.user_new_checkout_date = moment(moment(date).format(dateFormatStr));
          }
        );

        componentRef.onDestroy(() => {
            const subscriptions = (<any>componentRef).dateChangeSubscriptions;
            if (subscriptions) {
              subscriptions.unsubscribe();
            }
          }
        );
      },
      PopupSize.Mini,
      false);
  }

  public setupCheckInPopup() {
    this.checkInPopupConfiguration = new PopupConfig(
      "Device Check-in",
      null,
      DeviceCheckinFormComponent,
      (componentRef: ComponentRef<DeviceCheckinFormComponent>) => {
        if (!this.guestuPhoneSelected) {
          return;
        }

        componentRef.instance.fluidLayout = true;
        componentRef.instance.independentMode = false;
        componentRef.instance.deviceIdentifier = this.guestuPhoneSelected.deviceId;
        componentRef.instance.devicePushToken = this.guestuPhoneSelected.keys.push_token;
        componentRef.instance.entityIdentifier = this.authService.getUserSelectedEntityId();
        componentRef.instance.room = this.guestuPhoneSelected.assignment;

        var successSub = componentRef.instance.onSuccess.subscribe(() => {
          this.notifierService.success('The device has been successfully configured!', 'Device Check-in');
          this.guestuPhoneSelected = null;
          this.getCheckInPopup().hide();
        });

        var failSub = componentRef.instance.onFailure.subscribe((errorDescription) => {
          this.notifierService.error(errorDescription, 'Device Check-in failed');
        });

        componentRef.onDestroy(() => {
          if (successSub) {
            successSub.unsubscribe();
          }

          if (failSub) {
            failSub.unsubscribe();
          }
        });
        
        // Store pointer to call later on popup approve action
        this.checkInPopupSubmitFn = () => componentRef.instance.doCheckIn();
      },
      PopupSize.Tiny,
      false,      // auto-focus
      true,       // closable
      true,       // show confirmation button
      'Check-In', // confirmation button text
      true,       // show cancellation button
      'Cancel',      // cancellation button text
      () => this.onCheckInPopupApprove(), // Lambda function will keep closure
      () => this.onCheckInPopupDeny()     // Lambda function will keep closure
    );
  }

  public onCheckInPopupDeny(): false | void {
    this.guestuPhoneSelected = null;
    return null;
  }

  public onCheckInPopupApprove(): false | void {
    this.checkInPopupSubmitFn();
    // Avoid popup closing after approve action button click, it will be latter handled by the onSuccess subscriber
    return false;
  }

  public onCheckOutDateUpdatePopupDeny(elem: JQuery<HTMLElement>) {
    this.guestuPhoneSelected = null;
  }

  public onCheckOutDateUpdatePopupApprove(elem: JQuery<HTMLElement>) {
    const updateCheckOutCommand: GPhoneCheckOutDateUpdateCommandDTO = new GPhoneCheckOutDateUpdateCommandDTO();
    updateCheckOutCommand.entityId = this.authService.getUserSelectedEntityId();
    updateCheckOutCommand.roomNumber = this.guestuPhoneSelected.assignment;
    // The date format and concatenation below forces an UTC/+00 date and avoids the server JSON deserialization with timezone conversions - that would lead to incorrect date storage
    // This is an exceptional case where we're not interested in having a date+tZone, just a plain date without hours being relevant
    updateCheckOutCommand.newCheckOutDate = moment(moment.parseZone(this.guestuPhoneSelected.keys.user_new_checkout_date).format("YYYY-MM-DDT00:00:00")+'+00:00').utc();

    this.devicesService
      .sendUpdateCheckOutDateCommand(updateCheckOutCommand)
      .subscribe(
        () => {
          this.notifierService.success('The check-out date has been successfully updated!', 'Check-out update');
        },
        (error: any) => {
          this.notifierService.error('The check-out date update has failed!', 'Check-out update');
        }
      );

    this.guestuPhoneSelected = null;
  }

  public onDataGridSortingChange(currentPage: number): void {
  }

  /*
     Filtering Form
  */
  public applyDataFilter(): void {
    // Trigger
    this.filter$.next(this.searchFilter);
  }

  /*
     Error handling
  */
  private errorHandle(error: any): void {
    $(this.devicesGridContainer.nativeElement).dimmer('hide');
    throw Error(error);
  }

  private getClassFromRating(rating: number): string {
    switch (rating) {
      case 1:
        return "red rating-angry-alt gu icon";
      case 2:
        return "orange rating-sad-alt gu icon";
      case 3:
        return "yellow rating-neutral-alt gu icon";
      case 4:
        return "olive rating-good-alt gu icon";
      case 5:
        return "green rating-happy-alt gu icon";
    }
    return "";
  }

  private redirectToChat(dev: Device) {
    const entityId = this.searchFilter.entityId ? this.searchFilter.entityId : this.authService.getUserSelectedEntityId();
    if (!entityId && !dev.keys.user_id) {
      return;
    }

    const conversationId = `${entityId}-${dev.keys.user_id}`;
    this.router.navigate(["chat", conversationId]);
  }

  private actionButtonSync(device: Device) {
    this.sendDeviceSimpleCommand(device.keys.push_token, Commands.Status);
  }

  private actionButtonCheckout(device: Device) {
    this.sendDeviceSimpleCommand(device.keys.push_token, Commands.Reset);
  }

  private actionButtonCheckin(device: Device) {
    this.guestuPhoneSelected = device;
    this.getCheckInPopup().reloadContentComponent();
    this.getCheckInPopup().show();
  }

  private actionButtonQuietSound(device: Device) {
    this.sendDeviceSimpleCommand(device.keys.push_token, Commands.QuietSound);
  }

  private actionButtonSound(device: Device) {
    this.sendDeviceSimpleCommand(device.keys.push_token, Commands.Sound);
  }

  private sendDeviceSimpleCommand(token: string, action: string) {
    const simpleCommandDto: GPhoneSimpleCommandDTO = new GPhoneSimpleCommandDTO();
    simpleCommandDto.entityId = this.authService.getUserSelectedEntityId();
    simpleCommandDto.deviceRegistrationId = token;
    simpleCommandDto.data = new GPhoneCommandBodyDTO();
    simpleCommandDto.data.action = action;
    
    this.devicesService
      .sendCommand(simpleCommandDto)
      .subscribe(
        () => {
          this.notifierService.success('Command successfully sent to device!', 'Device Command');
        },
        (error) => {
          this.notifierService.success('Failed to send command to device!', 'Device Command');
        }
      );
  }
}
