<div class="concierge status ui form" [hidden]="hidden">
  <div class="inline fields" >
    <!-- state Concierge Dropdown -->
    <div class="field">
      <div #conciergeStatusDdl class="ui tiny compact floating dropdown button" [ngClass]="getConciergeDdlBgColor()" [class.disabled]="conciergeStatusDdlIsLoading">
        <span class="text"></span>
        <i class="dropdown icon"></i>
        <div class="menu">
          <div class="scrolling menu">
            <div data-value="Online" data-text="Online" data-color="green" class="item">
              <div class="ui tiny green empty circular label"></div>
              Online
            </div>
            <div data-value="Offline" data-text="Offline" data-color="grey" class="item">
              <div class="ui tiny grey empty circular label"></div>
              Offline
            </div>
          </div>
        </div>
      </div>
      <label>for guests</label>
    </div>
    <!-- state For ActiveUsers Dropdown -->
    <div class="field">
      <div #conciergeForActiveUsersStatusDdl class="ui tiny compact floating dropdown button" [ngClass]="getConciergeForActiveUsersDdlBgColor()" [class.disabled]="conciergeForActiveUsersDdlIsLoading">
        <span class="text"></span>
        <i class="dropdown icon"></i>
        <div class="menu">
          <div class="scrolling menu">
            <div data-value="None" data-text="None" data-color="black" class="item">
              <div class="ui tiny black empty circular label"></div>
              None
            </div>
            <div data-value="Online" data-text="Online" data-color="green" class="item">
              <div class="ui tiny green empty circular label"></div>
              Online
            </div>
            <div data-value="Offline" data-text="Offline" data-color="grey" class="item">
              <div class="ui tiny grey empty circular label"></div>
              Offline
            </div>
          </div>
        </div>
      </div>
      <label>for active conversations</label>
    </div>
  </div>
</div>
