

import { AppLayout } from '@models/enum/application/AppLayout';
import { MultimediaContentDTO } from '@models/multimedia-content/MultimediaContentDTO';
import { AirportDTO } from '@models/AirportDTO';
import { CurrencyDTO } from '@models/CurrencyDTO';
import { VoiceConfigurationDTO } from '@models/voice/VoiceConfigurationDTO';
import { RequestConfigurationDTO } from '@models/requests/RequestConfigurationDTO';
import { MembershipConfigurationDTO } from '@models/membership/MembershipConfigurationDTO';

/**
* Data transfer object for Entity's Chat service configuration
*/

export class AppConfigurationDTO {


    /**
    * Unique identifier
    */
    id?: number | null;

    /**
    * Application layout
    */
    layout: AppLayout;

    /**
    * Logo
    */
    logo: MultimediaContentDTO;

    /**
    * Temp json to save the firebase or devs configs
    */
    additionalConfigs: string;

    /**
    * The Airports list
    */
    airports: AirportDTO[];

    /**
    * Entity Timezone
    */
    timezone: string;

    /**
    * Entity currency
    */
    currency: CurrencyDTO;

    /**
    * URL Backend Nonius
    */
    backendNonius: string;

    /**
    * Indicates if the Tv service is enabled to the belonging Entity.  If not enabled it won't be possible to control the tv.
    */
    hasTv: boolean;

    /**
    * Nonius tenant
    */
    noniusTenant: string;

    /**
    * Indicates if the belonging Entity has DoorLock service.  If not true it won't be possible to use the door lock.
    */
    hasDoorLock: boolean;

    /**
    * Indicates if the Entity are used the airport by location or entity.
    */
    useLocationAirport: boolean;

    /**
    * Override the group settings.
    */
    overrideGroupSettings: boolean;

    /**
    * Indicates if send requests to hub
    */
    sendRequestsToHub: boolean;

    /**
    * Indicates if sync chats with hub
    */
    syncChatsWithHub: boolean;

    /**
    * Indicates if has express checkout
    */
    hasExpressCheckout: boolean;

    /**
    * Indicates if has B-Guest notifications
    */
    hasBGuestNotifications: boolean;

    /**
    * Indicates target on pms charge
    */
    targetPMSCharge: number;

    /**
    * Show time on guest bill
    */
    showTimeOnGuestBill: boolean;

    /**
    * Bill window filter
    */
    billWindowFilter: number;

    /**
    * Bill display filter
    */
    billDisplayFilter: boolean;

    /**
    * Voice configuration
    */
    voiceConfiguration: VoiceConfigurationDTO;

    /**
    * Has WebApp
    */
    hasWebApp: boolean;

    /**
    * Has Shopping Cart
    */
    hasShoppingCart: boolean;

    /**
    * Has Room Control
    */
    hasRoomControl: boolean;

    /**
    * IOS devices have FCM
    */
    iosHasFCM: boolean;

    /**
    * Request configuration
    */
    requestConfiguration: RequestConfigurationDTO;

    /**
    * Membership configuration
    */
    membershipConfiguration: MembershipConfigurationDTO;

    /**
    * Sync POS with Hub
    */
    syncPOSWithHub: boolean;

    /**
    * Indicates if orders will be printed
    */
    printOrders: boolean;

    /**
    * Indicates if orders have PMS Charge
    */
    ordersHavePMSCharge: string;

    /**
    * Has Dynamic Links for Check-In Online
    */
    hasDynamicLinksForCheckInOnline: boolean;

    /**
    * Has Dynamic Links for WebViews
    */
    hasDynamicLinksForWebViews: boolean;

    /**
    * Indicates what's the check-in type
    */
    checkInType: string;

    /**
    * Check-In time
    */
    checkInTime: string;

    /**
    * Check-Out time
    */
    checkOutTime: string;

    /**
    * Use transactions on express check-out
    */
    useTransactionsOnExpressCheckOut: boolean;

    /**
    * Indicates if sync activities with hub
    */
    syncActivitiesWithHub: boolean;

    /**
    * Indicates what's the crs stays source
    */
    crsStaysSource: string;

    /**
    * Indicates if apply timezone on reservation datetime fields
    */
    applyTimezoneOnReservationDatetimeFields: boolean;

    /**
    * Indicates if apply timezone on stay datetime fields
    */
    applyTimezoneOnStayDatetimeFields: boolean;

    /**
    * Indicates if override stay check-out time
    */
    overrideStayCheckOutTime: boolean;

    /**
    * Indicates if has FCM for Flutter
    */
    fcmForFlutter: boolean;

    /**
    * Indicates if use rooms whitelist
    */
    useRoomsWhitelist: boolean;

    /**
    * Indicates that only allow check-out on departure date
    */
    onlyAllowCheckOutOnDepartureDate: boolean;
}
