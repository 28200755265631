import { Component, OnInit, EventEmitter, Output, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ListingsService } from '@app/listings/listings.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { DropdownComponent } from '@shared/sui-components/sui-dropdown/sui-dropdown.component';
import { TimezoneListingDTO } from '@models/listings/TimezoneListingDTO';


@Component({
  selector: 'timezone-dropdown',
  templateUrl: './timezone-dropdown.component.html',
  styleUrls: ['./timezone-dropdown.component.css']
})
export class TimezoneDropdownComponent implements OnInit, AfterViewInit {
  /**
   * Holds the current timezone list
   */
  @Input() collection: Array<any>;
  /**
   * Placeholder text
   */
  public readonly placeholder: string = 'Select a timezone';

  /**
   * Load data as soon as possible or wait until first opening of dropdown list
   */
  @Input() loadDataImmediately: boolean;
  /**
   * Holds the currently selected timezone
   */
  @Input() selectedValue: any;
  /**
   * Currency change event emitter
   */
  @Output() selectedChange: EventEmitter<TimezoneListingDTO> = new EventEmitter<TimezoneListingDTO>();
  /** 
   * Currency collection change emitter 
   * */
  @Output() collectionChange: EventEmitter<any> = new EventEmitter<any>();
  /** 
   * Dropdown view child component
   * */
  @ViewChild("timezoneDropdown") private timezoneDropdown: DropdownComponent;
  /**
   * Whether dropdown should select new option when using keyboard shortcuts. Setting to false will require enter or left click to confirm a choice.
   */
  @Input() selectOnKeydown: boolean = false;
  
  constructor(private listingsService: ListingsService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.selectedValue) {
      this.setSelectedValue(this.selectedValue);
    }
  }

  /**
   * Gets the observable of timezone listing
   * @return Timezone listing DTO
   */
  public getDataObservable = (): Observable<TimezoneListingDTO[]> => {
    return this.listingsService.getTimezones();
  }

  /**
   * Gets the display name from a timezone object instance
   * @param item Timezone object instance
   * @return Timezone name
   */
  public nameResolver(item: TimezoneListingDTO) {
    return item ? item.name : null;
  }

  /**
   * Value change handler
   * @param event Event instance and elements { value: (option val.), item: (DTO) }
   * @emits selectedChange
   */
  public onValueChange(event: any) {
    if (event) {
      // store...
      this.selectedValue = event.item;
      // & forward
      this.selectedChange.emit(event.item);
    }
  }

  /**
   * Collection change handler
   * @param event Event instance and elements { value: (option val.), item: (DTO) }
   * @emits collectionChange
   */
  public onCollectionChange() {
    this.collectionChange.emit();
  }

  /**
   * Set selected value change handler
   * @param item Timezone DTO
   */
  public setSelectedValue(value: string) {
    if (this.timezoneDropdown) {
      this.timezoneDropdown.setSelectedValue(value);
    }
  }

  /**
   * Hide dropdown list
   */
  public hide() {
    if (this.timezoneDropdown) {
      this.timezoneDropdown.hide();
    }
  }

  /**
   * Error handler
   * @param error Error description object
   */
  private errorHandle(error: any): void {
    if (!environment.production) {
      console.log(error);
    }
  }
}
