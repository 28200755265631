import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, Input } from '@angular/core';
import { OSM, Vector as VectorSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Point } from "ol/geom";
import { Feature, View, Map as OlMap } from 'ol';
import { Style, Icon } from 'ol/style';

@Component({
  selector: 'openlayers',
  templateUrl: 'openlayers.component.html',
  styleUrls: ['openlayers.component.css']
})

/** openlayers component*/
export class OpenlayersComponent implements OnInit, AfterViewInit {
  map: OlMap;
  points: VectorSource;

  @ViewChild("mapElement") mapElement: ElementRef;
  @ViewChild('popupMap') popupMap: ElementRef;

  @Input() public popupHeader: string;

  ngOnInit() { 
    this.points = new VectorSource({});
  }

  /** After view init the map target can be set! */
  ngAfterViewInit() {
    $(this.popupMap.nativeElement).modal();
    this.createMap();
  }

  ngOnDestroy() {
    if (this.popupMap && this.popupMap.nativeElement) {
      this.popupMap.nativeElement.remove();
    }
  }

  private createMap() {
    if (this.map) {
      return;
    }

    this.map = new OlMap({
      target: this.mapElement.nativeElement.id,
      layers: [
        new TileLayer({ 
          source: new OSM() 
        })
      ],
      view: new View({
        center: [0, 0],
        zoom: 18,
      })
    });

    this.map.addLayer(new VectorLayer({
      source: this.points,
      style: new Style({
        image: new Icon(({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          opacity: 0.75,
          src: 'https://maps.google.com/mapfiles/ms/micons/red.png'
        }))
      })
    }));
  }

  public show() {
    $(this.popupMap.nativeElement).modal("show");
    this.map.updateSize();
  }

  /**
   * Add only one point to the map, this mean the points layer with be clear without events
   * ps: google default coordinate lat lon
   * @param lat latitude value
   * @param lon longitude value
   */
  public addOncePoint(lat: number, lon: number) {
    var coord = fromLonLat([lon, lat]);
    this.points.clear(true);
    this.points.addFeature(new Feature({
      geometry: new Point(coord)
    }));
    this.map.getView().setCenter(coord);
  }
}