import { Component, Input } from '@angular/core';

@Component({
    selector: 'text-link',
    templateUrl: 'text-link.component.html',
    styleUrls: ['text-link.component.css']
})
/** text-link component*/
export class TextLinkComponent {

  @Input() public text: string;
  @Input() public isHyperlink: boolean;
  @Input() public click: Function;

    /** text-link ctor */
    constructor() {

    }

  public onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.click) {
      this.click(event);
    }

  }
}
