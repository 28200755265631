﻿

	 import { EntityType } from '@models/enum/entity/EntityType';
	 import { HospitalityTypeDTO } from '@models/entity/HospitalityTypeDTO';
	 import { ApplicationDTO } from '@models/ApplicationDTO';
	 import { GeoPointDTO } from '@models/types/GeoPointDTO';
	 import { LocationDTO } from '@models/LocationDTO';
	 import { MultimediaContentFullDTO } from '@models/multimedia-content/MultimediaContentFullDTO';
	 import { Moment } from 'moment';
    
    export class EntityDTO {
        
        
        /**
        * Entity unique identifier.
        */
        id?: number | null;
        
        /**
        * Entity name.
        */
        name: string;
        
        /**
        * Entity short name.
        */
        shortName: string;
        
        /**
        * Type of Entity
        */
        type: EntityType;
        
        /**
        * Entity hospitality type
        */
        hospitalityType: HospitalityTypeDTO;
        
        /**
        * Related mobile Application
        */
        application: ApplicationDTO;
        
        /**
        * An 0 to 5 value defining accommodation overall quality
        */
        starRanking?: number | null;
        
        /**
        * Physical location GPS coordinates of the Entity
        */
        coordinates: GeoPointDTO;
        
        /**
        * Entity's geographical location name.  Can be a country, state, city, ...  <remarks>  Only applicable to entities of the following types: Entity, Group Member and Hospitality  </remarks>
        */
        location: LocationDTO;
        
        /**
        * Image to use on group members listing in case Entity is of <see cref="F:AppGenerator.Business.Enum.Entity.EntityType.GROUP" /> type
        */
        groupMembersListImage: MultimediaContentFullDTO;
        
        /**
        * Image of customer's accommodation/company logo
        */
        logoImage: MultimediaContentFullDTO;
        
        /**
        * Indicates whether the Entity is marked as deleted or not.
        */
        isDeleted: boolean;
        
        /**
        * Date and time of entity creation
        */
        createdOn: Moment;
        
        /**
        * Date and time of entity update
        */
        updatedOn: Moment;
    }
