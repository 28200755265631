﻿

	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    export class AirportSearchFilter extends SearchFilter {
        
        
        /**
        * The IATA airport code, also known as an IATA location identifier, IATA station code or simply a location identifier, is a three-letter code designating many airports around the world
        */
        iata: string;
        
        /**
        * The ICAO airport code or location indicator is a four-letter code designating aerodromes around the world
        */
        icao: string;
        
        /**
        * Airport name.
        */
        name: string;
        
        /**
        * Airport's geographical location unique identifier
        */
        locationId?: number | null;
    }
