import { PagedResult } from '@app/core/PagedResult';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { ISearchFilter } from '@models/search-filters';
import * as moment from 'moment';

declare module '@angular/common/http/src/client' {
  export interface HttpClient {
    getPagedResult<T>(this: HttpClient, url: string, filter: ISearchFilter): Observable<PagedResult<T>>;
    downloadFile(this: HttpClient, url: string, body: any): Observable<void>;
  }
}

function getPagedResult<T>(this: HttpClient, url: string, filter: ISearchFilter): Observable<PagedResult<T>> {

  // Gets a representation of the search filter in an HttpParams instance
  let params = new HttpParams();

  Object.keys(filter)
    .forEach((key) => {
      if (!filter[key] || key === 'contentLanguage')
        return;
      if (filter[key] instanceof Date || filter[key] instanceof moment) {
        params = params.set(key, moment(filter[key]).utc().toJSON());
      } else {
        params = params.set(key, filter[key]);
      }
    });

  // Gets a representation of the search filter in an HttpHeaders instance
  let headers = new HttpHeaders();
  if (filter.contentLanguage) {
    headers = headers.set('X-Content-Language', filter.contentLanguage.toString());
  }

  return this.get<T>(url, { params: params, headers: headers, observe: 'response' })
    .pipe(
      map((response) => {
        let paged: PagedResult<T> = new PagedResult<T>();
        paged.items = response.body;
        paged.pageSize = Number(response.headers.get('x-paging-size'));
        paged.totalCount = Number(response.headers.get('x-paging-total'));
        return paged;
      })
    );
}

function downloadFile(this: HttpClient, url: string, body: any): Observable<void> {

  // Gets a representation of the search filter in an HttpParams instance
  let params = new HttpParams();

  Object.keys(body)
    .forEach((key) => {
      if (!body[key]) return;
      if (body[key] instanceof Date || body[key] instanceof moment) {
        params = params.set(key, moment(body[key]).utc().toJSON());
      } else {
        params = params.set(key, body[key]);
      }
    });

  return this.get(url, { params: params, responseType: 'arraybuffer', observe: 'response' })
    .pipe(
      map((res: HttpResponse<ArrayBuffer>) => {
        res.headers.get('Content-Type');

        const contentDisposition = res.headers.get('content-disposition') || '';
        const matches = /filename="([^";]+)/ig.exec(contentDisposition);
        const fileName = (matches && matches[1].trim() || 'untitled');

        const blob = new Blob([res.body], { type: 'application/octet-stream' });

        const url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName; // gives it a name via an a tag
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
    );
}

HttpClient.prototype.getPagedResult = getPagedResult;
HttpClient.prototype.downloadFile = downloadFile;
