﻿

	 import { GeoPointDTO } from '@models/types/GeoPointDTO';
	 import { MessagingSource } from '@models/enum/chat/MessagingSource';
	 import { MessagingType } from '@models/enum/chat/MessagingType';
	 import { MessagingCommunicationMedium } from '@models/enum/chat/MessagingCommunicationMedium';
    
    /**
    * Data transfer object for a create a new message
    */
    
    export class ChatMessageCreateDTO {
        
        
        /**
        * Chat message text
        */
        message: string;
        
        /**
        * The device identifier who sent the message
        */
        deviceIdentifier?: string | null;
        
        /**
        * The coordinates where the message was sent
        */
        coordinates: GeoPointDTO;
        
        /**
        * The source of the message
        */
        source: MessagingSource;
        
        /**
        * The Type of the message
        */
        type: MessagingType;
        
        /**
        * The comunication medium of the message
        */
        medium: MessagingCommunicationMedium;
    }
