﻿


    
    export class RequestTotalAverageDTO {
        
        
        /**
        * Rating average
        */
        average: number;
        
        /**
        * Total request
        */
        totalRequest: number;
        
        /**
        * Total request message
        */
        totalRequestMessage: number;
    }
