import { Component, OnInit, OnDestroy, ComponentRef } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationsService } from '@app/notifications/notifications.service';
import { PersistenceManager } from '@models/persistence';
import {
  EntityDTO, IAuthUser,
  NotificationCountersDTO, EntityType
} from '@app/models';

import {
  PopupConfig, EntityDropdownsComponent,
  EntityDropdownsConfig
} from '@app/shared';

@Component({
  selector: 'navigation-menu',
  templateUrl: 'navigation-menu.component.html',
  styleUrls: ['navigation-menu.component.css']
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
  /**
   * Indicates if user is logged in the system
   */
  public isUserLoggedIn: boolean = false;

  /**
   * User information
   */
  public userInformation: IAuthUser;

  /**
  * Entity to which user has access to
  */
  public selectedEntity: EntityDTO;

  /**
   * Notification counters
   */
  public notificationCounters: NotificationCountersDTO;

  /**
   * Stores all component's observable subscriptions for latter cancellation
   */
  private subscription = new Subscription();

  /**
   * Popup configuration object
   */
  public popupConfiguration: PopupConfig;

  /**
   * Local Entity dropdown configuration
   */
  private entityDropdownConfigurationLocal: EntityDropdownsConfig;

  /**
   * Persistence reference of Entity dropdown configuration
   */
  private entityDropdownConfigurationPersistence: EntityDropdownsConfig;

  /**
   * Local selected Entity Id from the dropdown (popup)
   */
  private selectedEntityId: number;

  constructor(private router: Router,
    public authService: AuthenticationService,
    private persistenceManager: PersistenceManager,
    private notificationsService: NotificationsService) {
    this.subscription.add(
      this.notificationsService
        .onNotificationCountersUpdate()
        .subscribe((counters: NotificationCountersDTO) => this.notificationCounters = counters)
    );

    if (this.showLinkToPopupEntityChange) {
      this.initPopup();
    }
  }

  public ngOnInit() {
    if (this.showLinkToPopupEntityChange) {
      this.setupPersistence();
    }

    this.subscription.add(
      this.authService
        .isUserAuthenticated$
        .subscribe(status => this.isUserLoggedIn = status)
    );

    this.subscription.add(
      this.authService
        .userInformation$
        .subscribe(info => this.userInformation = info));

    this.subscription.add(
      this.authService
        .selectedEntity$
        .subscribe(entity => this.selectedEntity = entity)
    );

    this.isUserLoggedIn = this.authService.isUserAuthenticated();
    this.userInformation = this.authService.getUserInformation();
  }

  private setupPersistence() {
    this.entityDropdownConfigurationPersistence = this.persistenceManager.storeLocalStorage<EntityDropdownsConfig>("EntityDropdownsConfig", EntityDropdownsConfig, psf => psf.selectedEntityId = this.authService.getUserEntityAccessId());
    this.entityDropdownConfigurationLocal = new EntityDropdownsConfig();
    this.copyEntityConfiguration(this.entityDropdownConfigurationPersistence, this.entityDropdownConfigurationLocal);
  }

  private entityDb: EntityDropdownsComponent;
  private initPopup() {
    this.popupConfiguration = new PopupConfig(
      "Change entity",
      null,
      EntityDropdownsComponent,
      (componentRef: ComponentRef<EntityDropdownsComponent>) => {
        this.entityDb = componentRef.instance;
        componentRef.instance.configuration = this.entityDropdownConfigurationLocal;
        componentRef.instance.showOnFocus = false;
        componentRef.instance.showFirstLoading = false;
        componentRef.instance.selectOnKeydown = false;

        var subs: Subscription = componentRef.instance.entityIdChange.subscribe((entityId) => this.selectedEntityId = entityId);
        componentRef.onDestroy(() => { if (subs) subs.unsubscribe() });
      });
  }

  public onPopupDeny(elem: JQuery<HTMLElement>) {
    if (this.entityDropdownConfigurationPersistence.selectedEntityId === this.entityDropdownConfigurationLocal.selectedEntityId &&
      this.entityDropdownConfigurationPersistence.selectedEntityMemberId === this.entityDropdownConfigurationLocal.selectedEntityMemberId) {
      return;
    }
    //rollback the configurations
    this.entityDb.updateEntityDropdownConfiguration(this.entityDropdownConfigurationPersistence);
    this.authService.setSelectedEntity(this.selectedEntityId);
  }

  public onPopupApprove(elem: JQuery<HTMLElement>) {
    //save the news configurations
    this.copyEntityConfiguration(this.entityDropdownConfigurationLocal, this.entityDropdownConfigurationPersistence);
    this.persistenceManager.setStoreLocalStorage("EntityDropdownsConfig", this.entityDropdownConfigurationPersistence);
    this.authService.setSelectedEntity(this.selectedEntityId);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Limits an integer display value to '99+' when greater than 99
   * @param value Integer value
   */
  private truncateNotificationsCounterValue(value?: number) {
    return value
      ? (value > 99 ? '99+' : value)
      : null;
  }

  private copyEntityConfiguration(source: EntityDropdownsConfig, target: EntityDropdownsConfig): void {
    target.selectedEntityId = source.selectedEntityId;
    target.selectedEntityMemberId = source.selectedEntityMemberId;
  }

  /**
   * Get entity name display
   */
  public getEntityName(): string {
    return this.selectedEntity != null ? '@' + this.selectedEntity.name : (this.showLinkToPopupEntityChange() ? '@all' : '');
  }

  /**
   * Show the link to change entity inside the popup
   */
  public showLinkToPopupEntityChange(): boolean {
    return this.authService.userIsAdminOrManager() || (this.authService.getUserAccessEntityType() === EntityType.GROUP);
  }

  /**
   * Terminate user session
   */
  public logout(): void {
    this.authService.logout().subscribe();
  }

  /**
   * Component error handler
   * @param error
   */
  private errorHandle(error: any): void {
    console.log(error);
  }

}
