  <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="this.authService.userIsAdminOrManager() && requestConfigurationDTO">
      <!-- left side -->
      <div class="eight wide column">
  
        <h4 class="ui top attached header">
          <div><i class="calendar alternate icon"></i>Availability Period</div>
        </h4>
        <div class="ui attached segment">
          <div class="ui grid">

            <div class="row">
              <div class="column">
                <sui-checkbox [(inputValue)]="requestConfigurationDTO.requestStartTime && requestConfigurationDTO.requestEndTime"
                              [isSlider]="false" 
                              [labelName]="'Has availability period'"
                              (onChangeValue)="onHasAvailabilityPeriodChange($event)">
                </sui-checkbox>
              </div>
            </div>

            <div class="row">
              <div class="column ui form">
                <div class="fields" [class.disabled]="!requestConfigurationDTO.requestStartTime && !requestConfigurationDTO.requestEndTime">
                  <div class="field">
                    <div class="ui tiny header">Start Time</div>
                    <time-picker [(inputValue)]="requestConfigurationDTO.requestStartTime"
                                 (onChangeComplete)="onRequestTimeChange($event)">
                    </time-picker>
                  </div>
                  <div class="field">
                    <div class="ui tiny header">End Time </div>
                    <time-picker [(inputValue)]="requestConfigurationDTO.requestEndTime"
                                 (onChangeComplete)="onRequestTimeChange($event)">
                    </time-picker>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
  
      </div>
  
      <!-- right side -->
      <div class="eight wide column">
      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
  </div>
  
  