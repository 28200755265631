import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GridPaginationComponent } from './pagination/grid-pagination/grid-pagination.component';
import { SimplePagingComponent } from './pagination/simple-paging/simple-paging.component';
import { MessagingContainerComponent } from './messaging/messaging-container/messaging-container.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { HeaderActionsComponentDirective } from '@app/shared/app-header/header-actions-component.directive';
import { AppHeaderService } from '@app/shared/app-header/app-header.service';
import { BreadcrumbComponent } from '@app/shared/sui-components/sui-breadcrumb/sui-breadcrumb.component';
import { BreadcrumbSectionComponent } from '@app/shared/sui-components/sui-breadcrumb/sui-breadcrumb-section/sui-breadcrumb-section.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DataGridImageItemComponent } from './data-grid/data-grid-image-item/data-grid-image-item.component';
import { DataGridCounterItemComponent } from './data-grid/data-grid-counter-item/data-grid-counter-item.component';
import { DropdownComponent } from '@app/shared/sui-components/sui-dropdown/sui-dropdown.component';
import { InputComponent } from '@app/shared/sui-components/sui-input/sui-input.component';
import { NumberInputComponent } from '@app/shared/sui-components/sui-number-input/sui-number-input.component';
import { CheckboxComponent } from '@app/shared/sui-components/sui-checkbox/sui-checkbox.component';
import { EditableLabelComponent } from '@app/shared/sui-components/sui-editable-label/sui-editable-label.component';
import { CoreModule } from '@app/core/core.module';
import { AutofocusDirective } from '@shared/directives/auto-focus.directive';
import { DomChangeDirective } from '@shared/directives/dom-change.directive';
import { ActionButtonsComponent } from '@app/shared/components/action-buttons/action-buttons.component';
import { EntityDropdownsComponent } from '@app/shared/components/entity-dropdowns/entity-dropdowns.component';
import { SuiDatepickerModule } from 'ng2-semantic-ui';
import { BetweenDatesComponent } from '@app/shared/components/between-dates/between-dates.component';
import { OpenlayersComponent } from '@shared/components/openlayers/openlayers.component';
import { TextLinkComponent } from '@shared/components/text-link/text-link.component';
import { MomentDateComponent } from '@shared/components/moment-date/moment-date.component';
import { GaEventTrackerDirective } from '@shared/directives/ga-event-tracker';
import { ViewContainerDirective } from '@shared/directives/view-container.directive';
import { MessageComponent } from '@shared/sui-components/sui-message/sui-message.component';
import { AppNotifierComponent } from '@app/notifications/app-notifier/app-notifier.component';
import { AppNotifierService } from '@app/notifications/app-notifier/app-notifier.service';
import { PopupComponent } from '@shared/components/popup/popup.component';
import { RadioComponent } from '@shared/components/radio/radio.component';
import { TextareaComponent } from '@shared/sui-components/sui-textarea/sui-textarea.component';
import { MultipleSelectDropdownComponent } from '@shared/components/multiple-select-dropdown/multiple-select-dropdown.component';
import { DatePickerComponent } from '@shared/components/date-picker/date-picker.component';
import { ChartComponent } from '@shared/components/chart/chart.component';
import { TabsComponent } from '@shared/components/tabs/tabs.component';
import { TabComponent } from '@shared/components/tabs/tab/tab.component';
import { ButtonsGroupComponent } from '@shared/components/buttons-group/buttons-group.component';
import { CountriesDropdownComponent } from './components/countries-dropdown/countries-dropdown.component';
import { LanguagesDropdownComponent } from './components/languages-dropdown/languages-dropdown.component';
import { ChipsComponent } from './components/chips/chips.component';
import { CurrencyDropdownComponent } from './components/currency-dropdown/currency-dropdown.component';
import { TimezoneDropdownComponent } from './components/timezone-dropdown/timezone-dropdown.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { CdkDropListGroupComponent } from '@shared/components/cdk-drop-list-group/cdk-drop-list-group.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorPickerComponent } from '@shared/components/color-picker/color-picker.component';
import { FontsDropdownComponent } from './components/fonts-dropdown/fonts-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    DragDropModule,
    SuiDatepickerModule,
    ColorPickerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    GridPaginationComponent,
    SimplePagingComponent,
    MessagingContainerComponent,
    AppHeaderComponent,
    HeaderActionsComponentDirective,
    BreadcrumbComponent,
    BreadcrumbSectionComponent,
    DataGridComponent,
    DataGridImageItemComponent,
    DataGridCounterItemComponent,
    DropdownComponent,
    InputComponent,
    NumberInputComponent,
    CheckboxComponent,
    EditableLabelComponent,
    ActionButtonsComponent,
    EntityDropdownsComponent,
    BetweenDatesComponent,
    OpenlayersComponent,
    TextLinkComponent,
    MomentDateComponent,
    MessageComponent,
    PopupComponent,
    RadioComponent,
    TextareaComponent,
    MultipleSelectDropdownComponent,
    DatePickerComponent,
    ChartComponent,
    TabsComponent,
    TabComponent,
    ButtonsGroupComponent,
    CountriesDropdownComponent,
    LanguagesDropdownComponent,
    ChipsComponent,
    CurrencyDropdownComponent,
    TimezoneDropdownComponent,
    TimePickerComponent,
    CdkDropListGroupComponent,
    ColorPickerComponent,
    FontsDropdownComponent,
  ],
  declarations: [
    GridPaginationComponent,
    SimplePagingComponent,
    MessagingContainerComponent,
    AppHeaderComponent,
    HeaderActionsComponentDirective,
    BreadcrumbComponent,
    BreadcrumbSectionComponent,
    DataGridComponent,
    DataGridImageItemComponent,
    DataGridCounterItemComponent,
    DropdownComponent,
    InputComponent,
    NumberInputComponent,
    CheckboxComponent,
    EditableLabelComponent,
    AutofocusDirective,
    DomChangeDirective,
    GaEventTrackerDirective,
    ActionButtonsComponent,
    EntityDropdownsComponent,
    BetweenDatesComponent,
    OpenlayersComponent,
    TextLinkComponent,
    MomentDateComponent,
    ViewContainerDirective,
    MessageComponent,
    PopupComponent,
    AppNotifierComponent,
    RadioComponent,
    TextareaComponent,
    MultipleSelectDropdownComponent,
    DatePickerComponent,
    ChartComponent,
    TabsComponent,
    TabComponent,
    ButtonsGroupComponent,
    CountriesDropdownComponent,
    LanguagesDropdownComponent,
    ChipsComponent,
    CurrencyDropdownComponent,
    TimezoneDropdownComponent,
    TimePickerComponent,
    CdkDropListGroupComponent,
    ColorPickerComponent,
    FontsDropdownComponent,
  ],
  providers: [
    AppHeaderService,
    AppNotifierService
  ],
  entryComponents: [
    CheckboxComponent,
    DataGridImageItemComponent,
    DataGridCounterItemComponent,
    DropdownComponent,
    InputComponent,
    NumberInputComponent,
    EditableLabelComponent,
    ActionButtonsComponent,
    EntityDropdownsComponent,
    BetweenDatesComponent,
    OpenlayersComponent,
    TextLinkComponent,
    MomentDateComponent,
    PopupComponent,
    RadioComponent,
    TextareaComponent,
    MultipleSelectDropdownComponent,
    DatePickerComponent,
    ChartComponent,
    TabsComponent,
    TabComponent,
    ButtonsGroupComponent,
    ChipsComponent,
    CurrencyDropdownComponent,
    TimezoneDropdownComponent,
    TimePickerComponent,
    CdkDropListGroupComponent,
    ColorPickerComponent,
    FontsDropdownComponent,
  ]
})
export class SharedModule { }
