﻿

	 import { Moment } from 'moment';
	 import { RequestNoteDTO } from '@models/requests/RequestNoteDTO';
    
    export class RequestNoteFullDTO extends RequestNoteDTO {
        
        
        /**
        * Related Request unique identifier
        */
        requestId: number;
        
        /**
        * The first user to read the note since it's creation
        */
        readBy: string;
        
        /**
        * The username of the user that created the note
        */
        createdBy: string;
        
        /**
        * Last update date and time
        */
        lastUpdatedOn?: Moment | null;
        
        /**
        * User that last updated this entity
        */
        lastUpdatedBy: string;
    }
