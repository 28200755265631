<div class="ui buttons">
  <button *ngFor="let btn of buttons; trackBy: trackRowByIndex"
          class="ui button"
          [class.active]="btn.selected"
          [class.disabled]="!btn.active"
          [class.loading]="btn.isLoading"
          (click)="onButtonClick($event, btn)">
    {{btn.displayText}}
  </button>
</div>
