/*
    @Service
        Authentication service
    @Description
        Service for user authentication related actions
    @Notes
        -
*/
import { of, from, throwError, Observable, Subject, BehaviorSubject } from 'rxjs';
import { flatMap, catchError, finalize } from 'rxjs/operators';
// # Imports
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Login } from '../authentication/login';
import { IAuthUser, AuthUser } from '@models/auth';
import { EntityType } from '@models/enum';
import { PersistenceManager } from '@models/persistence';
import { EntityDTO } from '@models/entity/EntityDTO';
import { ErrorsService } from '@app/core/errors/errors.service';

// # Service
@Injectable()
export class AuthenticationService implements OnDestroy {
  private userAuthenticated$ = new Subject<boolean>();
  public isUserAuthenticated$: Observable<boolean> = this.userAuthenticated$.asObservable();
  private _userInformation = new BehaviorSubject<AuthUser>(null);
  public userInformation$: Observable<IAuthUser> = this._userInformation.asObservable();
  private _userEntity = new BehaviorSubject<EntityDTO>(null);
  public userEntity$: Observable<EntityDTO> = this._userEntity.asObservable();
  // selected entity
  private selectedEntity = new BehaviorSubject<EntityDTO>(null);
  public selectedEntity$: Observable<EntityDTO> = this.selectedEntity.asObservable();

  public storageAuthTokenKey: string = environment.localStorage.authTokenKey;
  public storageAuthUserKey: string = environment.localStorage.authUserKey;
  public storageAccessEntityKey: string = environment.localStorage.accessEntityKey;
  public storageSelectedEntityKey: string = environment.localStorage.selectedEntityKey;
  private clientId: string = 'appgenerator.backoffice.web';
  private defaultClientScopes: string = 'openid token_validation user_info appgenerator_identity appgenerator_core';

  private endpoints = {
    session: {
      start: environment.apis.identity + 'session/start',
      end: environment.apis.identity + 'session/end'
    },
    auth: {
      userInfo: environment.authServer.baseUrl + 'connect/userinfo',
      introspect: environment.authServer.baseUrl + 'connect/introspect'
    },
    listings: {
      entities: environment.apis.core + 'listings/entities'
    },
    entities: {
      get: environment.apis.core + 'entities'
    }
  };

  private knownRoles = {
    sa: 'sa',
    admin: 'appgenerator.admin',
    manager: 'appgenerator.manager',
    enduser: 'appgenerator.user',
    customer_staff: 'appgenerator.customer.staff'
  }

  constructor(private httpClient: HttpClient,
    private router: Router,
    private errorsService: ErrorsService,
    private persistenceManager: PersistenceManager) {
    const isUserAuthenticated: boolean = this.isUserAuthenticated();
    const userInfo = JSON.parse(localStorage.getItem(this.storageAuthUserKey)) as AuthUser;
    const entity = JSON.parse(localStorage.getItem(this.storageAccessEntityKey)) as EntityDTO;
    const selectedEntity = JSON.parse(localStorage.getItem(this.storageSelectedEntityKey)) as EntityDTO;

    this.userAuthenticated$.next(isUserAuthenticated);

    if (userInfo) {
      this._userInformation.next(userInfo);
    }

    if (entity) {
      this._userEntity.next(entity);
    }

    if (selectedEntity) {
      this.selectedEntity.next(selectedEntity);
    }
  }

  ngOnDestroy() {
    this.userAuthenticated$.unsubscribe();
    this._userEntity.unsubscribe();
    this._userInformation.unsubscribe();
    this.selectedEntity.unsubscribe();
  }

  /*
    Actions
  */

  public login(username: string, password: string): Observable<any> {
    if (this.isUserAuthenticated()) {
      const userInfo = JSON.parse(localStorage.getItem(this.storageAuthUserKey));
      const entity = JSON.parse(localStorage.getItem(this.storageAccessEntityKey)) as EntityDTO;
      const selectedEntity = JSON.parse(localStorage.getItem(this.storageSelectedEntityKey)) as EntityDTO;

      this.userAuthenticated$.next(true);
      this._userInformation.next(userInfo);
      this._userEntity.next(entity);
      this.selectedEntity.next(selectedEntity);

      if (environment.dev)
        console.log('Login, user info obj:', userInfo);

      return from(userInfo);
    }

    const login = new Login(username, password, this.clientId, this.defaultClientScopes);

    return this.httpClient
      .post<Object>(this.endpoints.session.start, login)
      .pipe(
        flatMap((authInfo: any) => {
          localStorage.setItem(this.storageAuthTokenKey, authInfo.access_token);

          if (environment.dev)
            console.log('Login, user auth token:', authInfo.access_token);

          const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', environment.authServer.introspectApiKey);
          
          const body = `token=${authInfo.access_token}`;

          return this.httpClient.post<Object>(this.endpoints.auth.introspect, body, { headers: headers, withCredentials: true });
        }),
        flatMap((userInfo: AuthUser) => {

          if (!Array.isArray(userInfo.role)) {
            userInfo.role = Array.of(<any>userInfo.role);
          }

          if (!Array.isArray(userInfo.capability))
            userInfo.capability = userInfo.capability 
              ? Array.of(<any>userInfo.capability) 
              : [];

          if (!Array.isArray(userInfo.request_type))
            userInfo.request_type = userInfo.request_type 
              ? Array.of(<any>userInfo.request_type)
              : [];

          // const info = userInfo as AuthUser;
          localStorage.setItem(this.storageAuthUserKey, JSON.stringify(userInfo));
          this._userInformation.next(userInfo);

          if (environment.dev)
            console.log('Login, user info:', userInfo);

          // Get Entity info, entity that the user has access to. This is done to optimize calls whenever we need to know the type of entity in hands
          if (!this.userIsAdminOrManager()) {
            return this.httpClient.get<EntityDTO>(this.endpoints.entities.get + '/' + userInfo.entity_access.toString());
          } else {
            localStorage.removeItem(this.storageAccessEntityKey);
            localStorage.removeItem(this.storageSelectedEntityKey);
            return of(null);
          }
        }),
        flatMap((userEntity: EntityDTO) => {
          // if not null the user is not admin/manager
          if (userEntity) {
            localStorage.setItem(this.storageAccessEntityKey, JSON.stringify(userEntity));
            this._userEntity.next(userEntity);
          }

          // TODO: remove this when the token return multi entities claims
          localStorage.setItem(this.storageSelectedEntityKey, JSON.stringify(userEntity));
          this.selectedEntity.next(userEntity);

          this.userAuthenticated$.next(true);
          return of(true);
        }),
        catchError(error => {
          if (localStorage.getItem(this.storageAuthTokenKey)) {
            this.logout().subscribe();
          }
          else {
            this.frontEndLogout();
          }
          
          return this.handleError(error);
        })
      );
  }

  public logout(): Observable<any> {
    return this.httpClient
      .post(this.endpoints.session.end, { "clientId": this.clientId })
      .pipe(
        finalize(() => this.frontEndLogout())
      );
  }

  public frontEndLogout(redirectUrl: string = null): void {
    // Clear local storage data
    localStorage.clear();

    // Redirect user to login page, keep redirect url after login if passed in
    const navigationExtras = !redirectUrl
      ? {}
      : {
        queryParams: {
          r: redirectUrl
        }
      };

    if (this.router.url !== '/login') {
      this.router
        .navigate(['login'], navigationExtras)
        .then(() => this.afterLogoutCleanUp(), () => this.afterLogoutCleanUp());
    }
  }

  public afterLogoutCleanUp(): void {
    // Reset search filters manager (filtering values persistence)
    this.persistenceManager.reset();

    // Update subscriptions subscribers with neutral values
    this.userAuthenticated$.next(false);
    this._userEntity.next(null);
    this._userInformation.next(null);
    this.selectedEntity.next(null);
  }

  /*
    Helper Functions
  */

  public isUserAuthenticated(): boolean {
    return localStorage.getItem(this.storageAuthTokenKey) != null;
  }

  public getUserInformation(): IAuthUser {
    if (!this._userInformation) {
      return null as IAuthUser;
    }

    return this._userInformation.value;
  }

  public getUserEntityAccessId(): number {
    const userInfo = this.getUserInformation();

    if (!userInfo) {
      return null;
    }

    return userInfo.entity_access;
  }

  public getUserApplicationAccessId(): number {
    if (!this._userInformation) {
      return null;
    }

    return this._userInformation.value.entity_access_application_id;
  }

  public getUserId(): number {
    if (!this._userInformation || !this._userInformation.value) {
      return null;
    }

    return this._userInformation.value.sub;
  }

  public getToken(): string {
    return this.isUserAuthenticated() ? localStorage.getItem(this.storageAuthTokenKey) : null;
  }

  public getUserAccessEntity(): EntityDTO {
    if (!this._userEntity) {
      return null;
    }

    return this._userEntity.value;
  }

  public getUserAccessEntityType(): EntityType {
    const entity = this.getUserAccessEntity();

    if (entity) {
      return entity.type;
    }

    return null;
  }

  public userIsSuperAdmin(): boolean {
    const userInfo = this._userInformation.value;

    if (!userInfo) {
      return false;
    }

    return userInfo.role.includes(this.knownRoles.sa);
  }

  public userIsAdmin(): boolean {
    const userInfo = this._userInformation.value;

    if (!userInfo) {
      return false;
    }

    return userInfo.role.includes(this.knownRoles.admin) || userInfo.role.includes(this.knownRoles.sa);
  }

  public userIsManager(): boolean {
    const userInfo = this._userInformation.value;

    if (!userInfo) {
      return false;
    }

    return userInfo.role.includes(this.knownRoles.manager);
  }

  public userIsAdminOrManager(): boolean {
    return this.userIsAdmin() || this.userIsManager();
  }

  public userIsCustomerStaff(): boolean {
    const userInfo = this._userInformation.value;

    if (!userInfo)
      return false;

    return userInfo.role.includes(this.knownRoles.customer_staff);
  }

  public getUserCapabilities(): string[] | null {
    if (!this._userInformation.value)
      return null;

    return this._userInformation.value.capability;
  }

  public getUserCapability(name: string): boolean {
    const capabilities = this.getUserCapabilities();

    if (!capabilities)
      return false;

    return capabilities.includes(name);
  }

  public getUserRequestTypes(): string[] | null {
    if (!this._userInformation.value)
      return null;

    return this._userInformation.value.request_type;
  }

  public getUserRequestType(name: string): boolean {
    const requestTypes = this.getUserRequestTypes();

    if (!requestTypes)
      return false;

    return requestTypes.includes(name);
  }

  public hasClaimCapability(name: string) {
    if (this.userIsAdminOrManager())
      return true;
    
    switch (name) {
      case 'requests':
      case 'chat':
      case 'guests-notifications':
      case 'guests-feedback':
      case 'guestu-phones':
        if (this.userIsCustomerStaff()) {
          const capabilities = this.getUserCapabilities();
          if (capabilities && capabilities.length > 0)
            return this.getUserCapability(name);
        }
        break;
      case 'reports':
      case 'settings':
        return false;
    }

    return true;
  }

  /**
  * Set the selected entity
  * @param entityId
  */
  public setSelectedEntity(entityId?: number) {
    if (this.getUserSelectedEntityId() === entityId) {
      return;
    }

    if (!entityId) {
      this.selectedEntity.next(null);
      localStorage.removeItem(this.storageSelectedEntityKey);
      return;
    }

    this.httpClient.get<EntityDTO>(this.endpoints.entities.get + '/' + entityId.toString())
      .subscribe((response) => {
        if (response) {
          localStorage.setItem(this.storageSelectedEntityKey, JSON.stringify(response));
          this.selectedEntity.next(response);
        }
      },
        (error: any) => {
          this.handleError(error);
          throw (error);
        }
      );
  }

  public getUserSelectedEntity(): EntityDTO {
    if (!this.selectedEntity) {
      return null;
    }

    return this.selectedEntity.value;
  }

  public getUserSelectedEntityId(): number {
    if (!this.selectedEntity || !this.selectedEntity.value) {
      return null;
    }

    return this.selectedEntity.value.id;
  }

  private handleError(error: any) {
    if (environment.dev) {
      console.log('Error on login.');
      console.dir(error);
    }

    this.errorsService.log(error);

    return throwError(error);
  }
}
