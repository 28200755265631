import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '../core/authentication.service';
import { PagedResult } from '@app/core/PagedResult';
import '@app/core/extensions/HttpClient';
import {
  SatisfactionSurveyDTO,
  SurveySearchFilter,
  DatesDTO
} from '@app/models';

// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.core + 'surveys';

@Injectable()
export class SurveysService {
  constructor(private httpClient: HttpClient, private authService: AuthenticationService) { }

  public get(filter: SurveySearchFilter): Observable<PagedResult<SatisfactionSurveyDTO[]>> {
    if (!filter) {
      return throwError('Invalid call parameters - missing Entity identifier');
    }

    filter.entityId = this.authService.getUserSelectedEntityId();

    return this.httpClient
      .getPagedResult<SatisfactionSurveyDTO[]>(API_BASE_URL, filter)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getByIdentifier(identifier: number): Observable<SatisfactionSurveyDTO> {
    return this.httpClient
      .get<SatisfactionSurveyDTO>(API_BASE_URL + '/' + identifier)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public markAllAsRead(entityId: number): Observable<void> {
    return this.httpClient
      .patch<void>(API_BASE_URL + '/read', "\"" + entityId + "\"")
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public export(datesDto: DatesDTO) {

    datesDto.id = this.authService.getUserSelectedEntityId();

    return this.httpClient
      .downloadFile(API_BASE_URL + '/export', datesDto)
      .pipe(catchError(e => this.handleError(e)));
  }

  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }
}
