import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { ActivitiesConfigurationDTO } from '@models/activities';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DropdownComponent } from '@app/shared';

@Component({
  selector: 'activities-settings',
  templateUrl: './activities-settings.component.html',
  styleUrls: ['./activities-settings.component.css']
})
export class ActivitiesSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("componentDimmer") componentDimmer: ElementRef;

  public configurationDTO: ActivitiesConfigurationDTO;
  private rxSubscription: Subscription = new Subscription();
  private fetchingComponentData: boolean;

  @ViewChild("versionDropdown") private versionDropdown: DropdownComponent;

  constructor(
    private entityService: EntityService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.load();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  private load() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .getActivitiesConfigurations()
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((data) => {
            this.configurationDTO = data;
          })
      );
    }
  }

  save(): void {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .updateActivitiesConfigurations(this.configurationDTO)
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((data) => {
            this.configurationDTO = data;
          })
      );
    }
  }

  private setComponentDimmerVisibility(visible: boolean): void {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }
}
