  <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="integrationConfigurationDto">
      <!-- left side -->
      <div class="eight wide column">
  
        <h4 class="ui top attached header">
          <div><i class="external alternate icon"></i>Stays Sync</div>
        </h4>
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">

            <div class="row">
              <div class="column">
                <div class="ui tiny header">Enabled</div>
                  <sui-checkbox [(inputValue)]="integrationConfigurationDto.staysSyncEnable"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
              </div>
            </div>
            
            <div class="row">
              <div class="column ui form">
                <div class="field" [class.disabled]="isDisabled()">
                  <div class="ui tiny header">Interval (days)</div>
                  <sui-input [placeholder]="'Interval'"
                             [(inputValue)]="integrationConfigurationDto.staysSyncInterval"
                             (onChangeComplete)="save()"
                             (keydown.enter)="save()">
                  </sui-input>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Run a new stays sync</div>
                  <button class="ui primary button" (click)="executeStaysSyncClick($event)">
                    Execute
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
  
      </div>

      <!-- right side -->
      <div class="eight wide column">
      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
    <div #popupStaysSyncConfirm class="ui tiny modal" style="display: none;">
      <div class="header">Run a new stays sync</div>
      <div class="content">
        <p>Are you sure you want to run a new stays sync?</p>
        <p></p>
        <p>Note: this operation could take a while</p>
      </div>
      <div class="actions">
        <div class="ui negative button">No</div>
        <div class="ui positive right labeled icon button">
          Yes
          <i class="checkmark icon"></i>
        </div>
      </div>
    </div>

  </div>
  
