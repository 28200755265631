import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { ReportsComponent } from './reports.component';
import { AuthenticationGuard } from '../core/authentication-guard.service';
import { SharedModule } from '@app/shared/shared.module';
import { NavigationService } from '@app/navigation/navigation.service';
import { ExportDataSettingsComponent } from './tabs/export-data-settings/export-data-settings.component';
import { ChatAverageResponseComponent } from './tabs/chat-average-response/chat-average-response.component';
import { RatingAverageComponent } from './tabs/rating-average/rating-average.component';
import { RatingAverageCountryComponent } from './tabs/rating-average-country/rating-average-country.component';
import { RatingAverageEntitiesComponent } from './tabs/rating-average-entities/rating-average-entities.component';
import { RequestAverageResponseComponent } from './tabs/request-average-response/request-average-response.component';
import { TotalCheckinCountryComponent } from './tabs/total-checkin-country/total-checkin-country.component';
import { InteractionsComponent } from './tabs/interactions/interactions.component';
import { ChatResponseCustomerComponent } from './tabs/chat-response-customer/chat-response-customer.component';
import { ClaimsGuard } from '../core/claims.guard';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: 'reports',
        data: { title: 'Reports' },
        component: ReportsComponent,
        canActivate: [AuthenticationGuard, ClaimsGuard]
      }
    ])
  ],
  exports: [
    ExportDataSettingsComponent,
    ChatAverageResponseComponent,
    RatingAverageComponent,
    RatingAverageCountryComponent,
    RatingAverageEntitiesComponent,
    RequestAverageResponseComponent,
    TotalCheckinCountryComponent,
    InteractionsComponent,
    ChatResponseCustomerComponent
  ],
  declarations: [
    ReportsComponent,
    ExportDataSettingsComponent,
    ChatAverageResponseComponent,
    RatingAverageComponent,
    RatingAverageCountryComponent,
    RatingAverageEntitiesComponent,
    RequestAverageResponseComponent,
    TotalCheckinCountryComponent,
    InteractionsComponent,
    ChatResponseCustomerComponent
  ],
  providers: [
    //  DevicesService,
    NavigationService
  ],
  entryComponents: [
    ExportDataSettingsComponent,
    ChatAverageResponseComponent,
    RatingAverageComponent,
    RatingAverageCountryComponent,
    RatingAverageEntitiesComponent,
    RequestAverageResponseComponent,
    TotalCheckinCountryComponent,
    InteractionsComponent,
    ChatResponseCustomerComponent
  ]
})
export class ReportsModule { }
