﻿

    
    /**
    * Place Type enum. Maps to DB identity values.
    */
    
    export enum PlaceType {
        
        
        Undefined = "Undefined",
        
        POI = "POI",
        
        Stop = "Stop",
        
        nearby = "nearby"
    }
