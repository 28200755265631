import { Component, OnInit, ElementRef, ViewChild, ComponentRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { RequestService } from '../request.service';
import { ListingsService } from '@app/listings/listings.service';
import { EntityService } from '@app/entity/entity.service';
import {
  RequestDTO,
  RequestSearchFilter,
  PersistenceManager,
  RequestState, Platform, ClosedOlderThan,
  RequestTypeListingDTO
} from '@app/models';
import {
  AppHeaderService, BreadcrumbSection, DataGridConfiguration,
  DataGridImageItemComponent, DataGridComponent, DataGridCounterItemComponent
} from '@app/shared';
import { CustomDatePipe } from '@app/core/pipes/custom-date.pipe';
import { EmptyFieldPipe } from '@app/core/pipes/empty-field.pipe';
import { NotificationsService } from '@app/notifications/notifications.service';
import { AuthenticationService } from '@app/core/authentication.service';

@Component({
  selector: 'request-list',
  templateUrl: 'request-list.component.html',
  styleUrls: ['request-list.component.css']
})

export class RequestListComponent implements OnInit, OnDestroy, AfterViewInit {
  /** RxJS subscriptions collection, to dispose on destroy */
  private rxSubscriptions = new Subscription();

  /** List of requests to display */
  public requests: RequestDTO[] = null;

  /*  Paging  */
  public totalRecords: number;
  public pageSize: number = 15;

  /*  Data Filtering & Filtering form */
  public searchFilter: RequestSearchFilter;
  public requestsTypes: RequestTypeListingDTO[] = null;

  /** Data Grid configuration */
  public gridConfiguration: DataGridConfiguration<RequestDTO>;

  /*  UI  */
  @ViewChild(DataGridComponent) dataGridComponent: DataGridComponent;
  @ViewChild('requestsGridContainer') requestsGridContainer: ElementRef;
  
  /* State DropDowns */
  @ViewChild('DdStates') ddStates: ElementRef;
  public requestStatesList: any[];
  @ViewChild('DdClose') ddClose: ElementRef;
  public requestClosedList: any[];
  @ViewChild('popupCommand') popupCommand: ElementRef;

  constructor(private requestsService: RequestService,
    private router: Router,
    private appHeaderService: AppHeaderService,
    private listingsService: ListingsService,
    private persistenceManager: PersistenceManager,
    private notificationsService: NotificationsService,
    private authService: AuthenticationService,
    private entityService: EntityService) {
    this.requestStatesList = Object.keys(RequestState);
    this.requestClosedList = Object.keys(ClosedOlderThan);
    this.appHeaderService.resetBreadcrumb(new BreadcrumbSection('Requests', ['/requests']));

    this.setupRequestsDataGrid();

    this.rxSubscriptions.add(
      this.notificationsService
        .onNotificationCountersUpdate()
        .subscribe(() => {
          this.get();
        })
    );
  }

  public ngOnInit(): void {
    this.setupPersistence();

    this.rxSubscriptions.add(
      this.authService
        .selectedEntity$
        .subscribe((entity) => this.get(1))
    );
  }

  ngAfterViewInit(): void {
    this.initDbStates();
    this.initPopup();
  }

  public ngOnDestroy(): void {
    if (this.rxSubscriptions) {
      this.rxSubscriptions.unsubscribe();
    }
  }

  private setupPersistence() {
    this.searchFilter = this.persistenceManager.store<RequestSearchFilter>("RequestSearchFilter", RequestSearchFilter, psf => {
      psf.state = [RequestState.New, RequestState.Working];
    });
  }

  private initDbStates() {
    $(this.ddStates.nativeElement)
      .dropdown({
        maxSelections: 2,
        onChange: (value: any, text: string, $choice: JQuery): any => {
          let states: RequestState[] = value.split(',');
          this.searchFilter.state = states ? states : null;
        }
      }).dropdown('set selected', this.searchFilter.state);

    $(this.ddClose.nativeElement)
        .dropdown({
          clearable: true,
          onChange: (value: any, text: string, $choice: JQuery): any => {
            if (value) {
              $(this.popupCommand.nativeElement).modal("show"); 
            }
        }
      });
  }

  private initPopup() {
    $(this.popupCommand.nativeElement).modal({
      onDeny: (elem): false | void => {
        $(this.ddClose.nativeElement).dropdown('clear');
      },
      onApprove: (elem): false | void => {
        let state : ClosedOlderThan = $(this.ddClose.nativeElement).dropdown('get value');
        if (!state) return;

        this.entityService.patchRequestClosed(state).subscribe(() => {
            this.get();
          }
        );
        $(this.ddClose.nativeElement).dropdown('clear');
        this.notificationsService.refreshNotificationCounters();
      }
    });
  }

  private get(page?: number): void {
    if (page) {
      this.searchFilter.page = page;
    }

    this.searchFilter.pageSize = this.pageSize;
    this.searchFilter.includeMetadata = true;
    this.searchFilter.orderBy = this.gridConfiguration.getColumnsSortAsApiCallParam();

    this.dataGridComponent.setGridAsLoading(true);

    this.requestsService
      .get(this.searchFilter)
      .subscribe(
        (response) => {
          this.requests = response.items;
          this.pageSize = response.pageSize;
          this.totalRecords = response.totalCount;
        },
        (error: any) => {
          this.errorHandle(error);
          throw(error);
        },
        () => this.dataGridComponent.setGridAsLoading(false)
      );
  }

  private setUserAsDemoUser(): void {
    console.log("I'm doing nothing here... yet! Code me please! Let me get in touch with your backend! :D");
  }

  /*
     DataGrid Component Interaction
  */
  private setupRequestsDataGrid(): void {
    this.gridConfiguration = new DataGridConfiguration(null, true, true, true, true, false, false, this.datagridRowHasWarning);

    this.gridConfiguration.addColumn({
      index: 0,
      resolver: (dataItem: RequestDTO, columnConfig, row) => { return new CustomDatePipe().transform(dataItem.lastUpdate); },
      isSortable: true,
      headerText: 'Last update',
      sortDataField: 'updatedOn',
      currentSortDirection: null,
      columnGridWidth: 2
    });

    this.gridConfiguration.addColumn({
      index: 1,
      headerText: 'Guest name',
      isSortable: false,
      component: DataGridCounterItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridCounterItemComponent>, req: RequestDTO) => {
        const instance = componentRef.instance;
        instance.text = req.chatUser.fullName || req.chatUser.name;
        instance.hasLabel = req.unreadNotes && req.unreadNotes > 0;
        instance.labelValue = req.unreadNotes;
      }
    });

    this.gridConfiguration.addColumn({
      index: 2,
      headerText: 'Request',
      isSortable: false,
      component: DataGridImageItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridImageItemComponent>, req: RequestDTO) => {
        const instance = componentRef.instance;
        instance.imageSource = req.type.icon;
        instance.text = req.type.name;
        if (req.associatedContent && req.associatedContent.name) {
          instance.text += ` - ${req.associatedContent.name}`;
        }
        if ((instance.hasLabel = req.wasRead === false)) {
          instance.labelIsCircular = false;
          instance.labelValue = "unread";
        }
      }
    });

    this.gridConfiguration.addColumn({
      index: 3,
      headerText: 'State',
      isSortable: false,
      component: DataGridCounterItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridCounterItemComponent>, req: RequestDTO) => {
        const instance = componentRef.instance;
        instance.text = req.state;
        instance.hasLabel = true;
        instance.labelValue = null;
        instance.labelColor = this.getColorFromRequestState(req);
      }
    });

    this.gridConfiguration.addColumn({
      index: 4,
      headerText: 'Source',
      isSortable: false,
      resolver: (dataItem: RequestDTO, columnConfig, row) => { return new EmptyFieldPipe().transform(this.getNameFromPlatform(dataItem.platform)); },
      columnGridWidth: 3
    });

    this.gridConfiguration.addColumn({
      index: 5,
      headerText: 'Property',
      isSortable: false,
      displayPropertyName: 'targetEntity.name',
      columnGridWidth: 3
    });
  }

  public onDataRowClick(request: RequestDTO) {
    this.router.navigate(['/requests', request.id]);
  }

  public onDataGridSortingChange(currentPage: number): void {
  }

  public onDataGridPagingChange(page: number) {
    this.searchFilter.page = page;
    this.get(page);
  }

  private datagridRowHasWarning(request: RequestDTO): boolean {
    return request.wasRead === false;
  }

  /*
     Filtering Form
  */
  public applyDataFilter(): void {
    this.dataGridComponent.resetPagingAndSorting();
    this.get(1);
  }

  /*
  * dropdown resolver
  */
  public requestNameResolver(item: RequestTypeListingDTO): string {
    if (!item) {
      return null;
    }

    return item.name;
  }

  public requestIconResolver(item: RequestTypeListingDTO): string {
    if (!item) {
      return null;
    }

    return item.icon;
  }

  public requestObservableResolver = () => {
    if (!this.listingsService) {
      return null;
    }
    return this.listingsService.getRequestTypes();
  }

  public resolveRequestState(olderThan : ClosedOlderThan): string {
    if (!olderThan) {
      return "";
    }

    switch (olderThan) {
    case ClosedOlderThan.Ten:
      return "10";
    case ClosedOlderThan.Thirty:
      return "30";
    case ClosedOlderThan.Ninety:
      return "90";
    case ClosedOlderThan.All:
    default:
      return "All";
    }
  }

  /**
   * Error handler
   * @param error Error description object
   */
  private errorHandle(error: any): void {
    console.log(error);
    this.dataGridComponent.setGridAsLoading(false);
  }

  private getColorFromRequestState(requestDto: RequestDTO): string {
    switch (requestDto.state) {
      case RequestState.New:
        return "new-request";
      case RequestState.Working:
        return "working-request";
      case RequestState.Closed:
        return "grey";
      case RequestState.Cancelled:
      default:
        return "red";
    }
  }

  private getNameFromPlatform(platform: Platform): string {
    if (!platform) {
      return "";
    }

    switch (platform) {
      case Platform.GuestUPhone:
        return "GuestU Phone";
      case Platform.B2BApp:
        return "Mobile App";
      case Platform.Clarice:
        return "Clarice";
      case Platform.B2BAppNG:
        return "Web App";
      case Platform.Unknown:
      default:
        return "Unknown";
    }
  }

}
