

    
    /**
    * GuestU company different products that rely on the BackOffice.
    */
    
    export enum Platform {
        
        
        Unknown = "Unknown",
        
        B2BApp = "B2BApp",
        
        GuestUPhone = "GuestUPhone",
        
        Clarice = "Clarice",

        B2BAppNG = "B2BAppNG"
    }
