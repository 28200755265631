/*
    @Service
        Notifications service
    @Description
        Service class for notifications related operations and events, in-app or desktop.
    @Notes
        -
*/
// ReSharper disable once InconsistentNaming
import { Injectable, OnDestroy } from '@angular/core';
import { of, from, throwError, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '@env/environment';
import { AuthenticationService } from '../core/authentication.service';
import { NotificationCountersDTO } from '@models/notifications';
import { NotifierService } from '@app/core/notifications/notifier.service';
import { TitleService } from '@app/core/services/title.service';

/** Ad-hoc type for definition of a desktop notification */
declare var Notification: any;
/** API base url */
const API_BASE_URL: string = environment.apis.core + 'notifications';

/**
 * Service class for notifications related operations and events, in-app or desktop.
 */
@Injectable()
export class NotificationsService implements OnDestroy {
  /**
   * Polling interval (in ms)
   */
  private getNotificationCountersDelay: number = 30000;

  /**
   * Polling setTimeout identifier for later subscription cancellation
   */
  private getNotificationCountersIntervalId: any = null;

  /**
   * Notifications counter Observable
   */
  private notificationCounters = new Subject<NotificationCountersDTO>();

  /**
   * Current notification counters object
   */
  private counters: NotificationCountersDTO;

  constructor(private httpClient: HttpClient, private authService: AuthenticationService,
    private notifierService: NotifierService, private titleService: TitleService) {
    this.authService
      .isUserAuthenticated$
      .subscribe(
        (isAuth: boolean) => {
          if (!isAuth) {
            this.titleService.setNotificationsCount(null);
            this.counters = null;
            clearTimeout(this.getNotificationCountersIntervalId);
            return;
          }

          // Ask user (browser api) for permission to send desktop notifications
          this.requestDesktopNotificationPermission();
        });
    this.authService.selectedEntity$.subscribe((entity) => this.refreshNotificationCounters());
  }

  public ngOnDestroy() {
    // notification polling for active/opened or new requests counter
    clearTimeout(this.getNotificationCountersIntervalId);
    this.getNotificationCountersIntervalId = null;
  }

  /**
   * Observable to get notified whenever the notification counters are updated
   * @return {Observable<NotificationCountersDTO>}
   */
  public onNotificationCountersUpdate(): Observable<NotificationCountersDTO> {
    return this.notificationCounters.asObservable();
  }

  /**
   * Get notification counters from server
   * @param entityId Entity that's the target of the counting
   */
  private getNotificationCounters(): Observable<NotificationCountersDTO> {

    if (!this.authService.isUserAuthenticated()) {
      return of(null);
    }

    const entityId = this.authService.getUserSelectedEntityId();
    const httpParameters = entityId ? { params: new HttpParams().set('entityId', entityId.toString()) } : {};

    return this.httpClient
      .get<NotificationCountersDTO>(API_BASE_URL + '/counters', httpParameters)
      .pipe(catchError(error => this.handleError(error)));
  }

  /**
   * Fetches notification counters from server
   */
  public refreshNotificationCounters(): void {
    this.getNotificationCounters()
      .subscribe(
        (counters: NotificationCountersDTO) => {
          // Toastr notification
          this.notifyAboutCounters(counters);
          // Subscribers of changes
          this.notificationCounters.next(counters);
          // Re-set polling interval
          this.renewGetNotificationCountersLoop();
          // Counter update on page title
          if (counters) {
            let counter: number = 0;
            if (this.authService.hasClaimCapability('requests'))
              counter += counters.unreadRequests;
            if (this.authService.hasClaimCapability('chat'))
              counter += counters.unreadConversations;
            if (this.authService.hasClaimCapability('guests-feedback'))
              counter += counters.newSurveys;
            this.titleService.setNotificationsCount(counter);
          }
          // Store data
          this.counters = counters;
        },
        (error: any) => this.handleError(error)
      );
  }

  /**
   * Reset/Renew the polling interval for notification counters fetch
   */
  private renewGetNotificationCountersLoop(): void {
    if (!!this.getNotificationCountersIntervalId) {
      clearTimeout(this.getNotificationCountersIntervalId);
    }

    this.getNotificationCountersIntervalId = setTimeout(() => this.refreshNotificationCounters(), this.getNotificationCountersDelay);
  }

  /**
   * Requests user permission to display web application's notifications on his desktop
   */
  public requestDesktopNotificationPermission() {
    if (Notification && Notification.permission === 'default') {
      Notification.requestPermission(result => {
        if (result === 'denied') {
          if (environment.dev) {
            console.log('Permission wasn\'t granted. Allow a retry.');
          }

          return;
        }
        if (result === 'default') {
          if (environment.dev) {
            console.log('The permission request was dismissed.');
          }

          return;
        }
      });
    }
  }

  /**
   * Send (show) a desktop notification
   * @param notification
   */
  private sendDesktopNotification(notification: Notification) {
    if (Notification && Notification.permission !== 'granted') {
      if (environment.dev) {
        console.log('No permission to send notifications.');
      }

      return;
    }

    notification.onclick = () => {
      parent.focus();
      window.focus(); // just in case, for older browsers
      notification.close();
    };

    //setTimeout(notification.close.bind(notification), 5000);
  }

  /**
   * Verifies if user allowed desktop notifications from the web application
   */
  private desktopNotificationsAreAllowed() {
    return Notification && Notification.permission === 'granted';
  }

  /**
   * Notify user about counters, if changed.
   * Notification will be in-app if browser window/tab is active (focus) or desktop notification otherwise.
   * @param counters Current counters
   */
  private notifyAboutCounters(counters: NotificationCountersDTO) {
    // First run (counters == undefined), set.
    if (!this.counters) {
      this.counters = counters;
    }

    // Same values as current, skip!
    if (JSON.stringify(this.counters) === JSON.stringify(counters)) {
      return;
    }

    let notificationText: string = 'You have new items that require your attention:';
    let newRequests: number = 0;
    let newConversations: number = 0;
    let newGuestsFeedback: number = 0;

    if (this.authService.hasClaimCapability('requests')) {
      if (counters.unreadRequests > this.counters.unreadRequests) {
        newRequests = counters.unreadRequests - this.counters.unreadRequests;
  
        if (newRequests > 0) {
          notificationText += '\r\n' + newRequests + ' new request(s)';
        }
      }
    }
    
    if (this.authService.hasClaimCapability('chat')) {
      if (counters.unreadConversations > this.counters.unreadConversations) {
        newConversations = counters.unreadConversations - this.counters.unreadConversations;
  
        if (newConversations > 0) {
          notificationText += '\r\n' + newConversations + ' new chat conversation(s)';
        }
      }
    }
    
    if (this.authService.hasClaimCapability('guests-feedback')) {
      if (counters.newSurveys > this.counters.newSurveys) {
        newGuestsFeedback = counters.newSurveys - this.counters.newSurveys;
  
        if (newGuestsFeedback > 0) {
          notificationText += '\r\n' + newGuestsFeedback + ' new guest(s) feedback';
        }
      }
    }
    
    this.counters = counters;

    // No new items => skip!
    if (newRequests + newConversations + newGuestsFeedback === 0) {
      return;
    }

    // IF user is with active page/tab, show in-app notification only;
    //  show desktop notification otherwise
    if (document.hasFocus() || !this.desktopNotificationsAreAllowed()) {
      this.notifierService.info(notificationText.replace(new RegExp('[\r]?\n', 'g'), '<br>'), 'New Items');
      return; // do not execute in browser (SO) notification
    }

    const notification = new Notification('GuestU OPS', {
      icon: environment.appUrl + 'assets/images/icons/favicon.png',
      body: notificationText,
      timestamp: Math.floor(Date.now())
    });

    this.sendDesktopNotification(notification);
  }


  /**
   * Service error handler
   * @param error
   */
  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }
}
