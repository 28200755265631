﻿

	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    export class RequestTypeSearchFilter extends SearchFilter {
        
        
        id?: number | null;
        
        name: string;
        
        formId?: number | null;
    }
