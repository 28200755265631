import { Component, ViewChild, ComponentRef, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment/moment';
import { SurveysService } from '@app/surveys/surveys.service';
import { AuthenticationService } from '@app/core/authentication.service';
import { StatisticsService } from '@app/statistics/statistics.service';
import { Subscription } from 'rxjs';
import {
  SatisfactionSurveyDTO,
  PersistenceManager,
  SurveySearchFilter, StatsSurveyDTO
} from "@app/models";
import {
  AppHeaderService, BreadcrumbSection, DataGridConfiguration,
  DataGridImageItemComponent, DataGridComponent
} from '@app/shared';
import { CustomDatePipe } from '@app/core/pipes/custom-date.pipe';
import { NotificationsService } from '@app/notifications/notifications.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'guests-feedback',
  templateUrl: 'guests-feedback.component.html',
  styleUrls: ['guests-feedback.component.css']
})
export class GuestsFeedbackComponent implements OnInit, OnDestroy {
  /** Data grid configuration */
  public gridConfiguration: DataGridConfiguration<SatisfactionSurveyDTO>;

  /** RxJS subscriptions, to dispose on destroy */
  private rxSubscriptions = new Subscription();

  /*  UI  */
  @ViewChild(DataGridComponent) dataGridComponent: DataGridComponent;
  public guestsFeedback: SatisfactionSurveyDTO[] = null;
  public searchFilter: SurveySearchFilter;
  public totalRecords: number;

  public statsSurvey: StatsSurveyDTO;
  private skipNextNotificationsCounterUpdate: boolean = false;
  private markAllAsReadTimeoutId: any = null;
  private markAllAsReadDelay: number = 3000; // 3 seconds


  constructor(private surveysService: SurveysService, private statisticsService: StatisticsService,
    private authService: AuthenticationService, private persistenceManager: PersistenceManager,
    private notificationsService: NotificationsService, private appHeaderService: AppHeaderService)
  {
    this.appHeaderService.resetBreadcrumb(new BreadcrumbSection('Guests Feedback', ['/guests-feedback']));
    this.setupRequestsDataGrid();

    this.rxSubscriptions.add(
      this.notificationsService
      .onNotificationCountersUpdate()
      .subscribe(() => {
        if (this.skipNextNotificationsCounterUpdate) {
          this.skipNextNotificationsCounterUpdate = false;
          return;
        }
        this.get(1);
      })
    );
  }

  ngOnInit() {
    this.setupPersistence();

    this.rxSubscriptions.add(this.authService.selectedEntity$.subscribe((entity) => {
      this.get(1);
      this.getSurvey();
    }));
    this.markAllAsRead();
  }

  public ngOnDestroy(): void {
    this.rxSubscriptions.unsubscribe();

    // Clear 'markAllAsRead' timeout by id
    clearTimeout(this.markAllAsReadTimeoutId);
    this.markAllAsReadTimeoutId = null;
  }

  private setupPersistence() {
    this.searchFilter = this.persistenceManager.store<SurveySearchFilter>("SurveySearchFilter", SurveySearchFilter, psf => {
      psf.pageSize = 50;
      psf.fromDate = <any>moment(moment.now()).add(-5, 'days').toDate();
      psf.toDate = <any>moment(moment.now()).toDate();
    });
  }

  private get(page?: number): void {
    if (page) {
      this.searchFilter.page = page;
    }

    this.searchFilter.includeMetadata = true;
    this.searchFilter.orderBy = this.gridConfiguration.getColumnsSortAsApiCallParam();

    this.dataGridComponent.setGridAsLoading(true);

    this.rxSubscriptions.add(
      this.surveysService
      .get(this.searchFilter)
      .pipe(finalize(() => this.dataGridComponent.setGridAsLoading(false)))
      .subscribe(
        (response) => {
          this.guestsFeedback = response.items;
          this.searchFilter.pageSize = response.pageSize;
          this.totalRecords = response.totalCount;
        },
        (error: any) => {
          this.errorHandle(error);
          throw (error);
        },
        () => { }
      ));
  }

  private getSurvey(): void {
    this.rxSubscriptions.add(
      this.statisticsService
        .getSurvey()
        .subscribe((response) => {
          this.statsSurvey = response;
        },
          (error: any) => {
            this.errorHandle(error);
            throw (error);
          }
        ));
  }


  private markAllAsRead() {
    this.markAllAsReadTimeoutId = setTimeout(
      () => {
        if (this.authService.userIsAdminOrManager()) {
          return;
        }

        this.rxSubscriptions.add(this.surveysService
          .markAllAsRead(this.searchFilter.entityId)
          .subscribe(
            () => {
              this.notificationsService.refreshNotificationCounters();
            },
            (error: any) => {
              this.errorHandle(error);
              throw (error);
            }
          ));
      },
      this.markAllAsReadDelay
    );
  }

  /*
     DataGrid Component Interaction
  */
  private setupRequestsDataGrid(): void {
    this.gridConfiguration = new DataGridConfiguration(null, false, false, true, true, false, true, this.datagridRowHasWarning);

    this.gridConfiguration.addColumn({
      index: 0,
      resolver: (dataItem: SatisfactionSurveyDTO, columnConfig, row) => { return new CustomDatePipe().transform(dataItem.createdOn); },
      isSortable: true,
      currentSortDirection: null,
      sortDataField: 'createdOn',
      headerText: 'Date',
      columnGridWidth: 2
    });

    this.gridConfiguration.addColumn({
      index: 1,
      headerText: 'Guest rating',
      isSortable: false,
      columnGridWidth: 3,
      component: DataGridImageItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridImageItemComponent>, ss: SatisfactionSurveyDTO) => {
        const instance = componentRef.instance;
        instance.iconClass = this.getClassFromRating(ss.rating);
        instance.text = ss.user.name;
      }
    });

    this.gridConfiguration.addColumn({
      index: 2,
      headerText: 'Country',
      isSortable: false,
      columnGridWidth: 2,
      component: DataGridImageItemComponent,
      componentResolver: (componentRef: ComponentRef<DataGridImageItemComponent>, ss: SatisfactionSurveyDTO) => {
        const instance = componentRef.instance;
        let iconClass: string = "", text: string = "";
        if (ss && ss.user && ss.user.country) {
          iconClass = ss.user.country.isoCode.toLowerCase() + " flag";
          text = ss.user.country.name;
        }
        instance.iconClass = iconClass;
        instance.text = text;

        if ((instance.hasLabel = ss.wasRead === false)) {
          instance.labelIsCircular = false;
          instance.labelValue = "unread";
        }
      }
    });

    this.gridConfiguration.addColumn({
      index: 3,
      headerText: 'Comments',
      isSortable: false,
      displayPropertyName: 'comment',
      columnGridWidth: 7
    });

    this.gridConfiguration.addColumn({
      index: 4,
      headerText: 'Property',
      isSortable: false,
      displayPropertyName: 'entity.name',
      columnGridWidth: 2
    });

  }

  private getClassFromRating(rating: number): string {
    switch (rating) {
      case 1:
        return "red rating-angry-alt gu icon";
      case 2:
        return "orange rating-sad-alt gu icon";
      case 3:
        return "yellow rating-neutral-alt gu icon";
      case 4:
        return "olive rating-good-alt gu icon";
      case 5:
        return "green rating-happy-alt gu icon";
    }
    return "";
  }

  public onDataGridSortingChange(currentPage: number): void {

  }

  public onDataGridPagingChange(page: number) {
    this.searchFilter.page = page;
    this.get(page);
  }

  private datagridRowHasWarning(ss: SatisfactionSurveyDTO): boolean {
    return ss.wasRead === false;
  }

  /*
   Filtering Form
  */
  public applyDataFilter(): void {
    this.dataGridComponent.resetPagingAndSorting();
    this.get(1);
    this.skipNextNotificationsCounterUpdate = true;
    this.notificationsService.refreshNotificationCounters();
  }

  /*
   Error handling
  */
  private errorHandle(error: any): void {
    console.log(error);
    this.dataGridComponent.setGridAsLoading(false);
  }
}
