import { Injectable, Type } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { IBreadcrumbSection } from '@app/shared/sui-components/sui-breadcrumb/sui-breadcrumb.component';

export { IBreadcrumbSection, BreadcrumbSection } from '@app/shared/sui-components/sui-breadcrumb/sui-breadcrumb.component';
export interface IHeaderBreadcrumbConfig {
  resetCurrentConfig: boolean | null;
}

@Injectable()
export class AppHeaderService {
  private breadcrumbReset$ = new Subject<IBreadcrumbSection>();
  private breadcrumbSectionPush$ = new Subject<IBreadcrumbSection>();
  private breadcrumbSectionPop$ = new Subject<void>();
  private actionsComponentType$ = new Subject<Type<any>>();
  private actionsComponentUnload$ = new Subject();

  
  public getBreadcrumbReset(): Observable<IBreadcrumbSection> {
    return this.breadcrumbReset$.asObservable();
  }

  public resetBreadcrumb(section: IBreadcrumbSection) {
    this.breadcrumbReset$.next(section);
  }

  public getBreadcrumbSectionPush(): Observable<IBreadcrumbSection> {
    return this.breadcrumbSectionPush$.asObservable();
  }

  public breadcrumbSectionPush(section: IBreadcrumbSection) {
    this.breadcrumbSectionPush$.next(section);
  }

  public getBreadcrumbSectionPop(): Observable<void> {
    return this.breadcrumbSectionPop$.asObservable();
  }

  public breadcrumbSectionPop(section: any) {
    this.breadcrumbSectionPop$.next(section);
  }

  public getActionsComponentType(): Observable<Type<any>> {
    return this.actionsComponentType$.asObservable();
  }

  public setActionsComponent(type: Type<any>) {
    this.actionsComponentType$.next(type);
  }

  public getUnloadActionsComponent(): Observable<any> {
    return this.actionsComponentUnload$.asObservable();
  }

  public unloadActionsComponent() {
    this.actionsComponentUnload$.next();
  }
}
