/**
* Data transfer object for pressreader configuration
*/

export class PressReaderConfigurationDTO {

  enabled: boolean;

  siteId: string | null;

  secret: string | null;

  mainUrl: string | null;

  redirectUrl: string | null;
}
