import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from "@app/core/authentication.service";
import { StatisticsService } from '@app/statistics/statistics.service';
import * as moment from "moment/moment";
import { ChartConfiguration } from 'chart.js';
import { ChartComponent } from "@app/shared";
import {
  TotalAverageDTO
} from "@app/models";

@Component({
  selector: 'rating-average-country',
  templateUrl: 'rating-average-country.component.html',
  styleUrls: ['rating-average-country.component.css']
})
/** rating-average-country component*/
export class RatingAverageCountryComponent implements OnInit, OnDestroy {

  // All Subscriptions that should be disposed on destroy
  private rxSubscription = new Subscription();

  public chartData: ChartConfiguration;
  public totalAverageDto: TotalAverageDTO;

  @ViewChild(ChartComponent) chart: ChartComponent;

  @Input() public fromDate: moment.Moment;

  /** rating-average ctor */
  constructor(private statisticsService: StatisticsService, private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.init();
    this.rxSubscription.add(this.authService.selectedEntity$.subscribe((entity) => {
      this.getChartsInformation(this.fromDate);
    }));
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  private init() {
    this.chartData = {
      type: "horizontalBar",
      data: {
        labels: [],
        datasets: [
          {
            label: "Rating Average",
            data: []
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
           // align: 'right',
            anchor: 'end',
            clamp: true,
            font: {
              weight: 'bold'
            }
          }
        },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,   // minimum value will be 0.
              max: 100
            }
          }]
        }
      }
    };
  }

  public getChartsInformation(date: moment.Moment = moment()) {

    this.chart.isDimmed = true;
    this.rxSubscription.add(this.statisticsService.getSurveysAverageByCountry(date.utc()).pipe(finalize(() => this.chart.isDimmed = false)).subscribe((data) => {

      let newLabels = [];
      let newDataSet = [];
      let reviewsDataSet = [];

      data.forEach((row) => {
        newLabels.push(row.name);
        newDataSet.push(row.value);
        reviewsDataSet.push(row.value2);
      });

      // we need to chance the full obj because the angular references detected changes or implements ngDoCheck hook 
      this.chartData.data.datasets = [
        {
          label: "Rating Average",
          data: newDataSet
        },
        {
          label: "Reviews",
          data: reviewsDataSet
        }
      ];

      this.chartData.data.labels = newLabels;
    }));

    this.rxSubscription.add(this.statisticsService.getSurveysTotalAverage(date.utc()).subscribe((data) => {
      this.totalAverageDto = data;
    }));
  }
}
