﻿

	 import { ChatUserHistoryRecordDTO } from '@models/chat/ChatUserHistoryRecordDTO';
	 import { MessagingUserState } from '@models/enum/chat/MessagingUserState';
	 import { Moment } from 'moment';
    
    export class ChatUserDTO {
        
        
        /**
        * Messaging User unique identifier
        */
        id?: number | null;
        
        /**
        * Identity database unique identifier (the real id for the User)
        */
        userId?: number | null;
        
        /**
        * User's unique generated code (from mobile devices)
        */
        code: string;
        
        /**
        * User's name
        */
        name: string;

        /**
        * Full User's name
        */
        fullName: string;
        
        /**
        * User's email address
        */
        email: string;
        
        /**
        * User's telephone number
        */
        telephone: string;
        
        /**
        * User's history
        */
        session: ChatUserHistoryRecordDTO;
        
        /**
        * Messaging User state
        */
        state?: MessagingUserState | null;
        
        /**
        * Indicates if this messaging user is result of a demonstration routine
        */
        isDemo: boolean;
        
        /**
        * Indicates whether the Entity is marked as deleted or not.
        */
        isDeleted: boolean;
        
        /**
        * Date and time of creation.
        */
        createdOn: Moment;
        
        /**
        * Last update date and time.
        */
        lastUpdatedOn: Moment;
    }
