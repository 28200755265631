﻿

	 import { EntityDTO } from '@models/entity/EntityDTO';
	 import { ChatUserFullDTO } from '@models/chat/ChatUserFullDTO';
	 import { Moment } from 'moment';
    
    export class SatisfactionSurveyDTO {
        
        
        id: number;
        
        /**
        * The Entity
        */
        entity: EntityDTO;
        
        user: ChatUserFullDTO;
        
        rating: number;
        
        comment: string;
        
        createdOn: Moment;
        
        /**
        * Indicates if a Survey's details were ever retrieved
        */
        wasRead: boolean;
        
        /**
        * Unique identifier for the user that has first "seen" the Survey's details
        */
        readBy?: number | null;
        
        /**
        * Date and time of the first time that the Survey's details retrieved and marked as "seen"
        */
        readOn?: Moment | null;
    }
