﻿

    
    /**
    * Application Screen Views content type enumeration.  This maps directly to the ViewContentType DB table Id and Code columns.
    */
    
    export enum ViewContentType {
        
        
        Unknown = "Unknown",
        
        ENTITY = "ENTITY",
        
        PLACES = "PLACES",
        
        COLLECTIONS = "COLLECTIONS",
        
        EVENTS = "EVENTS",
        
        GENERIC_CONTENT = "GENERIC_CONTENT",
        
        FORM = "FORM",
        
        WEBVIEW = "WEBVIEW",
        
        ENTITY_INFO = "ENTITY_INFO",
        
        FACILITIES = "FACILITIES",
        
        CONTACTS = "CONTACTS",
        
        NEARBY = "NEARBY",
        
        REQUESTS = "REQUESTS"
    }
