﻿

	 import { Moment } from 'moment';
    
    export class GPhoneCheckInCommandDTO {
        
        
        entityId: number;
        
        name: string;
        
        email: string;
        
        roomNumber: string;
        
        checkout: Moment;
        
        country: string;
        
        lang: string;
        
        accountId: string;
    }
