<div>
  <div class="ui small statistics">
    <div class="statistic">
      <div class="value">
        {{statsSurvey?.ratingMonth}}
      </div>
      <div class="label">
        Rating last 30 days
      </div>
    </div>

    <div class="statistic">
      <div class="value">
        {{statsSurvey?.ratingAverage}}
      </div>
      <div class="label">
        Rating average
      </div>
    </div>

    <div class="statistic">
      <div class="value">
        {{statsSurvey?.totalReviewsToday}}
      </div>
      <div class="label">
        Total reviews today
      </div>
    </div>

    <div class="statistic">
      <div class="value">
        {{statsSurvey?.totalReviews}}
      </div>
      <div class="label">
        Total reviews
      </div>
    </div>
  </div>

  <div class="ui form">
    <div class="fields">

      <div class="six wide field">
        <between-dates
          [(fromDate)]="searchFilter.fromDate"
          [(toDate)]="searchFilter.toDate">
        </between-dates>
      </div>

        <div class="three wide field">
          <label>Guest name</label>
          <sui-input [placeholder]="'Guest name'"
                     [(inputValue)]="searchFilter.userName"
                     (keydown.enter)="applyDataFilter()">
          </sui-input>
        </div>

        <div class="two wide field">
          <label> </label>
          <div (click)="applyDataFilter()" class="ui primary button">Search</div>
        </div>

      </div>
    </div>

  <data-grid #dataGridComponent
             [(configuration)]="gridConfiguration"
             [data]="guestsFeedback"
             [dataPageSize]="searchFilter.pageSize"
             [dataTotalRecords]="totalRecords"
             (dataPageChange)="onDataGridPagingChange($event)"
             (dataSortingChange)="onDataGridSortingChange($event)">
  </data-grid>

</div>

