<!--Begin Container-->
<div class="ui vertically padded blurring dimmable centered grid">
  <div *ngIf="authService.hasClaimCapability('requests')" class="row">
    <div class="ui statistics">
      <div class="four wide column circular statistic" style="background-color: rgb(227, 234, 233)">
        <div class="value">
          {{dashboard?.openRequests}}
        </div>
        <div class="label">
          Active requests
        </div>
      </div>

      <div class="four wide column circular statistic" style="background-color: rgb(227, 223, 227)">
        <div class="value">
          {{dashboard?.closedRequestsToday}}
        </div>
        <div class="label">
          Closed requests today
        </div>
      </div>

      <div class="four wide column circular statistic" style="background-color: rgb(243, 232, 226) ">
        <div class="value">
          {{dashboard?.closedRequestsMonth}}
        </div>
        <div class="label">
          Closed requests last 30 days
        </div>
      </div>

      <div class="four wide column circular statistic" style="background-color: rgb(244, 96, 106)">
        <div class="value">
          {{dashboard?.unreadRequestMessages}}
        </div>
        <div class="label">
          Unread request messages
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="authService.hasClaimCapability('chat')" class="row">
    <div class="ui statistics">
      <div class="four wide column circular statistic" style="background-color: rgb(227, 234, 233)">
        <div class="value">
          {{dashboard?.openChats}}
        </div>
        <div class="label">
          Active chats
        </div>
      </div>

      <div class="four wide column circular statistic" style="background-color: rgb(227, 223, 227)">
        <div class="value">
          {{dashboard?.closedChatsToday}}
        </div>
        <div class="label">
          Closed chats today
        </div>
      </div>

      <div class="four wide column circular statistic" style="background-color: rgb(243, 232, 226)">
        <div class="value">
          {{dashboard?.closedChatsMonth}}
        </div>
        <div class="label">
          Closed chats last 30 days
        </div>
      </div>

      <div class="four wide column circular statistic" style="background-color: rgb(244, 96, 106)">
        <div class="value">
          {{dashboard?.unreadChatMessages}}
        </div>
        <div class="label">
          Unread chat messages
        </div>
      </div>
    </div>
  </div>

  <!-- dimmer -->
  <div #componentDimmer class="ui inverted dimmer">
    <div class="ui loader"></div>
  </div>
</div>
