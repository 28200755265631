﻿

	 import { GroupLocationDTO } from '@models/GroupLocationDTO';
	 import { LocationDTO } from '@models/LocationDTO';
	 import { EntityState } from '@models/enum/entity/EntityState';
	 import { ContactDTO } from '@models/ContactDTO';
	 import { ChatConfigurationDTO } from '@models/chat/ChatConfigurationDTO';
	 import { MultimediaContentFullDTO } from '@models/multimedia-content/MultimediaContentFullDTO';
	 import { GPhoneState } from '@models/enum/g-phone/GPhoneState';
	 import { LanguageDTO } from '@models/i18n/LanguageDTO';
	 import { EntityDTO } from '@models/entity/EntityDTO';
    
    export class EntityFullDTO extends EntityDTO {
        
        
        /**
        * Entity description.
        */
        description: string;
        
        /**
        * Number of rooms available on this Entity/accommodation
        */
        numberOfRooms?: number | null;
        
        /**
        * Location name, city or city zone, where the Entity, of <see cref="F:AppGenerator.Business.Enum.Entity.EntityType.GROUP_MEMBER" /> accommodation exists.  <remarks>  This is for exclusive use of <see cref="F:AppGenerator.Business.Enum.Entity.EntityType.GROUP_MEMBER" /> type entities.  These locations are created in the parent Entity (of <see cref="F:AppGenerator.Business.Enum.Entity.EntityType.GROUP" /> type)  </remarks>
        */
        groupAssignedLocation: GroupLocationDTO;
        
        /**
        * Location list of where the Entity exists or it's services and information is available  <remarks>  Only applicable to entities of type: Destination  </remarks>
        */
        locations: LocationDTO[];
        
        /**
        * Entity's information and application (internal) state, not available to our customers
        */
        state?: EntityState | null;
        
        /**
        * Telephone number for event notifications via Telegram app integration
        */
        telegramPhoneNumber: string;
        
        /**
        * Email address for event notications
        */
        notificationsEmail: string;
        
        /**
        * Indicates if Entity (customer) is a partner on Destination type applications, eg. Clarice
        */
        isPartnerInDestinationApp: boolean;
        
        /**
        * Indicates if Entity (customer) is available and/or searchable on Destination type applications, eg. Clarice
        */
        isAvailableToDestinationApp: boolean;
        
        /**
        * Indicates if Entity (customer) has exclusivity on end users available events; Used on Destination type applications, eg. Clarice
        */
        hasEventsExclusivity: boolean;
        
        /**
        * Inidicates if Entity (customer) has access to the games module on the backoffice and mobile application
        */
        isManagingApplicationGames: boolean;
        
        /**
        * Customer defined contact for Entity manager
        */
        managerContact: ContactDTO;
        
        /**
        * Entity's own concierge service definition and configurations.
        */
        conciergeService: ChatConfigurationDTO;
        
        /**
        * Entity's gallery images
        */
        images: MultimediaContentFullDTO[];
        
        /**
        * GuestU Phone product/service state on this Entity
        */
        gPhoneState: GPhoneState;
        
        /**
        * Entity's associated login user unique identifier
        */
        entityLoginId?: string | null;
        
        /**
        * Entity's information default display language
        */
        defaultLanguage: LanguageDTO;
        
        /**
        * User that created the entity
        */
        createdBy: string;
        
        /**
        * User that last updated the entity
        */
        updatedBy: string;
    }
