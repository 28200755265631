﻿

    
    export enum PrizeOperationStatus {
        
        
        Success = "Success",
        
        Error = "Error",
        
        MissingName = "MissingName",
        
        MissingQuantity = "MissingQuantity",
        
        InvalidQuantity = "InvalidQuantity",
        
        NoGameToAssign = "NoGameToAssign",
        
        MoreThanOneFileSpecified = "MoreThanOneFileSpecified",
        
        NumberOfPrizesWinBiggerThanQuantity = "NumberOfPrizesWinBiggerThanQuantity",
        
        InvalidGame = "InvalidGame",
        
        InvalidVirtualQuantity = "InvalidVirtualQuantity",
        
        InvalidNMaxDaily = "InvalidNMaxDaily",
        
        InvalidNDaysToClaim = "InvalidNDaysToClaim",
        
        InvalidEmail = "InvalidEmail"
    }
