﻿

	 import { Moment } from 'moment';
	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    export class PushNotificationSearchFilter extends SearchFilter {
        
        
        /**
        * Application's related Entity unique identifier
        */
        entityId?: number | null;
        
        /**
        * Push notification text content filter
        */
        text: string;
        
        /**
        * Creation datetime
        */
        createdOn?: Moment | null;
    }
