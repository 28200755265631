import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AuthenticationGuard } from '@app/core/authentication-guard.service';

import { DashboardComponent } from './dashboard.component';
import { StatisticsService } from '@app/statistics/statistics.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild([
      {
          path: 'dashboard',
          component: DashboardComponent,
          canActivate: [AuthenticationGuard]
      }
    ])
  ],
  declarations: [
    DashboardComponent
  ],
  providers: [
    StatisticsService
  ]
})
export class DashboardModule {}
