﻿


    
    export class AirportListingDTO {
        
        
        /**
        * Airport unique identifier.
        */
        id?: number | null;
        
        /**
        * Airport name.
        */
        name: string;
    }
