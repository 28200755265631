import { throwError,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '../core/authentication.service';
import { RequestSearchFilter, RequestNotesSearchFilter } from '@models/search-filters';
import { PagedResult } from '@app/core/PagedResult';
import '@app/core/extensions/HttpClient';
import {
  RequestDTO,
  RequestFullDTO,
  RequestNoteCreateDTO,
  RequestUpdateStateDTO,
  RequestNoteFullDTO,
  EntityType
} from '@app/models';
import * as moment from "moment/moment";

// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.core + 'requests';

@Injectable()
export class RequestService {
  constructor(private httpClient: HttpClient, private authService: AuthenticationService) { }

  public get(filter: RequestSearchFilter): Observable<PagedResult<RequestDTO[]>> {
    filter.entityId = this.authService.getUserSelectedEntityId();

    return this.httpClient
      .getPagedResult<RequestDTO[]>(API_BASE_URL, filter)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getByIdentifier(identifier: number): Observable<RequestFullDTO> {
    return this.httpClient
      .get<RequestFullDTO>(API_BASE_URL + '/' + identifier)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getNotes(requestId: number, filter: RequestNotesSearchFilter): Observable<PagedResult<RequestNoteFullDTO[]>> {
    if (!filter) {
      filter = new RequestNotesSearchFilter();
    }

    if (!requestId) {
      return throwError(Error('Missing or invalid parameters'));
    }

    return this.httpClient
      .getPagedResult<RequestNoteFullDTO[]>(API_BASE_URL + '/' + requestId + '/notes', filter)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public updateState(requestId: number, updateStateDto: RequestUpdateStateDTO): Observable<HttpResponse<RequestFullDTO>> {
    return this.httpClient
      .patch<RequestFullDTO>(API_BASE_URL + '/' + requestId + '/state', updateStateDto, { observe: 'response' })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public markAsRead(requestId: number): Observable<RequestFullDTO> {
    return this.httpClient
      .patch<RequestFullDTO>(API_BASE_URL + '/' + requestId + '/read', '')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public addNote(requestId: number, note: RequestNoteCreateDTO): Observable<HttpResponse<RequestNoteFullDTO>> {
    return this.httpClient
      .post<RequestNoteFullDTO>(API_BASE_URL + '/' + requestId + '/notes', note, { observe: 'response' })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public markNoteAsRead(requestId: number, noteId: number): Observable<HttpResponse<RequestNoteFullDTO>> {
    return this.httpClient
      .patch<RequestNoteFullDTO>(API_BASE_URL + '/' + requestId + '/notes/' + noteId + '/read', '', { observe: 'response' })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public markMessageAsReadBefore(requestId: number, fromDate: moment.Moment): Observable<void> {
    const httpOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .patch<void>(API_BASE_URL + '/' + requestId + '/notes/read-before', "\"" + moment(fromDate).utc().toJSON() + "\"", { headers: httpOptions })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getMessagesCheckRead(requestId: number, messagesIds: number[]): Observable<number[]> {
    return this.httpClient
      .post<number[]>(API_BASE_URL + '/' + requestId + '/notes/checkread', messagesIds)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }
}
