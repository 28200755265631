﻿

    
    /**
    * Describes the availability of the Web Application.  Offline should be used when in maintenance.  Online should be the usual state, when the application is available for any user.  AdminOnly should be used when only admins can login, a temporary state.
    */
    
    export enum ApplicationAvailability {
        
        
        Offline = "Offline",
        
        Online = "Online",
        
        AdminOnly = "AdminOnly"
    }
