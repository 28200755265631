import { Directive, ElementRef, EventEmitter, OnDestroy, Output, Input } from '@angular/core';

/*
 * https://nitayneeman.com/posts/listening-to-dom-changes-using-mutationobserver-in-angular/
 */
@Directive({
  selector: '[domChange]'
})
export class DomChangeDirective implements OnDestroy {
  private changes: MutationObserver;

  @Input()
  public options : MutationObserverInit = {
    attributes: true
  };

  @Output()
  public domChange: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const element = this.elementRef.nativeElement;

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => this.domChange.emit(mutation));
      }
    );

    this.changes.observe(element, this.options);
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
