  <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="chatSettingsDTO">
      <!-- right side -->
      <div class="eight wide column">
        
        <h4 class="ui top attached header">
          <div><i class="external alternate icon"></i>Chat Settings</div>
        </h4>
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">
            <div class="doubling three column row">
              <div class="column ui form">
                <div class="field">
                  <div class="ui tiny header">Auto close new chat thread on user registration</div>
                  <sui-checkbox [(inputValue)]="chatSettingsDTO.autoCloseNewUserChatMsg"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
              <div class="column ui form">
              </div>
              <div class="column ui form">
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
  </div>