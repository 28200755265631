import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { RequestListComponent } from './request-list/request-list.component';
import { RequestService } from './request.service';
import { AuthenticationGuard } from '../core/authentication-guard.service';
import { RequestDetailComponent } from './request-detail/request-detail.component';
import { RequestDetailGuard } from './request-detail/request-detail-guard.service';
import { SharedModule } from '@app/shared/shared.module';
import { RequestDetailActionsComponent } from './request-detail-actions/request-detail-actions.component';
import { RequestDetailActionsService } from '@app/requests/request-detail-actions/request-detail-actions.service';
import { NavigationService } from '@app/navigation/navigation.service';
import { ClaimsGuard } from '../core/claims.guard';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    CoreModule,
    RouterModule.forChild([
      {
          path: 'requests',
          component: RequestListComponent,
          canActivate: [AuthenticationGuard, ClaimsGuard]
      },
      {
        path: 'requests/:id',
        data: { title: 'Request' },
        canActivate: [AuthenticationGuard, ClaimsGuard, RequestDetailGuard],
        component: RequestDetailComponent
      }
    ])
  ],
  declarations: [
    RequestListComponent,
    RequestDetailComponent,
    RequestDetailActionsComponent
  ],
  providers: [
    RequestService,
    RequestDetailGuard,
    RequestDetailActionsService,
    NavigationService
  ],
  entryComponents: [
    RequestDetailActionsComponent
  ]
})
export class RequestModule {}
