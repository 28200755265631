﻿

    
    export enum IntegrationSystemType {
        
        
        Unknown = "Unknown",
        
        IntegrationHub = "IntegrationHub",
        
        CRS = "CRS",
        
        PMS = "PMS",
        
        All = "All"
    }
