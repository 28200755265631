<!-- Navigation Menu -->
<navigation-menu></navigation-menu>
<!-- App Contents -->
<main id="app-content-wrapper" class="main container flex-container for columns">
  <div id="app-content-header" class="ui segment">
    <div class="ui padded basic segment">
      <app-header></app-header>
    </div>
  </div>
  <div id="app-content-container" class="ui padded segment">
    <router-outlet></router-outlet>
  </div>
</main>
<!-- Dimmer -->
<div class="ui dimmer" #appDimmer>
  <div class="ui loader"></div>
</div>
