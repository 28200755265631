/*
  Represents a paged result set.
  Encapsulates a list of a business entity objects and paging metadata.
*/

export class PagedResult<T> implements IPagedResult<T> {
  public items: T;
  public page: number | null;
  public pageSize: number | null;
  public totalCount?: number | null;
}

export interface IPagedResult<T> {
  items: T;
  page: number;
  pageSize: number;
  totalCount?: number;
}
