﻿

	 import { MultimediaContentFullDTO } from '@models/multimedia-content/MultimediaContentFullDTO';
	 import { Moment } from 'moment';
    
    export class ContactTypeDTO {
        
        
        /**
        * Entity unique identifier.
        */
        id?: number | null;
        
        /**
        * Entity name.
        */
        name: string;
        
        /**
        * Representative type code.
        */
        refCode: string;
        
        /**
        * Indicates if it's a social network contact type
        */
        isSocialNetwork: boolean;
        
        /**
        * Indicates if it's a street address contact type
        */
        isAddress: boolean;
        
        /**
        * Indicates if it's a place coordinates
        */
        isPlaceCoordinates: boolean;
        
        /**
        * Indicates if it's a travel review web site contact type
        */
        isReviewWebsite: boolean;
        
        /**
        * Representative image file unique identifier
        */
        multimediaFileId: number;
        
        /**
        * Representative image file
        */
        image: MultimediaContentFullDTO;
        
        /**
        * Indicates whether the Entity is marked as deleted or not.
        */
        isDeleted: boolean;
        
        /**
        * Date and time of creation.
        */
        createdOn: Moment;
        
        /**
        * Last update date and time.
        */
        updatedOn: Moment;
    }
