  <!-- Info area, grid -->
<div class="ui vertically padded blurring dimmable grid">

  <!--Begin Container-->
  <div class="row">
    <!-- left side -->
    <div class="ten wide column">
      <div class="ui form">
        <div class="fields">
          <div class="eight wide field">
            <between-dates [(fromDate)]="datesExportGuestData.fromDate"
                           [(toDate)]="datesExportGuestData.toDate">
            </between-dates>
          </div>
          <div class="three wide field">
            <label>File Type</label>
            <radio [collection]="fileExtensionList"
                   [isInline]="true"
                   [labelNameResolver]="resolveLabelName"
                   [valueResolver]="resolveValue"
                   [checkedResolver]="resolveChecked"
                   [(selectedValue)]="datesExportGuestData.format">
            </radio>
          </div>
          <div class="five wide field">
            <label>&nbsp;</label>
            <div class="ui primary button" (click)="onClickExport($event)">
              <i class="ui file download icon"></i>
              Export Guests
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- right side -->
    <div class="six wide column">

    </div>
  </div>

  <!--Finish Container-->
  <!-- dimmer -->
  <div #componentDimmer class="ui inverted dimmer">
    <div class="ui loader"></div>
  </div>

</div>

