<div class="ui grid">
  <div *ngIf="totalAverageDto" style="margin-top: 30px;" class="centered column two ui wide">
    <div class="ui centered grid">
      <div class="ui statistic">
        <div class="value">{{totalAverageDto?.totalRequest}}</div>
        <div class="label">Requests</div>
      </div>

      <div class="ui statistic">
        <div class="value">{{totalAverageDto?.totalRequestMessage}}</div>
        <div class="label">Request Messages</div>
      </div>

      <div class="ui statistic">
        <div class="value">{{totalAverageDto?.average}}</div>
        <div class="label">Minutes</div>
      </div>
    </div>
  </div>
  <div class="fourteen wide column">
    <chart style="height: 350px;"
           [type]="chartData.type"
           [datasets]="chartData.data.datasets"
           [labels]="chartData.data.labels"
           [options]="chartData.options">
    </chart>
  </div>
</div>
