import { UserType, IAuthUser } from '.';

export class AuthUser implements IAuthUser {
  sub?: number | null;
  email?: string | null;
  email_verified?: boolean | null;
  role?: string[] | null;
  entity_access?: number | null;
  entity_access_application_id?: number | null;
  username?: string | null;
  display_name?: string | null;
  user_type?: UserType | null;
  capability?: string[] | null;
  request_type?: string[] | null;

  constructor(data?: IAuthUser) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.sub = data["sub"] !== undefined ? data["sub"] : <any>null;
      this.email = data["email"] !== undefined ? data["email"] : <any>null;
      this.email_verified = data["email_verified"] !== undefined ? data["email_verified"] : <any>null;
      if (data["role"] && data["role"].constructor === Array) {
        this.role = [];
        for (let item of data["role"])
          this.role.push(item);
      }
      this.entity_access = data["entity_access"] !== undefined ? data["entity_access"] : <any>null;
      this.entity_access_application_id = data["entity_access_application_id"] !== undefined ? data["entity_access_application_id"] : <any>null;
      this.username = data["username"] !== undefined ? data["username"] : <any>null;
      this.display_name = data["display_name"] !== undefined ? data["display_name"] : <any>null;
      this.user_type = data["user_type"] !== undefined ? data["user_type"] : <any>null;
      if (data["capability"] && data["capability"].constructor === Array) {
        this.capability = [];
        for (let item of data["capability"])
          this.capability.push(item);
      }
      if (data["request_type"] && data["request_type"].constructor === Array) {
        this.request_type = [];
        for (let item of data["request_type"])
          this.request_type.push(item);
      }
    }
  }

  static fromJS(data: any): AuthUser {
    data = typeof data === 'object' ? data : {};
    let result = new AuthUser();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["sub"] = this.sub !== undefined ? this.sub : <any>null;
    data["email"] = this.email !== undefined ? this.email : <any>null;
    data["email_verified"] = this.email_verified !== undefined ? this.email_verified : <any>null;
    if (this.role && this.role.constructor === Array) {
      data["role"] = [];
      for (let item of this.role)
        data["role"].push(item);
    }
    data["entity_access"] = this.entity_access !== undefined ? this.entity_access : <any>null;
    data["entity_access_application_id"] = this.entity_access_application_id !== undefined ? this.entity_access_application_id : <any>null;
    data["username"] = this.username !== undefined ? this.username : <any>null;
    data["display_name"] = this.display_name !== undefined ? this.display_name : <any>null;
    data["user_type"] = this.user_type !== undefined ? this.user_type : <any>null;
    if (this.capability && this.capability.constructor === Array) {
      data["capability"] = [];
      for (let item of this.capability)
        data["capability"].push(item);
    }
    if (this.request_type && this.request_type.constructor === Array) {
      data["request_type"] = [];
      for (let item of this.request_type)
        data["request_type"].push(item);
    }

    return data;
  }

  clone() {
    const json = this.toJSON();
    let result = new AuthUser();
    result.init(json);
    return result;
  }
}
