﻿


    
    export class TupleTotalDTO {
        
        
        /**
        * Total
        */
        total1: number;
        
        /**
        * Total
        */
        total2: number;
    }
