import { throwError,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '../core/authentication.service';
import '@app/core/extensions/HttpClient';

// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.identity;

/**
 * User preferences service class
 */
@Injectable()
export class UserPreferencesService {
  constructor(private httpClient: HttpClient, private authService: AuthenticationService) {
  }

  /**
   * Change a user's password
   * @param currentPassword User's current password
   * @param newPassword User's new password
   * @param passwordConfirmation User's new password confirmation
   */
  public changePassword(currentPassword: string, newPassword: string, passwordConfirmation: string): Observable<void> {
    const userId = this.authService.getUserId();

    const payload: any = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      passwordConfirmation: passwordConfirmation
    };

    if (!userId) {
      return throwError(Error('Missing or invalid parameters'));
    }

    return this.httpClient
      .post<void>(API_BASE_URL + 'users/' + userId + '/password', payload)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  /**
   * Generic error handler
   * @param error Error description object
   */
  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }
}
