﻿

    
    export enum DeviceType {
        
        
        Unknown = "Unknown",
        
        Phone = "Phone",
        
        Tablet = "Tablet",
        
        AndroidTV = "AndroidTV",
        
        AndroidWear = "AndroidWear"
    }
