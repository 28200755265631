export class MyStaysSearchMethodDTO {
    /**
    * Identifier.
    */
    id: number;
    
    /**
    * Name
    */
    name: string;

    /**
    * Code
    */
    code: string;
}
