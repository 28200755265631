﻿

	 import { SearchFilter } from '@models/search-filters/SearchFilter';
	 import { IQuerySearchFilter } from '@models/search-filters/IQuerySearchFilter';
    
    /**
    * Query, filtering and ordering core definitions for readable and searchable business entities
    */
    
    export class QuerySearchFilter extends SearchFilter implements IQuerySearchFilter {
        
        
        /**
        * Search (text) query
        */
        query: string;
        
        fullTextSearchRank: number;
    }
