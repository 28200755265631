import { Directive, ElementRef, Input, HostListener } from "@angular/core";
import { GoogleAnalyticsService, GaEventTracking } from "@app/core/google-analytics.service";

@Directive({
  selector: "[gaEventTracker]"
})
export class GaEventTrackerDirective {

  constructor(private el: ElementRef, private googleAnalyticsService: GoogleAnalyticsService) {
  }

  @Input('gaEventTracker') option: GaEventTracking;

  @HostListener('click', ['$event']) onClick($event) {
    this.googleAnalyticsService.emitEvent(this.option);
  }
}
