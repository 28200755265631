﻿

    
    /**
    * Language code enumeration.
    */
    
    export enum Language {
        
        
        Unknown = "Unknown",
        
        EN = "EN"
    }
