import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'data-grid-image-item',
  templateUrl: 'data-grid-image-item.component.html',
  styleUrls: ['data-grid-image-item.component.css']
})
export class DataGridImageItemComponent implements OnInit {

  @Output() public click: EventEmitter<any> = new EventEmitter();

  @Input() public hasLabel: boolean;
  @Input() public imageSource: string;
  @Input() public text: string;
  @Input() public labelValue: string | any;
  @Input() public labelIsCircular: boolean;
  @Input() public iconClass: string;
  @Input() public tooltipLeft: string;

  @Input() public rightIconClass: string;
  @Input() public tooltipRight: string;

  constructor() { }

  ngOnInit() {
  }

  public hasText(): boolean {
    return (!!this.text || this.text === "");
  }

  public hasImage(): boolean {
    return this.imageSource && this.imageSource !== "";
  }

  public hasLeftIconClass(): boolean {
    return this.iconClass && this.iconClass !== "" || this.text && this.text !== "" || this.hasText();
  }

  public hasRightIconClass(): boolean {
    return this.rightIconClass && this.rightIconClass !== "";
  }

  public isClickable(): boolean {
    return this.click && this.click.observers && this.click.observers.length > 0;
  }

  public onClick(event: any) {
    this.click.emit(event);
  }

}
