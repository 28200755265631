import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatepickerMode, ɵu, Transition, TransitionDirection } from 'ng2-semantic-ui';

@Component({
  selector: 'date-picker',
  templateUrl: 'date-picker.component.html',
  styleUrls: ['date-picker.component.css']
})
/** date-picker component*/
export class DatePickerComponent {

  @Input() public placeholder: string;
  @Input() public pickerMode: DatepickerMode = DatepickerMode.Date;

  /**
 * date [()]
 */
  dateLocal: string;
  @Input()
  set date(val) {
    this.dateLocal = val;
    this.dateChange.emit(this.dateLocal);
  }

  get date() {
    return this.dateLocal;
  }
  @Output() dateChange: EventEmitter<any> = new EventEmitter();

  @Input() public maxDate: Date;
  @Input() public minDate: Date;

  @Output() public onDateChange: EventEmitter<any> = new EventEmitter();

  /** date-picker ctor */
  constructor() {
  }

  @ViewChild(ɵu)
  set suiDatepicker(directive: ɵu) {
    //https://github.com/edcarroll/ng2-semantic-ui/blob/master/src/modules/popup/components/popup.ts
    directive.popup.open = () => {

      // Only attempt to open if currently closed.
      if (!directive.popup.isOpen) {
        // Cancel the closing timer.
        clearTimeout(directive.popup.closingTimeout);

        directive.popup.transitionController.stopAll();
        directive.popup.transitionController.animate(
          new Transition(directive.popup.config.transition,
            directive.popup.config.transitionDuration,
            TransitionDirection.In,
            () => {
              // Focus any element with [autofocus] attribute.
              const autoFocus = (<any>directive.popup.elementRef.nativeElement).querySelector("[autofocus]") as HTMLElement | null;
              if (autoFocus) {
                // Autofocus after the browser has had time to process other event handlers.
                setTimeout(() => autoFocus.focus(), 10);
                // Try to focus again when the modal has opened so that autofocus works in IE11.
                setTimeout(() => autoFocus.focus(), directive.popup.config.transitionDuration);
              }
            }));

        // Finally, set the popup to be open.
        (<any>directive.popup)._isOpen = true;
        directive.popup.onOpen.emit();
      }
    };

    directive.popup.config.isInline = true;
  };

  public pickerSelectedDateChange(event: any) {
    this.onDateChange.emit(event);
  }
}
