import { Component, Input, Output, EventEmitter, OnDestroy, NgZone, OnInit } from '@angular/core';
import { CustomDatePipe } from '@app/core/pipes/custom-date.pipe';
import { Subscription } from 'rxjs';
import { Moment } from 'moment/moment';

@Component({
  selector: 'moment-date',
  templateUrl: 'moment-date.component.html',
  styleUrls: ['moment-date.component.css']
})

/** moment-date component*/
export class MomentDateComponent implements OnInit, OnDestroy {

  /**
   * Text date ti display
  */
  public textDate: string;

  private subscription: Subscription;

  // private date: string;
  /**
* date [()]
*/
  private dateLocal: Moment;
  @Input()
  set date(value) {
    this.dateLocal = value;
    this.dateChange.emit(this.dateLocal);
  }

  get date() {
    return this.dateLocal;
  }
  @Output() private dateChange: EventEmitter<any> = new EventEmitter();

  /** moment-date ctor */
  constructor(private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.subscription = this.ngZone.onStable.subscribe(() => {
      this.textDate = new CustomDatePipe().transform(this.dateLocal);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
