import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ChatSettingsDTO } from '@models/chat/ChatSettingsDTO';

@Component({
    selector: 'chat-settings',
    templateUrl: 'chat-settings.component.html',
    styleUrls: ['chat-settings.component.css']
})
/** chat-settings component*/
export class ChatSettingsComponent {
    /** chat-settings ctor */
    constructor(private entityService: EntityService, private authService: AuthenticationService) {
    }

    @ViewChild("componentDimmer") componentDimmer: ElementRef;

    public chatSettingsDTO: ChatSettingsDTO;
    private rxSubscription: Subscription = new Subscription();
    private fetchingComponentData: boolean;

    ngOnInit() {
        this.load();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        if (this.rxSubscription) {
            this.rxSubscription.unsubscribe();
        }
    }

    private load() {
        const entityId = this.authService.getUserSelectedEntityId();
        if (entityId) {
            this.setComponentDimmerVisibility(true);
            this.rxSubscription.add(
                this.entityService
                    .getChatSettings()
                    .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
                    .subscribe((data) => {
                        this.chatSettingsDTO = data;
                    })
            );
        }
    }

    save(): void {
        const entityId = this.authService.getUserSelectedEntityId();
        if (entityId) {
            this.setComponentDimmerVisibility(true);
            this.rxSubscription.add(
                this.entityService
                    .updateChatSettings(this.chatSettingsDTO)
                    .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
                    .subscribe((data) => {
                        this.chatSettingsDTO = data;
                    })
            );
        }
    }

    onCheckboxChange(value: boolean): void {
        this.save();
    }

    private setComponentDimmerVisibility(visible: boolean): void {
        const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
            duration: { show: 400, hide: 800 },
        });

        if (visible) {
            this.fetchingComponentData = true;
            dimmerElement.dimmer("show");
        } else {
            dimmerElement.dimmer("hide");
            this.fetchingComponentData = false;
        }
    }
}
