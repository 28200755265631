﻿

	 import { ConciergeServiceState } from '@models/enum/ConciergeServiceState';
    
    /**
    * Data transfer object for a create a new message
    */
    
    export class ChatServiceStateDTO {
        
        
        /**
        * Unique identifier
        */
        id?: number | null;
        
        /**
        * Availability state of the chat service.
        */
        state: ConciergeServiceState;
        
        /**
        * Default message
        */
        message: string;
        
        /**
        * The enduser are allow to send messages.
        */
        allowSendMessages: boolean;
    }
