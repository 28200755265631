<div style="height: 100%"
     (click)="onClick($event)"
     [hidden]="inEditMode">{{getLabelTextValue()}}</div>

<sui-input [hidden]="!inEditMode"
           (domChange)="onDomChange($event)"          
           (keyup.enter)="onKeyEnter($event)"
           (blur)="onBlur($event)"
           [(inputValue)]="inputValue">
</sui-input>

