﻿

    
    /**
    * ConsiergeServiceState model entity enumeration.
    */
    
    export enum ConciergeServiceState {
        
        
        None = "None",
        
        Online = "Online",
        
        Offline = "Offline"
    }
