<!-- Info area, grid -->
<div class="ui vertically padded blurring dimmable grid">
  <div class="doubling two column row">
    <!-- » Request Info Column -->
    <div class="column">
      <h4 class="ui top attached header">
        <div><i class="user circle icon"></i>Guest info</div>
      </h4>
      <div class="ui attached segment">
        <div class="ui grid">
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Guest name</div>
              <p>{{request?.chatUser?.name | EmptyField}}</p>
            </div>
            <div class="column">
              <div class="ui tiny header">Country</div>
              <p>
                <i class="ui bottom aligned smaller" [ngClass]="getFlagClass()">
                  {{request?.chatUser?.country?.name | EmptyField}}
                </i>
              </p>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Check-in</div>
              <p>{{request?.chatUser?.session?.checkInDate | CustomDate:true}}</p>
            </div>
            <div class="column">
              <div class="ui tiny header">Check-out</div>
              <p>{{request?.chatUser?.session?.checkOutDate | CustomDate:true}}</p>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Email</div>
              <p>{{request?.chatUser?.email | EmptyField}}</p>
            </div>
            <div class="column">
              <div class="ui tiny header">Telephone</div>
              <p>{{request?.chatUser?.telephone | EmptyField}}</p>
            </div>
          </div>
          <div class="doubling two column row">
            <div class="column">
              <div class="ui tiny header">Source</div>
              <p>{{getNameFromPlatform(request?.platform) | EmptyField}}</p>
            </div>
            <div class="column">
              <div class="ui tiny header">Property</div>
              <p>{{request?.targetEntity?.name | EmptyField}}</p>
            </div>
          </div>
        </div>
      </div>
      <h4 class="ui top attached header">
        <div><i class="list icon"></i>Request info</div>
      </h4>
      <div id="req-form-info-table" class="ui attached segment">
        <table class="ui very basic definition table">
          <tbody>
          <tr>
            <td class="three wide column">Request</td>
            <td>
              <img class="ui bottom aligned smaller image" src="{{request?.type?.icon}}" alt="icon">
              <span class="middle aligned">{{request?.type?.name}}</span>
            </td>
          </tr>
          <tr>
            <td class="three wide column">Created</td>
            <td>{{request?.createdOn | CustomDate:false:0 }}</td>
          </tr>
          <tr>
            <td class="three wide column">Content type</td>
            <td>{{request?.associatedContent?.type | EmptyField }}</td>
          </tr>
          <tr>
            <td class="four wide column">Content name</td>
            <td>{{request?.associatedContent?.name | EmptyField }}</td>
          </tr>
          <tr *ngFor="let kvp of request?.requestItems">
            <td class="three wide column">{{kvp.key}}</td>
            <td [innerHTML]="kvp.value | EmptyField"></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- » Request Notes Column -->
    <div class="column">
      <div class="full height flex-container for columns">
        <h4 class="ui top attached header">
          <div><i class="sticky note icon"></i>Messages</div>
        </h4>
        <div class="flex-container for columns ui attached segment" [class.secondary]="!clientAppSupportsNotes">
          <div class="flex-container for columns" *ngIf="clientAppSupportsNotes">
            <messaging-container #notesContainer
                                 [collection]="notes"
                                 [showDimmerOnInit]="false"
                                 [userNameResolver]="noteUserNameResolver"
                                 [dateResolver]="noteDateResolver"
                                 [messageResolver]="noteMessageResolver"
                                 [isReadResolver]="noteIsReadResolver"
                                 [isFromEndResolver]="noteIsFromEndUser"
                                 [enableEventScroll]="true"
                                 (scrollOnTop)="onScroll($event)">
            </messaging-container>

            <div class="ui divider"></div>
            <form class="ui reply form">
              <div class="field">
                <textarea #newNoteTextArea minlength="1" maxlength="256" [placeholder]="messagePlaceHolder()" [readonly]="shouldSendMsgBeDisabled()" rows="3" class="fixed size" style="background-color: rgba(151, 175, 152, 0.3);" name="noteText" [(ngModel)]="newNoteText"></textarea>
              </div>
              <button class="ui right floated compact tiny primary button" (click)="addNote()" [disabled]="shouldSendMsgBeDisabled()">Send message</button>
            </form>
            <div #notesAreaDimmer class="ui inverted dimmer">
              <div class="ui loader"></div>
            </div>
          </div>
          <div class="flex-container for columns" style="-ms-align-items: center; -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center;" *ngIf="!clientAppSupportsNotes">
            Feature unsupported by client application
          </div>
        </div>
        <div *ngIf="!clientAppSupportsNotes" style="margin-top: 10px">
          <button class="ui right floated compact tiny primary button" (click)="onClickOpenChat($event)" >Open chat</button>         
        </div>
      </div>
    </div>
  </div>
  <!-- dimmer -->
  <div #componentDimmer class="ui inverted dimmer">
    <div class="ui loader"></div>
  </div>
</div>
