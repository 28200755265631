import { Component, OnInit } from '@angular/core';
import { UserPreferencesService } from '@app/user-preferences/user-preferences.service';
import { AppHeaderService, BreadcrumbSection } from '@shared/app-header/app-header.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotifierService } from '@app/core/notifications/notifier.service';


@Component({
  selector: 'user-preferences',
  templateUrl: 'user-preferences.component.html',
  styleUrls: ['user-preferences.component.css']
})
export class UserPreferencesComponent implements OnInit {
  public loadingData: boolean = false;

  public currentPwdError: boolean = false;
  public newPwdError: boolean = false;
  public confirmPwdError: boolean = false;
  public defaultErrorMessage: string = "All fields are required! Min. required length is 8 characters.";
  public errorMessage: string;

  public currentPassword: string;
  public newPassword: string;
  public confirmPassword: string;

  public newPwdVisible: boolean = false;
  public confirmPwdVisible: boolean = false;
  public currentPwdVisible: boolean = false;

  constructor(private preferencesService: UserPreferencesService, private appHeaderService: AppHeaderService, private notifierService: NotifierService) {
    this.appHeaderService.resetBreadcrumb(new BreadcrumbSection('User Preferences', ['/user-preferences']));
  }

  ngOnInit() {
  }


  /** Change user password */
  public changePassword(): void {
    this.loadingData = true;

    if (!this.formDataIsValid()) {
      this.loadingData = false;
      return;
    }

    this.preferencesService
      .changePassword(this.currentPassword, this.newPassword, this.confirmPassword)
      .subscribe(
        () => {
          this.loadingData = false;
          this.clearForm();
          this.notifierService.success('Your password was changed!', 'Password Change');
        },
        (error: HttpErrorResponse) => {
          if (environment.dev) {
            console.log(error);
          }
          this.errorMessage = error.error.error;
          this.loadingData = false;
        }
      );
  }

  /** Verifies if form data is valid */
  private formDataIsValid(): boolean {
    let formFieldsErrors = {
      currentPwd: {
        required: this.isEmptyOrWhiteSpaces(this.currentPassword),
        length: !this.currentPassword || this.currentPassword.length < 8
      },
      newPwd: {
        required: this.isEmptyOrWhiteSpaces(this.newPassword),
        length: !this.newPassword || this.newPassword.length < 8
      },
      confirmPwd: {
        required: this.isEmptyOrWhiteSpaces(this.confirmPassword),
        length: !this.confirmPassword || this.confirmPassword.length < 8
      }
    };

    this.currentPwdError = formFieldsErrors.currentPwd.required;
    this.newPwdError = formFieldsErrors.newPwd.required;
    this.confirmPwdError = formFieldsErrors.confirmPwd.required;

    if (this.currentPwdError || this.newPwdError || this.confirmPwdError) {
      this.errorMessage = 'Missing required field(s)';
      return false;
    }

    this.currentPwdError = formFieldsErrors.currentPwd.length;
    this.newPwdError = formFieldsErrors.newPwd.length;
    this.confirmPwdError = formFieldsErrors.confirmPwd.length;

    if (this.currentPwdError || this.newPwdError || this.confirmPwdError) {
      this.errorMessage = 'Field(s) must be at least 8 characters long';
      return false;
    }
    
    if (this.newPassword !== this.confirmPassword) {
      this.newPwdError = true;
      this.confirmPwdError = true;
      this.errorMessage = 'Passwords do not match';
      return false;
    }

    this.clearFormErrors();

    return true;
  }

  public toggleNewPwdVisibility(): void {
    this.newPwdVisible = !this.newPwdVisible;
  }

  public toggleConfirmPwdVisibility(): void {
    this.confirmPwdVisible = !this.confirmPwdVisible;
  }

  public toggleCurrentPwdVisibility(): void {
    this.currentPwdVisible = !this.currentPwdVisible;
  }

  /** Clears form */
  private clearForm(): void {
    this.currentPassword = this.newPassword = this.confirmPassword = null;
    this.clearFormErrors();
  }

  /** Clear displayed errors */
  private clearFormErrors(): void {
    this.currentPwdError = this.newPwdError = this.confirmPwdError = false;
    this.errorMessage = null;
  }

  /**
   * Verifies if a string is null, empty or white-space only
   * @param str String to validate
   */
  private isEmptyOrWhiteSpaces(str: string){
    return !str || str.match(/^\s$/) !== null;
  }
}
