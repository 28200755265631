import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'grid-pagination',
  templateUrl: 'grid-pagination.component.html',
  styleUrls: ['grid-pagination.component.css']
})
export class GridPaginationComponent implements OnInit {
  @Output() private pageChange: EventEmitter<any> = new EventEmitter();
  
  public visiblePageIndexButtons: Array<number>;
  private numberOfVisiblePageButtons: number = 3;
  private halfVisiblePageButtons: number = Math.round(this.numberOfVisiblePageButtons / 2);

  public pageNumber: number = 1;
  @Input() private totalRecords: number;
  @Input() private pageSize: number;

  constructor() { }

  public ngOnInit() {
    if (this.numberOfVisiblePageButtons % 2 === 0) {
      this.numberOfVisiblePageButtons++;
    }

    this.updateLayout();
  }

  private updateLayout() {
    this.recalculateVisiblePageButtons();
  }

  private selectPage(page: number): void {
    if (page === this.pageNumber || page > this.getMaxPageNumber()) {
      return;
    }

    this.pageNumber = page;
    this.pageChange.emit(page);
    this.updateLayout();
  }

  public goToFirstPage(): void {
    if (this.pageNumber === 1) {
      return;
    }

    this.pageNumber = 1;
    this.pageChange.emit(this.pageNumber);
    this.updateLayout();
  }

  public goToLastPage(): void {
    const maxPageNum = this.getMaxPageNumber();

    if (maxPageNum < 1 || this.pageNumber === maxPageNum) {
      return;
    }

    this.pageNumber = maxPageNum;
    this.pageChange.emit(this.pageNumber);
    this.updateLayout();
  }

  public nextPage(): void {
    if (this.pageNumber + 1 > this.getMaxPageNumber()) {
      return;
    }

    this.pageNumber++;
    this.pageChange.emit(this.pageNumber);
    this.updateLayout();
  }

  public previousPage(): void {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.pageChange.emit(this.pageNumber);
      this.updateLayout();
    }
  }

  public setPage(page: number): void {
    this.pageNumber = page;
    this.updateLayout();
  }

  private recalculateVisiblePageButtons(): void {
    const offset = this.numberOfVisiblePageButtons - this.halfVisiblePageButtons;
    let start = this.pageNumber <= this.halfVisiblePageButtons ? 1 : this.pageNumber - offset;
    const end = this.pageNumber <= this.halfVisiblePageButtons ? this.halfVisiblePageButtons + offset : this.pageNumber + offset;
    const pageIndexes: Array<number> = new Array<number>(this.numberOfVisiblePageButtons);
    let currIndex = 0;

    while(start <= end) {
      pageIndexes[currIndex++] = start++;
    }

    this.visiblePageIndexButtons = pageIndexes;
  }

  private getVisiblePageButtonsHalf(): number {
    return this.halfVisiblePageButtons;
  }

  public getMaxPageNumber(): number {
    return Math.ceil(this.totalRecords / this.pageSize);
  }

  private isMaxPageNumberVisible(): boolean {
    return this.visiblePageIndexButtons.includes(this.getMaxPageNumber());
  }
}
