﻿

    
    export enum MultimediaType {
        
        
        Unknown = "Unknown",
        
        Image = "Image",
        
        Audio = "Audio",
        
        Video = "Video",
        
        Icon = "Icon",
        
        Transport = "Transport",
        
        PointSubType = "PointSubType",
        
        MobileIcon = "MobileIcon",
        
        File = "File",
        
        Banner = "Banner"
    }
