<div class="ui vertically padded blurring dimmable two column stackable grid">
  <div class="stackable two column row">
    <div class="six wide tablet five wide computer four wide large screen three wide widescreen column">
      <form class="ui form" autocomplete="off">
        <h4 class="ui dividing header">Change Password</h4>
        <div class="field">
          <label>Current password</label>
          <div class="field" [class.error]="currentPwdError">
            <div class="ui icon input">
              <input type="password" [type]="currentPwdVisible ? 'text' : 'password'" [(ngModel)]="currentPassword" placeholder="Current password" autocomplete="off" name="current-password">
              <i class="eye link icon" [class.slash]="currentPwdVisible" [class.outline]="currentPwdVisible" (click)="toggleCurrentPwdVisibility()"></i>
            </div>
          </div>
        </div>
        <div class="field">
          <label>New password</label>
          <div class="field" [class.error]="newPwdError">
            <div class="ui icon input">
              <input type="password" [type]="newPwdVisible ? 'text' : 'password'" [(ngModel)]="newPassword" placeholder="New password" autocomplete="new-password" name="new-password">
              <i class="eye link icon" [class.slash]="newPwdVisible" [class.outline]="newPwdVisible" (click)="toggleNewPwdVisibility()"></i>
            </div>
          </div>
        </div>
        <div class="field">
          <label>Confirm password</label>
          <div class="field" [class.error]="confirmPwdError">
            <div class="ui icon input">
              <input type="password" [type]="confirmPwdVisible ? 'text' : 'password'" [(ngModel)]="confirmPassword" placeholder="Confirm password" autocomplete="new-password" name="new-password-confirmation">
              <i class="eye link icon" [class.slash]="confirmPwdVisible" [class.outline]="confirmPwdVisible" (click)="toggleConfirmPwdVisibility()"></i>
            </div>
          </div>
        </div>
        <div class="ui negative message" *ngIf="errorMessage">{{errorMessage}}</div>
        <button (click)="changePassword()" class="ui button" [class.loading]="loadingData" [class.disabled]="loadingData" type="submit" name="change-pwd-button">Change password</button>
      </form>
    </div>
  </div>
</div>
