﻿

	 import { GeoPointDTO } from '@models/types/GeoPointDTO';
	 import { MessagingSource } from '@models/enum/chat/MessagingSource';
	 import { MessagingType } from '@models/enum/chat/MessagingType';
	 import { MessagingState } from '@models/enum/chat/MessagingState';
	 import { MessagingCommunicationMedium } from '@models/enum/chat/MessagingCommunicationMedium';
	 import { ChatMessageDTO } from '@models/chat/ChatMessageDTO';
    
    export class ChatMessageFullDTO extends ChatMessageDTO {
        
        
        deviceIdentifier?: string | null;
        
        coordinates: GeoPointDTO;
        
        source: MessagingSource;
        
        type: MessagingType;
        
        state: MessagingState;
        
        medium: MessagingCommunicationMedium;
    }
