export class TimezoneListingDTO {
    /**
    * Timezone unique identifier.
    */
    id: string;
    
    /**
    * Timezone name
    */
    name: string;
}