import { Component, Output, Input, EventEmitter } from '@angular/core';
import * as moment from 'moment/moment';

@Component({
  selector: 'between-dates',
  templateUrl: 'between-dates.component.html',
  styleUrls: ['between-dates.component.css']
})

/** between-dates component*/
export class BetweenDatesComponent {

  //datePicker
  public maxDate: Date;
  public maxFromDate: Date;
  public minToDate: Date;

  /**
  * fromDate [()]
  */
  private fromDateLocal: moment.Moment;
  @Input()
  set fromDate(val) {
    this.fromDateLocal = val;
    this.fromDateChange.emit(this.fromDateLocal);
  }

  get fromDate() {
    return this.fromDateLocal;
  }
  @Output() private fromDateChange: EventEmitter<any> = new EventEmitter();

  /**
  * toDate [()]
  */
  private toDateLocal: moment.Moment;
  @Input()
  set toDate(val) {
    this.toDateLocal = val;
    this.toDateChange.emit(this.toDateLocal);
  }

  get toDate() {
    return this.toDateLocal;
  }
  @Output() private toDateChange: EventEmitter<any> = new EventEmitter();

  /** between-dates ctor */
  constructor() {
    //block the calendar to dont let selected more then today
    this.maxDate = moment(moment.now()).toDate();
    this.maxFromDate = this.maxDate;
  }


  public pickerSelectedToDateChange(event: Date) {
    if (event) {
      this.maxFromDate = event;
    }
  }

  public pickerSelectedFromDateChange(event: Date) {
    this.minToDate = event;
  }
}
