import { Component, Input, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'multiple-select-dropdown',
  templateUrl: 'multiple-select-dropdown.component.html',
  styleUrls: ['multiple-select-dropdown.component.css']
})
/** multiple-select-dropdown component*/
export class MultipleSelectDropdownComponent implements AfterViewInit {

  @Input() collection: Array<any>;
  @Input() defaultText: string;
  @Input() labelName: string;
  @Input() nameResolver: Function;
  @Input() valueResolver: Function;
  @Input() maxSelections: number | false = false;
  @Input() search: boolean = false;

  /*  onChange Dropdown value  */
  @Output() public onChangeValue: EventEmitter<any> = new EventEmitter();

  /**
* selectedValue [()]
*/
  selectedValueLocal: any;
  @Input()
  set selectedValue(val) {
    this.selectedValueLocal = val;
    this.selectedValueChange.emit(this.selectedValueLocal);
  }

  get selectedValue() {
    return this.selectedValueLocal;
  }
  @Output() selectedValueChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('DdStates') ddStates: ElementRef;

  /**
   * Don't execute the events, OnChange, OnAdd, OnRemove
   */
  private shouldSilenceEvents: boolean;

  /** multiple-select-dropdown ctor */
  constructor() {

  }

  public ngAfterViewInit(): void {
    this.initListingsDropDown();
  }

  private initListingsDropDown() {
    $(this.ddStates.nativeElement)
      .dropdown({
        maxSelections: this.maxSelections,
        fullTextSearch: true,
        match: 'text',
        onChange: (value: any, text: string, $choice: JQuery): void => {
          if (this.shouldSilenceEvents) return;

          if (!value) {
            this.selectedValue = null;
            this.onChangeValue.emit(null);
            return;
          }
        },
        onAdd: (value: any, addedText: string, $addedChoice: JQuery): void => {
          if (this.shouldSilenceEvents) return;

          var item;
          if ($addedChoice) {
            item = this.getCollectionItemByIndex($addedChoice.data('index'));

            if (!(this.selectedValue instanceof Array)) {
              this.selectedValue = [];
            }

            this.selectedValue.push(item);
            this.onChangeValue.emit({ value, item });
          }
        },
        onRemove: (value: any, removedText: string, $removedChoice: JQuery): void => {
          if (this.shouldSilenceEvents) return;

          var item;
          if ($removedChoice) {
            item = this.getCollectionItemByIndex($removedChoice.data('index'));
            this.selectedValue.forEach((elem, index) => {
              //TODO: maybe a public function?
              if (elem.id === item.id) {
                this.selectedValue.splice(index, 1);
                return;
              }
            });

            this.onChangeValue.emit({ value, item });
          }
        }
      });
    this.shouldSilenceEvents = true;
    $(this.ddStates.nativeElement).dropdown('set selected', this.getSelectedValueList(this.selectedValue));
    this.shouldSilenceEvents = false;
  }

  private getSelectedValueList(selectedValue: any): Array<string> {
    if (!selectedValue) return null;
    return selectedValue.map((item) => String(this.resolveValue(item)));
  }

  private getCollectionItemByIndex(index: number): any {
    if (this.collection && this.collection.length > 0) {
      return this.collection[index];
    }
    return null;
  }

  public setSelectedValue(selectedValue: any) {
    this.shouldSilenceEvents = true;

    $(this.ddStates.nativeElement).dropdown('clear').dropdown('set selected', this.getSelectedValueList(selectedValue));

    this.shouldSilenceEvents = false;
  }

  public show() {
    $(this.ddStates.nativeElement).dropdown('show');
  }

  public hide() {
    $(this.ddStates.nativeElement).dropdown('hide');
  }

  /* Data Resolvers */
  private resolveName(item: any) {
    if (!this.nameResolver) return item;

    return this.nameResolver(item);
  }

  private resolveValue(item: any) {
    if (!this.valueResolver) return item;

    return this.valueResolver(item);
  }

}
