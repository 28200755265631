 <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="fullMembershipConfigurationDto">
      <!-- left side -->
      <div class="eight wide column">
  
        <h4 class="ui top attached header">
          <div><i class="id card icon"></i>Settings</div>
        </h4>
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">

            <div class="row">
              <div class="column">
                <div class="ui tiny header">Enabled</div>
                <sui-checkbox [(inputValue)]="fullMembershipConfigurationDto.enabled"
                              (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>

            <div class="doubling two column row">
              <div class="column ui form">
                <div class="field" [class.disabled]="isDisabled()">
                  <div class="ui tiny header">Version</div>
                  <sui-dropdown #versionDropdown defaultText="Select option" [collection]="versionCollection"
                                [(selectedValue)]="fullMembershipConfigurationDto.version"
                                (valueChange)="onSaveEvent($event)" [observable]="versionDropdownObservable"
                                [nameResolver]="versionDropdownNameResolver" loadDataImmediately="true"
                                forceSelectedValueOnStart="true" [isClearable]="false"></sui-dropdown>
                </div>
              </div>
              <div class="column"></div>
            </div>

            <div class="doubling two column row" *ngIf="selectedEntity?.type == 'GROUP' && fullMembershipConfigurationDto.version == 2">
              <div class="column ui form">
                <div class="column" [class.disabled]="isDisabled()">
                  <div class="ui tiny header">Default Tenant</div>
                  <sui-input [placeholder]="'Tenant'" [(inputValue)]="fullMembershipConfigurationDto.defaultTenant"
                             (onChangeComplete)="save()" (keydown.enter)="save()">
                  </sui-input>
                </div>
              </div>
              <div class="column"></div>
            </div>

            <div class="doubling two column row" *ngIf="fullMembershipConfigurationDto.version == 1">
              <div class="column ui form">
                <div class="field" [class.disabled]="isDisabled()">
                  <div class="ui tiny header">Icon</div>
                  <div class="" *ngIf="!showIconRemoveBtn()">
                    <label for="iconFileInput" class="ui mini green button">
                      <i class="ui upload icon"></i>
                      Upload
                    </label>
                    <input type="file" (change)="iconFileChange($event)" name="icon" style="display: none;" id="iconFileInput" />
                  </div>
                  <div style="margin: 10px 5px 10px 0">
                    <img style="margin: 0 0 5px 5px" class="ui tiny image" src="{{fullMembershipConfigurationDto?.iconUrl}}">
                    <label class="ui mini red button" *ngIf="showIconRemoveBtn()" (click)="onClickIconRemove($event)">
                      <i class="ui trash alternate icon"></i>
                      Remove
                    </label>
                  </div>
                </div>
              </div>
              <div class="column ui form">
                <div class="field" [class.disabled]="isDisabled()">
                  <div class="ui tiny header">Status Bar Logo</div>
                  <div class="" *ngIf="!showStatusBarLogoRemoveBtn()">
                    <label for="statusBarFileInput" class="ui mini green button">
                      <i class="ui upload icon"></i>
                      Upload
                    </label>
                    <input type="file" (change)="statusBarLogoFileChange($event)" name="statusBar" style="display: none;" id="statusBarFileInput" />
                  </div>
                  <div style="margin: 10px 5px 10px 0">
                    <img style="margin: 0 0 5px 5px" class="ui tiny image" src="{{fullMembershipConfigurationDto?.statusBarLogoUrl}}">
                    <label class="ui mini red button" *ngIf="showStatusBarLogoRemoveBtn()" (click)="onClickStatusBarLogoFileRemove($event)">
                      <i class="ui trash alternate icon"></i>
                      Remove
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
  
      </div>
  
      <!-- right side -->
      <div class="eight wide column">
      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
  </div>
  
