﻿

    
    export enum GPhoneState {
        
        
        Created = "Created",
        
        Demo = "Demo",
        
        Config = "Config",
        
        Delivered = "Delivered",
        
        Ready = "Ready",
        
        Cancelled = "Cancelled"
    }
