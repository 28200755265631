<sui-dropdown
  [defaultText]="placeholder"
  [collection]="countries"
  [(selectedValue)]="selectedCountry"
  (valueChange)="onValueChange($event)"
  [nameResolver]="countryNameResolver"
  [itemIconResolver]="countryIconResolver"
  [observable]="getDataObservable"
  [loadDataImmediately]="loadDataImmediately"
  [itemType]="dropdownItemType">
</sui-dropdown>
