﻿


    
    export class TotalAverageDTO {
        
        
        /**
        * Rating average
        */
        average: number;
        
        /**
        * Total
        */
        total: number;
    }
