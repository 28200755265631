<div #listingsDropDown class="ui fluid search selection dropdown">
  <input type="hidden">
  <i class="dropdown icon"></i>
  <div class="default text" (click)="clickOnInput($event)">{{ defaultText }}</div>

  <input class="search" (click)="clickOnInput($event)" autocomplete="off" tabindex="0">
  <div class="menu">
    <ng-container>
      <div #options *ngFor="let item of collection; let i = index" class="item" 
           [attr.data-index]="i" [attr.data-value]="item.id" [style.font-family]="item.name">
        {{ resolveName(item) }}
      </div>
    </ng-container>
  </div>
</div>
