import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from "@app/core/authentication.service";
import { StatisticsService } from '@app/statistics/statistics.service';
import * as moment from "moment/moment";
import { ChartConfiguration } from 'chart.js';
import { ChartComponent } from "@app/shared";
import {
  TupleTotalDTO
} from "@app/models";

@Component({
    selector: 'total-checkin-country',
    templateUrl: 'total-checkin-country.component.html',
    styleUrls: ['total-checkin-country.component.css']
})
/** total-checkin-country component*/
export class TotalCheckinCountryComponent implements OnInit, OnDestroy {
    
  // All Subscriptions that should be disposed on destroy
  private rxSubscription = new Subscription();

  public chartData: ChartConfiguration;
  public totalDto: TupleTotalDTO;

  @ViewChild(ChartComponent) chart: ChartComponent;

  @Input() public fromDate : moment.Moment;

  /** rating-average ctor */
  constructor(private statisticsService: StatisticsService, private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.init();
    this.rxSubscription.add(this.authService.selectedEntity$.subscribe((entity) => {
      this.getChartsInformation(this.fromDate);
    }));
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  private init() {
    this.chartData = {
      type: "bar",
      data: {
        labels: [],
        datasets: [
          {
            label: "Total check-ins",
            data: []
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              return ctx.chart.$totalizer.totals[ctx.dataIndex];
            },
            align: 'top',
            anchor: 'start',
            font: {
              weight: 'bold'
            },
            display: (ctx) => {
              return ctx.datasetIndex === ctx.chart.$totalizer.utmost;
            }
          }  
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    };
  }

  public getChartsInformation(date: moment.Moment = moment()) {

    this.chart.isDimmed = true;
    this.rxSubscription.add(this.statisticsService.getTotalCheckinByCountry(date.utc()).pipe(finalize(() => this.chart.isDimmed = false)).subscribe((data) => {
      if (!data || data && data.length <= 0) return;

      let newLabels = [];
      let newDataSet = [];
      let dateFormat: string = 'YYYY/MM/DD';
      //Difference in number of days
      if (moment.utc().diff(moment(date), 'days') > 60) {
        dateFormat = 'YYYY/MM';
      }

      let keyNames: string[] = Object.keys(data[0]);   
      keyNames.shift();

      keyNames.forEach((value) => {
        newLabels.push(moment(value).format(dateFormat));
      });

      data.forEach((row, index) => {

        let data = {
          label: row['name'],
          data: []
        }

        keyNames.forEach((key) => {
          data.data.push(row[key]);
        });

        newDataSet.push(data);
      });

      // we need to chance the full obj because the angular references detected changes or implements ngDoCheck hook 
      this.chartData.data.datasets = newDataSet;

      this.chartData.data.labels = newLabels;
    }));

    this.rxSubscription.add(this.statisticsService.getCheckinTotalAverage(date.utc()).subscribe((data) => {
      this.totalDto = data;
    }));
  }
}
