<div class="ui equal width grid" >
  <div class="row">
    <div class="column" *ngIf="authService.userIsAdminOrManager()" >
      <label class="dropdown-label-name">Entity</label>
      <sui-dropdown #entityDd
                    [defaultText]="'Select Entity'"
                    [collection]="entities"
                    [(selectedValue)]="entityIdValue"
                    [nameResolver]="entityNameResolver"
                    (valueChange)="onEntityDbChange($event)"
                    [observable]="entityObservableResolver"
                    [showOnFocus]="showOnFocus"
                    [selectOnKeydown]="selectOnKeydown"
                    [showFirstLoading]="showFirstLoading"
                    >
      </sui-dropdown>
    </div>
    <div class="column" *ngIf="!this.hideGroupMemberDdl" [ngClass]="{'member-padding-left': authService.userIsAdminOrManager() }" >
      <label class="dropdown-label-name">Group Member</label>
      <sui-dropdown #entityMemberDd
                    [defaultText]="'Select Group Member'"
                    [collection]="entityGroupMembers"
                    [(selectedValue)]="entityMemberIdValue"
                    [nameResolver]="entityNameResolver"
                    (valueChange)="onEntityMemberDbChange($event)"
                    [observable]="entityMembersObservableResolver"
                    [showOnFocus]="showOnFocus"
                    [selectOnKeydown]="selectOnKeydown"
                    [showFirstLoading]="showFirstLoading"
                    >
      </sui-dropdown>
    </div>
  </div>
</div>

