﻿

	 import { Moment } from 'moment';
	 import { GPhoneCommandBodyDTO } from '@models/guest-u-phone/commands/GPhoneCommandBodyDTO';
    
    export class GPhoneCheckInCommandBodyDTO extends GPhoneCommandBodyDTO {
        
        
        /**
        * Action to be performed on/by the device
        */
        action: string;
        
        /**
        * Guest's name
        */
        guest: string;
        
        /**
        * Guest's email address
        */
        email: string;
        
        /**
        * Guest's check-out date
        */
        checkOut: Moment;
        
        /**
        * Assigned room number/identifier
        */
        room: string;
        
        /**
        * Guest nationality as a country ISO code
        */
        countryIsoCode: string;
        
        /**
        * Guest's main/preferred language as ISO code
        */
        languageIsoCode: string;
    }
