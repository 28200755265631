import { AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { finalize } from 'rxjs/operators';
import { NotifierService } from '@app/core/notifications/notifier.service';
import { Observable, of, Subscription } from 'rxjs';
import { EntityDTO, FullMembershipConfigurationDTO } from '@app/models';
import { DropdownComponent } from '@app/shared';

@Component({
  selector: 'membership-settings',
  templateUrl: './membership-settings.component.html',
  styleUrls: ['./membership-settings.component.css']
})
export class MembershipSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("componentDimmer") componentDimmer: ElementRef;

  public fullMembershipConfigurationDto: FullMembershipConfigurationDTO;
  private rxSubscription = new Subscription();
  private fetchingComponentData: boolean;
  private notifierService = this.injector.get(NotifierService);
  private selectedEntity: EntityDTO;

  @ViewChild("versionDropdown") private versionDropdown: DropdownComponent;
  public versionCollection: {id: string, name: string }[] = [
    {"id": "1", "name": "v1"},
    {"id": "2", "name": "v2"},
  ];

  constructor(
    private entityService: EntityService,
    private authService: AuthenticationService,
    private injector: Injector
  ) { }

  ngOnInit() {
    this.selectedEntity = this.authService.getUserSelectedEntity();
    this.getMembershipConfigurations();
  }

  getMembershipConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .getMembershipConfigurations()
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((data) => {
            if (data == null) {
              this.createMembershipConfigurations();
            } else {
              this.fullMembershipConfigurationDto = data;
              if (this.versionDropdown) {
                this.versionDropdown.hide();
              }
            }
          })
      );
    }
  }

  createMembershipConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .createMembershipConfigurations(new FullMembershipConfigurationDTO(), true)
          .pipe(finalize(() => this.setComponentDimmerVisibility(false)))
          .subscribe((data) => {
            this.fullMembershipConfigurationDto = data;
          })
      );
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  isDisabled(): boolean {
    return !this.fullMembershipConfigurationDto || !this.fullMembershipConfigurationDto.enabled;
  }

  onCheckboxChange(value: boolean) {
    this.save();
  }

  onChangeButtonIcon(value: string) {
    this.fullMembershipConfigurationDto.buttonIcon = value;
    this.save();
  }

  public statusBarLogoFileChange(event: EventTarget) {
    this.setComponentDimmerVisibility(true);

    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;

    const formData: FormData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.entityService.updateMembershipConfigurationsUploadStatusBarLogo(formData)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((membershipConfig) => {
        this.fullMembershipConfigurationDto = membershipConfig;
      });
  }

  public onClickStatusBarLogoFileRemove(event: Event) {
    event.stopPropagation();

    if (!this.fullMembershipConfigurationDto) {
      return;
    }

    this.setComponentDimmerVisibility(true);
    this.entityService.updateMembershipConfigurationsRemoveStatusBarLogo(this.fullMembershipConfigurationDto)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((appConfig) => {
        this.fullMembershipConfigurationDto = appConfig;
      });
  }

  public iconFileChange(event: EventTarget) {
    this.setComponentDimmerVisibility(true);

    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;

    const formData: FormData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.entityService.uploadMembershipIcon(formData)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((membershipConfig) => {
        this.fullMembershipConfigurationDto = membershipConfig;
      });
  }

  public onClickIconRemove(event: Event) {
    event.stopPropagation();

    this.setComponentDimmerVisibility(true);
    
    this.entityService.removeMembershipIcon()
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((appConfig) => {
        this.fullMembershipConfigurationDto = appConfig;
      });
  }


  public showStatusBarLogoRemoveBtn(): boolean {
    if (this.fullMembershipConfigurationDto && this.fullMembershipConfigurationDto.statusBarLogoUrl) {
      return true;
    }
    return false;
  }

  public showIconRemoveBtn(): boolean {
    if (this.fullMembershipConfigurationDto && this.fullMembershipConfigurationDto.iconUrl) {
      return true;
    }
    return false;
  }

  public versionDropdownObservable = (): Observable<any> => {
    return of(this.versionCollection);
  }

  public versionDropdownNameResolver(item: {id: string, name: string }) {
    return item.name;
  }

  public onSaveEvent(event: any) {
    this.save()
  }

  save() {
    this.setComponentDimmerVisibility(true);
    this.entityService
      .updateMembershipConfigurations(this.fullMembershipConfigurationDto)
      .pipe(finalize(() => {
        this.setComponentDimmerVisibility(false)
        if (this.versionDropdown) {
          this.versionDropdown.setSelectedValue(this.fullMembershipConfigurationDto.version)
          this.versionDropdown.hide();
        }
      }))
      .subscribe((data) => {
        this.fullMembershipConfigurationDto = data;
      });
  }

  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }

}
