import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AuthenticationGuard } from '@app/core/authentication-guard.service';
import { PushNotificationsComponent } from '@app/push-notifications/push-notifications.component';
import { PushNotificationsService } from '@app/push-notifications/push-notifications.service';
import { ClaimsGuard } from '../core/claims.guard';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild([
      {
        path: 'guests-notifications',
        data: { title: 'Guests Notifications' },
        component: PushNotificationsComponent,
        canActivate: [AuthenticationGuard, ClaimsGuard]
      }
    ])
  ],
  declarations: [
    PushNotificationsComponent
  ],
  providers: [
    PushNotificationsService
  ]
})
export class PushNotificationsModule {}
