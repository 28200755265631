﻿

	 import { Moment } from 'moment';
	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    export class RequestNotesSearchFilter extends SearchFilter {
        
        
        sentByUser?: boolean | null;
        
        /**
        * Get all records from date
        */
        fromDate?: Moment | null;
    }
