﻿


    
    export class UploadedFileDTO {
        
        
        name: string;
        
        fileName: string;
        
        base64: string;
        
        mediaType: string;
    }
