﻿

	 import { Moment } from 'moment';
	 import { GPhoneCommandBodyDTO } from '@models/guest-u-phone/commands/GPhoneCommandBodyDTO';
    
    export class GuestCheckOutUpdateCommandBodyDTO extends GPhoneCommandBodyDTO {
        
        
        entityId: number;
        
        roomNumber: string;
        
        newCheckOutDate: Moment;
    }
