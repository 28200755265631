﻿

    
    export class GPhoneCommandDTO<TCommandDataDTO> {
        
        
        entityId: number;
        
        deviceRegistrationId: string;
        
        data: TCommandDataDTO;
    }
