import { Component, ViewChild, ElementRef } from '@angular/core';
import { SurveysService } from '@app/surveys/surveys.service';
import { finalize } from 'rxjs/operators';
import {
  DatesDTO, FileExtension
} from '@app/models';

@Component({
  selector: 'export-data-settings',
  templateUrl: 'export-data-settings.component.html',
  styleUrls: ['export-data-settings.component.css']
})
/** export-data-settings component*/
export class ExportDataSettingsComponent {
  public datesExportGuestData: DatesDTO = new DatesDTO();

  // > Dimmers
  @ViewChild('componentDimmer') componentDimmer: ElementRef;
  private fetchingComponentData: boolean;

  public fileExtensionList: any[];
  /** export-data-settings ctor */
  constructor(private surveysService: SurveysService) {
    this.fileExtensionList = Object.keys(FileExtension).filter(ext => ext === FileExtension.Csv || ext === FileExtension.Xlsx );
    this.datesExportGuestData.format = FileExtension.Csv;
  }

  public onClickExport(event: Event) {
    event.stopPropagation();

    this.surveysService.export(this.datesExportGuestData)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe();
  }

  public resolveValue(entry: string) {
    return entry;
  }

  public resolveChecked = (entry: FileExtension) : true | null => {
    if (!this.datesExportGuestData) return null;

    if (this.datesExportGuestData.format === entry) {
      return true;
    }

    return null;
  }

  public resolveLabelName(entry: string) {

    if (!entry) {
      return entry;
    }

    switch (entry) {
      case FileExtension.Csv:
        return "CSV";
      case FileExtension.Xlsx:
        return "Excel";
      default:
        return "Unknown";
    }
  }

  // Component 
  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement =
      $(this.componentDimmer.nativeElement)
        .dimmer({
          duration: { show: 400, hide: 800 }
        }
        );

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer('show');
    } else {
      dimmerElement.dimmer('hide');
      this.fetchingComponentData = false;
    }
  }
}
