import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sui-breadcrumb-section',
  templateUrl: 'sui-breadcrumb-section.component.html',
  styleUrls: ['sui-breadcrumb-section.component.css']
})
export class BreadcrumbSectionComponent implements OnInit {
  @Input() public routerNavigationUrl: any[];
  @Input() public text: string;
  @Input() public isLink: boolean;
  @Input() private markedAsActive: boolean;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  private navigateToUrl() {
    if (this.isLink && this.routerNavigationUrl) {
      this.router.navigate(this.routerNavigationUrl);
    }
  }
}
