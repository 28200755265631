import * as moment from 'moment';

export class Device {
  deviceId: string;
  id: string;
  assignment: string;
  alerts?: {};
  calls: {};
  keys: DeviceKeys;
  auto_update?: string;
  lastlog?: {};
  lastmsg?: {
    dateTime: moment.Moment;
    log: string;
    isToday: boolean;
  };
  tags?: string;

  constructor(id) {
    this.deviceId = id;
    //better use a replace then a substring, why? because if the id have more then 4 digits this dont work!
    this.id = id.replace("GuestUPhone", ""); //.substring(11);
  }
  private _lastLogTimestamp: string | null;

  private _daysAgo: number | null;

  private _str: string;

  static contains(device: Device, str: string): boolean {
    return Device.asLowerStr(device).indexOf(str) >= 0;
  }

  public setDeviceId(id) {
    this.deviceId = id;
    this.id = id.substring(11);
  }

  public isWarn() {
    return this.tags && this.tags.indexOf("WARNING") > -1;
  }

  public isDeployed() {
    return this.tags && this.tags.indexOf("Deployed") > -1;
  }

  public setLastMsg() {
    if (!this.lastlog) {
      return;
    }

    let key = Object.keys(this.lastlog)[0];
    const datetime = moment(key);

    this.lastmsg = { dateTime: datetime, log: this.lastlog[key], isToday: moment.utc().diff(datetime, 'days') >= 0 };
  }

  static asLowerStr(d: Device): string {
    if (!d._str) {
      d._str = `${d.id} ${d.tags} ${Object.values(d.keys).join(' ')}`.toLowerCase();
    }
    return d._str;
  }

  static preComputeStuff(d: Device) {
    if (!d._lastLogTimestamp) {
      d._lastLogTimestamp = Object.keys(d.lastlog).shift() || null;
    }
    if (!d._str) {
      d._str = `${d.id} ${d.tags} ${Object.values(d.keys).join(' ')}`.toLowerCase();
    }
  }

  static lastLogTimestamp(d: Device): string {
    if (!d._lastLogTimestamp) {
      d._lastLogTimestamp = Object.keys(d.lastlog).shift() || null;
    }
    return d._lastLogTimestamp;
  }

  lastLogTimestamp() {
    if (!this._lastLogTimestamp) {
      this._lastLogTimestamp = this.lastlog !== undefined ? Object.keys(this.lastlog).shift() || undefined : undefined;
    }
    return this._lastLogTimestamp;
  }

  lastLogMoment() {
    return moment(this.lastLogTimestamp());
  }

  // checkInRange() : string {
  //   this.keys.user_checkin
  // }

  daysAgo(): number | null {
    if (!this._daysAgo) {
      this._daysAgo = moment().startOf('day').diff(this.lastLogMoment().startOf('day'), 'days');
    }
    return this._daysAgo;
  }
}

export class DeviceKeys {
  PIN?: string;
  action: string;
  ads?: string;
  battery?: string;
  battery_p?: string;
  checkin_location?: string;
  demo?: string;
  device?: DeviceModel;
  entity?: string;
  entity_id?: string;
  flaky_power?: string;
  gms_version?: string;
  imei?: string;
  language?: string;
  last_call_reception?: string;
  last_screen_off?: moment.Moment;
  location?: string;
  location_alias?: string;
  location_coords?: string;
  location_name?: string;
  location_time?: moment.Moment;
  location_today?: string;
  new_homescreen?: string;
  next_reset_alarm?: string;
  next_update_alarm?: string;
  notif_time?: string;
  notif_time_m?: string;
  phonenumber?: string;
  power?: string;
  push_token?: string;
  reset?: string;
  reset_motive?: string;
  simid?: string;
  status?: Status;
  storage?: string;
  survey?: string;
  survey_comment?: string;
  survey_rating?: number;
  user_checkin?: moment.Moment;
  user_checkout_date?: moment.Moment;
  user_new_checkout_date?: moment.Moment;
  user_country?: string;
  user_id?: string;
  user_inquiry_date?: moment.Moment; //moment
  user_name?: string;
  version?: string;
  webview_version?: string;
}

export class DeviceModel {
  brand?: string;
  model?: string;
  os?: number;
}

export enum Status {

  InUse = "InUse",

  "Shutdown*" = "Shutdown*",

  Shutdown = "Shutdown",

  OnCheckIn = "OnCheckIn",

  OnBoarding = "OnBoarding"

}

export enum Commands {

  Status = "status",

  Reset = "reset",

  QuietSound = "quiet_sound",

  Sound = "sound"

}
