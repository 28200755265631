﻿

	 import { EntityType } from '@models/enum/entity/EntityType';
    
    export class EntityListingDTO {
        
        
        /**
        * Unique identifier
        */
        id?: number | null;
        
        /**
        * Given name
        */
        name: string;
        
        /**
        * Entity type
        */
        type: EntityType;
        
        /**
        * Associated mobile application unique identifier
        */
        applicationId: number;
    }
