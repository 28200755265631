﻿

    
    export enum MessagingState {
        
        
        Undefined = "Undefined",
        
        Sent = "Sent",
        
        Received = "Received",
        
        Read = "Read"
    }
