/**
* Data transfer object for pos configuration
*/
export class PosConfigurationDTO {

  hasOutletNameSyncUpdate: boolean;

  hasCategoryNameSyncUpdate: boolean;

  hasProductNameSyncUpdate: boolean;

  HasModifierGroupNameSyncUpdate: boolean;

  HasModifierItemNameSyncUpdate: boolean;

  outletPublishedByDefault: boolean;

  categoryPublishedByDefault: boolean;

  productPublishedByDefault: boolean;

  hasSingleProductUpdate: boolean;

  hasSingleModifierUpdate: boolean;

  showPosSyncTab: boolean;
}
