<div class="ui grid">
  <div class="eight wide column" style="padding-right: 7px" >
    <label class="datepicker-label-name" >Start Date</label>
    <div class="ui left icon input"  >
      <i class="calendar icon"></i>
      <input suiDatepicker
             placeholder="Start Date"
             [(ngModel)]="fromDate"
             [pickerMode]="'date'"
             [pickerMaxDate]="maxFromDate"
             (pickerSelectedDateChange)="pickerSelectedFromDateChange($event)"
             [pickerUseNativeOnMobile]="false">
    </div>
  </div>

  <div class="eight wide column" style="padding-left: 7px" >
    <label class="datepicker-label-name" >End Date</label>
    <div class="ui left icon input" >
      <i class="calendar icon"></i>
      <input suiDatepicker
             placeholder="End Date"
             [(ngModel)]="toDate"
             [pickerMode]="'date'"
             [pickerMaxDate]="maxDate"
             [pickerMinDate]="minToDate"
             (pickerSelectedDateChange)="pickerSelectedToDateChange($event)"
             [pickerUseNativeOnMobile]="false">
    </div>
  </div>
</div>
