﻿

    
    export enum AppLayout {
        
        
        List = "List",
        
        Sections = "Sections"
    }
