import { Component, ViewChild, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { ListingsService } from '@app/listings/listings.service';
import { AuthenticationService } from '@app/core/authentication.service';
import { Observable } from 'rxjs';
import { EntityListingDTO, EntityType } from '@app/models';
import { DropdownComponent } from '@app/shared/sui-components/sui-dropdown/sui-dropdown.component';

@Component({
  selector: 'entity-dropdowns',
  templateUrl: 'entity-dropdowns.component.html',
  styleUrls: ['entity-dropdowns.component.css'],
  host: { '[class.wide]': 'shouldDropDownListsBeVisible()', '[class.field]': 'shouldDropDownListsBeVisible()', '[class.five]': 'isFullSize() && shouldDropDownListsBeVisible()', '[class.three]': '!isFullSize() && shouldDropDownListsBeVisible()' }
})
/** entity-dropdowns component*/
export class EntityDropdownsComponent implements OnInit {
  /*  Configuration/Columns */
  @Input() @Output() public configuration: IEntityDropdownsConfig;
  @Output() public entityChange: EventEmitter<EntityListingDTO> = new EventEmitter();
  @Output() public entityMemberChange: EventEmitter<EntityListingDTO> = new EventEmitter();

  @Output() public selectedChange: EventEmitter<EntityListingDTO> = new EventEmitter();

  /**
   * Local value from entityId
   * @param val 
   * @returns {} 
   */
  set entityIdValue(val) {
    //force the cast to number type
    if (val) {
      val = Number(val);
    }

    this.configuration.selectedEntityId = val;

    if (this.configuration.selectedEntityMemberId) {
      this.entityId = this.configuration.selectedEntityMemberId;
    } else {
      this.entityId = val;
    }
  }

  get entityIdValue() {
    return this.configuration.selectedEntityId;
  }

  /**
  * Local value from entityMemberId
  * @param val 
  * @returns {} 
  */
  set entityMemberIdValue(val) {
    //force the cast to number type
    if (val) {
      val = Number(val);
    }

    this.configuration.selectedEntityMemberId = val;

    if (val && this.entityId !== val) {
      this.entityId = val;
    } else if (!this.configuration.selectedEntityMemberId && this.entityId !== this.configuration.selectedEntityId) {
      this.entityId = this.configuration.selectedEntityId;
    }
  }

  get entityMemberIdValue() {
    return this.configuration.selectedEntityMemberId;
  }

  /**
  * entityId [()]
  */
  private entityIdLocal: number;
  @Input()
  set entityId(val) {
    this.entityIdLocal = val;
    this.entityIdChange.emit(this.entityIdLocal);
  }

  get entityId() {
    return this.entityIdLocal;
  }
  @Output() entityIdChange: EventEmitter<number> = new EventEmitter();

  /**
  * Show dropdown menu automatically on the first loading
  */
  @Input() showFirstLoading: boolean = true;

  /*semantic settings*/
  /**
   * Whether to show dropdown menu automatically on element focus.
   */
  @Input() showOnFocus: boolean = true;

  /**
   * Whether dropdown should select new option when using keyboard shortcuts. Setting to false will require enter or left click to confirm a choice.
   */
  @Input() selectOnKeydown: boolean = true;

  private entities: EntityListingDTO[] = null;
  private entityGroupMembers: EntityListingDTO[] = null;

  /** dropdowns **/
  // Child Components @View
  @ViewChild("entityMemberDd") private entityMemberDdComponent: DropdownComponent;
  @ViewChild("entityDd") private entityDdComponent: DropdownComponent;
  private resetEntityMemberDb: boolean;

  /**
   * Hide the group member dropdown 
   */
  private hideGroupMemberDdlLocal: boolean;
  set hideGroupMemberDdl(val: boolean) {

    if (this.authService.getUserAccessEntityType() !== EntityType.GROUP && !this.authService.userIsAdminOrManager() || this.authService.userIsAdminOrManager() && !this.configuration.selectedEntityId) {
      this.hideGroupMemberDdlLocal = true;
      // this.changeDetectorRef.detectChanges();
      return;
    }

    if (this.hideGroupMemberDdlLocal !== val) {
      this.hideGroupMemberDdlLocal = val;
      //this.changeDetectorRef.detectChanges();
    }
  }

  get hideGroupMemberDdl(): boolean {
    return this.hideGroupMemberDdlLocal;
  }

  /** entity-dropdowns ctor */
  constructor(private listingsService: ListingsService, public authService: AuthenticationService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (!this.configuration) {
      throw new Error('Configuration object is missing');
    }

    //TODO: maybe a better solution? for the angular error->  ExpressionChangedAfterItHasBeenCheckedError
    if (this.configuration.selectedEntityMemberId || this.configuration.selectedEntityId && this.authService.getUserSelectedEntity() && this.authService.getUserSelectedEntity().type === EntityType.GROUP || this.authService.getUserAccessEntityType() !== EntityType.GROUP && !this.authService.userIsAdminOrManager()) {
      this.hideGroupMemberDdl = false;
    } else {
      this.hideGroupMemberDdl = true;
    }
  }

  /*
  * dropdown resolver
  */
  private entityNameResolver(item: EntityListingDTO): string {
    if (!item) {
      return null;
    }

    return item.name;
  }

  //save the group item
  private entityEventChangeItem: EntityListingDTO;

  //event - > { value, item }
  private onEntityDbChange(event: { value: any, item: EntityListingDTO }) {

    if (event && event.item && event.item.type !== EntityType.GROUP) {
      this.hideGroupMemberDdl = true;
    } else {
      this.hideGroupMemberDdl = false;
    }

    if (this.entityMemberDdComponent && (this.entityEventChangeItem && event && event.item && this.entityEventChangeItem.id !== event.item.id || this.resetEntityMemberDb && this.configuration.selectedEntityMemberId)) {
      this.entityMemberDdComponent.reset(!this.hideGroupMemberDdl);
    }

    if (!this.configuration.selectedEntityMemberId) {
      this.selectedChange.emit(event.item);
    }
    this.entityEventChangeItem = event.item;

    this.resetEntityMemberDb = true;
    if (event) {
      this.entityChange.emit(event.item);
    } else {
      this.entityChange.emit(null);
    }
  }

  public entityObservableResolver = (): Observable<EntityListingDTO[]> => {
    if (!this.listingsService) {
      return null;
    }

    return this.listingsService.getEntities();
  }

  //event - > { value, item }
  private onEntityMemberDbChange(event: { value: any, item: EntityListingDTO }) {

    if (!this.configuration.selectedEntityMemberId && this.entityId !== this.configuration.selectedEntityId) {
      this.selectedChange.emit(this.entityEventChangeItem);
    } else {
      this.selectedChange.emit(event.item);
    }

    this.entityMemberChange.emit(event.item);
  }

  private entityMembersObservableResolver = (): Observable<EntityListingDTO[]> => {
    if (!this.listingsService) {
      return null;
    }

    let entityId = this.configuration.selectedEntityId;

    //TODO: remove this when we have multi entities claims
    if (!this.authService.userIsAdminOrManager()) {
      entityId = this.authService.getUserEntityAccessId();
    }

    if (!entityId) return null; 

    return this.listingsService.getEntityGroupMembers(entityId);
  }

  private shouldDropDownListsBeVisible() {
    return this.authService.userIsAdminOrManager() || this.authService.getUserAccessEntityType() === EntityType.GROUP;
  }

  private isFullSize() {
    return this.authService.userIsAdminOrManager() && !this.hideGroupMemberDdl;
  }

  public updateEntityDropdownConfiguration(newConfig: IEntityDropdownsConfig) {
    if (this.entityDdComponent) {
      this.entityDdComponent.updateSelected(newConfig.selectedEntityId);
    }

    if (this.entityMemberDdComponent) {
      this.entityMemberDdComponent.updateSelected(newConfig.selectedEntityMemberId);
    }
  }
}

/**
 * Describes a entity dropdowns configuration
 */
export interface IEntityDropdownsConfig {
  selectedEntityId?: number | null;
  selectedEntityMemberId?: number | null;
}

export class EntityDropdownsConfig implements IEntityDropdownsConfig {
  public selectedEntityId?: number | null;
  public selectedEntityMemberId?: number | null;

  constructor(selectedEntityId?: number, selectedEntityMemberId?: number) {
    this.selectedEntityId = selectedEntityId;
    this.selectedEntityMemberId = selectedEntityMemberId;
  }
}
