<div class="ui right floated mini pagination menu">
  <a class="icon item" (click)="goToFirstPage()" [ngClass]="{'disabled': this.pageNumber === 1}">
    <i class="step backward icon"></i>
  </a>
  <a class="icon item" (click)="previousPage()" [ngClass]="{'disabled': this.pageNumber === 1}">
    <i class="left chevron icon"></i>
  </a>
  <a *ngFor="let page of visiblePageIndexButtons" (click)="selectPage(page)" class="item" [ngClass]="{'active': page==pageNumber, 'disabled': page > getMaxPageNumber()}">{{page}}</a>
  <a class="icon item" (click)="nextPage()" [ngClass]="{'disabled': this.pageNumber + 1 > this.getMaxPageNumber()}">
    <i class="right chevron icon"></i>
  </a>
  <a class="icon item" (click)="goToLastPage()" [ngClass]="{'disabled': this.getMaxPageNumber() < 1 || this.pageNumber == this.getMaxPageNumber()}">
    <i class="step forward icon"></i>
  </a>
</div>
