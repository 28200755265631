﻿

    
    export enum ClosedOlderThan {
        
        
        Ten = "Ten",
        
        Thirty = "Thirty",
        
        Ninety = "Ninety",
        
        All = "All"
    }
