﻿

	 import { Moment } from 'moment';
    
    export class DeviceDTO {
        
        
        /**
        * Device unique identifier
        */
        id: string;
        
        /**
        * New Checkout
        */
        newCheckout: Moment;
    }
