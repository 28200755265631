import { throwError,  Observable, of, never } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '../core/authentication.service';
import { PushNotificationDTO, PushNotificationFullDTO } from '@models/push-notifications';
import { PushNotificationSearchFilter } from '@models/search-filters';
import '@app/core/extensions/HttpClient';
import { PagedResult } from '@app/core/PagedResult';

// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.core + 'push-notifications';

@Injectable()
export class PushNotificationsService {
  constructor(private httpClient: HttpClient, private authService: AuthenticationService) { }

  /**
   * Gets a filtered list of push notifications
   * @param filter Filter instance
   */
  public get(filter: PushNotificationSearchFilter): Observable<PagedResult<PushNotificationDTO[]>> {
    return this.httpClient
      .getPagedResult<PushNotificationDTO[]>(API_BASE_URL + '/notifications', filter)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  /**
   * Gets a push notification by it's unique identifier
   * @param identifier Unique identifier
   */
  public getByIdentifier(identifier: number): Observable<PushNotificationFullDTO> {
    return this.httpClient
      .get<PushNotificationFullDTO>(API_BASE_URL + '/notifications' + '/' + identifier)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  /**
   * Creates/Sends a new push notification for a set of devices running the target entity's mobile application
   * @param pushNotification Push notification definition
   */
  public create(pushNotification: PushNotificationFullDTO): Observable<HttpResponse<void>> {
    return this.httpClient
      .post<void>(API_BASE_URL + '/notifications', pushNotification, { observe: 'response' })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  /**
 * Creates/Sends a new push notification for a set of devices running the target entity's mobile application
 * @param pushNotification Push notification definition
 */
  public createCollection(pushNotification: PushNotificationFullDTO[]): Observable<HttpResponse<void>> {
    return this.httpClient
      .post<void>(API_BASE_URL + '/notifications-collection', pushNotification, { observe: 'response' })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }


  /**
   * Error handler
   * @param error Error object
   */
  private handleError(error: any) {
    return throwError(error);
  }
}
