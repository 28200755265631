  <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="configurationDTO">
      <!-- left side -->
      <div class="sixteen wide column">
        
        <h4 class="ui top attached header">
          <div><i class="staylinked icon"></i>Settings</div>
        </h4>
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">  
            
            <div class="doubling four column row">
              <div class="column">
                <div class="ui tiny header">Always Login via My Stays</div>
                <sui-checkbox [(inputValue)]="configurationDTO.alwaysLoginViaMyStays"
                              (onChangeValue)="save()">
                </sui-checkbox>
              </div>
              <div class="column">
                <div class="ui tiny header">Check for Room Status Ready</div>
                <sui-checkbox [(inputValue)]="configurationDTO.checkRoomStatusReady"
                              (onChangeValue)="save()">
                </sui-checkbox>
              </div>
              <div class="column">
                <div class="ui tiny header">Check for Payment Pending</div>
                <sui-checkbox [(inputValue)]="configurationDTO.checkPaymentPending"
                              (onChangeValue)="save()">
                </sui-checkbox>
              </div>
              <div class="column">
                <div class="ui tiny header">Check for Alice Onboarding Not Completed</div>
                <sui-checkbox [(inputValue)]="configurationDTO.checkAliceOnboardingNotCompleted"
                              (onChangeValue)="save()">
                </sui-checkbox>
              </div>
            </div>

            <div class="doubling four column row">
              <div class="column">
                <div class="ui tiny header">Minimum Check-In Time</div>
                <time-picker [(inputValue)]="configurationDTO.minimumCheckInTime"
                             (onChangeComplete)="save()">
                </time-picker>
              </div>
              <div class="column">
                <div class="ui tiny header">Room Status Ready Type</div>
                  <sui-dropdown #roomStatusReadyTypesDropdown defaultText="Select option" [collection]="roomStatusReadyTypesCollection"
                                [(selectedValue)]="configurationDTO.roomStatusReadyType"
                                (valueChange)="save()" [observable]="roomStatusReadyTypeDropdownObservable"
                                [nameResolver]="roomStatusReadyTypeDropdownNameResolver" loadDataImmediately="true"
                                forceSelectedValueOnStart="true" [isClearable]="true" [showFirstLoading]="false">
                  </sui-dropdown>
              </div>
            </div>

            <div class="row">
              <div class="column">

                <cdk-drop-list-group [availableItemsLabel]="'Available Search Methods'"
                                     [selectedItemsLabel]="'Selected Search Methods'"
                                     [availableItemsCollection]="availableSearchMethodsList"
                                     [selectedItemsCollection]="selectedSearchMethodsList"
                                     (selectedItemsOnChange)="onChangeSelectedItems($event)">
                </cdk-drop-list-group>
                
                <div class="ui basic without padding segment">
                  Note: This feature only works with "Crs Stays Source = B-Guest" and "B-Guest Version = v2" unless you only use default search method "{{ defaultSearchMethodName }}"
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
  </div>
  
