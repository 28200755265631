  <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="configurationDTO">
      
      <!-- left side -->
      <div class="eight wide column">
  
        <h4 class="ui top attached header">
          <div><i class="newspaper outline icon"></i>General Settings</div>
        </h4>
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">

            <div class="row">
              <div class="column">
                <div class="ui tiny header">Enabled</div>
                  <sui-checkbox [(inputValue)]="configurationDTO.enabled"
                                (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
              </div>
            </div>
            
            <div class="doubling two column row">
              <div class="column ui form">
                <div class="field" [class.disabled]="isDisabled()">
                  <div class="ui tiny header">Site Id</div>
                  <sui-input [placeholder]="'Site Id'"
                             [(inputValue)]="configurationDTO.siteId"
                             (onChangeComplete)="save()"
                             (keydown.enter)="save()">
                  </sui-input>
                </div>
              </div>
              <div class="column ui form">
                <div class="field" [class.disabled]="isDisabled()">
                  <div class="ui tiny header">Secret</div>
                  <sui-input [placeholder]="'Secret'"
                             [(inputValue)]="configurationDTO.secret"
                             (onChangeComplete)="save()"
                             (keydown.enter)="save()">
                  </sui-input>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="column ui form">
                <div class="field" [class.disabled]="isDisabled()">
                  <div class="ui tiny header">Main URL</div>
                  <sui-input [placeholder]="'Main URL'"
                             [(inputValue)]="configurationDTO.mainUrl"
                             (onChangeComplete)="save()"
                             (keydown.enter)="save()">
                  </sui-input>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="column ui form">
                <div class="field" [class.disabled]="isDisabled()">
                  <div class="ui tiny header">Redirect URL</div>
                  <sui-input [placeholder]="'Redirect URL'"
                             [(inputValue)]="configurationDTO.redirectUrl"
                             (onChangeComplete)="save()"
                             (keydown.enter)="save()">
                  </sui-input>
                </div>
              </div>
            </div>

          </div>
        </div>
  
      </div>

      <!-- right side -->
      <div class="eight wide column">
        <h4 class="ui top attached header">
          <div><i class="hourglass half icon"></i>Access Lifetime</div>
        </h4>
  
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">
          
            <div class="row">
              <div class="column ui form">
                <div class="field" [class.disabled]="isDisabled()">
                  <data-grid #dataGridComponent
                             [(configuration)]="gridConfiguration"
                             [data]="accessLifetimeDTO">
                  </data-grid>
                </div>
                <div class="field" [class.disabled]="isDisabled()">
                  <div class="ui buttons">
                    <button class="ui button" (click)="addNewAccessLifetimeOnClick($event)">Add new</button>
                    <div class="or"></div>
                    <button class="ui positive button" (click)="saveAccessLifetimeOnClick($event)">Save</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
  </div>
  
