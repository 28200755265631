﻿

	 import { EntityDTO } from '@models/entity/EntityDTO';
	 import { ChatUserFullDTO } from '@models/chat/ChatUserFullDTO';
	 import { ChatMessageDTO } from '@models/chat/ChatMessageDTO';
    
    export class ChatConversationDTO {
        
        
        /**
        * Conversation unique identifier
        */
        id: string;
        
        entity: EntityDTO;
        
        /**
        * Chat/Messaging module user
        */
        user: ChatUserFullDTO;
        
        lastMessage: ChatMessageDTO;
        
        unreadMessages: number;
    }
