﻿

    
    export enum MessagingUserState {
        
        
        Open = "Open",
        
        Closed = "Closed"
    }
