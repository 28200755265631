<div *ngFor="let actionButton of getButtonsArray(); trackBy: trackRowByIndex"
     class="action-button container"
     data-position="left center"
     data-variation="mini"
     [attr.data-tooltip]="actionButton.tooltip"
     (click)="onClick($event, actionButton)">
  <i [ngClass]="actionButton.icon" class="icon link"></i>
</div>

<div *ngIf="hasPopup" #popupCommand class="ui tiny modal" style="display: none;">
  <div class="header">{{popupHeader}}</div>
  <div class="content" [innerHtml]="popupContent">
    <p>Are you sure you want to execute this command?</p>
  </div>
  <div class="actions">
    <div class="ui negative button">No</div>
    <div class="ui positive right labeled icon button">Yes<i class="checkmark icon"></i></div>
  </div>
</div>
