﻿

	 import { MessagingUserState } from '@models/enum/chat/MessagingUserState';
    
    /**
    * Chat user update state data transfer object class
    */
    
    export class ChatUserUpdateStateDTO {
        
        
        /**
        * Chat user state
        */
        state: MessagingUserState;
    }
