<div class="ui middle aligned two column padded centered grid" *ngIf="authFormShoudBeVisible">
  <div class="stretched row">
    <div class="fourteen wide mobile eight wide tablet six wide computer four wide large screen three wide widescreen column">

      <div>
        <img src="./assets/images/logos/guestu_white_300.png" alt="GuestU" class="ui centered medium image">
        <div class="ui hidden divider"></div>
      </div>

      <div id="login-form-container" class="ui basic segment">
        <form id="login-form" #loginForm class="ui large form">
          <div class="ui stacked segment">
            <div class="field">
              <div class="ui left icon input">
                <i class="user icon"></i>
                <input type="text" name="email" autocomplete="registration-email" placeholder="E-mail address" [(ngModel)]="username">
              </div>
            </div>
            <div class="field">
              <div class="ui left icon input">
                <i class="lock icon"></i>
                <input type="password" name="password" autocomplete="registration-pwd" placeholder="Password" [(ngModel)]="password" (keyup.enter)="login()">
              </div>
            </div>
            <div class="ui fluid large blue submit button" (click)="login()">Login</div>
          </div>
          <div class="ui error message">
            <div *ngIf="errorMessage !== null" [innerHTML]="errorMessage"></div>
          </div>
        </form>
        <div *ngIf="false" class="ui raised center aligned segment">Don't remember your password? <a href="#" onclick="return false;">Recover</a></div>
        <div class="ui dimmer">
          <div class="ui loader"></div>
        </div>
      </div>

    </div>
  </div>
</div>
