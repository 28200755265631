﻿

	 import { ChatUserDTO } from '@models/chat/ChatUserDTO';
	 import { EntityDTO } from '@models/entity/EntityDTO';
	 import { ApplicationDTO } from '@models/ApplicationDTO';
	 import { Platform } from '@models/enum/admin/Platform';
	 import { MobileOS } from '@models/enum/MobileOS';
	 import { GeoPointDTO } from '@models/types/GeoPointDTO';
	 import { Moment } from 'moment';
    
    export class ChatUserHistoryRecordDTO {
        
        
        /**
        * Stay unique identifier
        */
        id: number;
        
        /**
        * Chat user reference
        */
        chatUser: ChatUserDTO;
        
        /**
        * The Hospitality Entity
        */
        hospitality: EntityDTO;
        
        /**
        * Application being used unique identifier
        */
        application: ApplicationDTO;
        
        /**
        * Platform being used by end user
        */
        platform?: Platform | null;
        
        /**
        * Device operating system
        */
        mobileOS?: MobileOS | null;
        
        /**
        * Stay registration GPS position
        */
        coordinates: GeoPointDTO;
        
        /**
        * Stay check-in date
        */
        checkInDate?: Moment | null;
        
        /**
        * Stay check-out date
        */
        checkOutDate?: Moment | null;
        
        /**
        * Stay room identifier
        */
        room: string;
        
        /**
        * Reservation number or code assigned to end-user (by external system)  for faster access to other functionality
        */
        reservationNumber: string;
        
        /**
        * Application version
        */
        appVersion: string;
        
        /**
        * Application build
        */
        appBuild: string;
        
        /**
        * Device operating system version
        */
        deviceOsVersion: string;
        
        /**
        * Device assigned identifier
        */
        deviceIdentifier: string;
        
        /**
        * External system assigned account identifier for stay/user
        */
        pmsAccountId: string;
        
        /**
        * External system, CRS, assigned account identifier for stay/user
        */
        crsAccountId: string;
    }
