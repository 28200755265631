

    
    /**
    * Mobile devices operating systems
    */
    
    export enum MobileOS {
        
        
        iOS = "iOS",
        
        Android = "Android",

        Web = "Web"
    }
