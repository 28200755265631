import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FontLoaderService {
  loadFont(fontName: string, fontUrl: string): void {
    const style = document.createElement('style');
    style.innerHTML = `
      @font-face {
        font-family: '${fontName}';
        src: url('${fontUrl}') format('truetype');
      }
    `;
    document.head.appendChild(style);
  }
}
