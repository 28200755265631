﻿


    
    export class RequestTypeDTO {
        
        
        id?: number | null;
        
        name: string;
        
        icon: string;
        
        image: string;
        
        backgroundImage: string;
    }
