// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const apisBaseUrl: string = 'https://staging-api.guestu.com/';

export const environment = {
  production: false,
  qa: true,
  dev: true,
  appUrl: 'https://staging-ops.guestu.com/',
  apis: {
    // APIs base URL
    baseUrl: apisBaseUrl,
    // User Identity Management
    identity: apisBaseUrl + 'identity/',
    // Core API
    core: apisBaseUrl + 'appgenerator/',
    // Integrations API
    integrations: apisBaseUrl + 'integrations/',
  },
  localStorage: {
    authTokenKey: 'auth.token.qa',
    authUserKey: 'auth.user.qa',
    accessEntityKey: 'user.access.entity.qa',
    selectedEntityKey: 'user.selected.entity.qa'
  },
  authServer: {
    baseUrl: 'https://staging-auth.guestu.com/idserver/',
    introspectApiKey: 'Basic dG9rZW5fdmFsaWRhdGlvbjpHdTM1N1UxbjdyMDVwM2M3MTBuNWMwcDM='
  },
  firebase: {
    apiKey: 'AIzaSyAGNhTArzCGy1Bvu5M7JsJ_z_tY5nyCA5I',
    databaseURL: 'https://appstorage-df9a7.firebaseio.com'
  },
  googleAnalytics: {
    enabled: false,
    key: 'UA-59055067-27'
  }
};
