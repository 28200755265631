﻿

	 import { LanguageDTO } from '@models/i18n/LanguageDTO';
    
    export class LanguageFullDTO extends LanguageDTO {
        
        
        /**
        * Entity native name.
        */
        nativeName: string;
        
        /**
        * Entity flag icon.
        */
        flagIcon: string;
    }
