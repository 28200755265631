// # Imports
import { Injectable } from '@angular/core';
import { ISearchFilter } from '@models/search-filters/ISearchFilter';
import { PersistenceSearchFilter } from '@models/persistence/PersistenceSearchFilter';
import { IEntityDropdownsConfig } from "@app/shared";

export class Dictionary {
  constructor(public key: string, public value: any) { }
}

// # Service
@Injectable()
export class PersistenceManager {

  public persistenceStore: Dictionary[];

  constructor() {
    this.persistenceStore = new Array();
  }

  private addPersistence<T>(key: string, persistence: T): T {
    this.persistenceStore.push(new Dictionary(key, persistence));

    return persistence;
  }

  public reset() {
    this.persistenceStore = new Array();
  }

  public remove(className: string): boolean {
    let index: number = this.persistenceStore.findIndex((value) => value.key === className);
    if (index > -1) {
      this.persistenceStore.splice(index, 1);
      return true;
    }
    return false;
  }

  /**
    * Store the persistence object or get the store persistence object
    * @param persistence The Persistence object type to store or get from the store
    * @returns {Persistence} object
    */
  public store<T>(key: string, cObj: new () => T, whenNew?: (value: T) => void): T {

    //if (!key.endsWith('SearchFilter')) {
    //  return null;
    //}

    let item: Dictionary = this.persistenceStore.find(value => value.key === key);
    if (item) {
      return item.value;
    }

    var persistenceObj = new cObj();
    if (whenNew) {
      whenNew(persistenceObj);
    }

    return this.addPersistence(key, persistenceObj);
  }

  public storeLocalStorage<T>(key: string, cObj: new () => T, whenNew?: (value: T) => void): T {

    const item = JSON.parse(localStorage.getItem(key));

    if (item) {
      return item;
    }

    var persistenceObj = new cObj();
    if (whenNew) {
      whenNew(persistenceObj);
    }

    localStorage.setItem(key, JSON.stringify(persistenceObj));

    return persistenceObj;
  }

  public setStoreLocalStorage<T>(key: string, persistenceObj: T) {
    localStorage.setItem(key, JSON.stringify(persistenceObj));
  }

  public setupPersistence<TS extends ISearchFilter>(key: string, searchFilter: TS, configuration: IEntityDropdownsConfig, whenNew?: any) {
    var persistence: PersistenceSearchFilter<TS> = this.store<PersistenceSearchFilter<TS>>(key, PersistenceSearchFilter, whenNew);
    //local refs
    searchFilter = persistence.searchFilter;
    configuration = persistence.entityDropdownConfig;
  }

  private handleError(error: Object) {
    console.dir(error || 'Persistence error');
  }

}
