﻿

	 import { Moment } from 'moment';
    
    export class GPhoneCheckOutDateUpdateCommandDTO {
        
        
        entityId: number;
        
        roomNumber: string;
        
        newCheckOutDate: Moment;
    }
