import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { TabComponent, ITabConfig, TabConfig } from './tab/tab.component';

@Component({
  selector: 'tabs',
  templateUrl: 'tabs.component.html',
  styleUrls: ['tabs.component.css']
})
/** tabs component*/
export class TabsComponent implements OnInit {

  public tabs: ITabConfig[] = [];

  @Output() selected: EventEmitter<ITabConfig> = new EventEmitter();

  private selectedValue: ITabConfig;

  ngOnInit(): void {
  }

  public addTab(tab: ITabConfig) {
    let newTab = new TabConfig(tab);

    if (!this.selectedValue && newTab.isVisible) {
      newTab.selected = true;
      this.selectedValue = newTab;
      this.selected.emit(newTab);
    }

    this.tabs.push(newTab);
  }

  public onTabClick(selectedTab: ITabConfig) {
    if (!selectedTab.active || this.selectedValue === selectedTab) return;

    this.tabs.forEach((tab) => tab.selected = selectedTab === tab);

    selectedTab.tabComponent.reload();
    this.selectedValue = selectedTab;
    this.selected.emit(selectedTab);
  }

  /**
   * Reload all active tabs and rebuild the dynamic components
   * @returns {} 
  */
  public reloadActiveTabs() {
    if (this.selectedValue && this.selectedValue.tabComponent) {
      this.selectedValue.tabComponent.reload();
    }
  }

  public reset(keepSelected: boolean = false) {
    this.tabs = [];
    if (!keepSelected) {
      this.selectedValue = null;
    }
  }

  public hideTabNav() {
    let actives = this.tabs.filter((tab) => tab.isVisible);
    if (actives.length <= 1) {
      return false;
    }
    return true;
  }
}
