<div class="ui labeled input">
  <div class="ui basic label">
    <input class="color_picker"
           type="text"
           [(ngModel)]="inputValue"
           [style.background]="inputValue"
           [cpOKButton]="true"
           [cpSaveClickOutside]="true"
           [cpOKButtonClass]= "'mini positive ui button'"
           [(colorPicker)]="inputValue"
           (colorPickerOpen)="onOpen($event)"
           (colorPickerClose)="onClose($event)"/>
  </div>
  <input class="color_picker_value"
         type="text"
         [(ngModel)]="inputValue"
         [value]="inputValue"
         (change)="onChange($event)"/>
</div>
