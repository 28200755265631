export class CurrencyDTO {
    /**
    * Currency unique identifier.
    */
    id: number;

    constructor(public item: any = null) {
        if (item) {
            this.id = item.id;
        }
    }
}