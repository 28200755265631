﻿

    
    /**
    * Application Request State enum.  Maps to DB identity values - [app].[RequestType] table.
    */
    
    export enum RequestState {
        
        
        New = "New",
        
        Working = "Working",
        
        Closed = "Closed",
        
        Cancelled = "Cancelled"
    }
