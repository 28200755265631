<div class="ui grid">
  <div class="four column unpadded row">
    <div class="left floated fourteen wide unpadded column">
      <span class="middle aligned">{{text}}</span>
    </div>
    <div class="right floated right aligned two wide unpadded column">
      <div *ngIf="hasLabel" class="ui mini horizontal label" [class.circular]="labelIsCircular" [ngClass]="labelColor">{{labelValue}}</div>
    </div>
  </div>
</div>
