import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { AuthenticationGuard } from '@app/core/authentication-guard.service';
import { ChatService } from './chat.service';
import { ConversationListComponent } from './conversation-list/conversation-list.component';
import { ChatDetailComponent } from './chat-detail/chat-detail.component';
import { ChatDetailGuard } from './chat-detail/chat-detail-guard.service';
import { EntityService } from '@app/entity/entity.service';
import { EndUsersService } from '@app/endusers/endusers.service';
import { ChatDetailActionsComponent } from '@app/chat/chat-detail-actions/chat-detail-actions.component';
import { ChatDetailActionsService } from '@app/chat/chat-detail-actions/chat-detail-actions.service';
import { ConversationListActionsComponent } from '@app/chat/conversation-list-actions/conversation-list-actions.component';
import { ConversationListActionsService } from '@app/chat/conversation-list-actions/conversation-list-actions.service';
import { ListingsService } from '@app/listings/listings.service';
import { CoreModule } from '@app/core/core.module';
import { NavigationService } from '@app/navigation/navigation.service';
import { ClaimsGuard } from '../core/claims.guard';

@NgModule({
  imports: [
    SharedModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: 'chat',
        data: { title: 'Conversations' },
        component: ConversationListComponent,
        canActivate: [AuthenticationGuard, ClaimsGuard]
      },
      {
        path: 'chat/:id',
        data: { title: 'Conversation' },
        canActivate: [AuthenticationGuard, ClaimsGuard, ChatDetailGuard],
        component: ChatDetailComponent
      }
    ])
  ],
  declarations: [
    ConversationListComponent,
    ChatDetailComponent,
    ChatDetailActionsComponent,
    ConversationListActionsComponent
  ],
  providers: [
    ChatService,
    ChatDetailGuard,
    EntityService,
    EndUsersService,
    ChatDetailActionsService,
    ListingsService,
    ConversationListActionsService,
    NavigationService
  ],
  entryComponents: [
    ChatDetailActionsComponent,
    ConversationListActionsComponent
  ]
})
export class ChatModule { }
