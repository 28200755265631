﻿

	 import { Moment } from 'moment';
    
    export class RequestNoteDTO {
        
        
        /**
        * Unique identifier
        */
        id: number;
        
        /**
        * Note text
        */
        text: string;
        
        /**
        * Indicates if the note was created by the final (app) user
        */
        wasSentByUser: boolean;
        
        /**
        * Indicates wheather the note has been read or not
        */
        wasRead: boolean;
        
        /**
        * The date in which the note was firstly read
        */
        readOn?: Moment | null;
        
        /**
        * The date of creation
        */
        createdOn: Moment;
    }
