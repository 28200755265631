﻿

    
    export enum PushNotificationTargetScreen {
        
        
        Home = 0,
        
        Place = 1,
        
        Collection = 2,
        
        Event = 3,
        
        Concierge = 4
    }
