import { EventEmitter, Output, Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment/moment';
import { CountryDTO } from '@models/i18n/CountryDTO';
import { LanguageDTO } from '@models/i18n/LanguageDTO';
import { DevicesService } from '@app/devices/devices.service';
import { GPhoneCheckInCommandDTO } from '@models/guest-u-phone/commands/GPhoneCheckInCommandDTO';


@Component({
  selector: 'device-checkin-form',
  templateUrl: './device-checkin-form.component.html',
  styleUrls: ['./device-checkin-form.component.css']
})
export class DeviceCheckinFormComponent implements OnInit, AfterViewInit {
  /** Success event emitter */
  @Output() public onSuccess: EventEmitter<void> = new EventEmitter<void>();
  /** Failure event emitter */
  @Output() public onFailure: EventEmitter<string> = new EventEmitter<string>();
  /** Fluid layout will take all available width */
  @Input() public fluidLayout: boolean = false;
  /**
   * Independent mode will include a submit button and will handle
   * the form information submission. When false, the button will not be shown,
   * the check-in action and success/failure handlers should be called and subscribed
   * by the parent/host component
   */
  @Input() public independentMode: boolean = true;
  /** Device unique identifier, typically GPXXXX or GuestUPhoneXXXX */
  @Input() public deviceIdentifier: string;

  /** Date-pickers mode */
  public pickerMode: string = 'datetime';
  /** Indicates if data from the form is being submitted */
  public submittingData: boolean;
  /** Indicates if a set of dropdown list for Entity selection should be shown */
  public showEntitySelection: boolean;
  /** Indicates if a device selection control should be shown */
  public showDeviceSelection: boolean;
  /** Indicates if a condition check failed while loading essential component data */
  public initError: boolean;
  /** Current error message to be displayed on form */
  public errorMessage: string;
  /** Indicates if form has all fields correctly validated */
  public formIsValid: boolean = true;
  /** Indicates if the user has already tried to submit the information in the form */
  public formWasSubmitted: boolean;

  private _entityIdentifier: number;
  private _deviceIdentifier: string;
  private _devicePushToken: string;
  
  /** Current value of the form's country field */
  private _country: CountryDTO;
  /** Current value of the form's language field */
  private _language: LanguageDTO;
  /** Current value of the form's check-out field */
  private _checkOutDate: Date;

  /**
   * Check-in reactive form fields definition and validators setup
   */
  public checkInForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$")]),
    country: new FormControl(null),
    language: new FormControl(null),
    room: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.minLength(1)]),
    checkOutDate: new FormControl(new Date(), Validators.required),
    accountId: new FormControl('')
  });


  constructor(private devicesService: DevicesService) {
  }

  ngOnInit() {
  }

  public ngAfterViewInit(): void {
  }


  /**
   * Submit data for guest check-in event
   */
  public doCheckIn() {
    if (this.initError) {
      console.error('Component initialization error');
      return false;
    }

    if (!this._devicePushToken || !this._entityIdentifier) {
      console.error('Missing mandatory parameters');
      return false;
    }

    this.errorMessage = null;
    this.formWasSubmitted = true;
    
    this.formIsValid = this.checkInForm.valid;
    this.submittingData = false;
    
    if (this.formIsValid) {
      const commandDto = new GPhoneCheckInCommandDTO();
      commandDto.entityId = this.getEntity();
      // The date format and concatenation below forces an UTC/+00 date and avoids the server JSON deserialization with timezone conversions - that would lead to incorrect date storage
      // This is an exceptional case where we're not interested in having a date+tZone, just a plain date without hours being relevant
      // moment(moment.parseZone(this.guestuPhoneSelected.keys.user_new_checkout_date).format("YYYY-MM-DDT00:00:00"));
      commandDto.checkout = this.getCheckOutDate()
        ? commandDto.checkout = moment(moment.parseZone(this.getCheckOutDate()).format("YYYY-MM-DDT00:00:00")+'+00:00').utc()
        : null;
      commandDto.email = this.getEmail();
      commandDto.country = this.getSelectedCountry() ? this.getSelectedCountry().isoCode : null;
      commandDto.lang = this.getSelectedLanguage() ? this.getSelectedLanguage().isoCode : null;
      commandDto.name = this.getName();
      commandDto.email = this.getEmail();
      commandDto.roomNumber = this.room;
      commandDto.accountId = this.checkInForm.controls.accountId.value;

      this.submittingData = true;

      this.devicesService
        .sendCheckInCommand(commandDto)
        .subscribe(
          () => {
            this.onSuccess.emit();
            this.submittingData = false;
          },
          (error) => {
            console.debug(error);
            this.submittingData = false;
            this.onFailure.emit('An error has occurred while processing the check-in information');
          }
        );
      
    }
    else {
      this.errorMessage = 'Check-in form has invalid or missing information!';
    }
  }
  
  /**
   * Check-out date change handler
   * @param date Currently selected date for check-out
   */
  public onCheckOutDateChange(date) {
  }


  /**
   * Gets the current value of name field
   * @return Current form input value for name property
   */
  get entityIdentifier(): number {
    return this._entityIdentifier;
  }
  /**
   * Sets the current value of entity identifier field
   * @param value Value to set into entity identifier field
   */
  set entityIdentifier(value: number) {
    this._entityIdentifier = value;
  }
  
  /**
   * Gets the current value of device push token field
   * @return Current form input value for device push token property
   */
  get devicePushToken(): string {
    return this._devicePushToken;
  }
  /**
   * Sets the current value of device push token field
   * @param value Value to set into device push token field
   */
  set devicePushToken(value: string) {
    this._devicePushToken = value;
    //this.checkInForm.controls.pushToken.setValue(value);
  }


  /**
   * Gets the current value of name field
   * @return Current form input value for name property
   */
  get country(): CountryDTO {
    return this._country;
  }
  /**
   * Sets the current value of name field
   * @param value Value to set into field
   */
  set country(value: CountryDTO) {
    this._country = value;
    this.checkInForm.controls.country.setValue(value);
  }

  /**
   * Gets the current value of language field
   * @return Current form input value for language property
   */
  get language(): LanguageDTO {
    return this._language;
  }
  /**
   * Sets the current value of language field
   * @param value Value to set into language field
   */
  set language(value: LanguageDTO) {
    this._language = value;
    this.checkInForm.controls.language.setValue(value);
  }

  /**
   * Gets the current value of check-out field
   * @return Current form input value for check-out property
   */
  get checkOutDate(): Date {
    return this._checkOutDate;
  }
  /**
   * Sets the current value of check-out field
   * @param value Value to set into check-out field
   */
  set checkOutDate(value: Date) {
    this._checkOutDate = value;
    this.checkInForm.controls.checkOutDate.setValue(value);
  }

  /**
   * Gets the current value of room field
   * @return Current form input value for room property
   */
  get room(): string {
    return this.checkInForm.controls.room.value;
  }
  /**
   * Sets the current value of room field
   * @return Current form input value for room property
   */
  set room(value: string) {
    this.checkInForm.controls.room.setValue(value);
  }

  /**
   * Gets the current value of name field
   * @return Current form input value for name property
   */
  public getName(): any {
    return this.checkInForm.controls.name.value;
  }

  /**
   * Gets the current value of email field
   * @return Current form input value for email property
   */
  public getEmail(): any {
    return this.checkInForm.controls.email.value;
  }

  /**
   * Gets the currently selected country
   * @return Currently selected country DTO instance
   */
  public getSelectedCountry(): CountryDTO {
    return this.checkInForm.controls.country.value;
  }

  /**
   * Gets the currently selected language
   * @return Currently selected language DTO instance
   */
  public getSelectedLanguage(): LanguageDTO {
    return this.checkInForm.controls.language.value;
  }
  
  /**
   * Gets the current value of check-in field
   * @return Current form input value for check-in property
   */
  public getCheckInDate(): any {
    return this.checkInForm.controls.checkInDate.value;
  }

  /**
   * Gets the current value of check-out field
   * @return Current form input value for check-out property
   */
  public getCheckOutDate(): any {
    return this.checkInForm.controls.checkOutDate.value;
  }

  /**
   * Gets the current value of entity field
   * @return Current form input value for check-out property
   */
  public getEntity(): number {
    return this._entityIdentifier;
  }

  /**
   * Gets the current value of device identifier field
   * @return Current form input value for target device property
   */
  public getDeviceIdentifier(): string {
    return this._deviceIdentifier;
  }

  /**
   * Gets the device associated push token
   * @return Device push token value
   */
  public getDevicePushToken(): string {
    return this._devicePushToken;
  }
}
