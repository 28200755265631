<div class="ui form">
  <div id="radio" [class.grouped]="!isInline" [class.inline]="isInline" class="fields" >
    <label>{{groupLabelName}}</label>

    <div *ngFor="let item of collection; let i=index; trackBy: trackByIndex;" class="field">
      <div class="ui radio checkbox">
        <input type="radio" [value]="resolveValue(item)" [checked]="resolveChecked(item, radioGroup)" (change)="onSelectionChange(item)" >
        <label>{{resolveLabelName(item)}}</label>
      </div>
    </div>

  </div>
</div>
