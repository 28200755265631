﻿

	 import { Moment } from 'moment';
	 import { Platform } from '@models/enum/admin/Platform';
	 import { MobileOS } from '@models/enum/MobileOS';
	 import { ChatConversationDTO } from '@models/chat/ChatConversationDTO';
    
    export class ChatConversationFullDTO extends ChatConversationDTO {
        
        
        checkInDate?: Moment | null;
        
        checkOutDate?: Moment | null;
        
        platform?: Platform | null;
        
        mobileOS?: MobileOS | null;
        
        createdOn: Moment;
    }
