import { Component, Input, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'action-buttons',
  templateUrl: 'action-buttons.component.html',
  styleUrls: ['action-buttons.component.css']
})
/** action-buttons component*/
export class ActionButtonsComponent {

  /* Action button Configuration */
  @Input() @Output() public configuration: IActionButtonConfig[];

  @Input() public hasPopup: boolean;

  @ViewChild('popupCommand') popupCommand: ElementRef;

  private popupHeader: string = "Are you sure you want to execute this command?";

  //need to use <p> </p>
  private popupContent: string;

  /** action-buttons ctor */
  constructor() {

  }

  public getButtonsArray(): IActionButtonConfig[] {
    if (this.configuration) {
      return this.configuration.filter(b => b.isVisible);
    }

    return this.configuration;
  }

  private tooltipResolver(actionButton: IActionButtonConfig): string {
    return actionButton.tooltip;
  }

  private onClick(event: Event, actionBtnConfig: IActionButtonConfig) {
    event.stopPropagation();

    if (!actionBtnConfig.hasPopup) {
      actionBtnConfig.callbackResolver(event);
      return;
    }

    if (actionBtnConfig.popupHeader) {
      this.popupHeader = actionBtnConfig.popupHeader;
    }
    this.popupContent = actionBtnConfig.popupContent;
    $(this.popupCommand.nativeElement).modal('setting', 'onApprove', (elem): any => {
       actionBtnConfig.callbackResolver(event);
    });
    $(this.popupCommand.nativeElement).modal("show"); 
  }

  public trackRowByIndex(index: number, item: any): number {
    return index;
  }

}

/**
 * Describes a Action button configuration
 */
export interface IActionButtonConfig {
  index?: number;
  icon?: string | null;
  popupHeader?: string | null;
  popupContent?: string | null;
  tooltip?: string | null;
  callbackResolver?: Function | null;
  hasPopup: boolean;
  isVisible: boolean;
}

export class ActionButtonConfig implements IActionButtonConfig {
  public index: number;
  public icon: string;
  public popupHeader?: string;
  public popupContent?: string;
  public tooltip?: string;
  public callbackResolver: Function;
  public hasPopup: boolean;
  public isVisible: boolean;

  constructor(icon: string, callbackResolver: Function, popupHeader?: string, popupContent?: string, tooltip?: string, hasPopup: boolean = true, isVisible: boolean = true) {
    this.icon = icon;
    this.popupHeader = popupHeader;
    this.popupContent = popupContent;
    this.tooltip = tooltip;
    this.callbackResolver = callbackResolver;
    this.hasPopup = hasPopup;
    this.isVisible = isVisible;
  }

}
