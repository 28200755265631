<div class="ui grid">
  <!-- Don't change order of [class.x] attributes, otherwise SemanticUI will not apply the correct columns number -->
  <div (click)="onClick($event)" [class.one]="!hasLabel" [class.two]="hasLabel" [class.column]="true" class="unpadded row" [class.clickable]="isClickable()" >
    <ng-container *ngIf="hasLeftIconClass()">
      <div [attr.data-tooltip]="tooltipLeft" class="left aligned unpadded fourteen wide mobile fourteen wide tablet eleven wide computer twelve wide large screen fourteen wide widescreen column icon-right">
        <div>
          <ng-container *ngIf="!hasImage()">
            <i class="ui large" [ngClass]="iconClass">
              <ng-container *ngIf="hasText()">
                <span style="padding-left: 5px;"> {{text | EmptyField}} </span>
              </ng-container>
            </i>
          </ng-container>
          <ng-container *ngIf="hasImage()">
            <img class="ui bottom aligned spaced smaller image" src="{{imageSource}}" alt="icon">
            <span class="middle aligned ellipsisText">{{text}}</span>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div *ngIf="hasLabel" [attr.data-tooltip]="tooltipRight" class="right aligned unpadded two wide mobile two wide tablet five wide computer four wide large screen two wide widescreen column">
      <ng-container *ngIf="!hasRightIconClass()">
        <div class="ui red mini horizontal label" [class.circular]="labelIsCircular">{{labelValue}}</div>
      </ng-container>
      <ng-container *ngIf="hasRightIconClass()">
        <i style="margin: 0;" class="ui large" [ngClass]="rightIconClass"></i>
      </ng-container>
    </div>
  </div>
</div>
