<div class="ui {{cssClasses}} breadcrumb">
  <ng-container *ngFor="let section of sections; let last = last">
    <sui-breadcrumb-section
      [text]="section.text"
      [isLink]="section.isLink"
      [routerNavigationUrl]="section.routerNavigationUrl">
    </sui-breadcrumb-section>
    <ng-container *ngIf="!last">
      <ng-container *ngIf="!dividerIsIcon">
        <span class="divider">{{this.dividerValue}}</span>
      </ng-container>
      <ng-container *ngIf="dividerIsIcon">
        <i class="{{this.dividerValue}} icon divider"></i>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
