﻿

	 import { LocationDTO } from '@models/LocationDTO';
	 import { Moment } from 'moment';
    
    export class AirportDTO {
        
        
        /**
        * Airport unique identifier.
        */
        id?: number | null;
        
        /**
        * The IATA airport code, also known as an IATA location identifier, IATA station code or simply a location identifier,[1] is a three-letter code designating many airports around the world
        */
        iata: string;
        
        /**
        * The ICAO airport code or location indicator is a four-letter code designating aerodromes around the world
        */
        icao: string;
        
        /**
        * Airport name.
        */
        name: string;
        
        /**
        * Airport's geographical location name.  Can be a country, state, city, ...  <remarks>  Only applicable to entities of the following types: Entity, Group Member and Hospitality  </remarks>
        */
        location: LocationDTO;
        
        /**
        * Indicates whether the Airport is marked as deleted or not.
        */
        isDeleted: boolean;
        
        /**
        * User that created the Airport
        */
        createdBy: number;
        
        /**
        * Date and time of Airport creation
        */
        createdOn: Moment;
        
        /**
        * User that last updated the Airport
        */
        updatedBy: number;
        
        /**
        * Date and time of Airport update
        */
        updatedOn: Moment;
    }
