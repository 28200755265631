﻿

	 import { RequestState } from '@models/enum/request/RequestState';
    
    /**
    * Requests update state data transfer object class
    */
    
    export class RequestUpdateStateDTO {
        
        
        /**
        * Request state
        */
        state: RequestState;
    }
