import { Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { of, Observable } from 'rxjs';
import { environment } from '@env/environment';

// Cool library to deal with errors: https://www.stacktracejs.com
import * as StackTraceParser from 'error-stack-parser';
import { HttpErrorResponse } from '@angular/common/http';

// TODO: Implement this logging @ server!!!!
@Injectable()
export class ErrorsService {
  constructor(private injector: Injector) {
  }

  public log(error): Observable<any> {
    // Log the error to the console
    if (environment.dev) {
      console.dir(error);
    }
    // Send error to server
    const errorToSend = this.addContextInfo(error);
    // TODO: Implement/Connect
    return of(errorToSend);// fakeHttpService.post(errorToSend);
  }

  private addContextInfo(error) {
    // All the context details that you want (usually coming from other services; Constants, UserService...)
    const name = error.name || null;
    const appId = 'GuestU.OPS';
    const user = 'unknown';
    const time = new Date().getTime();
    const id = `${appId}-${user}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
    const errorToSend = { name, appId, user, time, id, url, status, message, /*stack*/ };
    return errorToSend;
  }
}
