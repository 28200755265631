import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sui-editable-label',
  templateUrl: 'sui-editable-label.component.html',
  styleUrls: ['sui-editable-label.component.css']
})

export class EditableLabelComponent implements OnInit {

  /* Value Change */
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  @Input() public click: Function;
  @Input() public blur: Function;

  @Input() public placeholder: string;

  @Input() public useDefaultValue: boolean = false;

  public inEditMode: boolean = false;
  private defaultLabelTextValue: string = "click to edit";
  private oldInputValueLocal: string;

  /**
  * inputValue [()]
  */
  private inputValueLocal: string;
  @Input()
  set inputValue(val) {
    this.inputValueLocal = val;
    this.inputValueChange.emit(this.inputValueLocal);
  }

  get inputValue() {
    return this.inputValueLocal;
  }
  @Output() public inputValueChange: EventEmitter<any> = new EventEmitter();

  ngOnInit() {

  }

  public getLabelTextValue(): string {
    if (this.useDefaultValue && (!this.inputValueLocal || (this.inputValueLocal && this.inputValueLocal.length <= 0))) {
      return this.defaultLabelTextValue;
    }
    return this.inputValueLocal;
  }

  public onKeyEnter(event: KeyboardEvent) {
    this.inEditMode = false;
  }

  public onClick(event: Event) {
    this.inEditMode = true;
    this.oldInputValueLocal = this.inputValueLocal;
    if (this.click) {
      this.click();
    }
  }

  public onBlur(event: FocusEvent) {
    this.inEditMode = false;
    this.emitValueChange();
    if (this.blur) {
      this.blur();
    }
  }

  private emitValueChange() {
    if (this.oldInputValueLocal !== this.inputValueLocal) {
      this.valueChange.emit(this.inputValue);
    }
  }

  public onDomChange(event: MutationRecord): void {
    if (event.attributeName === "hidden") {
      const element: Element = <Element>event.target;
      const childs = element.getElementsByTagName('input');

      if (childs.length > 0) {
        const input: HTMLInputElement = childs.item(0);
        input.focus();
      }
    }
  }

  private errorHandle(error: any): void {
    console.log(error);
  }

  private removeOnClick(event: Event) {
    this.inputValue = "";
    event.stopPropagation();
  }

}
