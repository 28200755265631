import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationGuard } from './core/authentication-guard.service';
import { AuthLoginComponent } from './authentication/login.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
          { path: 'login', component: AuthLoginComponent, data: { title: 'GuestU OPS'} },
          { path: '', redirectTo: 'dashboard', pathMatch: 'full', data: { title: 'Dashboard'}, canActivate: [AuthenticationGuard] },
          { path: '**', redirectTo: 'login', pathMatch: 'full' }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
