﻿

	 import { SearchFilter } from '@models/search-filters/SearchFilter';
    
    export class AppRequestTypeSearchFilter extends SearchFilter {
        
        
        applicationId?: number | null;
        
        requestTypeId?: number | null;
        
        isListed?: boolean | null;
    }
