﻿

	 import { Moment } from 'moment';
    
    export class GroupLocationDTO {
        
        
        /**
        * Entity unique identifier.
        */
        id?: number | null;
        
        /**
        * Entity name.
        */
        name: string;
        
        /**
        * The Entity of <see cref="F:AppGenerator.Business.Enum.Entity.EntityType.GROUP" /> which this Group Location belong
        */
        group?: number | null;
        
        /**
        * Type of Group Location.
        */
        locationType: number;
        
        /**
        * Group Location's parent Group Location.
        */
        parentLocation: GroupLocationDTO;
        
        /**
        * Default Language used to represent this entity instance
        */
        defaultLanguage?: number | null;
        
        /**
        * Indicates whether the Entity is marked as deleted or not.
        */
        isDeleted: boolean;
        
        /**
        * User that created this entity
        */
        createdBy: string;
        
        /**
        * Date and time of this entity creation
        */
        createdOn: Moment;
        
        /**
        * User that last updated this entity
        */
        updatedBy: string;
        
        /**
        * Date and time of this entity update
        */
        updatedOn: Moment;
    }
