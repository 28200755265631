﻿

    
    export enum FileExtension {
        
        
        Csv = "Csv",
        
        Xlsx = "Xlsx",
        
        Pdf = "Pdf",
        
        Txt = "Txt"
    }
