﻿


    
    export class SurveysAveragesDTO {
        
        
        /**
        * Average 7 days
        */
        average7Days: number;
        
        /**
        * Average 30 days
        */
        average30Days: number;
        
        /**
        * Average 3 Month
        */
        average3Month: number;
        
        /**
        * Entity name
        */
        name: string;
    }
