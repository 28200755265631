import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';

const appTitle = 'GuestU OPS';
const separator = ' / ';

@Injectable()
export class TitleService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {
  }

  public init() {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => route.firstChild),
        switchMap((route: ActivatedRoute) => route.data),
        map((data: any) => {
          if (data.title) {
            // If a route has a title set (e.g. data: {title: "Foo"}) then we use it
            return data.title;
          } else {
            // If not, we do a little magic on the url to create an approximation
            return this.router.url.split('/').reduce((acc, frag) => {
              if (acc && frag) {
                 acc += separator;
              }

              return this.router.url.split('/')
                .reduce((acc, frag) =>
                {
                  if (acc && frag) {
                     acc += separator;
                  }
                  return acc + TitleService.ucFirst(frag);
                });
            });
          }
        })
      )
      .subscribe((pathString) => this.setTitle(`${appTitle} | ${pathString}`));
  }

  static ucFirst(string) {
    if (!string) {
       return string;
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  public getTitle(): string {
    return this.titleService.getTitle();
  }

  public setTitle(newTitle: string) {
    const currentTitle = this.getTitle();
    const counterValueRegEx = new RegExp('\\((\\d+)\\)');

    if (currentTitle.startsWith('(')) {
      const notificationsCount = parseInt(currentTitle.replace(counterValueRegEx, '$1'));
      this.setNotificationsCount(notificationsCount);
    }
    else {
      this.titleService.setTitle(newTitle);
    }
  }

  public prependToTitle(text: string, separator: string = ' ') {
    const newTitle = text + separator + this.getTitle();
    this.titleService.setTitle(newTitle);
  }

  public setNotificationsCount(count: number) {
    const currentTitle = this.getTitle();

    if (count && count > 0) {
      if (currentTitle.startsWith('(')) {
        const replaceCountRegEx = new RegExp('\\(\\d+\\)');
        const newTitle = currentTitle.replace(replaceCountRegEx, '(' + count.toString() + ')');
        this.titleService.setTitle(newTitle);
      }
      else {
        this.prependToTitle(`(${ count })`);
      }
    }
    else {
      const removeCounterRegEx = new RegExp('\\(\\d+\\) +');
      const newTitle = currentTitle.replace(removeCounterRegEx, '');
      this.titleService.setTitle(newTitle);
    }
  }
}
