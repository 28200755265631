﻿


    
    export class RequestItemDTO {
        
        
        /**
        * Item key
        */
        key: string;
        
        /**
        * Item value
        */
        value: string;
        
        /**
        * Item code
        */
        code: string;
    }
