import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sui-message',
  templateUrl: './sui-message.component.html',
  styleUrls: ['./sui-message.component.css']
})
export class MessageComponent implements OnInit {
  public config: IMessageComponentConfiguration;

  @Input()
  public set configuration(config: IMessageComponentConfiguration) {
    this.config = Object.assign({}, this.configurationDefaults, config);
  }
  public get configuration() {
    return this.config;
  }

  private configurationDefaults = {
    type: null,
    header: null,
    messages: null,
    hidden: false,
    floating: false,
    compact: false,
    attached: false,
    showIcon: false,
    iconCssClass: null,
  }

  constructor() {
    this.config = this.configurationDefaults;
  }

  ngOnInit() {
  }

  hasSingleMessage() {
    return this.configuration.messages && this.configuration.messages.length === 1;
  }

  hasMultipleMessages() {
    return this.configuration.messages && this.configuration.messages.length > 1;
  }

  getCssClasses() {
    let cssClasses: any = {
      hidden: this.configuration.hidden,
      floating: this.configuration.floating,
      compact: this.configuration.compact,
      attached: this.configuration.attached
    };

    if (this.configuration.type) {
      cssClasses[MessageType[this.configuration.type]] = true;
    }

    return cssClasses;
  }
}

export enum MessageType {
  warning = 'warning',
  info = 'info',
  success = 'success',
  error = 'error'
}

export interface IMessageComponentConfiguration {
  type?: MessageType | null;
  header: string;
  messages: string[];
  hidden: boolean;
  floating: boolean;
  compact: boolean;
  attached: boolean;
  showIcon: boolean;
  iconCssClass: string;
}

export class MessageComponentConfiguration implements IMessageComponentConfiguration {
  public type?: MessageType | null;
  public header: string;
  public messages: string[];
  public hidden: boolean;
  public floating: boolean;
  public compact: boolean;
  public attached: boolean;
  public showIcon: boolean;
  public iconCssClass: string;
}
