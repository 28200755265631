﻿

    
    /**
    * Prefix values for Json Handler cache keys creation.
    */
    
    export enum JsonHandlerCachePrefix {
        
        
        ApplicationBaseConfiguration = "ApplicationBaseConfiguration",
        
        Calendar = "Calendar",
        
        RouteListing = "RouteListing",
        
        RouteDescription = "RouteDescription",
        
        RoadbookListing = "RoadbookListing",
        
        Translationlabels = "Translationlabels",
        
        Schedules = "Schedules",
        
        Events = "Events",
        
        Entities = "Entities",
        
        EntityLocations = "EntityLocations",
        
        LocationEntities = "LocationEntities",
        
        Points = "Points",
        
        GameResults = "GameResults",
        
        ConciergePredefinedMessages = "ConciergePredefinedMessages",
        
        GeoPlaces = "GeoPlaces",
        
        ScreenViewGenericContents = "ScreenViewGenericContents",
        
        ActionGameLevels = "ActionGameLevels",
        
        Campaigns = "Campaigns",
        
        ProductCategories = "ProductCategories",
        
        DeliverySchedules = "DeliverySchedules",
        
        GameRanking = "GameRanking",
        
        UserActions = "UserActions",
        
        CountryList = "CountryList"
    }
