﻿

	 import { ChatUserDTO } from '@models/chat/ChatUserDTO';
    
    export class ChatUserRegistrationDTO extends ChatUserDTO {
        
        
        accessToken: string;
        
        assignedPassword: string;
    }
