import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AuthenticationGuard } from '@app/core/authentication-guard.service';

import { GuestsFeedbackComponent } from './guests-feedback.component';
import { SurveysService } from '@app/surveys/surveys.service';
import { StatisticsService } from '@app/statistics/statistics.service';
import { SuiDatepickerModule } from 'ng2-semantic-ui';
import { ClaimsGuard } from '../core/claims.guard';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    SuiDatepickerModule,
    RouterModule.forChild([
      {
          path: 'guests-feedback',
          data: { title: 'Guests Feedback' },
          component: GuestsFeedbackComponent,
          canActivate: [AuthenticationGuard, ClaimsGuard]
      }
    ])
  ],
  declarations: [
    GuestsFeedbackComponent
  ],
  providers: [
    SurveysService,
    StatisticsService
  ]
})
export class SurveysModule {}
