import { throwError,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '../core/authentication.service';
import {
  ChatUserFullDTO
} from '@models/chat';

// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.core + 'endusers';

@Injectable()
export class EndUsersService {
  constructor(private httpClient: HttpClient, private authService: AuthenticationService) {
  }

  public updateDemo(userId: number, isDemo: boolean) : Observable<ChatUserFullDTO> {
    const httpOptions =  new HttpHeaders({ 'Content-Type':  'application/json' });

    return this.httpClient
      .patch<ChatUserFullDTO>(API_BASE_URL + '/' + userId + '/demo', isDemo, { headers: httpOptions })
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }
}
