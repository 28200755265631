import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { AppHeaderService, BreadcrumbSection } from '@app/shared/app-header/app-header.service';
import { StatisticsService } from '@app/statistics/statistics.service';
import { Subscription } from 'rxjs';
import {
  DashboardDTO
} from '@models/statistics';
import { AppNotifierService } from '@app/notifications/app-notifier/app-notifier.service';
import { AuthenticationService } from '@app/core/authentication.service';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.css']
})

/** dashboard component*/
export class DashboardComponent implements OnInit, OnDestroy {

  public dashboard: DashboardDTO;
  // Actions component (chat service state, global and for active users) all Subscriptions
  private subscription = new Subscription();
  //dashboard list
  private getLastDashboardDelay: number = 30000; // in milliseconds
  private getLastDashboardIntervalId: any = null;

  // > Dimmers
  @ViewChild('componentDimmer') componentDimmer: ElementRef;

  constructor(public authService: AuthenticationService, private statisticsService: StatisticsService, private appHeaderService: AppHeaderService,
    private appNotifierService: AppNotifierService) {
    this.appHeaderService.resetBreadcrumb(new BreadcrumbSection('Dashboard', ['/dashboard']));
  }

  ngOnInit() {
    this.subscription.add(this.authService.selectedEntity$.subscribe((entity) => this.getDashboard()));
  }

  ngOnDestroy(): void {
    // dashboard polling for active/opened requests
    clearTimeout(this.getLastDashboardIntervalId);
    this.getLastDashboardIntervalId = null;
    // Unsubscriptions
    this.subscription.unsubscribe();
  }

  private getDashboard(): void {

    this.setComponentDimmerVisibility(true);

    this.subscription.add(this.statisticsService
      .getDashboard()
      .subscribe((response) => {
        this.dashboard = response;
          this.appNotifierService.notify(null);
      },
        (error: any) => {
          this.handleErrors(error);
          throw(error);
        },
        () => {
          this.loopGetLastDashboard();
          this.setComponentDimmerVisibility(false);
        }
      ));
  }

  private loopGetLastDashboard(): void {
    if (!!this.getLastDashboardIntervalId) {
      clearTimeout(this.getLastDashboardIntervalId);
    }

    this.getLastDashboardIntervalId = setTimeout(() => this.getDashboard(), this.getLastDashboardDelay);
  }

  // Component 
  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement =
      $(this.componentDimmer.nativeElement)
        .dimmer({
          duration: { show: 400, hide: 800 }
        }
        );

    if (visible) {
      dimmerElement.dimmer('show');
    } else {
      dimmerElement.dimmer('hide');
    }
  }

  // Error Handling
  private handleErrors(error: any): void {
    console.log(error);
    this.setComponentDimmerVisibility(false);
  }

}
