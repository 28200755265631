﻿

	 import { Moment } from 'moment';
    
    export class ChatMessageDTO {
        
        
        /**
        * Chat message unique identifier.
        */
        id: number;
        
        /**
        * Chat message text
        */
        message: string;
        
        /**
        * Indicates if message was sent by the chat user or not
        */
        isFromEndUser?: boolean | null;
        
        /**
        * Creation date and time
        */
        createdOn?: Moment | null;
        
        /**
        * Last update date and time
        */
        lastUpdatedOn?: Moment | null;
    }
