﻿


    
    export class TripProfileDTO {
        
        
        /**
        * Entity unique identifier.
        */
        id: number;
        
        /**
        * Entity name.
        */
        name: string;
    }
