﻿

    
    export enum HospitalityType {
        
        
        Unknown = "Unknown",
        
        Hotel = "Hotel",
        
        Hostel = "Hostel",
        
        Apartment = "Apartment"
    }
