import { Component, OnInit, Input } from '@angular/core';

export interface IBreadcrumbSection {
  isLink: boolean | null;
  text: string | null;
  /** Array should be valid for Router's navigation method */
  routerNavigationUrl: any[] | null;
  markedAsActive: boolean | null;
}

export class BreadcrumbSection implements IBreadcrumbSection {
  public isLink: boolean;
  public text: string;
  public routerNavigationUrl: any[] | null;
  public markedAsActive: boolean;

  constructor(text: string, routerNavigationUrl: any[]) {
    this.isLink = true;
    this.text = text;
    this.routerNavigationUrl = routerNavigationUrl;
    this.markedAsActive = false;
  }
}

@Component({
  selector: 'sui-breadcrumb',
  templateUrl: 'sui-breadcrumb.component.html',
  styleUrls: ['sui-breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  @Input() dividerIsIcon: boolean;
  @Input() dividerValue: string;
  @Input() cssClasses: string;

  public sections: Array<IBreadcrumbSection> = [];
  
  constructor() {
  }

  ngOnInit() {
  }

  public resetBreadcrumb(section: IBreadcrumbSection) {
    // A fast way to delete all items and keep ref. to array
    this.sections.splice(0, this.sections.length);
    this.pushSection(section);
  }

  public pushSection(section: IBreadcrumbSection) {
    this.sections.push(section);
  }

  public popSection(): IBreadcrumbSection {
    return this.sections.pop();
  }
}
