import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationService } from '../core/authentication.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const API_INTEGRATIONS_URL: string = environment.apis.integrations;

@Injectable()
export class IntegrationsService {
  constructor(private httpClient: HttpClient, private authService: AuthenticationService) {
  }

  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }

  public runStaysSync() {
    const entityIdentifier = this.authService.getUserSelectedEntityId();

    if (!entityIdentifier) {
      return throwError(Error('Invalid call parameters - missing Entity identifier'));
    }

    return this.httpClient
      .get(API_INTEGRATIONS_URL + 'events/stays/sync?entityId=' + entityIdentifier)
      .pipe(
        catchError(e => this.handleError(e))
      );
  }


}
