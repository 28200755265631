import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sui-input',
  templateUrl: 'sui-input.component.html',
  styleUrls: ['sui-input.component.css']
})

export class InputComponent implements OnInit {

  @Output() public blur: EventEmitter<any> = new EventEmitter();
  @Output() public onChangeComplete: EventEmitter<void> = new EventEmitter();

  @Input() placeholder: string;

  private autofocus: boolean;

  /**
  * inputValue [()]
  */
  inputValueLocalOld: string;
  inputValueLocal: string;
  @Input()
  set inputValue(val) {
    this.inputValueLocal = val;
    this.inputValueChange.emit(this.inputValueLocal);
  }

  get inputValue() {
    return this.inputValueLocal;
  }
  @Output() inputValueChange: EventEmitter<any> = new EventEmitter();

  ngOnInit() {

  }

  onBlur(event: FocusEvent) {
    if (this.blur) {
      //check if the remove icon is hover, if NO, we call the blur event
      let elem = event.srcElement.nextElementSibling.matches(":hover");
      if (elem) {
        (<HTMLInputElement>event.currentTarget).focus();
      } else {
        this.blur.emit(event);
        if (this.inputValueLocalOld !== this.inputValueLocal) {
          this.inputValueLocalOld = this.inputValueLocal;
          this.onChangeComplete.emit();
        }
      }
    }
  }

  private errorHandle(error: any): void {
    console.log(error);
  }

  public removeOnClick(event: Event) {
    event.stopPropagation();

    this.inputValue = null;
    this.inputValueLocalOld = this.inputValueLocal;
    this.onChangeComplete.emit();
  }

  public showRemove(): boolean {
    return !this.inputValue || this.inputValue === "" && this.inputValue.length <= 0;
  }
}
