/*
    @Service
        Navigation service
    @Description
        Service class for navigation related operations and events.
    @Notes
        -
*/
import { Injectable } from '@angular/core';

@Injectable()
export class  NavigationService {
}
