  <!-- Info area, grid -->
  <div class="ui blurring dimmable grid">

    <!--Begin Container-->
    <div class="row" *ngIf="configurationDTO">
      <!-- left side -->
      <div class="eight wide column">
        
        <h4 class="ui top attached header">
          <div><i class="external alternate icon"></i>Settings</div>
        </h4>
        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">
            
            <div class="doubling two column row">

              <div class="column">
                <div class="ui tiny header">Search Interval (days)</div>
                <sui-number-input [placeholder]="'Search Interval (days)'"
                           [(inputValue)]="configurationDTO.searchInterval"
                           (onChangeComplete)="save()"
                           (keydown.enter)="save()">
                </sui-number-input>
              </div>
  
              <div class="column">
              </div>

            </div>

          </div>
        </div>

      </div>

    </div>
  
    <!--Finish Container-->
    <!-- dimmer -->
    <div #componentDimmer class="ui inverted dimmer">
      <div class="ui loader"></div>
    </div>
  
  </div>
  
