export class AppearanceConfigurationDTO {
    id: number;
    overrideGroupAppearanceSettings: boolean;
    homeScreenBackgroundColor: string;
    homeScreenHeaderColor: string;
    homeScreenMainColor: string;
    homeScreenSecondaryColor: string;
    homeScreenMainTextColor: string;
    contentPageBackgroundColor: string;
    contentPageHeaderColor: string;
    contentPageMainColor: string;
    contentPageSecondaryColor: string;
    contentPageMainTextColor: string;
    headerFontFamilyId: number | null;
    headerFontWeight: number | null;
    headerFontItalic: boolean;
    bodyFontFamilyId: number | null;
    bodyFontWeight: number | null;
    bodyFontBoolean: boolean;
}
