﻿



export class VoiceConfigurationDTO {


    enablePBX: boolean;

    hasPushes: boolean;

    hasKeyPad: boolean;

    hasCallReception: boolean;

    ipPBX: string;

    receptionPBX: string;

    wifiPBX: string;

    countryCodesPBX: string;

    doNotDisturbEnable: string;

    doNotDisturbDisable: string;
}
