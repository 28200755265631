﻿


    
    export class CountryDTO {
        
        
        /**
        * Country unique identifier.
        */
        id: number;
        
        /**
        * Country international name
        */
        name: string;
        
        /**
        * Country ISO31366-1 alpha2 code
        */
        isoCode: string;
    }
