<div class="ui grid">
  <div *ngIf="totalAverageDto" style="margin-top: 30px;" class="centered column two ui wide">
    <div class="ui centered grid">
      <div class="ui statistic">
        <div class="value">{{totalAverageDto?.total}}</div>
        <div class="label">Reviews</div>
      </div>

      <div class="ui statistic">
        <div class="value">{{totalAverageDto?.average}}</div>
        <div class="label">Points</div>
      </div>
    </div>
  </div>
  <div class="fourteen wide column">
    <chart style="height: 200px;"
           [type]="chartData.type"
           [datasets]="chartData.data.datasets"
           [labels]="chartData.data.labels"
           [options]="chartData.options">
    </chart>
  </div>
</div>
