<div class="ui vertically padded blurring dimmable stackable grid" [class.one]="fluidLayout" [class.two]="!fluidLayout" [class.column]="fluidLayout || !fluidLayout">
  <div class="ui column">
    <form class="ui form" [formGroup]="checkInForm" [class.loading]="submittingData" *ngIf="!initError">
      <div class="field" *ngIf="showDeviceSelection">
        <label>Device</label>
        <input type="text" name="device" placeholder="Device" formControlName="device" />
        <input type="hidden" formControlName="pushToken" />
      </div>
      <h4 class="ui dividing header">Guest</h4>
      <div class="required field" [class.error]="formWasSubmitted && checkInForm.controls.name.errors">
        <label>Name</label>
        <input type="text" name="name" placeholder="Name" formControlName="name" />
      </div>
      <div class="required field" [class.error]="formWasSubmitted && checkInForm.controls.email.errors">
        <label>Email</label>
        <input type="text" name="email" placeholder="Email address" formControlName="email" />
        <div *ngIf="formWasSubmitted && checkInForm.controls.email.errors?.pattern" class="ui tiny negative message">
          <p>Invalid email address</p>
        </div>
      </div>
      <div class="field">
        <label>Country</label>
        <countries-dropdown [(selectedCountry)]="country" [loadDataImmediately]="true"></countries-dropdown>
      </div>
      <div class="field">
        <label>Preferred language</label>
        <languages-dropdown [(selectedLanguage)]="language" [loadDataImmediately]="true"></languages-dropdown>
      </div>
      <h4 class="ui dividing header">Stay</h4>
      <div class="required field" [class.error]="formWasSubmitted && checkInForm.controls.room.errors">
        <label>Room</label>
        <input type="text" name="room" placeholder="Room number/name" formControlName="room" readonly="readonly" />
      </div>
      <div class="required field" [class.error]="formWasSubmitted && checkInForm.controls.checkOutDate.errors">
        <label>Check-out date</label>
        <date-picker [(date)]="checkOutDate" placeholder="Departure" pickerMode="date"></date-picker>
      </div>
      <div class="field" [class.error]="formWasSubmitted && checkInForm.controls.accountId.errors">
        <label>Account ID</label>
        <input type="text" name="accountId" placeholder="PMS Account Id" formControlName="accountId" />
      </div>
      <div class="ui negative message" *ngIf="errorMessage">{{errorMessage}}</div>
      <button *ngIf="independentMode" (click)="doCheckIn()" class="ui button" [class.loading]="submittingData" [class.disabled]="submittingData" type="submit" name="checkin-button">Check-In</button>
    </form>
  </div>
</div>
