﻿

    
    /**
    * Content Type enum.  Maps to DB identity values - [content].[Type] table.
    */
    
    export enum ContentType {
        
        
        /**
        * Content is a Generic Content
        */
        Generic = "Generic",
        
        /**
        * Content is a Place
        */
        Place = "Place",
        
        /**
        * Content is a Collection
        */
        Collection = "Collection",
        
        /**
        * Content is an Event
        */
        Event = "Event"
    }
